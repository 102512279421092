import { PRODUCT_SEARCH_INFO_MESSAGE } from '../../../utils/constants';
import { SMetaData, SReturnItem } from './types';
import { SReducerState as SSalesReturn } from './reducers/types';
import {
  ColumnType,
  RowMapType,
  SearchProductType,
} from '../../../components/Common/ProductSearchField/types';
import { formattedRupee, GlobalAppUtils, truncate } from '../../../utils/util';
import styles from '../../BillEntry/features/BillingTable/table.module.scss';

export const TableColumns = [
  { name: '#' },
  {
    name: 'Product',
    info: PRODUCT_SEARCH_INFO_MESSAGE,
  },
  { name: 'Batch No.' },
  { name: 'Returned Qty.' },
  { name: 'Accepted Qty.' },
  { name: 'Damaged Qty' },
  { name: 'Missing Qty' },
  { name: 'Wrong Qty.' },
  { name: '' },
];

export const INITIAL_STATE = {
  SItem: (): SReturnItem => ({
    index: 0,
    checked: false,
    batchId: '',
    batchNo: '',
    id: '',
    name: '',
    orderedQty: 0,
    invoicedQty: 0,
    acceptedQty: 0,
    wrongQty: 0,
    missingQty: 0,
    damagedQty: 0,
    uniqueId: '',
  }),
  SSalesReturn: (): SSalesReturn => ({
    rows: [INITIAL_STATE.SItem()],
    client: {
      focusedRow: 0,
      focusedColumn: 0,
      isBillValid: true,
      searchProducts: {
        isOpen: false,
        items: [],
        isFetching: false,
      },
    },
  }),
};

export const PRODUCT_SEARCH_TABLE_CONST: { columns: ColumnType[]; rowMapping: RowMapType[] } = {
  columns: [
    { key: 'Product' },
    { key: 'MRP' },
    { key: 'Stock' },
    { key: 'U.Ratio' },
    { key: 'Rack' },
    { key: 'Pack' },
    { key: 'Manufacturer' },
  ],
  rowMapping: [
    { key: 'name', width: '200', align: 'left' },
    {
      key: 'mrp',
      width: '90',
      align: 'left',
      formatter: (value) => formattedRupee(value as number),
      className: styles.productMrp,
    },
    {
      key: 'stock',
      width: '80',
      align: 'left',
      formatter: (value, row) =>
        GlobalAppUtils.getUnitStock(value as string, (row as SearchProductType).unitRatio),
    },
    { key: 'unitRatio', align: 'left' },
    { key: 'rack', align: 'left' },
    {
      key: 'packaging',
      align: 'left',
      formatter: (value) => GlobalAppUtils.formatPackaging(value as string),
      width: '90',
    },
    {
      key: 'manufacturer',
      width: '150',
      align: 'left',
      formatter: (value) => truncate((value || '') as string),
    },
  ],
};

export const BOX_CONDITION_OPTIONS = [
  {
    label: 'Un-Damaged',
    key: 'undamaged',
  },
  {
    label: 'Damaged',
    key: 'damaged',
  },
];

export const BILL_TYPE = {
  invoice: 'invoice',
};

export const INITIAL_META_DATA: SMetaData = {
  id: '',
  suggestionId: '',
  rxOrder: false,
  returns: [],
  reason: '',
};
