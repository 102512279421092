import { Autocomplete } from '@mui/material';
import TextField from '../../../../components/FormControl/TextField';
import React from 'react';
import { CustomerLoyaltyService } from '../../../../services/api/endpoints/customerService';
import { Props, RProfile } from './types';

const CustomerProfileSearch: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [profileList, setProfileList] = React.useState<RProfile[]>([]);

  React.useEffect(() => {
    setLoading(true);
    CustomerLoyaltyService.list()
      .then((response) => {
        const responseData = response.data.data.items;
        setProfileList(
          responseData.map((item) => ({
            id: item.id,
            name: item.name,
            color: item.color,
          })),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const selectedValue = profileList.find((item) => item.id === props.value);
  return (
    <Autocomplete
      options={profileList}
      value={selectedValue || null}
      onChange={(e, value) => {
        props.onChange(value?.id || '', value?.name || '');
      }}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || ''}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label='Select Profile'
          variant='standard'
          InputProps={{
            ...params.InputProps,
            style: { color: selectedValue?.color || '' },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <p style={{ color: option.color }}>{option.name}</p>
        </li>
      )}
    />
  );
};

export default CustomerProfileSearch;
