import React, { useCallback } from 'react';

const useBoolean = (initialValue = false) => {
  const [value, setValue] = React.useState(initialValue);

  const toggle = useCallback(() => setValue((v) => !v), []);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const set = useCallback((v: boolean) => setValue(v), []);

  return {
    value,
    toggle,
    setTrue,
    setFalse,
    set,
  };
};

export default useBoolean;
