import React, { PropsWithChildren, useState } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { localStore } from '../services/browserStorage';

interface ThemeContextType {
  setThemeName: (themeName: string) => void;
}

export const ThemeContext = React.createContext<ThemeContextType>({} as ThemeContextType);

interface ThemeProps {
  children: JSX.Element;
}

const ThemeProviderWrapper: React.FC<PropsWithChildren<ThemeProps>> = (props) => {
  const curThemeName = localStore.get('appTheme') || 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    localStore.set('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    // https://mui.com/material-ui/guides/interoperability/#css-injection-order-3
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={{ setThemeName }}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
