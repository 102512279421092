import { playNotificationSound } from '../../utils/util';

export const initFirebaseServiceWorker = async () => {
  const swPath = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;

  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(swPath);
      console.log('FireBase Service worker registered!', registration.scope);
    } catch (error) {
      console.error('Service worker registration failed', error);
    }

    // In Background handle only order notification
    navigator.serviceWorker.onmessage = (event) => {
      event.data.notificationSoundUrl && playNotificationSound(event.data.notificationSoundUrl);
    };
  }
};
