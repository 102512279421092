import styles from './table.module.scss';
import { MRTPaginationType } from './types';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { StoreUtils } from '../../../utils/util';

export const defaultTableStyles = {
  defaultColumn: {
    maxSize: 120,
    minSize: 10,
    size: 80,
  },
  localization: {
    noRecordsToDisplay: 'No records to display for selected time period.',
  },
  muiTableProps: {
    className: styles.table,
  },
  muiTablePaperProps: {
    elevation: 0,
  },
  muiTopToolbarProps: {
    className: styles.topToolbar,
  },
  muiBottomToolbarProps: {
    className: styles.bottomToolbar,
  },
  muiTableHeadRowProps: {
    className: styles.tableHeaderRow,
  },
  muiTableHeadCellProps: {
    className: styles.tableHeaderCell,
  },

  muiSearchTextFieldProps: {
    className: styles.searchTextField,
  },
};

export const hideActionStyles = {
  muiTableHeadCellProps: {
    sx: {
      display: 'none',
    },
  },
  muiTableBodyCellProps: {
    sx: {
      display: 'none',
    },
  },
};

export const initialPagination = (): MRTPaginationType => ({
  pageIndex: 0,
  pageSize: StoreUtils.getKeyFromActiveSession(['config', 'defaultPageSize']) || DEFAULT_PAGE_SIZE,
});
