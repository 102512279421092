import React from 'react';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { Props } from './types';
import styles from './dialogBox.module.scss';
import Icon from '../../FormControl/Icon';

const DialogBox: React.FC<Props> = ({ open, title, subtitle, children, onClose }) => (
  <Dialog
    maxWidth={false}
    className={styles.dialog}
    open={open}
    aria-labelledby='1p-dialog'
    onClose={onClose}
  >
    <Box>
      {title || subtitle ? (
        <Stack
          className={styles.header}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box>
            {title ? (
              <Typography variant='h4' className={styles.title}>
                {title}
              </Typography>
            ) : null}
            {subtitle ? (
              <Typography variant='body2' className={styles.subTitle}>
                {subtitle}
              </Typography>
            ) : null}
          </Box>

          <Box>
            {onClose ? (
              <IconButton className={styles.closeAction} onClick={onClose}>
                <Icon icon={'ri-close-line'} size='1.3rem' />
              </IconButton>
            ) : null}
          </Box>
        </Stack>
      ) : null}
      {children}
    </Box>
  </Dialog>
);

export default DialogBox;
