import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

export const DateUtils = {
  /** Get Title for Date Range Filter(s)*/
  getTimeRangeTitle: (startDate?: Date, endDate?: Date, date?: Dayjs): string => {
    if (startDate && endDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const format = start.year() === end.year() ? 'DD/MMM' : 'DD/MMM/YY';
      return `${start.format(format)}-${end.format(format)}`;
    } else if (date) return date.format('MMM/YY');

    return 'Filter';
  },

  getDiffInDays: (end: number): number => dayjs(end).diff(dayjs(), 'day'),
  /** Check if two dates are same*/
  isSameDay: (date1: Dayjs, date2: Dayjs): boolean =>
    date1.get('date') === date2.get('date') &&
    date1.get('month') === date2.get('month') &&
    date1.get('year') === date2.get('year'),

  /** Count how many days from today */
  countDaysFromNow: (epoch: number | undefined): number =>
    typeof epoch === 'number' ? Math.round(dayjs.unix(epoch).diff(dayjs(), 'day', true)) : 0,

  /** Check if date is after x months */
  isAfterXMonth: (epoch: number | string | null, x: number): boolean => {
    if (!epoch) return true;
    dayjs.extend(customParseFormat);
    const dayjsObj = typeof epoch === 'number' ? dayjs.unix(epoch) : dayjs(epoch, 'MM-YY');

    return dayjsObj.isAfter(dayjs().add(x, 'month'), 'month');
  },

  formatDate: (unix: number, format: string) => dayjs.unix(unix).format(format),
  getLastQuarter: () => {
    dayjs.extend(quarterOfYear);
    const lastQuarterStart = dayjs().subtract(1, 'quarter').startOf('quarter');
    const lastQuarterEnd = dayjs().subtract(1, 'quarter').endOf('quarter');
    return {
      from: lastQuarterStart,
      to: lastQuarterEnd,
    };
  },
  getFiscalYearRange: (offset = 0) => {
    const currentMonth = dayjs().month();
    const currentYear = dayjs().year();
    const fiscalYear = (currentMonth >= 3 ? currentYear : currentYear - 1) + offset;
    return {
      from: dayjs().set('year', fiscalYear).set('month', 3).startOf('month'),
      to: dayjs()
        .set('year', fiscalYear + 1)
        .set('month', 2)
        .endOf('month'),
    };
  },
};

export const DateConverters = {
  /** Convert epoch to 'MM-YYYY' Expiry Format */
  epochToExpiry: (epoch: number | undefined): string =>
    typeof epoch === 'number' && epoch !== 0 ? dayjs.unix(epoch).format('MM-YY') : '-',
  /** Convert epoch to 'DD-MM-YYYY' | 'DD-MM-YYYY.hh:mm A' format */
  epochToString: (epoch: number | undefined, time?: boolean): string => {
    const format = time ? 'DD-MM-YYYY.hh:mm A' : 'DD-MM-YYYY';
    return typeof epoch === 'number' ? dayjs.unix(epoch).format(format) : '-';
  },
  /** If today, return 'hh:mm A' else 'DD-MM-YYYY' */
  epochToMailFormat: (epoch: string) => {
    dayjs.extend(isToday);
    const date = dayjs.unix(+epoch / 1000);
    return date.isToday() ? date.format('hh:mm A') : date.format('DD-MM-YYYY');
  },
  epochToFormat: (epoch: number | undefined, format: string): string =>
    typeof epoch === 'number' ? dayjs.unix(epoch).format(format) : '-',
};

export const DateConst = {
  todayUnix: () => dayjs().unix(),
};

export const FilterDateMap = {
  Today: {
    from: dayjs().startOf('day'),
    to: dayjs().endOf('day'),
  },
  Yesterday: {
    from: dayjs().subtract(1, 'day').startOf('day'),
    to: dayjs().subtract(1, 'day').endOf('day'),
  },
  Tomorrow: {
    from: dayjs().add(1, 'day').startOf('day'),
    to: dayjs().add(1, 'day').endOf('day'),
  },
  'This Week': {
    from: dayjs().startOf('week'),
    to: dayjs().endOf('week'),
  },
  'Last Week': {
    from: dayjs().subtract(1, 'week').startOf('week'),
    to: dayjs().subtract(1, 'week').endOf('week'),
  },
  'This Month': {
    from: dayjs().startOf('month'),
    to: dayjs().endOf('month'),
  },
  'Last Month': {
    from: dayjs().subtract(1, 'month').startOf('month'),
    to: dayjs().subtract(1, 'month').endOf('month'),
  },
  'Last 2 Days': {
    from: dayjs().subtract(1, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  'Last 3 Days': {
    from: dayjs().subtract(2, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  'Last 4 Days': {
    from: dayjs().subtract(3, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  'Last 5 Days': {
    from: dayjs().subtract(4, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  'Last 7 Days': {
    from: dayjs().subtract(6, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  'Last 3 Month': {
    from: dayjs().subtract(2, 'month').startOf('month'),
    to: dayjs().endOf('month'),
  },
  'Last 30 Days': {
    from: dayjs().subtract(29, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  'Last 90 Days': {
    from: dayjs().subtract(89, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  'Last 180 Days': {
    from: dayjs().subtract(179, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  'Last 3 Months': {
    from: dayjs().subtract(2, 'month').startOf('month'),
    to: dayjs().endOf('day'),
  },
  'Last 6 Months': {
    from: dayjs().subtract(5, 'month').startOf('month'),
    to: dayjs().endOf('day'),
  },
  'Last 12 Months': {
    from: dayjs().subtract(11, 'month').startOf('month'),
    to: dayjs().endOf('day'),
  },
  'Next 2 Days': {
    from: dayjs().startOf('day'),
    to: dayjs().add(1, 'day').endOf('day'),
  },
  'Next 3 Days': {
    from: dayjs().startOf('day'),
    to: dayjs().add(2, 'day').endOf('day'),
  },
  'Next 4 Days': {
    from: dayjs().startOf('day'),
    to: dayjs().add(3, 'day').endOf('day'),
  },
  'Next 5 Days': {
    from: dayjs().startOf('day'),
    to: dayjs().add(4, 'day').endOf('day'),
  },
  'Next 7 Days': {
    from: dayjs().startOf('day'),
    to: dayjs().add(6, 'day').endOf('day'),
  },
  'Current Financial Year': DateUtils.getFiscalYearRange(),
  'Last Financial Year': DateUtils.getFiscalYearRange(-1),
  'Last Quarter': {
    from: DateUtils.getLastQuarter().from,
    to: DateUtils.getLastQuarter().to,
  },
};

const staticRangeHandler = {
  range: {},
  isSelected(range: { startDate: Date; endDate: Date }) {
    const definedRange = (this.range as () => { startDate: Date; endDate: Date })();
    return (
      dayjs(range.startDate).startOf('day').isSame(definedRange.startDate) &&
      dayjs(range.endDate).endOf('day').isSame(definedRange.endDate)
    );
  },
};

export const createRange = (
  ranges: {
    label: string;
    range: () => {
      startDate: Date;
      endDate: Date;
    };
  }[],
) => ranges.map((range) => ({ ...staticRangeHandler, ...range }));
