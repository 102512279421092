import { Props, SAllSupplierTypes, SupplierType } from './types';
import styles from './supplierSearch.module.scss';
import { Autocomplete, Box, Popper, Stack, Tooltip, Typography } from '@mui/material';
import TextField from '../../../../components/FormControl/TextField';
import React from 'react';
import { ApiError } from '../../../../services/api/base';
import { useAppContext } from '../../../../contexts/AppContext';
import { formattedRupee, percentageNumber } from '../../../../utils/util';
import { SUPPLIER_TYPE } from 'pages/WantedNoteNew/constant';

const SupplierSearch: React.FC<Props> = (props) => {
  const [allSupplierList, setAllSupplierList] = React.useState<SAllSupplierTypes[]>([]);

  const {
    snack: { show: showSnackbar },
  } = useAppContext();

  /**
   * handler for supplier list for wantedNote item
   */
  const getSupplierListData = async () => {
    try {
      const branches =
        props.branchList?.map((item) => ({ ...item, type: SUPPLIER_TYPE.Branches })) || [];

      const previousSupplier =
        props.prevSupplier?.map((item) => ({
          ...item,
          type: SUPPLIER_TYPE['Previous Supplier'],
        })) || [];

      const allSupplier =
        props.allSupplier?.map((item) => ({
          ...item,
          type: SUPPLIER_TYPE['All Supplier'],
        })) || [];

      const supplierList = [...branches, ...previousSupplier, ...allSupplier];
      setAllSupplierList(supplierList);
    } catch (err) {
      console.log(err);
      showSnackbar((err as ApiError).userMessage, 'error');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  };

  React.useEffect(() => {
    getSupplierListData();
  }, [props.allSupplier, props.prevSupplier]);

  return (
    <Autocomplete
      className={props.disabled ? styles.supplierInputDisable : styles.supplierInput}
      id='size-small-standard'
      size='small'
      options={allSupplierList}
      disabled={props.disabled}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      loading={props.isSupplierLoading}
      onKeyDown={handleKeyDown}
      disableClearable={true}
      groupBy={(option) => option?.type}
      getOptionLabel={(option: SAllSupplierTypes) => option?.name}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      PopperComponent={(props) => (
        <Popper {...props} style={{ width: '380px' }} placement='bottom-start' />
      )}
      value={props.supplier}
      onChange={(e, newValue) => {
        props.handleChange(newValue as SupplierType);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={props.inputRef}
          variant={props.variant || 'outlined'}
          label='Supplier'
          placeholder='Supplier Name'
          required={props.required || false}
          error={props.isError || false}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option?.id}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            sx={{ width: '100%' }}
          >
            <Stack spacing={1} direction={'column'}>
              <Typography color={'text.primary'} className={styles.supplierName}>
                {option?.name}
              </Typography>
              <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
                {(option?.marginPercent as number) >= 0 && (
                  <Tooltip title={'Margin Percent'} placement='top'>
                    <Typography color={'text.primary'} className={styles.info}>
                      Eff Mar: {percentageNumber(option?.marginPercent)}
                    </Typography>
                  </Tooltip>
                )}
                {(option?.scheme as string) && (
                  <Tooltip title={'Scheme'} placement='top'>
                    <Typography color={'text.primary'} className={styles.info}>
                      Sch: {option?.scheme}
                    </Typography>
                  </Tooltip>
                )}
                {(option?.mrp as number) >= 0 && (
                  <Tooltip title={'MRP'} placement='top'>
                    <Typography color={'text.primary'} className={styles.info}>
                      MRP: {formattedRupee(option?.mrp)}
                    </Typography>
                  </Tooltip>
                )}
                {(option?.ptr as number) >= 0 && (
                  <Tooltip title={'PTR'} placement='top'>
                    <Typography color={'text.primary'} className={styles.info}>
                      PTR: {formattedRupee(option?.ptr)}
                    </Typography>
                  </Tooltip>
                )}
                {(option?.discountPercent as number) >= 0 && (
                  <Tooltip title={'Cash Discount Percent'} placement='top'>
                    <Typography color={'text.primary'} className={styles.info}>
                      CD: {percentageNumber(option?.discountPercent)}
                    </Typography>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Box>
        </li>
      )}
    />
  );
};
export default SupplierSearch;
