import React from 'react';
import styles from '../dialogs.module.scss';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { handleLogout } from 'utils/auth';
import { useAppContext } from 'contexts/AppContext';
import { ApiError } from 'services/api/base';
import { ShiftManagementService } from 'services/api/endpoints/shiftManagementService';
import { Props } from 'pages/ShiftManagement/types';
import DialogBox from 'components/Dialoges/DialogBox';
import Button from 'components/FormControl/Button/';

const StaffShiftEndDialog: React.FC<Props> = ({ open, onClose }) => {
  const { snack } = useAppContext();

  const handleEndShift = async () => {
    try {
      const data: { [key: string]: boolean } = { logout: true };
      const response = await ShiftManagementService.staffEndShift(data);
      const successMsg = response.data.data.userMessage;
      snack.show(successMsg, 'success');

      handleLogout(false);
      onClose();
    } catch (error) {
      snack.show((error as ApiError).userMessage, 'error');
      console.log(error);
    }
  };

  return (
    <DialogBox open={open} onClose={onClose}>
      <DialogContent className={styles.container}>
        <DialogTitle className={styles.title}>End Shift</DialogTitle>
        <DialogContentText className={styles.content}>
          Are you sure you want to end this Shift ?
        </DialogContentText>
        <DialogActions className={styles.actionsContainer}>
          <Button
            variant='contained'
            size='small'
            text={'End Shift'}
            onClick={() => handleEndShift()}
          />
        </DialogActions>
      </DialogContent>
    </DialogBox>
  );
};
export default StaffShiftEndDialog;
