import { Tooltip } from '@mui/material';
import { TableIconButton } from '../../../components/Styled';
import Icon from '../../../components/FormControl/Icon';
import styles from './header.module.scss';
import KeyShortcutDialog from '../../../components/Dialoges/KeyShortcutDialog';
import React from 'react';
import useMenu from '../../../components/FormControl/Menu/useMenu';
import { useLocation } from 'react-router-dom';

const listOfHelpOptions = ['Keyboard Shortcuts'];

const getKeyboardShortcutsTab = (pathname: string) => {
  if (pathname.includes('bill-entry')) return 1;
  if (pathname.includes('purchase-entry')) return 2;
  return 0;
};

const HelpSection = () => {
  const { pathname } = useLocation();
  const tab = getKeyboardShortcutsTab(pathname);
  const appVersion = process.env.REACT_APP_VERSION;

  const [helpMenuIdx, setHelpMenuIdx] = React.useState(-1);

  const {
    MenuComponent: HelpMenu,
    handleOpen: openHelpMenu,
    handleClose: closeHelpMenu,
    open: isHelpMenuOpen,
  } = useMenu();

  const handleHelpItemClick = (_: string, index: number) => {
    closeHelpMenu();
    setHelpMenuIdx(index);
  };

  return (
    <>
      <Tooltip title={'Help'} placement='left'>
        <TableIconButton onClick={(e) => openHelpMenu(e)}>
          <Icon icon={'ri-question-line'} size={'1.2rem'} className={styles.helpIcon} />
        </TableIconButton>
      </Tooltip>
      <HelpMenu
        menuOptions={listOfHelpOptions}
        menuTitle={`v${appVersion}`}
        onItemSelect={handleHelpItemClick}
        open={isHelpMenuOpen}
        onClose={closeHelpMenu}
      />
      <KeyShortcutDialog open={helpMenuIdx === 0} onClose={() => setHelpMenuIdx(-1)} tab={tab} />
    </>
  );
};

export default HelpSection;
