import { SSalePaymentFilter, SSalePaymentInfo } from './types';
import { DateConst } from '../../../../utils/date';

export const INITIAL_STATE = {
  SPaymentInfo: (): SSalePaymentInfo => ({
    totalDue: 0,
    refNo: '',
    debit: {
      amount: '',
      voucherId: [],
    },
    paymentType: '',
    date: DateConst.todayUnix(),
    remark: '',
    payAmount: 0,
    saleDetails: [],
  }),
  SFilter: (): SSalePaymentFilter => ({
    orderBy: ORDER_BY['Due Amount'],
    orderType: ORDER_TYPE.Ascending,
  }),
};

const PaymentType = {
  Card: 'Card',
  Cash: 'Cash',
  UPI: 'UPI',
  Paytm: 'Paytm',
  Cheque: 'Cheque',
  'RTGS/NEFT': 'RTGS/NEFT',
};

export const WHOLESALE_BILL_PAYMENT_TYPE = {
  Default: { ...PaymentType, Split: 'Split' },
  WtSplit: {
    ...PaymentType,
  },
  WPartial: {
    ...PaymentType,
    Split: 'Split',
    Partial: 'Partial',
    Pending: 'Pending',
  },
  WPending: {
    ...PaymentType,
    Split: 'Split',
    Pending: 'Pending',
  },
  WPendingWtSplit: {
    ...PaymentType,
    Pending: 'Pending',
  },
};

export const ORDER_BY = {
  'Due Amount': 'Due Amount',
  'Bill Date': 'Bill Date',
};

export const ORDER_TYPE = {
  Ascending: 'Ascending',
  Descending: 'Descending',
};
