export const initialDoctorState = {
  id: '',
  name: '',
  phone: '',
  registerNo: '',
  clinicHospitalName: '',
  clinicHospitalAddress: '',
  city: '',
  pincode: '',
  professionalCredentials: '',
  medicalSpeciality: '',
  email: '',
};
