import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, ListItemText } from '@mui/material';
import Icon from '../../../../../components/FormControl/Icon';
import styles from '../sidebar.module.scss';
import { AccordionItemProps } from './types';

const AccordionItem: React.FC<AccordionItemProps> = ({ item, openVideoModal }) => (
  <Accordion className={styles.accordionContainer}>
    <AccordionSummary expandIcon={<Icon icon={'ri-arrow-down-s-line'} size='1.3rem' />}>
      <ListItemText primary={<span className={styles.accordionHeader}>{item?.question}</span>} />
    </AccordionSummary>
    <AccordionDetails>
      <div className={styles.imageContainer} onClick={() => openVideoModal(item?.videoLink[0])}>
        <img src={item?.imageLink} alt='image' className={styles.imageStyle} />
        <Icon icon={'ri-youtube-fill'} size='3.5rem' className={styles.youtubeIcon} />
      </div>
      <strong className={styles.helpTitle}>{item?.content[0]?.title}</strong>
      <p className={styles.helpDesc}>{item?.content[0]?.text}</p>
    </AccordionDetails>
  </Accordion>
);

export default AccordionItem;
