// Global Constant for All Components

import { SideBar } from './types';
import { ACCESS_CONTROL_KEYS_ENUM } from './accessControl';

export const DEFAULT_ITEM_IMAGE =
  'https://1pharmacy-files.s3.ap-south-1.amazonaws.com/images/2000001017/Tablets+%26+Capsule.png';

export const PHONE_NUMBER_REGEX = /^[0-9]+$/;
export const GST_OPTIONS = [0, 3, 5, 12, 18, 28, 40, 100];
export const PAGE_SIZE_OPTIONS = { 25: 25, 50: 50, 100: 100, 200: 200 };
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS['100'];
export const DEFAULT_GST = GST_OPTIONS[3];
export const RS_SYMBOL = '₹';
export const NEAR_EXPIRY_X_MONTH = 3;

export const SEARCH_DEBOUNCE_TIME = 250;
export const SEARCH_CHAR_LIMIT = 3;
export const IDLE_TIMEOUT = 4 * 60 * 60 * 1000;

export const ComingSoonHelpText =
  "With the help page coming, you'll find it easier than ever to connect with 1Pharmacy Network";

export const ShortCutMap = {
  Reset: 'alt+r',
  QR: 'alt+q',
  AddItem: 'alt+i',
  Submit: 'F1',
  SubmitAndPrint: 'F2',
  wantedNoteModal: 'alt+F10',
  CounterSaleSingle: 'alt+d',
  CounterSaleAll: 'alt+e',
  DeleteItem: 'ctrl+d',
  ViewItemInventory: 'ctrl+i',
};

export const SIDEBAR_MENU: SideBar[] = [
  {
    name: 'Sales',
    icon: 'ri-file-paper-line',
    defaultUrl: 'bill-entry/draft/1',
    accessKey: ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE,
    items: [
      {
        name: 'Bill Entry',
        url: 'bill-entry/draft/1',
        shortCut: 'Alt + N',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE,
      },
      {
        name: 'Bill History',
        url: 'bill-history',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.BILL_READ,
      },
      {
        name: 'Sales Return',
        url: 'sales-return',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_WRITE,
      },
      {
        name: 'Sales Return History',
        url: 'sales-return-history',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_READ,
      },
      {
        name: 'Online Orders',
        url: 'online-orders',
        shortCut: 'Alt + O',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.ONLINE_ORDER_READ,
      },
      {
        name: 'Customers',
        url: 'customers',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.CUSTOMER_READ,
      },
      {
        name: 'Doctors',
        url: 'doctors',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.DOCTOR_READ,
      },
      {
        name: 'Payments',
        url: 'payments-history',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.PAYMENT_READ,
      },
    ],
  },
  {
    name: 'Purchase',
    icon: 'ri-store-2-line',
    defaultUrl: 'purchase-entry',
    accessKey: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_WRITE,
    items: [
      {
        name: 'Purchase Entry',
        url: 'purchase-entry',
        shortCut: 'Alt + P',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_WRITE,
      },
      {
        name: 'Purchase Order',
        url: 'purchase-order',
        protected: true,
        accessKey: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_ORDER,
      },
      {
        name: 'Purchase History',
        url: 'purchase-history',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_READ,
      },
      {
        name: 'Purchase Return',
        url: 'purchase-return',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_WRITE,
      },
      {
        name: 'Purchase Return History',
        url: 'purchase-return-history',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_READ,
      },
      {
        name: 'Suppliers',
        url: 'suppliers',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.SUPPLIER_READ,
      },
      {
        name: 'Cheque Payments',
        url: 'cheque-payment',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.CHEQUE_PAYMENT_READ,
      },
      {
        name: 'Wanted Note',
        url: 'wanted-note',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.WANTED_NOTE_READ,
      },
      {
        name: 'Wanted Note History',
        url: 'wanted-note-history',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.WANTED_NOTE_READ,
      },
    ],
  },
  {
    name: 'WMS',
    icon: 'ri-file-paper-line',
    defaultUrl: 'wms/grn-history',
    accessKey: ACCESS_CONTROL_KEYS_ENUM.WMS,
    protected: true,
    items: [
      {
        name: 'GRN History',
        url: 'wms/grn-history',
      },
      {
        name: 'Picklist History',
        url: 'wms/picklist-history',
      },
      {
        name: 'Putaway History',
        url: 'wms/putaway-history',
      },
    ],
  },
  {
    name: 'FKH',
    icon: 'ri-store-3-line',
    defaultUrl: 'fkh/online-order',
    protected: true,
    accessKey: ACCESS_CONTROL_KEYS_ENUM.WMS_B2C,
    items: [
      {
        name: 'Online Order',
        url: 'fkh/online-order',
      },
      {
        name: 'Invoice Order',
        url: 'fkh/invoice-order/invoice',
      },
      {
        name: 'Pack Order',
        url: 'fkh/invoice-order/invoice?toPack=true',
      },
      {
        name: 'Sales Return',
        url: 'fkh/sales-return',
      },
      {
        name: 'Sales Return History',
        url: 'fkh/sales-return-history',
      },
    ],
  },
  {
    name: 'Wholesale',
    icon: 'ri-shopping-cart-2-line',
    defaultUrl: 'wholesale/bill-entry',
    protected: true,
    accessKey: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE,
    items: [
      {
        name: 'Bill Entry',
        url: 'wholesale/bill-entry',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_WRITE,
      },
      {
        name: 'Bill History',
        url: 'wholesale/bill-history',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_READ,
      },
      {
        name: 'Sales Return',
        url: 'wholesale-return-billing',
        // TODO : wholesale return access control
      },
      {
        name: 'Sales Return History',
        url: 'wholesale-return-history',
        // TODO : wholesale return access control
      },
      {
        name: 'Retailers',
        url: 'wholesale/retailers',
        protected: true,
      },
      {
        name: 'Sales Person',
        url: 'wholesale/sales-person',
      },
    ],
  },
  {
    name: 'Inventory',
    icon: 'ri-stock-line',
    defaultUrl: 'inventory',
    items: [
      {
        name: 'Merge Duplicates',
        url: 'merge-duplicate',
      },
      {
        name: 'Item Mapping',
        url: 'item-mapping',
      },
      {
        name: 'Request Sku',
        url: 'requested-sku',
        protected: true,
        accessKey: ACCESS_CONTROL_KEYS_ENUM.REQUEST_SKU,
      },
    ],
  },
  {
    name: 'Stock Transfer',
    icon: 'ri-exchange-funds-line',
    defaultUrl: 'stock-transfer',
    protected: true,
    accessKey: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER,
    items: [
      {
        name: 'Stock Transfer',
        url: 'stock-transfer',
        protected: true,
        accessKey: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_WRITE,
      },
      {
        name: 'Transfer Details',
        url: 'transfer-details',
        protected: true,
        accessKey: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_READ,
      },
      {
        name: 'Branches',
        url: 'branches',
        protected: true,
        accessKey: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_BRANCHES_READ,
      },
    ],
  },
  {
    name: 'Reports',
    icon: 'ri-folder-chart-line',
    defaultUrl: 'report',
  },

  {
    name: 'Dashboard',
    icon: 'ri-dashboard-line',
    defaultUrl: 'dashboard',
    accessKey: ACCESS_CONTROL_KEYS_ENUM.DASHBOARD_READ,
  },
  {
    name: 'Local Store Catalogue',
    icon: 'ri-global-line',
    defaultUrl: 'dunzo',
    accessKey: ACCESS_CONTROL_KEYS_ENUM.DUNZO_DASHBOARD,
    protected: true,
    items: [
      {
        name: 'Dunzo Catalogue',
        url: 'dunzo',
      },
    ],
  },
  {
    name: 'More',
    icon: 'ri-indent-decrease',
    items: [
      {
        name: 'MedWiki',
        url: 'medicine-wikipedia',
      },
      {
        name: 'Expenses Manager',
        url: 'manage-expenses',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.EXPENSES_MANAGER_READ,
      },
      {
        name: 'Cash & Bank Book',
        url: 'cash-bank-book',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.CASH_BANK_BOOK,
      },
      {
        name: 'Store Profile',
        url: 'store-profile',
        accessKey: ACCESS_CONTROL_KEYS_ENUM.STORE_PROFILE_READ,
      },
      {
        name: 'Settings',
        url: 'settings',
      },
    ],
  },
];

export const MIME_TYPE_MAP = {
  excel: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/ms-excel': ['.xls', '.csv'],
    'text/csv': ['.csv'],
  },
  image: {
    'image/*': ['.jpg', '.jpeg', '.png'],
  },
  pdf: {
    'application/pdf': ['.pdf'],
  },
  mail: ['application/octet-stream'],
  default: {
    '*/*': ['*'],
  },
};

export const PRODUCT_SEARCH_INFO_MESSAGE =
  '- For normal search, type 3 or more\ncharacter\n- For molecule search, type m.paracetamol\n- For MRP search, type 31.5 dolo\n- For wildcard search, type *dolo\n- For Batch search, type b.batchno';

export const GET_BULK_APPLY_TITLE = (columnName: string) =>
  `- Click here to apply ${columnName} in all rows`;
