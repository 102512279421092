export const REPORT_DETAILS: {
  [key: string]: {
    name: string;
    description: string;
    url: string;
    isWms?: boolean;
  }[];
} = {
  Stock: [
    {
      name: 'Batchwise Stock Report',
      description: 'Detailed view of the current stock status of the inventory.',
      url: '/report/stock-report',
    },
    {
      name: 'Expiry Report',
      description: 'Detailed view of all items which are expired or about to expire.',
      url: '/report/expiry-report',
    },
    {
      name: 'Stock Adjustment',
      description: 'Detailed view of all stocks which are adjusted.',
      url: '/report/stock-adjustment-report',
    },
    {
      name: 'Cycle Count Report',
      description: 'Detailed view for stock not in sync with WMS.',
      url: '/report/cycle-count-report',
      isWms: true,
    },
    {
      name: 'Non Moving Items',
      description: 'Detailed view to understand items which are not sold recently.',
      url: '/report/non-moving-items-report',
    },
    {
      name: 'Item Mapping',
      description: 'Detailed view to understand the item mapping history.',
      url: '/report/item-mapping-report',
    },
    {
      name: 'Low Stock',
      description: 'Detailed view to see which items are below the set threshold.',
      url: '/report/low-stock-report',
    },
    {
      name: 'Item Master Report',
      description: 'Detailed view of the item master.',
      url: '/report/item-master-report',
    },
    {
      name: 'Schedule H/H1/X/G Report',
      description: 'Detailed view of all the Schedule H/H1/X/G drugs sold.',
      url: '/report/schedule-report',
    },
    {
      name: 'New Item Report',
      description: 'Detailed view of all the new items added to the inventory.',
      url: '/report/new-item-report',
    },
    {
      name: 'Itemwise Stock Report',
      description:
        'Detailed view of the current itemwise stock status of the inventory with sales and purchase for a certain time range.',
      url: '/report/itemwise-stock-report',
    },
    {
      name: 'Bounce Item Report',
      description: 'Detailed view of Bounce Item Report.',
      url: '/report/bounce-item-report',
    },
    {
      name: 'Stock Audit Report',
      description: 'Detailed view of Stock Audit Report.',
      url: '/report/stock-audit-report',
    },
  ],
  Sales: [
    {
      name: 'Oversold Report',
      description: 'Detailed view of all the items which were sold more than they were bought.',
      url: '/report/oversold-report',
    },
    {
      name: 'Itemwise Sales Margin',
      description: 'Detailed itemwise view of the margin generated in each sales.',
      url: '/report/itemwise-sales-margin-report',
    },
    {
      name: 'Batchwise Sales Report',
      description: 'Detailed view of the batchwise information of all items sold',
      url: '/report/batchwise-sales-report',
    },
    {
      name: 'Itemwise Sales Report',
      description: 'Detailed view of the itemwise information of all items sold',
      url: '/report/itemwise-sales-report',
    },
    {
      name: 'Staffwise Sales Report',
      description: 'Detailed view of the staffwise information of all items sold',
      url: '/report/staffwise-sales-report',
    },
    {
      name: 'Salesperson Incentive Report',
      description: 'Detailed view of the incentives earned by the sales person.',
      url: '/report/salesperson-report',
    },
    {
      name: 'Shift Management Report',
      description: 'Detailed view of the Shift information of all Shifts',
      url: '/report/shift-management-report',
    },
  ],
  Purchase: [
    {
      name: 'Batchwise Purchase Report',
      description: 'Detailed view of the batchwise information of all items purchased',
      url: '/report/batchwise-purchase-report',
    },
    {
      name: 'Overbought Report',
      description: 'Detailed view of all the items which were bought more than they were sold.',
      url: '/report/overbought-report',
    },
  ],
  GST: [
    {
      name: 'GSTR Sales',
      description: 'Detailed view with GST breakup of Sales with 2 templates.',
      url: '/report/gst-report-sales',
    },
    {
      name: 'GSTR Purchase',
      description: 'Detailed view with GST breakup of Purchase with 2 templates.',
      url: '/report/gst-report-purchase',
    },
    {
      name: 'GSTR-1 Report',
      description:
        'Detailed view of invoice level or summary level information of the filed GSTR-1s.',
      url: '/report/gst-report-gstr1',
    },
    {
      name: 'GSTR-2A Report',
      description: "Auto-drafted statement that provides ITC details based on the supplier's data.",
      url: '/report/gst-report-gstr2a',
    },
    {
      name: 'GSTR-3B Report',
      description:
        'Simplified summary return to declare the summary GST liabilities for a particular tax period.',
      url: '/report/gst-report-gstr3b',
    },
  ],
  Wms: [
    {
      name: 'Branch PO Report',
      description: 'Detailed view of wms Branch PO.',
      url: '/report/branch-po-report',
      isWms: true,
    },
  ],
  Tally: [
    {
      name: 'Tally Master',
      description: 'Detailed view of Tally master with XML.',
      url: '/report/tally-master',
      isWms: true,
    },
    {
      name: 'Tally Transaction',
      description: 'Detailed view of Tally Transaction with XML.',
      url: '/report/tally-transaction',
      isWms: true,
    },
  ],
};

export const REPORT_STATUS = {
  generated: 'Generated',
  pending: 'Pending',
  failed: 'Failed',
};

export const moveColToFirst = (col: string[], name: string): string[] => {
  if (col.includes(name)) {
    return [name, ...col.filter((item) => item !== name)];
  }
  return col;
};
