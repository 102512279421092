import React from 'react';
import { Props, SDoctor } from './types';
import { DoctorService } from '../../../../services/api';
import { validation } from '../../constants';
import { Box, Divider, Grid, InputAdornment } from '@mui/material';
import TextField from '../../../../components/FormControl/TextField';
import styles from './addDoctor.module.scss';
import useFocus from '../../../../hooks/useFocus';
import { useAppContext } from '../../../../contexts/AppContext';
import { ApiError } from '../../../../services/api/base';
import SlideUpDialog from '../../../../components/Dialoges/SlideUpDialog';
import { initialDoctorState } from './constants';
import { RDoctor } from '../../../../services/api/types/doctor.types';

const AddDoctor: React.FC<Props> = (props) => {
  const [formState, setFormState] = React.useState<SDoctor>({ ...initialDoctorState });
  const [isFormValid, setIsFormValid] = React.useState<boolean>(true);

  const { inputRef: nameRef, setFocus: setNameFocus } = useFocus();
  const {
    snack: { show: showSnackbar },
  } = useAppContext();

  React.useEffect(() => {
    if (props.open) {
      if (props.data) {
        setFormState((prev) => ({ ...prev, ...props.data }));
      }
    }
    setNameFocus();
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
    setFormState({} as SDoctor);
  };
  const handleSubmit = async () => {
    try {
      if (validate()) {
        const doctorRequestData: RDoctor = {
          id: formState.id,
          name: formState.name,
          phone: formState.phone,
          registerNo: formState.registerNo,
          clinicHospitalName: formState.clinicHospitalName,
          clinicHospitalAddress: formState.clinicHospitalAddress,
          city: formState.city,
          pincode: formState.pincode,
          professionalCredentials: formState.professionalCredentials,
          medicalSpeciality: formState.medicalSpeciality,
          email: formState.email,
        };

        if (props.data) {
          const response = await DoctorService.update(props.data.id, doctorRequestData);
          showSnackbar('Doctor updated successfully', 'success');
          const responseData = {
            id: response.data.data.id,
            name: response.data.data.name,
            phone: response.data.data.phone || '',
            email: response.data.data.email || '',
            registerNo: response.data.data.registerNo || '',
            clinicHospitalName: response.data.data.clinicHospitalName || '',
            clinicHospitalAddress: response.data.data.clinicHospitalAddress || '',
            city: response.data.data.city || '',
            pincode: response.data.data.pincode || '',
            professionalCredentials: response.data.data.professionalCredentials || '',
            medicalSpeciality: response.data.data.medicalSpeciality || '',
          };
          props?.onSave?.(responseData);
        } else {
          const response = await DoctorService.create(doctorRequestData);
          showSnackbar('Doctor added successfully', 'success');
          const responseData = {
            id: response.data.data.id,
            name: response.data.data.name,
            phone: response.data.data.phone || '-',
            email: response.data.data.email || '-',
            registerNo: response.data.data.registerNo || '-',
            clinicHospitalName: response.data.data.clinicHospitalName || '-',
            clinicHospitalAddress: response.data.data.clinicHospitalAddress || '-',
            city: response.data.data.city || '-',
            pincode: response.data.data.pincode || '-',
            professionalCredentials: response.data.data.professionalCredentials || '-',
            medicalSpeciality: response.data.data.medicalSpeciality || '-',
          };
          props?.onSave?.(responseData);
        }

        props?.onSubmit?.();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      showSnackbar((error as ApiError).userMessage, 'error');
    }
  };
  const handleChange = (key: string, newValue: unknown) => {
    setFormState({
      ...formState,
      [key]: newValue,
    });
  };

  const validate = () => {
    const isValid =
      validation('name', formState.name) &&
      validation('phone', formState.phone) &&
      validation('pincode', formState.pincode);
    setIsFormValid(isValid);
    return isValid;
  };

  return (
    <SlideUpDialog
      open={props.open}
      onClose={handleClose}
      containerClass={styles.container}
      title={props.data ? 'Edit Doctor' : 'Add Doctor'}
      actionText={props.data ? 'Save' : 'Add'}
      actionType={'primary'}
      onAction={handleSubmit}
    >
      <Divider />

      <Box className={styles.form}>
        <form aria-label='customer-form' autoComplete='off'>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label='Name'
                fullWidth
                inputRef={nameRef}
                value={formState.name || ''}
                onChange={(event) => handleChange('name', event.target.value)}
                variant='standard'
                required
                error={!isFormValid && !validation('name', formState.name)}
                InputProps={
                  props.data
                    ? {}
                    : { startAdornment: <InputAdornment position='start'>Dr.</InputAdornment> }
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Contact'
                fullWidth
                value={formState.phone || ''}
                onChange={(event) => handleChange('phone', event.target.value)}
                inputType='mobile'
                variant='standard'
                error={!isFormValid && !validation('phone', formState.phone)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Email'
                fullWidth
                value={formState.email || ''}
                onChange={(event) => handleChange('email', event.target.value)}
                variant='standard'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Registration No'
                fullWidth
                value={formState.registerNo || ''}
                onChange={(event) => handleChange('registerNo', event.target.value)}
                variant='standard'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Professional Credentials'
                fullWidth
                value={formState.professionalCredentials || ''}
                onChange={(event) => handleChange('professionalCredentials', event.target.value)}
                variant='standard'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Medical Speciality'
                fullWidth
                value={formState.medicalSpeciality || ''}
                onChange={(event) => handleChange('medicalSpeciality', event.target.value)}
                variant='standard'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Clinic Name'
                fullWidth
                value={formState.clinicHospitalName || ''}
                onChange={(event) => handleChange('clinicHospitalName', event.target.value)}
                variant='standard'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Clinic City'
                fullWidth
                value={formState.city || ''}
                onChange={(event) => handleChange('city', event.target.value)}
                variant='standard'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Clinic Pincode'
                fullWidth
                value={formState.pincode || ''}
                inputProps={{ maxLength: 6 }}
                inputType='integer'
                onChange={(event) => handleChange('pincode', event.target.value)}
                variant='standard'
                error={!isFormValid && !validation('pincode', formState.pincode)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Clinic Address'
                placeholder='Address'
                fullWidth
                value={formState.clinicHospitalAddress || ''}
                multiline
                rows={2}
                variant={'standard'}
                onChange={(e) => handleChange('clinicHospitalAddress', e.target.value)}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </SlideUpDialog>
  );
};

export default AddDoctor;
