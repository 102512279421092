import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './sidebarmenu.module.scss';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import { SIDEBAR_MENU } from '../../../../utils/constants';
import { UserAccessControlData } from '../../../../utils/accessControl';

const SidebarMenu = React.memo(() => {
  const { isSidebarOpen } = useContext(SidebarContext);

  React.useEffect(() => {
    if (!isSidebarOpen) {
      setItemIndex(-1);
    }
  }, [isSidebarOpen]);
  const [itemIndex, setItemIndex] = useState<number>(-1);
  const itemIndexChangeHandler = (index: number) => {
    setItemIndex((prev) => (prev === index ? -1 : index));
  };

  return (
    <List className={styles.sideBarList}>
      {SIDEBAR_MENU.filter(
        (item) => !(UserAccessControlData.get(item.accessKey) && item?.protected),
      ).map((item, listIndex) => {
        const { name, icon, items, defaultUrl, accessKey } = item;
        return (
          <React.Fragment key={`menu-${listIndex}`}>
            <ListItem
              onClick={() => {
                itemIndexChangeHandler(listIndex);
              }}
              className={styles.navItem}
            >
              {defaultUrl ? (
                <NavLink
                  to={UserAccessControlData.get(accessKey) ? '#' : defaultUrl}
                  onClick={(e) => {
                    UserAccessControlData.get(accessKey) ? e.preventDefault() : null;
                  }}
                  className={({ isActive }) =>
                    `${styles.navItemLink} ${
                      isActive && (items || []).length > 0 ? styles.disableClick : ''
                    } ${UserAccessControlData.get(accessKey) && !items ? styles.disableNav : ''}`
                  }
                  tabIndex={-1}
                >
                  {UserAccessControlData.get(accessKey) && !items ? (
                    <ListItemIcon>
                      <i className={`ri-git-repository-private-fill ${styles.icon}`} />
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon>
                      <i className={`${icon} ${styles.icon}`} />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={name} className={styles.name} />
                </NavLink>
              ) : (
                <>
                  <ListItemIcon>
                    <i className={`${icon} ${styles.icon}`} />
                  </ListItemIcon>
                  <ListItemText primary={name} className={styles.name} />
                </>
              )}
            </ListItem>

            {items ? (
              <Collapse in={itemIndex >= 0 && listIndex === itemIndex} timeout='auto' unmountOnExit>
                <List className={styles.sideBarSubList}>
                  {items
                    .filter(
                      (child) => !(UserAccessControlData.get(child.accessKey) && child?.protected),
                    )
                    .map((child) => (
                      <ListItem key={child.name} className={styles.subListItem}>
                        <NavLink
                          to={child.url}
                          onClick={(e) => {
                            UserAccessControlData.get(child.accessKey) ? e.preventDefault() : null;
                          }}
                          className={({ isActive }) =>
                            isActive
                              ? styles.subNavItemActive
                              : child.shortCut
                              ? `${styles.subNavItemShortCut} ${
                                  UserAccessControlData.get(child.accessKey)
                                    ? styles.disableHover
                                    : ''
                                }`
                              : `${styles.subNavItem} ${
                                  UserAccessControlData.get(child.accessKey)
                                    ? styles.disableHover
                                    : ''
                                }`
                          }
                        >
                          <ListItemText
                            className={styles.subname}
                            primaryTypographyProps={{ className: styles.primaryListItem }}
                            primary={
                              <>
                                {UserAccessControlData.get(child.accessKey) && (
                                  <i
                                    className={`ri-git-repository-private-fill ${styles.iconLock}`}
                                  />
                                )}
                                {child.name}
                              </>
                            }
                            secondary={child.shortCut}
                          />
                        </NavLink>
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            ) : null}
          </React.Fragment>
        );
      })}
    </List>
  );
});

SidebarMenu.displayName = 'SidebarMenu';
export default SidebarMenu;
