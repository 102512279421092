import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import styles from './infoDialog.module.scss';
import { Props } from './types';

const InfoDialog: React.FC<Props> = ({
  open,
  title,
  description,
  type,
  okText,
  cancelText,
  backDropClose,
  handleClose,
  ack,
  handleOk,
  handleCancel,
}) => {
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const handleDialogClose = (reason: string) => {
    if (backDropClose === false && (reason === 'escapeKeyDown' || reason === 'backdropClick')) {
      // Don't close the dialog if the reason is escape key or backdrop click
      return;
    }
    handleClose && handleClose();
  };

  return (
    <Dialog
      onClose={(event, reason) => handleDialogClose(reason)} // Call handleClose fn (Based on backDropClose props)
      open={open}
      className={styles.infoDialogWrapper}
    >
      <DialogTitle className={styles.title}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          className={`${type === 'warning' ? styles.warningText : ''} ${styles.content}`}
        >
          {ack ? (
            <Checkbox value={checkBox} onChange={(e) => setCheckBox(!!e.target.checked)} />
          ) : null}
          <Typography>{description}</Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions className={'flexRow justifyCenter'}>
        <Button
          variant='contained'
          className={`m-r-16 ${styles.actionBtn}`}
          onClick={handleOk}
          disabled={ack && !checkBox}
          action={(actions) => actions?.focusVisible()}
        >
          {okText}
        </Button>

        {cancelText && (
          <Button
            variant='contained'
            onClick={() => {
              handleClose && handleClose();
              handleCancel && handleCancel();
            }}
            className={`${styles.actionBtn} ${styles.actionCancel}`}
          >
            {cancelText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
