import MultiSelect from 'components/FormControl/MultiSelect';
import { capitalize } from 'utils/util';
interface Props {
  items: string[];
  value: string[];
  handleFilterChange: (value: string[], key: string) => void;
  itemKey: string;
}
const ReportMultiSelect: React.FC<Props> = ({ handleFilterChange, items, value, itemKey }) => {
  const options = ['All', ...items.filter((item) => item !== 'All')];
  const handleChange = (newValue: string[]) => {
    if (
      newValue.filter((item) => item !== 'All').length === items.length &&
      value.length === items.length
    ) {
      // if user removed on All
      handleFilterChange([], itemKey);
    } else if (newValue.length && newValue[newValue.length - 1] === 'All') {
      // if user added All
      handleFilterChange(
        items.filter((item) => item !== 'All'),
        itemKey,
      );
    } else {
      handleFilterChange(
        newValue.filter((item) => item !== 'All'),
        itemKey,
      );
    }
  };
  return (
    <MultiSelect
      size='small'
      items={options}
      label={capitalize(itemKey)}
      value={
        value.length === items.length
          ? ['All', ...value.filter((item) => item !== 'All')]
          : value.filter((item) => item !== 'All')
      }
      variant='outlined'
      onChange={(e) => handleChange(e.target.value as string[])}
      width={250}
    />
  );
};
export default ReportMultiSelect;
