import SlideUpDialog from '../SlideUpDialog';
import styles from './notificationPanel.module.scss';
import React from 'react';
import { Props, SNotification } from './types';
import NotificationCard from './NotificationCard';
import { Box, Stack } from '@mui/material';
import Select from '../../FormControl/Select';
import { NotificationService } from '../../../services/api';
import { ApiError } from '../../../services/api/base';
import { useAppContext } from '../../../contexts/AppContext';
import { ALL, FILTERS } from './constants';

const NoDataAvailable = () => <Box className={styles.noDataAvailable}>No Notification Found</Box>;

const NotificationPanel = ({ open, notification, onClose, removeNotification }: Props) => {
  const [notificationCategory, setNoticationCategory] = React.useState<string>(ALL);
  const { snack } = useAppContext();

  const handleMarkAsRead = async (id: string) => {
    try {
      await NotificationService.markAsRead(id);
      removeNotification(id);
    } catch (error) {
      console.log(error);
      snack.show((error as ApiError).userMessage, 'error');
    }
  };

  const filteredNotificationData: SNotification[] = React.useMemo(
    () =>
      notification.filter(
        (item) => item.category === notificationCategory || notificationCategory === FILTERS.All,
      ),
    [notificationCategory, notification],
  );

  return (
    <SlideUpDialog
      open={open}
      onClose={() => {
        onClose();
        setNoticationCategory('All');
      }}
      title='Notification'
      direction='left'
      containerClass={styles.container}
    >
      <Stack className={styles.notificationContainer}>
        <Select
          label={'Notification Category'}
          className={styles.notificationCategory}
          items={Object.keys(FILTERS)}
          variant='standard'
          value={notificationCategory}
          onChange={(e) => setNoticationCategory(e.target.value as string)}
          fullWidth
        />
      </Stack>
      {filteredNotificationData.length ? (
        filteredNotificationData?.map((item) => (
          <NotificationCard
            key={item.id}
            item={item}
            onClose={onClose}
            updateNotification={removeNotification}
            handleMarkAsRead={handleMarkAsRead}
          />
        ))
      ) : (
        <NoDataAvailable />
      )}
    </SlideUpDialog>
  );
};

export default NotificationPanel;
