// Responsive Styled Components with breakpoints
import { Box, IconButton, styled } from '@mui/material';
import Button from '../FormControl/Button';
import Select from '../FormControl/Select';

export const PageActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  height: '40px',
  width: 'auto',
  [theme.breakpoints.between('xs', 'xl')]: {
    height: '30px',
    width: 'auto',
    padding: '0 5px',
    fontSize: '12px',
  },
}));

export const PageContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  height: 'calc(100vh - 185px)', // 35px($header-height) header + 90px footer($page-footer-height) + 60px PageHeader = 185px
  padding: '0',
  margin: 0,

  [theme.breakpoints.between('xs', 'xl')]: {
    height: 'calc(100vh - 140px)', // 35px header($header-height) + 60px footer($header-height) + 40 PageHeader = 160px
  },
}));

export const FilterSelect = styled(Select)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'xl')]: {
    '&.MuiInputBase-root': {
      height: '25px',
      marginTop: '6px',
    },
    '& .MuiSelect-select': {
      padding: '0',
    },
  },
}));

export const TableIconButton = styled(IconButton)(() => ({
  margin: '0',
  padding: '0',
}));
