import React from 'react';
import styles from './currencyDialog.module.scss';
import {
  Box,
  DialogActions,
  DialogContent,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import TextField from '../../../../components/FormControl/TextField';
import DialogBox from 'components/Dialoges/DialogBox';
import Button from 'components/FormControl/Button';
import { useAppContext } from 'contexts/AppContext';
import { getInitialState } from './constants';
import { Props, SCashType, SCurrencyInventoryType } from './types';
import { ShiftManagementService } from 'services/api/endpoints/shiftManagementService';
import { ApiError } from 'services/api/base';
import { StoreUtils, capitalize } from 'utils/util';
import { handleLogout } from 'utils/auth';

const CurrencyDialog: React.FC<Props> = ({ open, shift, onSubmit, onClose }) => {
  const [currencyInventory, setCurrencyInventory] = React.useState<SCurrencyInventoryType>(
    getInitialState(),
  );
  const {
    snack,
    dialog: { confirm },
  } = useAppContext();
  const userName = StoreUtils.getKeyFromActiveSession(['userData', 'name']);

  const handleChange = (value: string, key: string) => {
    const isNumericInput = !isNaN(+value);
    const currentValue = isNumericInput ? +value : 0;
    const currencyValue = +key;

    if (value.length > 14) return; // Not allow BigInt value

    const updatedCurrencyInventory = { ...currencyInventory };

    if (['card', 'upi'].includes(key)) {
      if (isNumericInput) {
        updatedCurrencyInventory[key] = currentValue;
      }
    } else {
      const prevValue = +(updatedCurrencyInventory.cash as SCashType)[key] * currencyValue;
      const difference = currentValue * currencyValue - prevValue;
      (updatedCurrencyInventory.cash as SCashType)[key] = currentValue;
      updatedCurrencyInventory.total = (updatedCurrencyInventory.total as number) + difference;
    }

    setCurrencyInventory(updatedCurrencyInventory);
  };

  const handleSubmit = async () => {
    const choice = await confirm({
      title: `Are you sure you want to ${shift} the shift?`,
      description: `Total Cash Amount: ₹ ${currencyInventory.total}`,
      type: 'warning',
      okText: 'Yes',
      cancelText: 'No',
    });

    if (choice) {
      const dataToSubmit: SCurrencyInventoryType = {
        cash: currencyInventory.cash,
        ...(shift === 'end' && { logout: true }),
      };
      const activeSession = StoreUtils.getActiveSession();
      const sessionId = activeSession.session.id;

      if (shift === 'end') {
        dataToSubmit.card = currencyInventory.card;
        dataToSubmit.upi = currencyInventory.upi;
      }

      try {
        const response = await ShiftManagementService.submit(sessionId, shift, dataToSubmit);
        const successMsg = response.data.data.userMessage;
        snack.show(successMsg, 'success');
        setCurrencyInventory(getInitialState());

        if (shift === 'end') {
          handleLogout(false);
        }
        onSubmit();
      } catch (error) {
        snack.show((error as ApiError).userMessage, 'error');
        console.log(error);
      }
    }
  };

  return (
    <DialogBox
      open={open}
      title={`Hi ${userName}, let's ${shift} the shift`}
      {...(onClose && { onClose })}
    >
      <DialogContent className={styles.dialogContent}>
        <Typography variant='h6' className={styles.subHead}>
          Cash:
        </Typography>

        <Stack direction={'row'}>
          <Box className={styles.contentSideBox}>
            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='500' className={styles.inputLabel}>
                ₹ 500
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['500']}
                name={'500'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>

            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='200' className={styles.inputLabel}>
                ₹ 200
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['200']}
                name={'200'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>

            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='100' className={styles.inputLabel}>
                ₹ 100
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['100']}
                name={'100'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>

            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='50' className={styles.inputLabel}>
                ₹ 50
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['50']}
                name={'50'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>

            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='20' className={styles.inputLabel}>
                ₹ 20
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['20']}
                name={'20'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>
          </Box>

          <Box className={styles.contentSideBox}>
            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='10' className={styles.inputLabel}>
                ₹ 10
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['10']}
                name={'10'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>

            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='5' className={styles.inputLabel}>
                ₹ 5
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['5']}
                name={'5'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>

            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='2' className={styles.inputLabel}>
                ₹ 2
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['2']}
                name={'2'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>

            <Stack direction='row' className={styles.currencyBox}>
              <InputLabel htmlFor='1' className={styles.inputLabel}>
                ₹ 1
              </InputLabel>
              <TextField
                className={styles.textField}
                value={(currencyInventory.cash as SCashType)['1']}
                name={'1'}
                variant='standard'
                inputType={'integer'}
                fullWidth
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
            </Stack>
            <Typography variant='h6' className={styles.totalText}>
              Total Cash Amount: ₹ {currencyInventory['total'] as number}
            </Typography>
          </Box>
        </Stack>

        {shift === 'end' ? (
          <>
            <Typography variant='h6' className={styles.subHead2}>
              Online Payments:
            </Typography>
            <Stack direction={'row'}>
              <Box className={styles.contentSideBox}>
                <Stack direction='row' className={styles.currencyBox}>
                  <InputLabel htmlFor='card' className={styles.inputLabel}>
                    Card
                  </InputLabel>
                  <Input
                    className={styles.textField}
                    name={'card'}
                    startAdornment={<InputAdornment position='start'>₹</InputAdornment>}
                    value={currencyInventory.card}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                  />
                </Stack>
              </Box>
              <Box className={styles.contentSideBox}>
                <Stack direction='row' className={styles.currencyBox}>
                  <InputLabel htmlFor='upi' className={styles.inputLabel}>
                    UPI
                  </InputLabel>
                  <Input
                    className={styles.textField}
                    name={'upi'}
                    startAdornment={<InputAdornment position='start'>₹</InputAdornment>}
                    value={currencyInventory.upi}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                  />
                </Stack>
              </Box>
            </Stack>
          </>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button
          text={`${capitalize(shift)} Shift`}
          variant={'contained'}
          size='small'
          onClick={() => handleSubmit()}
          enableLoading={true}
        />
      </DialogActions>
    </DialogBox>
  );
};

export default CurrencyDialog;
