import { AxiosResponse } from 'axios';
import { getRequest, postRequest } from '../base';

export const ShiftManagementService = {
  submit: async (
    id: string,
    shift: 'start' | 'end',
    data: unknown,
  ): Promise<AxiosResponse<{ data: { userMessage: string } }>> =>
    postRequest(`admin_shift_submit/${id}?shiftType=${shift}`, data),
  staffEndShift: async (data: unknown): Promise<AxiosResponse<{ data: { userMessage: string } }>> =>
    postRequest(`staff_shift_submit`, data),
  checkEndShift: async (): Promise<
    AxiosResponse<{ data: { userMessage: string }; statusCode: number }>
  > => getRequest(`check_end_shift`),
};
