import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import Button from '../../../../components/FormControl/Button';
import DialogBox from 'components/Dialoges/DialogBox';
import styles from '../dialogs.module.scss';

const ShiftRefreshDialog: React.FC<{ open: boolean }> = (props) => (
  <DialogBox open={props.open}>
    <DialogContent className={styles.container}>
      <DialogTitle className={styles.title}>Shift not Started</DialogTitle>
      <DialogContentText className={styles.content}>
        No active shift available. Please ask Admin to start shift.
      </DialogContentText>
      <DialogActions className={styles.actionsContainer}>
        <Button
          variant='contained'
          size='small'
          text={'Refresh'}
          onClick={() => {
            window.location.reload();
          }}
        />
      </DialogActions>
    </DialogContent>
  </DialogBox>
);

export default ShiftRefreshDialog;
