import React from 'react';
import { Autocomplete, Box, Typography, createFilterOptions } from '@mui/material';
import TextField from '../../FormControl/TextField';
import styles from './doctorSearch.module.scss';
import { SDoctor, Props } from './types';
import { DoctorService } from '../../../services/api';
import Icon from '../../FormControl/Icon';
import AddDoctor from '../../../pages/Doctor/components/AddDoctor';
import { RDoctor } from '../../../services/api/types/doctor.types';

const ADD_NEW_DOCTOR = 'Add New Doctor';

const DoctorSearch: React.FC<Props> = (props) => {
  const [doctorOption, setDoctorOption] = React.useState<SDoctor[]>([]);
  const [doctorName, setDoctorName] = React.useState<string>('');
  const [doctorId, setDoctorId] = React.useState<string>('');
  const [isAddDoctorModalOpen, setIsAddDoctorModalOpen] = React.useState<boolean>(false);

  const filter = createFilterOptions<SDoctor>();

  const updateDoctor = (doctor: RDoctor) => {
    setDoctorOption((prevData) => {
      const index = prevData.findIndex((item) => item.id === doctor.id);
      // Edit Item
      if (index !== -1) {
        const updatedData = [...prevData];
        updatedData[index] = doctor;
        return updatedData as SDoctor[];
      }
      // Add Item
      else {
        return [...prevData, doctor] as SDoctor[];
      }
    });
  };

  React.useEffect(() => {
    setDoctorName(props.selectedDoctorName || '');
    setDoctorId(props.selectedDoctorId || '');
  }, [props.selectedDoctorName, props.selectedDoctorId]);

  const handleSeparateDoctorChange = (newDoctor: SDoctor | null) => {
    props.onDoctorChange(newDoctor as SDoctor);
  };

  const handleOpen = () => {
    setIsAddDoctorModalOpen(true);
  };
  const handleClose = () => {
    setIsAddDoctorModalOpen(false);
  };

  React.useEffect(() => {
    DoctorService.list()
      .then((response) => {
        const responseData = response.data.data.items || [];
        const filterBillHistory: SDoctor[] = responseData.map(
          (item): SDoctor => ({
            id: item.id,
            name: item.name,
          }),
        );
        setDoctorOption(filterBillHistory);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  return (
    <>
      <Autocomplete
        freeSolo
        options={[...doctorOption]}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
        value={doctorName}
        disabled={props.disabled || false}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label || 'Doctor'}
            variant={props.variant || 'outlined'}
            placeholder='Dr. Name'
            className={styles.textField}
            required={props.TFRequired || false}
            error={props.TFError || false}
          />
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          filtered.push({
            id: '-',
            name: '',
            inputValue: ADD_NEW_DOCTOR,
          });
          return filtered;
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setIsAddDoctorModalOpen(true);
          } else if (newValue && newValue?.inputValue) {
            setIsAddDoctorModalOpen(true);
          } else {
            setDoctorName(newValue?.name || '');
            setDoctorId(newValue?.id || '');
            handleSeparateDoctorChange(newValue);
          }
        }}
        isOptionEqualToValue={(option, value) =>
          typeof option !== 'string' && typeof value !== 'string' && option.name === value.name
        }
        onInputChange={(event, newValue, reason) => {
          if (newValue !== ADD_NEW_DOCTOR) {
            setDoctorName(newValue);
          }
          if (reason !== 'reset') {
            handleSeparateDoctorChange({ name: newValue, id: doctorId });
          }
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option?.inputValue === ADD_NEW_DOCTOR ? (
              <div className={styles.listOptions} onClick={handleOpen}>
                <Typography className={styles.addDoctorOptionBox} variant='h5'>
                  <Icon icon='ri-add-line' size='1.2rem' />
                  Add Doctor
                </Typography>
              </div>
            ) : (
              <Box display={'flex'} flexDirection={'row'} sx={{ width: '200px' }}>
                <Box display={'flex'} sx={{ width: '100%' }} flexDirection={'column'}>
                  <Typography color={'text.primary'}>{option.name}</Typography>
                </Box>
              </Box>
            )}
          </li>
        )}
      />
      <AddDoctor
        open={isAddDoctorModalOpen}
        onClose={handleClose}
        onSave={(doctor) => {
          updateDoctor(doctor);
          handleClose();
        }}
      />
    </>
  );
};

export default DoctorSearch;
