import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import billEntryRootReducer from '../pages/BillEntry/reducers';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import WholesaleReturnRootReducer from '../pages/WholesaleReturn/ReturnBilling/reducers';
import purchaseEntryRootReducer from '../pages/PurchaseEntryRedux/reducers';
import grnEntryRootReducer from '../pages/WMS/GrnEntry/reducers';
import WMSSalesReturnRootReducer from '../pages/fkh/SalesReturn/reducers/slice';

const globalStore = configureStore({
  reducer: {
    billEntry: billEntryRootReducer,
    purchaseEntry: purchaseEntryRootReducer,
    wholeSaleReturn: WholesaleReturnRootReducer,
    grnEntry: grnEntryRootReducer,
    WMSSalesReturn: WMSSalesReturnRootReducer,
  },
});

export type SRootAppDispatch = typeof globalStore.dispatch;
export type SRootStore = ReturnType<typeof globalStore.getState>;
type ThunkAction<
  R, // Return type of the thunk function
  S, // state type used by getState
  E, // any "extra argument" injected into the thunk
  A extends Action, // known types of actions that can be dispatched
> = (dispatch: ThunkDispatch<S, E, A>, getState: () => S, extraArgument: E) => R;

export type SAppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  SRootStore,
  unknown,
  Action
>;

export const useAppDispatch = () => useDispatch<SRootAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<SRootStore> = useSelector;
export default globalStore;
