import { Box, Stack } from '@mui/material';
import styles from './tab.module.scss';
import React from 'react';
import { Props } from './types';

const ShortcutMapTab: React.FC<Props> = (props) => (
  <Box className={styles.tabContainer}>
    {props.shortcut.map((item) => (
      <div key={item.title} className={styles.keyGroup}>
        <span className={styles.keyTitle}>{item.title}</span>
        <Stack direction='row' alignItems='center' gap={1} className={styles.keys}>
          {item.keys.map((key, index) => {
            if (typeof key === 'string')
              return (
                <React.Fragment key={key}>
                  <span className={styles.key}>{key}</span>
                  {index < item.keys.length - 1 && '+'}
                </React.Fragment>
              );
            return key.map((k, index) => (
              <React.Fragment key={k}>
                <span className={styles.key}>{k}</span>
                {index < key.length - 1 && '/'}
              </React.Fragment>
            ));
          })}
        </Stack>
      </div>
    ))}
  </Box>
);
export default ShortcutMapTab;
