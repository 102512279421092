const USER = 'user';

export const GENERATE_OTP = `${USER}/otp/generate`;
export const LOGIN = `${USER}/login`;

// Bill Entry
export const GET_SEARCH_PRODUCT = 'search_item?search=';
export const GET_SEARCH_ITEM = 'sku_stock';
export const GET_SALES_ALTERNATIVES = 'get_sku_alternatives';

// Payments History
export const GET_PAYMENTS_HISTORY = 'all_payments';
export const GENERATE_PAYMENT_REPORT = 'payment_report';

// Purchase Entry
export const GET_SUPPLIER_LIST = 'supplier_listing';

// Online Orders
export const GET_B2C_ONLINE_ORDER = 'order_listing';
export const GET_B2B_ONLINE_ORDER = 'po_requested';

// Inventory
export const Inventory = {
  sku: {
    QUERY_SEARCH: 'search_my_sku',
    GET_INVENTORY: 'all_sku?',
    UPDATE_SKU: 'update_sku',
    ADD_SKU: 'create_sku',
    GET_SKU: 'sku_item',
    ADD_SKU_WITH_STOCK: 'update_sku_stock',
    GET_SKU_DETAILS: 'sku_details',
    SEARCH_DUP: 'search_my_sku',
    ADD_DUP: 'merge_sku',
  },
  stock: {
    GET_STOCK: 'stock_item',
    UPDATE_STOCK: 'update_stock',
    ADD_STOCK: 'stock',
  },
};
export const GET_MANUFACTURERS = 'get_manufacturers';
export const GET_PACKAGING = 'inventory_packaging';

// Purchase Return
export const SAVE_PURCHASE_RETURN = 'save_purchase_return';
export const RESET_PURCHASE_RETURN = 'reset_purchase_return';
export const SUBMIT_PURCHASE_RETURN = 'submit_purchase_return';
export const SUBMIT_EDIT_PURCHASE_RETURN = 'update_purchase_return';
export const FETCH_PURCHASE_RETURN = 'temp_purchase_return';
export const GET_EDIT_PURCHASE_RETURN = 'get_return';
export const CONVERT_PURCHASE_TO_RETURN = 'convert_purchase_to_return';
export const CONVERT_EXPIRY_TO_RETURN = 'convert_expiry_to_return';
// Settings
export const GET_SETTINGS = 'company_config';
export const UPDATE_SETTINGS = 'save_store_settings';

// Extra
export const UPLOAD_URL = 'upload_url';
