import { getRequest, postRequest } from '../base';
import { AxiosResponse } from 'axios';
import { RCustomer } from '../types/customer.types';

export interface RCustomerLoyalty {
  cid: string;
  color: string;
  createdBy: string;
  createdById: string;
  createdOn: number;
  enabledLoyalty?: boolean;
  id: string;
  name: string;
  table: string;
  discount?: number;
}

export const CustomerLoyaltyService = {
  create: async (data: unknown): Promise<AxiosResponse<{ data: RCustomerLoyalty }>> =>
    postRequest(`create_customer_profile`, data),

  list: async (): Promise<AxiosResponse<{ data: { items: RCustomerLoyalty[] } }>> =>
    getRequest(`customer_profile_listing`),

  get: async (id: string): Promise<AxiosResponse<{ data: { items: RCustomerLoyalty } }>> =>
    getRequest(`get_customer_profile/${id}`),

  update: async (id: string, data: unknown): Promise<AxiosResponse<{ data: RCustomerLoyalty }>> =>
    postRequest(`update_customer_profile/${id}`, data),

  delete: async (id: string): Promise<AxiosResponse> =>
    postRequest(`delete_customer_profile/${id}`, {}),
};

export type RBulkCustomer = RCustomer & {
  profileId?: string;
};

export const BulkCustomerService = {
  getAll: (ids: string[]): Promise<AxiosResponse<{ data: { items: RBulkCustomer[] } }>> =>
    postRequest(`get_bulk_customers`, { ids }),

  update: (data: unknown) => postRequest(`bulk_customer_update`, data),
};
