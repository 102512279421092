import React, { useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { TableIconButton } from '../../../../components/Styled';
import Icon from '../../../../components/FormControl/Icon';
import styles from '../header.module.scss';
import useBoolean from 'hooks/useToggle';
import useLearnmoreData from './hooks/useLearnmoredata';
import Sidebar from './Sidebar';
import { LearnMoreSectionProps } from './types';

const LearnMoreSection: React.FC<LearnMoreSectionProps> = ({ screenkey }) => {
  const { value: isSidebarOpen, toggle: toggleSidebar, setFalse: closeSidebar } = useBoolean(false);

  const { data: helpSectionData, isLoading, fetchData } = useLearnmoreData();

  useEffect(() => {
    if (isSidebarOpen) {
      fetchData(screenkey);
    }
  }, [isSidebarOpen, screenkey]);

  return (
    <>
      <Tooltip title={'Learn More'} placement='left'>
        <TableIconButton onClick={toggleSidebar}>
          <Icon icon={'ri-information-line'} size={'1.2rem'} className={styles.helpIcon} />
        </TableIconButton>
      </Tooltip>

      <Sidebar
        isOpen={isSidebarOpen}
        onClose={closeSidebar}
        helpSectionData={helpSectionData}
        isLoading={isLoading}
      />
    </>
  );
};

export default LearnMoreSection;
