import { TableIconButton } from 'components/Styled';
import { Stack, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { ReportService } from 'services/api';
import { SPastReportType } from '../types';
import { DateConverters } from 'utils/date';
import { useAppContext } from 'contexts/AppContext';
import { ApiError } from 'services/api/base';
import { MRTRowType } from 'components/Table/MRTTable/types';
import { REPORT_STATUS, moveColToFirst } from '../constants';
import Icon from 'components/FormControl/Icon';
import { clickToDownload } from 'utils/misc';
import { getJsonFromS3 } from 'utils/files';
import { SReportItemType, SSelectedFilter, useReportProps } from './types';
import { RAllItemwiseStock } from 'services/api/types/reports.types';
import { REPORT_TYPE, TABLE_MAPPING } from './constants';
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table';
import dayjs from 'dayjs';
import { dateRangeType } from 'components/PageDatePicker/types';

const useReport = ({ dateRange, closeHandler }: useReportProps) => {
  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = React.useState<boolean>(false);
  const [viewModal, setViewModal] = React.useState<boolean>(false);
  const [reportContent, setReportContent] = React.useState<SReportItemType[]>([]);
  const [reportColumns, setReportColumns] = React.useState<MRTColumnDef[]>([]);
  const [columnsFilter, setColumnsFilter] = React.useState<string[]>([]);
  const [groupByFilter, setGroupByFilter] = React.useState<string[]>([]);
  const [reportData, setReportData] = React.useState<SPastReportType[]>([]);
  const [selectedFilter, setSelectedFilter] = React.useState<SSelectedFilter>({
    type: 'All',
  } as SSelectedFilter);
  const {
    snack: { show: showSnackbar },
  } = useAppContext();

  const validate = () =>
    selectedFilter.columns?.length &&
    selectedFilter.groupBy &&
    REPORT_TYPE[selectedFilter.type as keyof typeof REPORT_TYPE];

  const handleGenerateReport = async () => {
    if (!validate()) {
      showSnackbar('Please Provide proper details', 'error');
      return;
    }
    try {
      // if all columns are selected then will pass only All
      let columns = selectedFilter.columns;
      if (selectedFilter.columns.length === columnsFilter.length) columns = ['All'];

      const res = await ReportService.itemWiseStock.generate(
        {
          reportColumns: columns,
          reportGroupBy: selectedFilter.groupBy,
          reportType: REPORT_TYPE[selectedFilter.type as keyof typeof REPORT_TYPE] || '',
        },
        dayjs(dateRange.get[0].startDate).unix(),
        dayjs(dateRange.get[0].endDate).unix(),
      );
      const item = res.data.data.userMessage;
      setReportData((prev) => [
        {
          createdOn: item.createdOn,
          createdOnStr: DateConverters.epochToString(item.createdOn, true),
          endEpoch: DateConverters.epochToString(+item.endEpoch),
          id: item.id,
          index: 1,
          createdBy: item.createdBy,
          downloadStatus: item.downloadStatus,
          monthEpoch: item.monthEpoch,
          reportDownloadLink: item.reportDownloadLink,
          viewStatus: item.viewStatus,
          reportViewLink: item.reportViewLink,
          startEpoch: DateConverters.epochToString(+item.startEpoch),
          reportColumns: item.reportColumns,
          reportType:
            REPORT_TYPE['B2B'] === item.reportType
              ? 'B2B'
              : REPORT_TYPE['B2C'] === item.reportType
              ? 'B2C'
              : REPORT_TYPE['All'] === item.reportType
              ? 'All'
              : 'Stock Transfer',
        },
        ...prev.map((item, index) => ({ ...item, index: index + 2 })),
      ]);
      showSnackbar('Report started generating', 'success');
    } catch (e) {
      console.log(e);
      showSnackbar((e as ApiError).userMessage, 'error');
    }
  };

  const handleDownloadReport = async (downloadLink: string) => {
    try {
      if (!downloadLink) {
        showSnackbar('Report not available', 'error');
        return;
      }
      await clickToDownload(downloadLink);
    } catch (e) {
      console.log(e);
      showSnackbar((e as ApiError).userMessage, 'error');
    }
  };

  const handleViewReport = async (url: string, columns: string[]) => {
    setDialogLoading(true);
    setViewModal(true);
    getJsonFromS3(url).then(async (res) => {
      try {
        const data = (await res.json()) as RAllItemwiseStock[];
        const items = data.map(
          (item, index): SReportItemType => ({
            id: item.id,
            index: index + 1,
            name: item.name,
            packaging: item.packaging,
            rack: item.rack,
            category: item.category,
            manufacturer: item.manufacturer,
            stock: item.stock,
            b2cSaleQty: item.b2cSaleQty,
            b2bSaleQty: item.b2bSaleQty,
            stockTransferQty: item.stockTransferQty,
            totalSaleQty: item.totalSaleQty,
            purchaseQty: item.purchaseQty,
            marginPercent: item.marginPercent,
            supplierName: item.supplierName,
          }),
        );
        setReportContent(items);
        const resColumns = moveColToFirst(columns[0] === 'All' ? columnsFilter : columns, 'Name');
        setReportColumns([
          {
            accessorKey: 'index',
            header: '#',
            maxSize: 10,
            enableColumnFilter: false,
          },
          ...resColumns.map((column) => TABLE_MAPPING[column]).filter((item) => item),
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        setDialogLoading(false);
      }
    });
  };

  const handleFilterChange = (value: string | string[], key: string) => {
    if (key === 'type') {
      setSelectedFilter((prev) => ({
        ...prev,
        type: value as string,
        columns: columnsFilter,
        groupBy: groupByFilter[0],
      }));
    } else
      setSelectedFilter((prev) => ({
        ...prev,
        [key]: value,
      }));
  };

  const rowActionRender = (row: MRTRowType<SPastReportType>) => (
    <Stack direction='row' spacing={0} alignItems='center'>
      {row.original.downloadStatus?.toLowerCase() === REPORT_STATUS.generated.toLowerCase() ? (
        <Tooltip title='Download' placement='right'>
          <TableIconButton onClick={() => handleDownloadReport(row.original.reportDownloadLink)}>
            <Icon icon='ri-download-line' size={'1rem'} />
          </TableIconButton>
        </Tooltip>
      ) : null}
      {row.original.viewStatus?.toLowerCase() === REPORT_STATUS.generated.toLowerCase() ? (
        <Tooltip title='View Report' placement='right'>
          <TableIconButton
            onClick={() =>
              handleViewReport(row.original.reportViewLink, row.original.reportColumns)
            }
          >
            <Icon icon='ri-eye-line' size={'1rem'} />
          </TableIconButton>
        </Tooltip>
      ) : null}
    </Stack>
  );

  const handleApplyFilter = (filter: dateRangeType[]) => {
    dateRange.set(filter);
    closeHandler();
  };

  useEffect(() => {
    setIsDataLoading(true);
    ReportService.itemWiseStock
      .getHeading()
      .then((res) => {
        const filters = res.data.data.filter;
        filters.map((filter) => {
          if (filter.label === 'Columns') {
            setColumnsFilter(filter.options);
            handleFilterChange(filter.options, 'columns');
          } else if (filter.label === 'Group By') {
            setGroupByFilter(filter.options);
            handleFilterChange(filter.options[0], 'groupBy');
          }
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
    ReportService.itemWiseStock
      .list()
      .then((res) => {
        const items = res.data.data.items;
        const reportItems = items.map(
          (item, index): SPastReportType => ({
            createdOn: item.createdOn,
            createdOnStr: DateConverters.epochToString(item.createdOn, true),
            endEpoch: DateConverters.epochToString(+item.endEpoch),
            id: item.id,
            index: index + 1,
            createdBy: item.createdBy,
            downloadStatus: item.downloadStatus,
            monthEpoch: item.monthEpoch,
            reportDownloadLink: item.reportDownloadLink,
            viewStatus: item.viewStatus,
            reportViewLink: item.reportViewLink,
            startEpoch: DateConverters.epochToString(+item.startEpoch),
            reportColumns: item.reportColumns,
            reportType:
              REPORT_TYPE['B2B'] === item.reportType
                ? 'B2B'
                : REPORT_TYPE['B2C'] === item.reportType
                ? 'B2C'
                : REPORT_TYPE['All'] === item.reportType
                ? 'All'
                : 'Stock Transfer',
          }),
        );
        setReportData(reportItems);
        setIsDataLoading(false);
      })
      .catch((e) => {
        console.log(e);
        showSnackbar((e as ApiError).userMessage, 'error');
      });
  }, []);
  return {
    reportData,
    selectedFilter,
    isDataLoading,
    columnsFilter,
    groupByFilter,
    dialogLoading,
    viewModal,
    reportColumns,
    reportContent,
    handleApplyFilter,
    setViewModal,
    handleGenerateReport,
    handleFilterChange,
    rowActionRender,
  };
};
export default useReport;
