// combineReducers
import { combineReducers } from '@reduxjs/toolkit';
import billingTableReducer from './billingTable/slice';
import customerReducer from './customer/slice';

const wholesaleReturnRootReducer = combineReducers({
  billingTable: billingTableReducer,
  customer: customerReducer,
});

export default wholesaleReturnRootReducer;
