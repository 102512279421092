import { ColumnType, RowMapType } from 'components/Common/ProductSearchField/types';
import { formattedRupee } from 'utils/util';
import { SAllSupplierTypes } from '../SupplierSearch/types';

export const PRODUCT_TABLE_CONST: { columns: ColumnType[]; rowMapping: RowMapType[] } = {
  columns: [
    { key: 'Product' },
    { key: 'MRP' },
    { key: 'Stock' },
    { key: 'U.Ratio' },
    { key: 'Rack' },
    { key: 'Pack' },
    { key: 'Manufacturer' },
  ],
  rowMapping: [
    { key: 'name', width: '200', align: 'left' },
    {
      key: 'mrp',
      width: '90',
      align: 'left',
      formatter: (value: unknown) => formattedRupee(value as number),
    },
    { key: 'stock', width: '40', align: 'left' },
    { key: 'unitRatio', align: 'left' },
    { key: 'rack', align: 'left' },
    { key: 'packaging', align: 'left' },
    { key: 'manufacturer', width: '150', align: 'left' },
  ],
};

export const validation = (key: string, value: string | number | SAllSupplierTypes): boolean => {
  switch (key) {
    case 'name':
      return (value as string).length > 0;
    case 'qty':
      return +(value as string) > 0;
    default:
      return true;
  }
};
