import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initLogRocket, initMicrosoftClarity } from './utils/misc';
import { initFirebaseServiceWorker } from './services/serviceWorker';
import { Provider } from 'react-redux';
import globalStore from './store';
import { BrowserRouter } from 'react-router-dom';

initLogRocket();
// setLogRocketUser();
initMicrosoftClarity();
initFirebaseServiceWorker();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={globalStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
