import React from 'react';
import styles from '../../../../layouts/HomeLayout/Sidebar/sidebar.module.scss';
import { StoreUtils } from 'utils/util';
import { useAppContext } from 'contexts/AppContext';
import { ShiftManagementService } from 'services/api/endpoints/shiftManagementService';
import { ApiError } from 'services/api/base';
import ShiftManagement from 'pages/ShiftManagement';
import { SPopupDataTypes } from 'pages/ShiftManagement/types';

interface Props {
  isSidebarOpen: boolean;
}

const ShiftEnd: React.FC<Props> = ({ isSidebarOpen }) => {
  const [isShiftDialogOpen, setIsShiftDialogOpen] = React.useState<boolean>(false);
  const [popupData, setPopupData] = React.useState<SPopupDataTypes | null>(null);
  const shiftPermission = StoreUtils.getKeyFromActiveSession(['shiftPermission']);
  const { pageLoading } = useAppContext();
  const shiftManagementConfig = StoreUtils.getKeyFromActiveSession(['config', 'shiftManagement']);

  const handleShiftDialogOpen = async () => {
    if (shiftPermission) {
      try {
        pageLoading.show();
        const response = await ShiftManagementService.checkEndShift();
        const resData = response.data;
        setPopupData({
          popupMsg: resData.data.userMessage,
          showCurrencyPopup: resData.statusCode === 200 ? true : false,
        });
        setIsShiftDialogOpen(true);
      } catch (error) {
        const apiError = error as ApiError;
        console.log(error);
        setPopupData({
          popupMsg: apiError.userMessage,
          showCurrencyPopup: false,
        });
      } finally {
        pageLoading.hide();
        setIsShiftDialogOpen(true);
      }
    } else {
      setIsShiftDialogOpen(true);
    }
  };

  return (
    <>
      {shiftManagementConfig ? (
        <div onClick={handleShiftDialogOpen} className={styles.logoutContainer}>
          <i className={`ri-calendar-schedule-line ${styles.endShiftIcon}`} />
          {isSidebarOpen && <span>End Shift</span>}
        </div>
      ) : null}

      {isShiftDialogOpen && (
        <ShiftManagement
          open={isShiftDialogOpen}
          onClose={() => setIsShiftDialogOpen(false)}
          popupData={popupData as SPopupDataTypes}
        />
      )}
    </>
  );
};
export default ShiftEnd;
