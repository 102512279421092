import SlideUpDialog from 'components/Dialoges/SlideUpDialog';
import Table from 'components/Table/MRTTable';
import { ReportViewProps } from './types';
import styles from './stockReport.module.scss';
import SearchBar from 'components/Common/SearchBar';
import { useState } from 'react';

const ReportView: React.FC<ReportViewProps> = ({ open, onClose, columns, rows, loading }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const handleChange = (value: string) => setSearchQuery(value);

  return (
    <SlideUpDialog
      containerClass={styles.reportViewContainer}
      onClose={onClose}
      loading={loading}
      open={open}
      title={'Report Details'}
    >
      <div className={styles.tableContainer}>
        <SearchBar onChange={handleChange} defaultValue={searchQuery} />
        <Table searchString={searchQuery} columns={columns} rows={rows} />
      </div>
    </SlideUpDialog>
  );
};
export default ReportView;
