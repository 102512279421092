import React from 'react';
import AdminEndShift from './adminShiftEnd';
import StaffShiftEndDialog from './Dialoges/StaffShiftEnd';
import { Props } from './types';
import { StoreUtils } from 'utils/util';

const ShiftManagement: React.FC<Props> = ({ open, onClose, popupData }) => {
  const shiftPermission = StoreUtils.getKeyFromActiveSession(['shiftPermission']);
  return shiftPermission ? (
    <AdminEndShift open={open} onClose={onClose} popupData={popupData} />
  ) : (
    <StaffShiftEndDialog open={open} onClose={onClose} />
  );
};
export default ShiftManagement;
