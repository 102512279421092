import React from 'react';
import { Paper } from '@mui/material';
import styles from './wrapper.module.scss';
import { PropsTypes } from './types';

const PageWrapper: React.FC<PropsTypes> = (props) => (
  <Paper className={styles.pageWrapper}>{props.children}</Paper>
);

export default PageWrapper;
