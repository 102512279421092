import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import React from 'react';
import styles from './notificationCard.module.scss';
import { Props } from './type';
import { useNavigate } from 'react-router-dom';
import Button from '../../../FormControl/Button';
import { NEAR_EXPIRY_CATEGORY, LOW_STOCK_REMINDER_CATEGORY } from '../constants';
import dayjs from 'dayjs';
import Icon from '../../../FormControl/Icon';

const NotificationCard = (props: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.item.type === 'external') {
      window.open(props.item.deeplink);
    } else if (props.item.type === 'internal') {
      if (props.item.category === LOW_STOCK_REMINDER_CATEGORY)
        navigate(`/inventory/${props.item.deeplink}`);
      else if (props.item.category === NEAR_EXPIRY_CATEGORY) navigate('report/expiry-report');
    }
    props.onClose();
  };

  return (
    <>
      <Card className={styles.container}>
        <CardHeader
          title={
            <Stack direction={'column'}>
              <Typography variant={'h4'}>{props.item.title}</Typography>
              <Typography fontSize={'0.7rem'}>
                {dayjs.unix(props.item.createdOn).format('hh:mm A')}
                {'  '}
                {dayjs.unix(props.item.createdOn).format('DD/MM/YY')}
              </Typography>
            </Stack>
          }
          subheader={
            <Typography marginTop={'5px'} fontSize={'0.8rem'}>
              {props.item.msg}
            </Typography>
          }
          className={styles.header}
        />
        <Divider />
        <CardContent className={styles.content}>
          <Button text={'Take Action'} size={'small'} onClick={handleClick} />
          <Tooltip title='Mark as Read' placement='right'>
            <IconButton size='small' onClick={() => props.handleMarkAsRead(props.item.id)}>
              <Icon icon={'ri-check-line'} />
            </IconButton>
          </Tooltip>
        </CardContent>
      </Card>
    </>
  );
};

export default NotificationCard;
