import React from 'react';

type eventKey = 'ctrlKey' | 'shiftKey' | 'altKey' | 'metaKey';
const map: { [key: string]: eventKey } = {
  ctrl: 'ctrlKey',
  alt: 'altKey',
  shift: 'shiftKey',
  command: 'metaKey',
};

interface keyMapType {
  key: string;
  handler: () => void;
  condition?: boolean;
}
const useKeyShortCut = (keyMap: keyMapType[]) => {
  const handler = (e: KeyboardEvent) => {
    if (e.key) {
      // Prevent Default Behaviour for some keys
      if (
        e.key === 'F1' ||
        (e.altKey && e.key === 'd') ||
        (e.altKey && e.key === 'e') ||
        (e.ctrlKey && e.key === 'd') ||
        (e.ctrlKey && e.key === 'p')
      )
        e.preventDefault();

      keyMap.map((keyMapItem) => {
        const { key: keys, handler, condition = true } = keyMapItem;
        if (
          keys
            .split('+')
            .every(
              (key) =>
                e[map[key]] ||
                e.key.toLowerCase() === key.toLowerCase() ||
                e.code.toLowerCase() === key.toLowerCase(),
            ) &&
          condition
        ) {
          handler();
        }
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [handler]);
};

export default useKeyShortCut;
