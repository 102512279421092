import dayjs, { Dayjs } from 'dayjs';
import { SCaseReducer } from './types';
import { INITIAL_STATE } from '../../constants';
import { SBillEntry, SBillPrint } from '../../types';
import { GrnEntryUtils } from '../../utils';

const loadRows: SCaseReducer<{ rows: SBillEntry[]; mode: 'new' | 'append' }> = (state, action) => {
  state.rows = action.payload.rows;
};

const handleRowChange: SCaseReducer<{
  key: string;
  value: unknown;
  rowIndex: number;
  batchIndex?: number;
}> = (state, action) => {
  const payload = action.payload;
  let row = state.rows[payload.rowIndex];
  switch (payload.key) {
    case 'qty': {
      row.poQty = +(payload.value as number);
      break;
    }
    case 'expiry': {
      const value = payload.value as Dayjs | null;
      console.log(dayjs(value).unix());
      row.batchDetails[payload.batchIndex || 0] = {
        ...row.batchDetails[payload.batchIndex || 0],
        expiry: dayjs(value).unix(),
      };
      break;
    }
    case 'rejectedQty': {
      const newValue = Math.min(
        +(payload.value as string | number),
        +row.batchDetails[payload.batchIndex || 0].receivedQty,
      );

      row.batchDetails[payload.batchIndex || 0] = {
        ...row.batchDetails[payload.batchIndex || 0],
        rejectedQty: newValue,
        acceptedQty: +row.batchDetails[payload.batchIndex || 0].receivedQty - +newValue,
      };
      break;
    }
    case 'receivedQty': {
      const remainingQty = GrnEntryUtils.getItemRemainingQty(
        row.poQty,
        row.batchDetails,
        payload.batchIndex || 0,
      );
      const newValue =
        +(payload.value as number | string) < remainingQty ? '' + payload.value : remainingQty;

      row.batchDetails[payload.batchIndex || 0] = {
        ...row.batchDetails[payload.batchIndex || 0],
        receivedQty: '' + newValue,
        acceptedQty: +newValue - +row.batchDetails[payload.batchIndex || 0].rejectedQty,
      };
      break;
    }
    case 'mrp':
    case 'rejectedReason':
    case 'batchNo': {
      row.batchDetails[payload.batchIndex || 0] = {
        ...row.batchDetails[payload.batchIndex || 0],
        [payload.key]: payload.value,
      };
      break;
    }
    default: {
      row = {
        ...row,
        [payload.key]: payload.value,
      };
      break;
    }
  }
  state.rows[payload.rowIndex] = row;
};

const updateClient: SCaseReducer<{ key: string; value: unknown }> = (state, action) => {
  state.client = {
    ...state.client,
    [action.payload.key]: action.payload.value,
  };
};

const setPrintState: SCaseReducer<SBillPrint> = (state, action) => {
  state.client = {
    ...state.client,
    printState: {
      ...state.client.printState,
      ...action.payload,
    },
  };
};

const handleExpandToggle: SCaseReducer<void> = (state) => {
  state.rows = state.rows.map((item) => ({
    ...item,
    openDetails: state.client.expandAll,
  }));
  state.client.expandAll = !state.client.expandAll;
};

const addBatchToItem: SCaseReducer<{
  rowIndex: number;
}> = (state, action) => {
  const payload = action.payload;
  const remainingQty =
    state.rows[payload.rowIndex].poQty -
    +state.rows[payload.rowIndex].batchDetails.reduce((acc, item) => acc + +item.receivedQty, 0);
  state.rows[payload.rowIndex].batchDetails.push({
    ...INITIAL_STATE.SBatchDetails(),
    receivedQty: '' + remainingQty,
    acceptedQty: remainingQty,
  });
};

const deleteBatchFromItem: SCaseReducer<{
  rowIndex: number;
  batchIndex: number;
}> = (state, action) => {
  const payload = action.payload;
  const batches = state.rows[payload.rowIndex].batchDetails.filter(
    (_, index) => index !== payload.batchIndex,
  );
  state.rows[payload.rowIndex].batchDetails = batches || [];
};

export default {
  loadRows,
  handleRowChange,
  addBatchToItem,
  updateClient,
  setPrintState,
  deleteBatchFromItem,
  handleExpandToggle,
};
