import Chip from '@mui/material/Chip';
import { capitalize, formattedRupee } from 'utils/util';
import styles from './chip.module.scss';
import { Props } from './types';
import React from 'react';
import { Box, Popover, Stack, Typography } from '@mui/material';
import { Partial } from '../SalePayment/constants';

export const PaymentModeChip = ({ paymentMode, fullWidth, pay }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handleSplitClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleSplitClose = () => {
    setAnchorEl(null);
  };

  const className =
    paymentMode.toLowerCase() === 'pending' || paymentMode.toLowerCase().includes('cancelled')
      ? styles.pendingPayment
      : paymentMode.toLowerCase().includes(Partial.toLowerCase()) ||
        paymentMode.toLowerCase() === 'failed' ||
        paymentMode.toLowerCase().includes('closed')
      ? styles.partialPayment
      : paymentMode.toLowerCase() === 'not required' ||
        paymentMode.toLowerCase().includes('ready to pick') ||
        paymentMode.toLowerCase().includes('picked')
      ? styles.blueChip
      : styles.successPayment;

  return paymentMode === '-' ? (
    <>-</>
  ) : (
    <>
      <Chip
        className={`${className} ${fullWidth && styles.fullWidth} payment-mode-chip`}
        label={capitalize(paymentMode)}
        {...(pay?.split && { onClick: handleSplitClick })}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleSplitClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={styles.splitBox}>
          <Box className={styles.header}>
            <Typography variant='h5'>{formattedRupee(pay?.amount)}</Typography>
          </Box>

          {pay?.split?.map((item) => (
            <Stack
              key={item.type}
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              className={styles.payBox}
            >
              <Typography variant='subtitle1'>{item.type}</Typography>
              <Typography variant='h5'>{formattedRupee(item.amount)}</Typography>
            </Stack>
          ))}
        </Box>
      </Popover>
    </>
  );
};
