import { Navigate, useLocation } from 'react-router-dom';
import { isUserAuthenticated } from 'utils/auth';
import HomeLayout from '../layouts/HomeLayout';
import BaseLayout from '../layouts/BaseLayout';
import { checkForAccessControl } from '../utils/accessControl';

const PrivateRoute = () => {
  const auth = isUserAuthenticated();
  const location = useLocation();

  if (auth) {
    const isErrorPage = location.pathname.startsWith('/status');
    if (isErrorPage) return <BaseLayout />;

    // access control filters to block url
    checkForAccessControl();

    return <HomeLayout />;
  }
  return <Navigate to='/login' state={{ from: location }} replace />;
};

export default PrivateRoute;
