import React from 'react';
import { Autocomplete, Checkbox, CircularProgress, createFilterOptions } from '@mui/material';
import TextField from '../../../../components/FormControl/TextField';
import Icon from '../../../../components/FormControl/Icon';
import styles from './customerSearch.module.scss';
import { Customer, Props } from './types';
import AddCustomer from 'pages/Customer/components/AddCustomer';
import { SCustomerSelected } from 'components/Common/CustomerSearch/types';
import { SEARCH_CHAR_LIMIT, SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import { useDebouncedCallback } from 'use-debounce';
import { ApiError } from 'services/api/base';
import { CustomerService } from 'services/api';
import { useAppContext } from 'contexts/AppContext';
import Select from '../../../../components/FormControl/Select';
import { ADD_CUSTOMER, SELECT_OPTIONS } from './constants';
import { isNumeric } from 'utils/util';

const CustomerSearch: React.FC<Props> = (props) => {
  const [toggleOpen, setToggleOpen] = React.useState<boolean>(false);
  const [customerOption, setCustomerOption] = React.useState<Customer[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [key, setKey] = React.useState<string>(SELECT_OPTIONS[0]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const filter = createFilterOptions<Customer>();
  const {
    snack: { show: showSnackbar },
  } = useAppContext();

  const handleAddCustomer = (newCustomer: SCustomerSelected) => {
    const newCustomerOption: SCustomerSelected = {
      name: newCustomer.name,
      phone: newCustomer.phone,
      discount: newCustomer.discount,
      id: newCustomer.id,
      loyalty: newCustomer.loyalty,
      profileId: newCustomer.profileId,
      profileName: newCustomer.profileName,
      enabledLoyalty: newCustomer.enabledLoyalty,
      color: newCustomer.color,
    };
    setCustomerOption([...props.selectedCustomer, newCustomerOption as Customer]);
  };

  const handleInputChange = (key: string, newValue: string) => {
    const isValidInput = key === 'Phone' ? isNumeric(newValue) : true;

    if (isValidInput) {
      setInputValue(newValue);
    }
  };

  const handleSelectChange = (key: string) => {
    setKey(key);
    handleReset();
  };

  const handleReset = () => {
    setCustomerOption([]);
    setInputValue('');
  };

  React.useEffect(() => {
    debouncedSearch(key.toLowerCase(), inputValue.trim());
  }, [inputValue]);

  const debouncedSearch = useDebouncedCallback((key: string, searchText: string) => {
    if (!(searchText.trim() && searchText.length >= SEARCH_CHAR_LIMIT)) {
      setCustomerOption([]);
      return;
    }

    setIsLoading(true);
    CustomerService.newCustomerSearch(key, searchText)
      .then((response) => {
        const responseData = response.data.data.items || [];
        setCustomerOption(
          responseData.map(
            (customer): Customer => ({
              id: customer.id,
              name: customer.name,
              phone: customer.phone,
              discount: customer.discount,
              loyalty: customer.loyalty,
              profileId: customer.profileId,
              profileName: customer.profileName,
              enabledLoyalty: customer.enabledLoyalty,
              color: customer.color,
            }),
          ),
        );
      })
      .catch((error) => {
        showSnackbar((error as ApiError).userMessage, 'error');
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, SEARCH_DEBOUNCE_TIME);

  const selectedIds = props.selectedCustomer.map((item) => item.id);
  return (
    <div className={styles.customerSearchBox}>
      <div className={styles.select}>
        <Select
          onChange={(e) => handleSelectChange(e.target.value as string)}
          label='Customer Search By'
          items={SELECT_OPTIONS}
          value={key}
          variant={props.variant || 'outlined'}
        />
      </div>
      <Autocomplete
        multiple
        className={styles.customerSearch}
        options={customerOption}
        disableCloseOnSelect
        value={props.selectedCustomer}
        inputValue={inputValue}
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        loading={props.isCustomerLoading}
        getOptionLabel={(option) => `${option?.name}-${option?.phone}`}
        limitTags={3}
        isOptionEqualToValue={(option: Customer, value: Customer) => option.id === value.id}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option?.inputValue === ADD_CUSTOMER ? (
              <div className={styles.addOption} onClick={() => setToggleOpen(true)}>
                <span>
                  <Icon icon='ri-add-line' size='1.2rem' />
                  {ADD_CUSTOMER}
                </span>
              </div>
            ) : (
              <div>
                <Checkbox
                  icon={<Icon icon='ri-checkbox-blank-line' />}
                  checkedIcon={<Icon icon='ri-checkbox-fill' />}
                  checked={selectedIds.includes(option.id)}
                  className={styles.checkbox}
                />
                {`${option?.name}-${option?.phone}`}
              </div>
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Customer'
            variant={props.variant || 'outlined'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '' && filtered.length === 0) {
            filtered.push({
              inputValue: ADD_CUSTOMER,
              name: '',
              id: 'add-customer',
              phone: '',
            });
          }
          return filtered;
        }}
        onChange={(e, newValue) => {
          props.handleChange(
            (newValue as Customer[]).filter((item) => item?.inputValue !== ADD_CUSTOMER),
          );
          handleReset();
        }}
        onInputChange={(event, newValue, reason) => {
          if (reason === 'input') {
            handleInputChange(key, newValue);
          } else if (reason !== 'reset') {
            handleReset();
          }
        }}
      />

      {toggleOpen && (
        <AddCustomer
          open={toggleOpen}
          onClose={() => setToggleOpen(false)}
          onSubmit={handleAddCustomer}
        />
      )}
    </div>
  );
};

export default CustomerSearch;
