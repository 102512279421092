import React from 'react';
import { ContextType, ProviderTypes } from './types';

export const WholeSaleBillEntryContext = React.createContext<ContextType>({} as ContextType);

export const WholeSaleBillEntryProvider: React.FC<ProviderTypes> = (props) => {
  // Reset Modal
  const [reset, setReset] = React.useState(false);
  const resetState = {
    isOpen: reset,
    open: () => setReset(true),
    close: () => setReset(false),
  };

  // Submit Modal
  const [submit, setSubmit] = React.useState(false);
  const submitState = {
    isOpen: submit,
    open: () => setSubmit(true),
    close: () => setSubmit(false),
  };

  // Search Product Table Modal
  const [productInputAnchorEl, setProductInputAnchorEl] = React.useState<HTMLInputElement | null>(
    null,
  );
  const productState = {
    anchorEl: productInputAnchorEl,
    open: (element: HTMLInputElement | HTMLTextAreaElement | null) =>
      setProductInputAnchorEl(element as HTMLInputElement),
    close: () => setProductInputAnchorEl(null),
  };

  // Add Skew Modal
  const [addSkew, setAddSkew] = React.useState(false);
  const addSkewState = {
    isOpen: addSkew,
    open: () => setAddSkew(true),
    close: () => setAddSkew(false),
  };

  // Split View Modal
  const [showSplitView, setShowSplitView] = React.useState<boolean>(false);
  const splitViewState = {
    isOpen: showSplitView,
    open: () => setShowSplitView(true),
    close: () => setShowSplitView(false),
  };

  // Payment Modal
  const [showPaymentModal, setShowPaymentModal] = React.useState<boolean>(false);
  const paymentModalState = {
    isOpen: showPaymentModal,
    open: () => setShowPaymentModal(true),
    close: () => setShowPaymentModal(false),
  };

  // Lot Modal
  const [lot, setLot] = React.useState(false);
  const lotState = {
    isOpen: lot,
    open: () => setLot(true),
    close: () => setLot(false),
  };

  const [page, setPage] = React.useState(0);
  const pageState = {
    page,
    setPage,
  };
  return (
    <WholeSaleBillEntryContext.Provider
      value={{
        reset: resetState,
        submit: submitState,
        productField: productState,
        addSkew: addSkewState,
        lot: lotState,
        page: pageState,
        splitView: splitViewState,
        paymentModal: paymentModalState,
      }}
    >
      {props.children}
    </WholeSaleBillEntryContext.Provider>
  );
};
