import { roundNumber, StoreUtils } from '../../utils/util';
import dayjs from 'dayjs';
import { DEFAULT_GST } from '../../utils/constants';
import { pendingDraftTypes, SBillEntry } from './types';
import { SearchProductType } from '../../components/Common/ProductSearchField/types';
import { RAddSkewStock } from '../Inventory/inventory.interface';
import { UserAccessControlData } from '../../utils/accessControl';
import { localStore } from '../../services/browserStorage';

export const BillingUtils = {
  calculateTotal: (
    mrp: number | string,
    qty: number | string,
    discount: number | string,
    overallDiscount: number | string | undefined,
    gst: number,
    loyalty: number | string | undefined,
    loyaltyRedeemDiscount: number | string | undefined,
  ) => {
    mrp = +(mrp || 0);
    qty = +(qty || 0);
    const sellingPrice = +(mrp - (mrp * +(discount || 0)) / 100);
    const discountAmount = ((+(discount || 0) + +(overallDiscount || 0)) / 100) * (mrp * qty);
    discount = +(discount || 0) + +(overallDiscount || 0) + +(loyaltyRedeemDiscount || 0);
    gst = +(gst || 0);
    const netItemAmount = (1 - discount / 100) * (mrp * qty);
    const loyaltyAmount = (+(loyalty || 0) / 100) * netItemAmount;
    const gstAmount = netItemAmount - netItemAmount / (1 + gst / 100);
    return {
      itemAmount: mrp * qty,
      netItemAmount,
      discountAmount,
      gstAmount: gstAmount,
      cgst: gstAmount / 2,
      sgst: gstAmount / 2,
      sellingPrice: roundNumber(sellingPrice),
      loyaltyAmount,
    };
  },

  calculateMarginPercent: (
    effectivePTR: number | undefined,
    netItemAmount: number,
    billQty: number | string,
    looseEnabled: boolean,
    unitRatio: number,
  ) => {
    if (typeof effectivePTR === 'number') {
      effectivePTR = looseEnabled ? +effectivePTR / unitRatio : +effectivePTR;

      return roundNumber((1 - (effectivePTR * +billQty) / netItemAmount) * 100);
    }
  },
  validate: {
    name: (productId: string, productName: string) => productId !== '' && productName !== '',
    billQty: (productQty: number | string) => +productQty > 0,
    mrp: (productMRP: string | number, productMargin?: number) =>
      +productMRP > 0 &&
      (typeof productMargin === 'number' &&
      StoreUtils.getKeyFromActiveSession(['config', 'restrictMargin'])
        ? productMargin > 0
        : true),
    sellingPrice: (sellingPrice: string | number, price: string | number) =>
      +sellingPrice > 0 && +price >= +sellingPrice,
    date: (value: number | null) => (value ? dayjs.unix(value).isValid() : true),
    customer: {
      name: (value: string | undefined, isSchedule: boolean) =>
        (!StoreUtils.getKeyFromActiveSession(['config', 'isScheduleDrugDisable']) && isSchedule) ||
        StoreUtils.getKeyFromActiveSession(['config', 'mandateCustomer'])
          ? (value || '').length > 0
          : true,
      doctor: (value: string | undefined, isSchedule: boolean) =>
        !StoreUtils.getKeyFromActiveSession(['config', 'isScheduleDrugDisable']) && isSchedule
          ? (value || '').length > 0
          : true,
      contactNo: (
        reminderDay: number | undefined,
        isSchedule: boolean,
        contactNo: string | undefined,
      ) =>
        (reminderDay || 0) > 0 ||
        (!StoreUtils.getKeyFromActiveSession(['config', 'isScheduleDrugDisable']) && isSchedule) ||
        StoreUtils.getKeyFromActiveSession(['config', 'mandateCustomer'])
          ? contactNo?.length === 10
          : (contactNo?.length || 0) > 0
          ? contactNo?.length === 10
          : true,
    },
  },
  getUnitStock: (stock: string, unitRatio = 1) => {
    let qty = stock;
    let looseQty = '0';
    if (stock) {
      if (stock.includes(':')) {
        const splitStock = stock.split(':');
        qty = splitStock[0];
        looseQty = splitStock[1];
      }
      return +qty * +unitRatio + +looseQty;
    }
    return 0;
  },
  checkQtyAgainstStock: (
    stock: string,
    billQty: string | number,
    isLooseBill: boolean,
    unitRatio = 1,
  ) => {
    if (!isLooseBill) return +billQty > +String(stock || 0).split(':')[0];
    else {
      const qty = BillingUtils.getUnitStock(stock, unitRatio);
      return +billQty > qty;
    }
  },

  /**
   * Convert Search Result Data into Product Row
   */
  convertSearchToProduct: (
    productData: SearchProductType,
    selectedBatchIdx: number,
    billQty: number | string,
    overallDiscountPercent: string | number,
    overallLoyaltyPercent: number | string | undefined,
  ) => {
    const productBatches = productData?.batches || [];
    const selectedBatchItem = productData.batches?.[selectedBatchIdx] || {};

    // Initial details from selected product
    const discount = selectedBatchItem.discount ?? productData.discount ?? 0;
    const mrp = selectedBatchItem.mrp || productData.mrp || 0;
    const stock = selectedBatchItem.stock || productData.stock || '';
    const gst = productData.gst ?? DEFAULT_GST;
    const unitRatio = productData.unitRatio || 1;
    // If product is looseEnabled, price is mrp/unitRatio else price is mrp
    const price = roundNumber(productData.looseEnabled ? mrp / unitRatio : mrp);

    const row: SBillEntry = {
      id: productData.id,
      name: productData.name,
      batchNo: selectedBatchItem?.batchNo || '',
      batchId: selectedBatchItem.id,
      batches: productBatches,
      expiry: selectedBatchItem.expiry || null,
      looseEnabled: !!productData.looseEnabled,
      unitRatioLock: !!productData.unitRatioLock,
      maximumDiscount: +(productData.maximumDiscount || 0),
      unitRatio,
      billQty,
      price,
      mrp,
      discount,
      loyalty: selectedBatchItem.loyalty,
      stock,
      gst,
      effPtr: selectedBatchItem?.effPtr,
      packaging: productData.packaging,
      hsnCode: productData.hsnCode,
      manufacturer: productData.manufacturer,
      discountEnabled: productData.discountEnabled,
      rack: productData.rack || '',
      scheduleType: productData.scheduleType || '',
      molecule: productData.molecule || '',
      totalStock: productData.stock || '',
      // <--For Wanted Note-->
      wantednoteId: productData.wantednoteId,
      threshold: productData.threshold,
      maxThreshold: productData.maxThreshold,
      // <!--For Wanted Note-->

      ...BillingUtils.calculateTotal(
        price,
        billQty,
        discount,
        overallDiscountPercent,
        gst,
        selectedBatchItem.loyalty,
        overallLoyaltyPercent,
      ),
    };

    row['marginPercent'] = BillingUtils.calculateMarginPercent(
      row.effPtr,
      row.netItemAmount,
      row.billQty,
      row.looseEnabled,
      row.unitRatio,
    );
    return row;
  },

  convertSkewToProduct: (
    item: RAddSkewStock,
    overallDiscount: string | number,
    overallLoyaltyPercent: string | number,
  ) => {
    const sku = item.sku;
    const batches = item.stock;
    const selectedBatchItem = batches?.[0] || {};

    const row: SBillEntry = {
      id: sku.id,
      name: sku.name,
      batchNo: selectedBatchItem?.batchNo || '',
      batches: batches,
      loyalty: selectedBatchItem.loyalty,
      expiry: selectedBatchItem.expiry ? selectedBatchItem.expiry : null,
      billQty: 1,
      batchId: selectedBatchItem?.id || '',
      threshold: sku.threshold,
      maxThreshold: sku.maxThreshold,
      mrp: sku.mrp,
      price: sku.mrp,
      discount: sku.discount,
      unitRatio: sku.unitRatio,
      looseEnabled: false,
      stock: sku.stock,
      gst: sku.gst,
      effPtr: selectedBatchItem.effPtr,
      marginPercent: selectedBatchItem.marginPercent,
      packaging: sku.packaging,
      hsnCode: sku.hsnCode,
      manufacturer: sku.manufacturer,
      rack: sku.rack,
      scheduleType: sku.scheduleType || '',
      ...BillingUtils.calculateTotal(
        sku.mrp,
        1,
        sku.discount,
        overallDiscount,
        sku.gst,
        selectedBatchItem.loyalty,
        overallLoyaltyPercent,
      ),
    };

    return row;
  },

  shouldAskForPassCode: () =>
    StoreUtils.getKeyFromActiveSession(['config', 'enableBillPin']) &&
    !UserAccessControlData.get('enableBillPin'),

  removeLocalDraft: (draftIndex: string) =>
    localStore.remove(`bill_${StoreUtils.getKeyFromActiveSession(['cid'])}_${draftIndex}`),

  saveLocalDraft: (draftIndex: string, data: object) =>
    localStore.set(`bill_${StoreUtils.getKeyFromActiveSession(['cid'])}_${draftIndex}`, data),
};

export const DraftIdxUtil = {
  list: () => ({
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    ...(StoreUtils.getKeyFromActiveSession(['config', 'estimateBill']) ? { Estimate: 6 } : {}),
  }),

  mapValueToKey: (value: number) => {
    const key = Object.entries(DraftIdxUtil.list()).find((entry) => entry[1] === value)?.[0];
    return key || '';
  },
  validate: (idx: number) => Object.values(DraftIdxUtil.list()).includes(idx),

  pendingDrafts: () => {
    const cid = StoreUtils.getKeyFromActiveSession(['cid']);
    const draftIndices = Object.values(DraftIdxUtil.list());

    return draftIndices.reduce((drafts, index) => {
      const key = `bill_${cid}_${index}`;
      if (localStorage.getItem(key)) {
        drafts[index === 6 ? 'Estimate' : index.toString()] = true;
      }
      return drafts;
    }, {} as pendingDraftTypes);
  },
};
