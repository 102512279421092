import React from 'react';
import { ContextType, ProviderTypes } from './types';

export const SalesReturnContext = React.createContext<ContextType>({} as ContextType);

export const SalesReturnProvider: React.FC<ProviderTypes> = (props) => {
  // Reset Modal
  const [reset, setReset] = React.useState(false);
  const resetState = {
    isOpen: reset,
    open: () => setReset(true),
    close: () => setReset(false),
  };

  // Submit Modal
  const [submit, setSubmit] = React.useState(false);
  const submitState = {
    isOpen: submit,
    open: () => setSubmit(true),
    close: () => setSubmit(false),
  };

  // Search Product Table Modal
  const [productInputAnchorEl, setProductInputAnchorEl] = React.useState<HTMLInputElement | null>(
    null,
  );
  const productState = {
    anchorEl: productInputAnchorEl,
    open: (element: HTMLInputElement | HTMLTextAreaElement | null) =>
      setProductInputAnchorEl(element as HTMLInputElement),
    close: () => setProductInputAnchorEl(null),
  };

  const [addUnitRatio, setAddUnitRatio] = React.useState(false);
  const addUnitRatioState = {
    isOpen: addUnitRatio,
    open: () => setAddUnitRatio(true),
    close: () => setAddUnitRatio(false),
  };

  // Add Skew Modal
  const [addSkew, setAddSkew] = React.useState(false);
  const addSkewState = {
    isOpen: addSkew,
    open: () => setAddSkew(true),
    close: () => setAddSkew(false),
  };

  return (
    <SalesReturnContext.Provider
      value={{
        reset: resetState,
        submit: submitState,
        productField: productState,
        addUnitRatio: addUnitRatioState,
        addSkew: addSkewState,
      }}
    >
      {props.children}
    </SalesReturnContext.Provider>
  );
};
