import { LoginService } from './../services/api/index';
import { localStore, sessionStore } from '../services/browserStorage';
import { removeFCMToken } from '../firebase';
import { StoreUtils } from './util';
import { initMicrosoftClarity, sendFCMTokenToServer } from './misc';

export const isUserAuthenticated = () => {
  const { session } = StoreUtils.getActiveSession();
  if (session) {
    return session['session-token'];
  }
};

export const handleLogout = async (removeSession = true) => {
  if (removeSession) await LoginService.logOut();
  await removeFCMToken();
  sessionStore.clear();
  localStore.clear();
  window.location.href = '/login';
};

export const handleLoginSuccess = async () => {
  // setLogRocketUser();
  initMicrosoftClarity();
  await sendFCMTokenToServer();
};

export const UserUtils = {
  isStoreAdmin: () => StoreUtils.getKeyFromActiveSession(['userData', 'isAdmin']) || false,
  is1PAdmin: () => StoreUtils.getKeyFromActiveSession(['userData', 'internal']) || false,
  isSuperAdmin: () => StoreUtils.getKeyFromActiveSession(['userData', 'superAdmin']) || false,
  isInternalAdmin: () => StoreUtils.getKeyFromActiveSession(['userData', 'internalAdmin']) || false,
  isChainStore: () => StoreUtils.getKeyFromActiveSession(['userData', 'isChainStore']) || false,
  isChainStoreMaster: () =>
    StoreUtils.getKeyFromActiveSession(['userData', 'isChainMaster']) || false,
  isMasterApprover: () =>
    StoreUtils.getKeyFromActiveSession(['userData', 'masterAuthority']) || false,
  isRequestedSku: () => StoreUtils.getKeyFromActiveSession(['userData', 'requestedSku']) || false,
};

export const CompanyUtils = {
  hasMasterRestriction: () =>
    StoreUtils.getKeyFromActiveSession(['config', 'masterRestriction']) || false,
};
