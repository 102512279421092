import { Badge, IconButton } from '@mui/material';
import styles from './header.module.scss';
import Icon from '../../../components/FormControl/Icon';
import NotificationPanel from '../../../components/Dialoges/NotificationPanel';
import React from 'react';
import useToggle from '../../../hooks/useToggle';
import { useAppContext } from '../../../contexts/AppContext';
import { SNotification } from '../../../components/Dialoges/NotificationPanel/types';
import { NotificationService } from '../../../services/api';
import { UserUtils } from '../../../utils/auth';

const NotificationSection = () => {
  const { value: showNotificationPanel, set: setShowNotificationPanel } = useToggle();
  const { appNotification } = useAppContext();
  const [notification, setNotification] = React.useState<SNotification[]>([]);
  const admin = UserUtils.isStoreAdmin();

  const removeNotification = (id: string) => {
    setNotification((prevData) => prevData.filter((item) => !(item.id === id)));
  };

  const handleClick = async () => {
    try {
      const response = await NotificationService.list();
      const responseData = response.data.data.items;
      setNotification(responseData);
      responseData.length ? appNotification.show() : appNotification.hide();
      setShowNotificationPanel(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClose = () => {
    setShowNotificationPanel(false);
    if (!notification.length) {
      console.log(notification.length);
      appNotification.hide();
    }
  };

  return (
    <>
      {admin ? (
        <IconButton onClick={handleClick} className={styles.notificationAction}>
          <Badge invisible={!appNotification.get} variant={'dot'} color='primary'>
            <Icon
              icon={appNotification.get ? 'ri-notification-4-fill' : 'ri-notification-4-line'}
              size={'1.1rem'}
              className={appNotification.get && styles.notificationCount}
            />
          </Badge>
        </IconButton>
      ) : null}
      <NotificationPanel
        open={showNotificationPanel}
        onClose={handleOnClose}
        notification={notification}
        removeNotification={removeNotification}
      />
    </>
  );
};

export default NotificationSection;
