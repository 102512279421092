import { AxiosResponse } from 'axios';
import { getRequest, postRequest } from '../base';

export const PurchaseOrderService = {
  list: async (): Promise<AxiosResponse<{ data: { items: ROrderTypeInfo[] } }>> =>
    getRequest(`po_integration`),

  login: async (type: string, userName: string, password: string) =>
    postRequest(`purchase_integration_login?type=${type}`, {
      login: userName,
      pwd: password,
    }),

  getBranchesPO: async (from: number, to: number): Promise<AxiosResponse> => {
    const url = `po_received?from=${from}&to=${to}`;
    return getRequest(url);
  },
  sync: async (type: string): Promise<AxiosResponse<{ data: { userMessage: string } }>> =>
    postRequest(`purchase_integration_sync?type=${type}`),

  listOrder: async (
    type: string,
    from?: number,
    to?: number,
  ): Promise<AxiosResponse<{ data: { items: ROrder[] } }>> =>
    getRequest(`integration_order?type=${type}&from=${from}&to=${to}`),

  createBranchPO: async (data: { items: RBranchPurchaseOrder[] }): Promise<AxiosResponse> =>
    postRequest('create_branch_po', data),
};

export interface RBranchPurchaseOrder {
  id: string;
  cid: string;
  qty: string | number;
}

export interface ROrderTypeInfo {
  createdBy: string;
  createdOn: number;
  id: string;
  key: string;
  loggedInAt: number;
  loginActive: boolean;
  name: string;
  syncedOn: number;
  userName: string;
}

export interface ROrder {
  supplierName: string;
  orderId: string;
  groupId: string;
  table: string;
  items: {
    batchNo: string;
    discountPercent: number;
    hsnCode: number;
    freeQty: number;
    discountPrice: number;
    gst: number;
    mrp: number;
    packaging: string;
    isNew: boolean;
    ptr: number;
    qty: number;
    name: string;
    csvName: string;
    csvPackaging: string;
    expiry: number;
    itemAmount: number;
    // branch po
    parentStock?: string;
    chainId?: string;
    schemePercent?: number;
    lot: string;
  }[];
  invoicedOn: number;
  orderedOn: number;
  createdOn: number;
  orderNo: string;
  invoiceNo: string;
  invoiceDoc: string;
  data: string;
  gstin: string;
  uploadType: string;
  cid: string;
  id: string;
  purchaseId?: string;
}
