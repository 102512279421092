import React, { useEffect } from 'react';
import { Popover, Stack } from '@mui/material';
import { DateRangePicker, DefinedRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './dateRange.scss';
import styles from './dateRange.module.scss';
import { onChangeItemType, DateRangePickerProps, dateRangeType } from './types';
import { DEFAULT_RANGES, getStaticRanges } from './constants';
import Button from 'components/FormControl/Button';
import dayjs from 'dayjs';
import { useAppContext } from 'contexts/AppContext';
/**
 * picker for range of date as filter
 */
const useDateRangePicker = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const openHandler = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const closeHandler = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const {
    snack: { show: showSnackbar },
  } = useAppContext();

  const DateRangePopOver = React.useMemo(() => {
    const DateRangeComponent: React.FC<DateRangePickerProps> = (props) => {
      // local state for component level date range
      const [dateRange, setDateRange] = React.useState<dateRangeType>(props.dateRange[0]);
      // creating static range as per Library component need
      const staticRanges = props.customStaticRanges
        ? getStaticRanges(props.customStaticRanges)
        : getStaticRanges(DEFAULT_RANGES);

      const validateDateRange = (startDate: Date, endDate: Date) => {
        if (props.maximumDateRange) {
          const diffInDays = dayjs(endDate).diff(dayjs(startDate), 'day');
          return diffInDays <= props.maximumDateRange;
        }
      };

      const handleDateChange = (item: onChangeItemType) => {
        const { startDate, endDate } = item.selection;
        if (validateDateRange(startDate, endDate)) {
          setDateRange(item.selection);
        } else {
          if (props.maximumDateRange) {
            const newEndDate = dayjs(startDate).add(props.maximumDateRange, 'day').toDate();
            setDateRange({
              startDate,
              endDate: newEndDate,
              key: 'selection',
            });
            showSnackbar(
              `The selected date range cannot exceed ${props.maximumDateRange} Days.`,
              'error',
            );
          }
        }
      };

      useEffect(() => {
        setDateRange({
          endDate: props.dateRange[0].endDate,
          startDate: props.dateRange[0].startDate,
          key: 'selection',
        });
      }, [props.dateRange]);

      return (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closeHandler}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Stack direction={'column'}>
            {props.showStaticRangesOnly ? (
              <DefinedRange
                className={styles.definedRange}
                ranges={dateRange ? [dateRange] : props.dateRange}
                staticRanges={staticRanges}
                inputRanges={[]}
                onChange={(item: onChangeItemType) => setDateRange(item.selection)}
              />
            ) : (
              <DateRangePicker
                className={props.hideStaticRanges ? styles.hideLeftBar : ''}
                ranges={dateRange ? [dateRange] : props.dateRange}
                onChange={
                  props.maximumDateRange
                    ? handleDateChange
                    : (item: onChangeItemType) => setDateRange(item.selection)
                }
                {...(!props.showInputRanges && { inputRanges: [] })}
                staticRanges={staticRanges}
                {...(props.disablePast && { minDate: new Date() })}
                {...(props.disableFuture && { maxDate: new Date() })}
                rangeColors={['#7F56D9']}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                direction='horizontal'
              />
            )}
            <Button
              text={'Apply'}
              className={styles.applyBtn}
              variant='contained'
              onClick={() => props.apply(dateRange ? [dateRange] : props.dateRange)}
            />
          </Stack>
        </Popover>
      );
    };
    return DateRangeComponent;
  }, [anchorEl]);
  return {
    openHandler,
    closeHandler,
    DateRangePopOver,
  };
};

export default useDateRangePicker;
