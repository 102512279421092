import { StoreUtils } from './util';
import { UserUtils } from './auth';

export const ACCESS_CONTROL_KEYS_ENUM = {
  BILL_READ: 'billRead',
  BILL_WRITE: 'billWrite',
  BILL_EDIT: 'billEdit',
  BILL_DELETE: 'billDelete',
  BILL_DISCOUNT: 'billDiscount',
  BILL_WHATSAPP: 'billWhatsapp',
  BILL_DATE: 'billDate',
  SALE_REPORT_DOWNLOAD: 'saleReportDownload',
  BILLED_ITEMS_EDIT: 'billedItemsEdit',
  CUSTOMER_DETAILS_EDIT: 'customerDetailsEdit',
  SALES_RETURN_READ: 'salesReturnRead',
  SALES_RETURN_WRITE: 'salesReturnWrite',
  SALES_RETURN_EDIT: 'salesReturnEdit',
  ONLINE_ORDER_READ: 'onlineOrderRead',
  CUSTOMER_READ: 'customerRead',
  DOCTOR_READ: 'doctorRead',
  PAYMENT_READ: 'paymentRead',
  PURCHASE_ORDER: 'purchaseIntegration',
  PURCHASE_READ: 'purchaseRead',
  PURCHASE_WRITE: 'purchaseWrite',
  PURCHASE_EDIT: 'purchaseEdit',
  PURCHASE_DELETE: 'purchaseDelete',
  PURCHASE_REPORT_DOWNLOAD: 'purchaseReportDownload',
  PURCHASE_RETURN_READ: 'purchaseReturnRead',
  PURCHASE_RETURN_WRITE: 'purchaseReturnWrite',
  PURCHASE_RETURN_EDIT: 'purchaseReturnEdit',
  SUPPLIER_READ: 'supplierRead',
  CHEQUE_PAYMENT_READ: 'chequePaymentRead',
  INVENTORY_READ: 'inventoryRead',
  INVENTORY_WRITE: 'inventoryWrite',
  INVENTORY_EDIT: 'inventoryEdit',
  REPORTS_READ: 'reportsRead',
  REPORTS_DOWNLOAD: 'reportsDownload',
  EXPIRY_REPORT_READ: 'expiryReportRead',
  EXPIRY_REPORT_DOWNLOAD: 'expiryReportDownload',
  WANTED_NOTE_READ: 'wantedNoteRead',
  EXPENSES_MANAGER_READ: 'expensesManagerRead',
  CASH_BANK_BOOK: 'cashBankBook',
  DASHBOARD_READ: 'dashboardRead',
  STORE_PROFILE_READ: 'storeProfileRead',
  DUNZO_DASHBOARD: 'dunzoDashboard',
  WHOLESALE: 'wholesale',
  BILL_MARGIN: 'staffMargin',
  STOCK_TRANSFER: 'stock_transfer',
  STOCK_TRANSFER_READ: 'stockTransferRead',
  STOCK_TRANSFER_WRITE: 'stockTransferWrite',
  STOCK_TRANSFER_EDIT: 'stockTransferEdit',
  STOCK_TRANSFER_CANCEL: 'stockTransferCancel',
  STOCK_TRANSFER_REJECT: 'stockTransferReject',
  STOCK_TRANSFER_ACCEPT: 'stockTransferAccept',
  STOCK_TRANSFER_BRANCHES_READ: 'stockTransferBranchesRead',
  WHOLESALE_READ: 'wholesaleRead',
  WHOLESALE_WRITE: 'wholesaleWrite',
  WHOLESALE_EDIT: 'wholesaleEdit',
  WHOLESALE_DELETE: 'wholesaleDelete',
  WHOLESALE_RETAILER_READ: 'wholesaleRetailerRead',
  WHOLESALE_RETAILER_WRITE: 'wholesaleRetailerWrite',
  WHOLESALE_RETAILER_EDIT: 'wholesaleRetailerEdit',
  ENABLE_BILL_PIN: 'enableBillPin',
  REQUEST_SKU: 'requestSku',
  WMS: 'wms',
  WMS_B2C: 'wmsB2c',
};

// add url and related access control here to block the url directly
export const UrlToAccessControlMap = {
  'bill-history': [ACCESS_CONTROL_KEYS_ENUM.BILL_READ],
  'bill-entry': [ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE],
  'online-orders': [ACCESS_CONTROL_KEYS_ENUM.ONLINE_ORDER_READ],
  customers: [ACCESS_CONTROL_KEYS_ENUM.CUSTOMER_READ],
  'payments-history': [ACCESS_CONTROL_KEYS_ENUM.PAYMENT_READ],
  'sales-return-history': [ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_READ],
  'sales-return': [ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_WRITE],
  'purchase-history': [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_READ],
  'purchase-entry': [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_WRITE],
  'purchase-return-history': [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_READ],
  'purchase-return': [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_WRITE],
  suppliers: [ACCESS_CONTROL_KEYS_ENUM.SUPPLIER_READ],
  'cheque-payment': [ACCESS_CONTROL_KEYS_ENUM.CHEQUE_PAYMENT_READ],
  'wanted-note': [ACCESS_CONTROL_KEYS_ENUM.WANTED_NOTE_READ],
  'manage-expenses': [ACCESS_CONTROL_KEYS_ENUM.EXPENSES_MANAGER_READ],
  dashboard: [ACCESS_CONTROL_KEYS_ENUM.DASHBOARD_READ],
  'store-profile': [ACCESS_CONTROL_KEYS_ENUM.STORE_PROFILE_READ],
  wholesale: [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE],
  'purchase-order': [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_ORDER],
  'stock-transfer': [
    ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER,
    ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_WRITE,
  ],
  'transfer-details': [
    ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER,
    ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_READ,
  ],
  branches: [
    ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER,
    ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_BRANCHES_READ,
  ],
  doctors: [ACCESS_CONTROL_KEYS_ENUM.DOCTOR_READ],
  dunzo: [ACCESS_CONTROL_KEYS_ENUM.DUNZO_DASHBOARD],
  requestSku: [ACCESS_CONTROL_KEYS_ENUM.REQUEST_SKU],
  'cash-bank-book': [ACCESS_CONTROL_KEYS_ENUM.CASH_BANK_BOOK],
};

// check function for access control and custom cases to block url
export const checkForAccessControl = () => {
  const path = location.pathname.split('/');
  const accessControlTypeFromUrl =
    UrlToAccessControlMap[path[1] as keyof typeof UrlToAccessControlMap];

  accessControlTypeFromUrl?.map((item) => {
    UserAccessControlData.get(item, true);
  });

  // case for expiry report and other report handling as all have common report path first
  if (path[1] === 'report' && path.length > 2) {
    path[2] === 'expiry-report'
      ? UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_READ, true)
      : UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.REPORTS_READ, true);
  }

  // case for bill edit and purchase edit when bill entry or purchase entry is enabled
  if (
    (path[1] === 'bill-entry' || path[1] === 'purchase-entry') &&
    path[2]?.length > 0 &&
    path.length == 3
  ) {
    path[1] === 'bill-entry'
      ? UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT, true)
      : UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.PURCHASE_EDIT, true);
  }

  // case for sales return edit and purchase return edit when sales return entry or purchase return entry is enabled
  if (
    (path[1] === 'sales-return' || path[1] === 'purchase-return') &&
    path[2]?.length > 0 &&
    location.search === '?type=edit'
  ) {
    path[1] === 'sales-return'
      ? UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_EDIT, true)
      : UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_EDIT, true);
  }

  // case for wholesale sub screens
  if (path[1] === 'wholesale') {
    path[2] === 'bill-entry'
      ? UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_WRITE, true)
      : path[2] === 'bill-history'
      ? UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_READ, true)
      : null;
  }

  if (path[1] === 'requested-sku') {
    UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.REQUEST_SKU, true);
  }
};

const redirectForAccessControl = (accessKey: string | undefined) => {
  if (
    accessKey &&
    accessKey === ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE &&
    window.location.href.includes('draft')
  ) {
    window.location.replace('/settings');
  } else {
    window.location.replace('/status/403');
  }
};

// util to check the data from session details
export const UserAccessControlData = {
  get: (accessKey: string | undefined, redirect = false) => {
    let res = undefined;
    if (accessKey === ACCESS_CONTROL_KEYS_ENUM.REQUEST_SKU) {
      res = StoreUtils.getKeyFromActiveSession(['userData', accessKey ?? '']);
    } else {
      res = StoreUtils.getKeyFromActiveSession(['userData', 'access', accessKey ?? '']);
    }

    // to check onboarded only screens so to block by default

    if (accessKey === ACCESS_CONTROL_KEYS_ENUM.WMS) {
      return !StoreUtils.getKeyFromActiveSession(['config', ACCESS_CONTROL_KEYS_ENUM.WMS]);
    }

    if (accessKey === ACCESS_CONTROL_KEYS_ENUM.WMS_B2C) {
      return !StoreUtils.isWmsB2cStore();
    }

    if (
      accessKey === ACCESS_CONTROL_KEYS_ENUM.WHOLESALE ||
      accessKey === ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER ||
      accessKey === ACCESS_CONTROL_KEYS_ENUM.DUNZO_DASHBOARD ||
      accessKey === ACCESS_CONTROL_KEYS_ENUM.PURCHASE_ORDER ||
      accessKey === ACCESS_CONTROL_KEYS_ENUM.REQUEST_SKU
    ) {
      if (accessKey === ACCESS_CONTROL_KEYS_ENUM.PURCHASE_ORDER) {
        // purchase order restriction is purchaseOrder or stock transfer restriction
        const accessStockTransfer = StoreUtils.getKeyFromActiveSession([
          'userData',
          'access',
          ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER ?? '',
        ]);
        res = !(res === false || accessStockTransfer === false);
      }
      if ((res === undefined || res) && redirect) {
        redirectForAccessControl(accessKey);
        return true;
      }
      if (res === undefined) {
        return true;
      }
      return res;
    }
    if (UserUtils.isStoreAdmin() && accessKey !== ACCESS_CONTROL_KEYS_ENUM.ENABLE_BILL_PIN) {
      return false;
    }
    if (redirect && res) {
      redirectForAccessControl(accessKey);
    }
    return res;
  },
};

// options shown to staff in settings page
export const ACCESS_CONTROLS: {
  [module: string]: {
    [functionality: string]: {
      name: string;
      label: string;
      hideDependencyKey?: string;
    }[];
  };
} = {
  Sales: {
    'Bill Entry': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILL_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE,
        label: 'Add',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT,
        label: 'Edit',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILL_DELETE,
        label: 'Delete',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILL_DISCOUNT,
        label: 'Discount',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILL_WHATSAPP,
        label: 'WhatsApp Share',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILL_DATE,
        label: 'Bill Date',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILL_MARGIN,
        label: 'Margin',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.SALE_REPORT_DOWNLOAD,
        label: 'Report',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.BILLED_ITEMS_EDIT,
        label: 'Billed Items Edit',
        hideDependencyKey: ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT,
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.CUSTOMER_DETAILS_EDIT,
        label: 'Customer Details Edit',
        hideDependencyKey: ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT,
      },
    ],
    'Sales Return': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_WRITE,
        label: 'Add',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_EDIT,
        label: 'Edit',
      },
    ],
    'Online Order': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.ONLINE_ORDER_READ,
        label: 'View',
      },
    ],
    Customers: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.CUSTOMER_READ,
        label: 'View',
      },
    ],
    Doctors: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.DOCTOR_READ,
        label: 'View',
      },
    ],
    Payment: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PAYMENT_READ,
        label: 'View',
      },
    ],
  },
  Purchase: {
    Purchase: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_WRITE,
        label: 'Add',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_EDIT,
        label: 'Edit',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_DELETE,
        label: 'Delete',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_REPORT_DOWNLOAD,
        label: 'Report',
      },
    ],
    'Purchase Return': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_WRITE,
        label: 'Add',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_EDIT,
        label: 'Edit',
      },
    ],
    Suppliers: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.SUPPLIER_READ,
        label: 'View',
      },
    ],
    'Cheque Payment': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.CHEQUE_PAYMENT_READ,
        label: 'View',
      },
    ],
    'Wanted Note': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.WANTED_NOTE_READ,
        label: 'View',
      },
    ],
  },
  Inventory: {
    Inventory: [
      { name: ACCESS_CONTROL_KEYS_ENUM.INVENTORY_READ, label: 'View' },
      { name: ACCESS_CONTROL_KEYS_ENUM.INVENTORY_WRITE, label: 'Add' },
      { name: ACCESS_CONTROL_KEYS_ENUM.INVENTORY_EDIT, label: 'Edit' },
    ],
  },
  'Stock Transfer': {
    'Stock Transfer': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_WRITE,
        label: 'Add',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_EDIT,
        label: 'Edit',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_CANCEL,
        label: 'Cancel',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_ACCEPT,
        label: 'Accept',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_REJECT,
        label: 'Reject',
      },
    ],
    Branches: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_BRANCHES_READ,
        label: 'View',
      },
    ],
  },
  Wholesale: {
    Bill: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_WRITE,
        label: 'Add',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_EDIT,
        label: 'Edit',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_DELETE,
        label: 'Delete',
      },
    ],
    Retailers: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_WRITE,
        label: 'Add',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_EDIT,
        label: 'Edit',
      },
    ],
  },
  'Reports/Dashboard': {
    'Other Reports': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.REPORTS_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.REPORTS_DOWNLOAD,
        label: 'Download',
      },
    ],
    'Expiry Report': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_READ,
        label: 'View',
      },
      {
        name: ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_DOWNLOAD,
        label: 'Download',
      },
    ],
    Dashboard: [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.DASHBOARD_READ,
        label: 'View',
      },
    ],
  },
  Others: {
    'Expenses Manager': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.EXPENSES_MANAGER_READ,
        label: 'View',
      },
    ],

    'Store Profile': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.STORE_PROFILE_READ,
        label: 'View',
      },
    ],

    'Cash & Bank Book': [
      {
        name: ACCESS_CONTROL_KEYS_ENUM.CASH_BANK_BOOK,
        label: 'View',
      },
    ],
  },
};

// dependency between different access control option within a page
export const DependentAccessControl = {
  [ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT]: {
    // type 1 are for access control that are dependent variable and no one depends on them for example: edit, delete
    dependentType: 1,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.BILL_READ,
      ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE,
      ACCESS_CONTROL_KEYS_ENUM.BILLED_ITEMS_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.CUSTOMER_DETAILS_EDIT,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.BILL_READ]: {
    // type 2 are for access control that are independent variable but some variable depends on them
    // for example read, write is independent but edit, delete depends on them
    dependentType: 2,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.BILL_DELETE,
      ACCESS_CONTROL_KEYS_ENUM.BILLED_ITEMS_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.CUSTOMER_DETAILS_EDIT,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE]: {
    dependentType: 2,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.BILL_DELETE,
      ACCESS_CONTROL_KEYS_ENUM.BILL_DISCOUNT,
      ACCESS_CONTROL_KEYS_ENUM.BILLED_ITEMS_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.CUSTOMER_DETAILS_EDIT,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.BILL_DELETE]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.BILL_READ, ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.BILL_DISCOUNT]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.BILL_WRITE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.BILLED_ITEMS_EDIT]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT, ACCESS_CONTROL_KEYS_ENUM.BILL_DISCOUNT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.CUSTOMER_DETAILS_EDIT]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.BILL_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_WRITE]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_EDIT, ACCESS_CONTROL_KEYS_ENUM.PURCHASE_DELETE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_READ]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_EDIT, ACCESS_CONTROL_KEYS_ENUM.PURCHASE_DELETE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_EDIT]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_READ, ACCESS_CONTROL_KEYS_ENUM.PURCHASE_WRITE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_DELETE]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_READ, ACCESS_CONTROL_KEYS_ENUM.PURCHASE_WRITE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_WRITE]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_READ]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_EDIT]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.PURCHASE_RETURN_READ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_WRITE]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_READ]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_EDIT]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.SALES_RETURN_READ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.INVENTORY_WRITE]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.INVENTORY_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.INVENTORY_READ]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.INVENTORY_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.INVENTORY_EDIT]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.INVENTORY_READ, ACCESS_CONTROL_KEYS_ENUM.INVENTORY_WRITE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.REPORTS_DOWNLOAD]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.REPORTS_READ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.REPORTS_READ]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.REPORTS_DOWNLOAD],
  },
  [ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_DOWNLOAD]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_READ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_READ]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_DOWNLOAD],
  },
  [ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_READ]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_DOWNLOAD],
  },
  [ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_READ]: {
    dependentType: 2,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_REJECT,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_ACCEPT,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_CANCEL,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_WRITE]: {
    dependentType: 2,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_REJECT,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_ACCEPT,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_CANCEL,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_EDIT]: {
    dependentType: 1,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_WRITE,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_READ,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_CANCEL]: {
    dependentType: 1,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_WRITE,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_READ,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_ACCEPT]: {
    dependentType: 1,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_WRITE,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_READ,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_REJECT]: {
    dependentType: 1,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_WRITE,
      ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER_READ,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_READ]: {
    dependentType: 2,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_DELETE,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_WRITE]: {
    dependentType: 2,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_EDIT,
      ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_DELETE,
    ],
  },
  [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_EDIT]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_READ, ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_WRITE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_DELETE]: {
    dependentType: 1,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_READ, ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_WRITE],
  },
  [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_WRITE]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_READ]: {
    dependentType: 2,
    dependency: [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_EDIT],
  },
  [ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_EDIT]: {
    dependentType: 1,
    dependency: [
      ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_WRITE,
      ACCESS_CONTROL_KEYS_ENUM.WHOLESALE_RETAILER_READ,
    ],
  },
};

// when any stores are not onboarded for particular feature
// add key here to not show that tab in staff settings
export const STAFF_SETTING_HIDE = {
  'Stock Transfer': ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER,
  Wholesale: ACCESS_CONTROL_KEYS_ENUM.WHOLESALE,
};
