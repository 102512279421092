import { combineReducers } from '@reduxjs/toolkit';
import billingTableReducer from './billingTable/slice';
import customerReducer from './customer/slice';

/**
 * @description Merge Billing Table and Customer Reducers.
 * @summary As of now we have keep billing and customer reducers separate as in most case both are independent.
 * If in future we need to merge them, we can do it here.
 */
const billEntryRootReducer = combineReducers({
  billingTable: billingTableReducer,
  customer: customerReducer,
});

export default billEntryRootReducer;
