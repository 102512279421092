import { getRequest, postRequest, putRequest } from './base';
import { AxiosResponse } from 'axios';
import {
  BillHistoryListProps,
  RBill,
  RBillHistory,
  RBillItemHistory,
  RBillLog,
  RCounterSalesSubmit,
  TPaymentUpdateData,
} from './types/billHistory.types';
import {
  PurchaseHistoryListProps,
  RPrintItem,
  RPurchase,
  RPurchaseHistory,
  RPurchaseLog,
} from './types/purchseHistory.types';
import { RAllDuplicateMergeData } from './types/duplicateMerge.types';
import {
  RAllSupplier,
  RLedgerDetails,
  RSupplier,
  RSupplierInfo,
  RSupplierLedger,
  RSupplierPurchaseReturn,
  RSupplierPurchases,
  RSupplierSearch,
  RVoucherList,
} from './types/supplier.types';
import {
  RAllNearExpiry,
  RGeneratedReport,
  RPastGeneratedReport,
  RPurchaseReturnHistoryReport,
  RReportHeaderData,
  TReportFilter,
} from './types/reports.types';
import {
  RPurchaseDraft,
  RPurchaseReturn,
  RPurchaseReturnHistory,
  RSaleReturn,
  RSalesReturnHistory,
} from './types/returnHistory.types';
import {
  RChartDashboard,
  RLowStockDashboard,
  RPaymentData,
  RPurchaseDashboard,
  RSalesDashboard,
  RStockDashboard,
} from './types/dashboard.types';
import {
  RBillingCustomers,
  RCustomer,
  RCustomerBills,
  RCustomerLedger,
  RCustomerReturn,
  RCustomerVoucher,
  RLedgerDetails as RCusLedgerDetails,
  TCustomer,
  RCustomersData,
} from './types/customer.types';
import {
  RAllWantedNoteData,
  RAssistant,
  RSupplierListType,
  TCreateWantedNote,
  TMultiAddWantedNote,
  TUpdateWantedNote,
  TWantedNodeUpdateStatus,
} from './types/wantedNote.types';
import {
  RBillPrefix,
  RSession,
  RStoreData,
  RUserList,
  TBillPrefix,
  TStoreData,
  TSuperAdminData,
  TUserData,
} from './types/settings.types';
import { RBill as REditBill, ROrderBill, RQRScanItem, TBill } from './types/billEntry.types';
import { REditPurchase, TPurchase } from './types/purchaseEntry.types';
import {
  RReturnEditBills,
  RReturnFromSale,
  TBill as TSalesReturnBill,
} from './types/salesReturn.types';
import { RPurchasePaymentDetail, TPurchasePayment, TSalePayment } from './types/payment.types';
import { RCheque } from './types/chequePayment.types';
import { RExpense, TItem } from './types/manageExpense.types';
import { RProfile, TProfile } from './types/storeProfile.types';
import { RSavedBill as RWholeSaleSavedBill, TSubmitBill } from './types/wholesale/billEntry.types';
import { RBillDetails, RWholeSaleHistoryData } from './types/wholesale/billHistory.types';
import {
  RBulkRetailers,
  RRetailer,
  RRetailerBill,
  RRetailerBillReturn,
  RRetailerLedger,
  RVoucherList as RRetailerVoucherList,
  TPharmacy,
} from './types/wholesale/retailerLedger.types';
import { RMedicineDetails, RMedicineSearch } from './types/medicineWikipedia.types';
import { RDunzoItems } from './types/dunzo.types';
import { RAllDoctors, RDoctor, RDoctorBills } from './types/doctor.types';
import { MasterItemResponse } from '../../pages/Inventory/inventory.interface';
import {
  RSavedBill as RStockTransferSavedBill,
  TSubmitBill as TStockTransferSubmitBill,
} from './types/stockTransfer.types';
import {
  ROut,
  ROutBillDetails,
  RPharmacyDetails,
  TBranchesFilterTypes,
} from './types/stockTransferDetails.types';
import { RChainPharmacy } from './types/chainPharmacy.types';
import { RBulkEdit, RBulkStockEdit } from './types/bulkEdit.types';
import { RPlanDetails, RPlanStatus, TPlanDetails } from './types/activePlan.types';
import { RDocument } from './types/document.types';
import { RCloseMatch, RMappingInfo, RSkuItem } from './types/skuMapping.types';
import { RDashboardData } from './types/masterDashboard.types';
import { RNotification } from './types/notification.types';
import { PurchaseOrderService, ROrder } from './endpoints/PurchaseOrderService';
import { LoginDataType, OtpDataType } from './types/login.types';
import {
  REditBill as RWholesaleReturnBill,
  RWholeSaleReturnSavedBill,
} from './types/wholesaleReturn/returnBilling.types';
import {
  RWholesaleReturn,
  RWholesaleReturnList,
} from './types/wholesaleReturn/returnHistory.types';
import { RChainConfigData } from 'pages/Settings/Components/ChainConfig/types';
import {
  RBankAccount,
  RBankDetails,
  RCashAndBankBookTypes,
  RCashData,
  RPassbookData,
  TBankDetails,
  TBankEntry,
  TCashEntry,
  TChequeItem,
  TContraVoucher,
} from './types/cashAndBankBook.types';
import { SCounterSale } from 'pages/BillEntry/types';
import { RWantedItemData, TLoadBulk, TWantedData } from './types/wantedNoteNew.types';
import { RCartData, RWantedSupplier, TMappedData } from './types/wantedNoteHistory';
import { RZoneTypes } from 'pages/Settings/Components/WmsZone/types';
import {
  RZoneUserTypes,
  RLocationTypes,
} from 'pages/Settings/Components/WmsZone/Features/ZoneAssignment/types';
import { SMasterTallyType } from 'pages/Reports/Tally/TallyMaster/types';
import { STransactionTallyType } from 'pages/Reports/Tally/TallyTransaction/types';
import { StoreUtils } from 'utils/util';
import { PaginationInfo } from 'hooks/serverPagination/types';

/**
 Basic CRUD:
 list  <- GET all items
 get <- GET single item by id
 create <- POST an item
 update <- PUT data to an item
 delete <- DELETE an item
 Specific:
 singleByName
 removeAll
 */

export const BillingService = {
  scanQr: async (qrCode: string): Promise<AxiosResponse<{ data: { items: RQRScanItem } }>> =>
    postRequest(`add_sku_barcode`, { barcode: qrCode }),
  orderToBill: (orderId: string): Promise<AxiosResponse<{ data: ROrderBill }>> =>
    getRequest(`convert_order_bill/${orderId}`),
  searchItemsRetailer: async (
    retailerId: string,
    from: number,
    to: number,
  ): Promise<AxiosResponse> =>
    getRequest(`retailer_details/${retailerId}?type=pastHistory&from=${from}&to=${to}`),

  search: async (query: string): Promise<AxiosResponse> =>
    getRequest(`search_sales_item?search=${encodeURIComponent(query)}`),
  selectSearch: async (id: string, checkQty?: boolean): Promise<AxiosResponse> => {
    const url = checkQty === undefined ? `sku_stock/${id}` : `sku_stock/${id}?checkQty=${checkQty}`;
    return getRequest(url);
  },
  batchSearch: async (id: string): Promise<AxiosResponse> => getRequest(`sku_all_batches/${id}`),
  zeroStock: async (id: string): Promise<AxiosResponse> => getRequest(`zero_sku_stock/${id}`),

  get: (billId: string): Promise<AxiosResponse<{ data: { bill: REditBill } }>> =>
    getRequest(`get_bill/${billId}`),
  create: (
    data: TBill,
    draftIndex: string,
    orderId: string | undefined,
    withPrint: boolean,
  ): Promise<AxiosResponse> => {
    const url = orderId
      ? `submit_bill/${draftIndex}?order_id=${orderId}`
      : `submit_bill/${draftIndex}?print=${withPrint}`;
    return postRequest(url, data);
  },
  update: (data: TBill, billId: string): Promise<AxiosResponse> =>
    postRequest(`bill_update/${billId}`, data),
  print: (billId: string): Promise<AxiosResponse> => postRequest(`print_bill/${billId}`, {}),
  whatsappBillLink: async (
    billId: string,
  ): Promise<AxiosResponse<{ data: { navigateTo: string } }>> =>
    postRequest(`bill_share/${billId}`),

  customerPastHistory: (id: string): Promise<AxiosResponse> =>
    getRequest(`customer_past_history/${id}`),

  getCustomerBillItems: (billId: string): Promise<AxiosResponse> =>
    getRequest(`customer_items_details/${billId}`),
  createCounterSale: (data: SCounterSale[]): Promise<AxiosResponse> =>
    postRequest(`create_counter_sale`, data),
};

export const PurchaseService = {
  search: async (searchString: string): Promise<AxiosResponse> =>
    getRequest(`search_purchase_item?search=${encodeURIComponent(searchString)}`),
  get: (purchaseId: string): Promise<AxiosResponse<{ data: { bill: REditPurchase } }>> =>
    getRequest(`purchase_entry/${purchaseId}`),
  create: ({
    data,
    orderId, // po
    poId, // branch po
    b2b, // partner integration
  }: {
    data: TPurchase;
    orderId?: string;
    poId?: string;
    b2b?: boolean;
  }): Promise<AxiosResponse> => {
    const url =
      `submit_purchase?` +
      (orderId ? `id=${orderId}` : ``) +
      (poId ? `poId=${poId}` : ``) +
      (b2b ? `&&b2b=${b2b}` : ``);
    return postRequest(url, data);
  },
  update: (data: TPurchase, purchaseId: string): Promise<AxiosResponse> =>
    postRequest(`purchase_update/${purchaseId}`, data),
  reset: (): Promise<AxiosResponse> => getRequest(`purchase_reset`),
  upload: (s3Urls: string[], supplierId: string): Promise<AxiosResponse<{ data: unknown }>> =>
    postRequest(`excel_purchase`, { fileLink: s3Urls, supplierId }),

  supplierAdditional: (id: string): Promise<AxiosResponse> =>
    getRequest(`supplier_past_history/${id}`),

  getDraft: (): Promise<AxiosResponse> => getRequest(`temp_purchase_data`),
  saveDraft: (data: unknown): Promise<AxiosResponse> => postRequest(`save_purchase`, data),

  orderToPurchase: (orderId: string): Promise<AxiosResponse<{ data: { item: ROrder } }>> =>
    getRequest(`integration_to_purchase/${orderId}`),
  branchOrderToPurchase: (orderId: string): Promise<AxiosResponse<{ data: { item: ROrder } }>> =>
    getRequest(`ws_to_ps/${orderId}`),
};

export const SearchService = {
  list: async (searchString: string): Promise<AxiosResponse> =>
    getRequest(`search_wanted_note_item?search=${encodeURIComponent(searchString)}`),
};

export const ChequeService = {
  list: async (from: number, to: number): Promise<AxiosResponse<{ data: { items: RCheque[] } }>> =>
    getRequest(`cheque_details?from=${from}&to=${to}`),
  print: async (chequeId: string): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    getRequest(`generate_cheque_pdf/${chequeId}`),
};

export const BillHistoryService = {
  list: async ({
    from,
    to,
    type,
    deliveryStatus,
    nextPageId,
    nextDataId,
  }: BillHistoryListProps): Promise<AxiosResponse<{ data: RBillHistory | RBillItemHistory }>> => {
    const url =
      `new_bill_listing?from=${from}&to=${to}&type=${type}` +
      (type == 'delivery' && deliveryStatus ? `&deliveryStatus=${deliveryStatus}` : ``) +
      (nextPageId ? `&nextPageId=${nextPageId}` : ``) +
      (nextDataId ? `&nextData=${nextDataId}` : ``);
    return getRequest(url);
  },

  get: async (billId: string): Promise<AxiosResponse<{ data: { bill: RBill } }>> =>
    getRequest(`get_bill/${billId}`),

  whatsappBillLink: BillingService.whatsappBillLink,

  downloadReport: async (
    from: number,
    to: number,
    type: string,
  ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    postRequest(`generate_sales_report?from=${from}&to=${to}&type=${type}`, {}),

  print: async (billId: string): Promise<AxiosResponse<{ data: { bill: string } }>> =>
    postRequest(`print_bill/${billId}`, {}),

  delete: async (billId: string): Promise<AxiosResponse> => postRequest(`delete_bill/${billId}`),

  convertEstimateToSale: async (billId: string): Promise<AxiosResponse<{ data: { id: string } }>> =>
    postRequest(`convert_estimate_to_sales/${billId}`),

  logs: async (billId: string): Promise<AxiosResponse<{ data: RBillLog }>> =>
    getRequest(`bill_logs/${billId}`),

  createCounterSalesInvoice: async (data: RCounterSalesSubmit): Promise<AxiosResponse> =>
    postRequest(`convert_counter_sales_to_sales`, data),

  deleteCounterSales: async (id: string): Promise<AxiosResponse> =>
    postRequest(`delete_counter_sales/${id}`),

  updateDeliveryStatus: async (id: string): Promise<AxiosResponse> =>
    postRequest(`update_delivery_status/${id}`),

  billPaymentUpdate: async (id: string, data: TPaymentUpdateData): Promise<AxiosResponse> =>
    postRequest(`bill_payment_update/${id}`, data),
};

// TODO:- Move to /api/endpoints/customerService
export const CustomerService = {
  list: async (): Promise<AxiosResponse<{ data: { customers: RCustomer } }>> =>
    getRequest('store_customers'),

  customerListing: async (
    paginationInfo: PaginationInfo,
    searchQuery: string,
    searchType: string,
  ): Promise<AxiosResponse<{ data: RCustomersData }>> => {
    const { nextPageId, nextData } = paginationInfo;
    const baseUrl = 'new_customer_listing';
    const params = new URLSearchParams();
    if (nextPageId) {
      params.append('nextPageId', nextPageId);
    }
    if (nextData) {
      params.append('nextData', nextData);
    }
    if (searchQuery) {
      params.append('search', searchQuery);
    }
    if (searchQuery && searchType) {
      params.append('searchType', searchType);
    }
    return getRequest(`${baseUrl}?${params.toString()}`);
  },
  get: async (customerId: string): Promise<AxiosResponse<{ data: RCustomer }>> =>
    getRequest(`customer/${customerId}`),

  create: async (data: TCustomer): Promise<AxiosResponse<{ data: { data: RCustomer } }>> =>
    postRequest('add_customer', data),
  update: async (customerId: string, data: TCustomer): Promise<AxiosResponse> =>
    postRequest(`update_customer/${customerId}`, data),
  detailsByType: async (
    customerId: string,
    type: 'bill' | 'return' | 'ledger' | 'voucher' | 'loyaltyLedger',
    from: number,
    to: number,
  ): Promise<
    AxiosResponse<{ data: RCustomerBills | RCustomerReturn | RCustomerLedger | RCustomerVoucher }>
  > => getRequest(`customer_bill/${customerId}?type=${type}&from=${from}&to=${to}`),
  ledgerDetails: async (ledgerId: string): Promise<AxiosResponse<{ data: RCusLedgerDetails }>> =>
    getRequest(`bill_ledger_details/${ledgerId}`),
  deletePayment: async (paymentId: string): Promise<AxiosResponse> =>
    postRequest(`delete_bill_ledger/${paymentId}`, {}),
  downloadReport: async (): Promise<AxiosResponse<{ data: { report: string } }>> =>
    postRequest(`customer_report`),
  downloadLedger: async (
    customerId: string,
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { s3Link: string } }>> =>
    getRequest(`download_customer_ledger/${customerId}?from=${from}&to=${to}`),
  newCustomerSearch: async (
    key: string,
    search: string,
  ): Promise<AxiosResponse<{ data: { items: RBillingCustomers } }>> =>
    getRequest(`customer_search?key=${key}&search=${search}`),
};

export const DoctorService = {
  list: async (): Promise<AxiosResponse<{ data: { items: RAllDoctors } }>> =>
    getRequest('store_doctor_listing'),
  get: async (
    doctorId: string,
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: RDoctorBills }>> =>
    getRequest(`doctor_details/${doctorId}?from=${from}&to=${to}`),
  create: async (data: RDoctor): Promise<AxiosResponse<{ data: RDoctor }>> =>
    postRequest('store_doctor', data),
  update: async (customerId: string, data: RDoctor): Promise<AxiosResponse<{ data: RDoctor }>> =>
    putRequest(`store_doctor/${customerId}`, data),
};

export const SupplierService = {
  list: async (apiType?: string): Promise<AxiosResponse<{ data: RAllSupplier }>> =>
    getRequest(`supplier_listing?type=${apiType}`),

  get: async (supplierId: string): Promise<AxiosResponse<{ data: { supplier: RSupplier } }>> =>
    getRequest(`get_supplier/${supplierId}`),

  create: async (data: unknown): Promise<AxiosResponse<{ data: { supplier: RSupplier } }>> =>
    postRequest(`add_supplier`, data),

  delete: async (supplierId: string): Promise<AxiosResponse> =>
    postRequest(`delete_supplier/${supplierId}`),

  update: async (
    supplierId: string,
    data: unknown,
  ): Promise<AxiosResponse<{ data: { supplier: RSupplier } }>> =>
    postRequest(`edit_supplier/${supplierId}`, data),

  detailsByType: async (
    supplierId: string,
    type: 'purchase' | 'return' | 'ledger',
    from: number,
    to: number,
  ): Promise<
    AxiosResponse<{ data: RSupplierPurchases | RSupplierPurchaseReturn | RSupplierLedger }>
  > => getRequest(`supplier_purchase/${supplierId}?type=${type}&from=${from}&to=${to}`),
  createVoucher: async (data: unknown, supplierId: string): Promise<AxiosResponse> =>
    postRequest(`create_voucher/${supplierId}`, data),
  voucherList: async (
    supplierId: string,
  ): Promise<AxiosResponse<{ data: { items: RVoucherList[]; supplier: RSupplierInfo } }>> =>
    getRequest(`supplier_purchase/${supplierId}?type=voucher`),
  ledgerDetails: async (ledgerId: string): Promise<AxiosResponse<{ data: RLedgerDetails }>> =>
    getRequest(`ledger_details/${ledgerId}`),
  deletePayment: async (paymentId: string): Promise<AxiosResponse> =>
    postRequest(`delete_ledger/${paymentId}`, {}),

  searchSupplier: async (
    searchString: string,
  ): Promise<AxiosResponse<{ data: { items: RSupplierSearch[] } }>> =>
    getRequest(`supplier_search?search=${encodeURIComponent(searchString)}`),

  downloadLedger: async (
    supplierId: string,
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { s3Link: string } }>> =>
    getRequest(`download_supplier_ledger/${supplierId}?from=${from}&to=${to}`),

  downloadReport: async (): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    getRequest(`generate_supplier_report`),
};

export const PaymentService = {
  makePayment: async (data: TPurchasePayment): Promise<AxiosResponse> =>
    postRequest(`make_purchase_payment`, data),

  getPurchasePayment: async (
    purchaseIds: string | string[],
    supplierId?: string,
  ): Promise<AxiosResponse<{ data: RPurchasePaymentDetail }>> => {
    if (typeof purchaseIds === 'string') {
      return getRequest(`purchase_payment_details/${purchaseIds}`);
    } else {
      return postRequest(`multi_purchase_payment_details`, {
        purchaseIds: purchaseIds,
        parentId: supplierId,
      });
    }
  },
};

export const SalePaymentService = {
  makePayment: async (data: TSalePayment): Promise<AxiosResponse> =>
    postRequest(`make_bill_payment`, data),

  getPayment: async (
    saleIds: string | string[],
    customerId?: string,
  ): Promise<AxiosResponse<{ data: unknown }>> => {
    if (typeof saleIds === 'string') {
      return getRequest(`bill_payment_details/${saleIds}`);
    } else {
      return postRequest(`multi_bill_payment_details`, { billIds: saleIds, parentId: customerId });
    }
  },
  getWholesalePayment: async (retailerId: string): Promise<AxiosResponse> =>
    postRequest('multi_bill_payment_details', { parentId: retailerId }),
};

export const PurchaseHistoryService = {
  list: async ({
    from,
    to,
    type,
    nextPageId,
    nextDataId,
  }: PurchaseHistoryListProps): Promise<AxiosResponse<{ data: RPurchaseHistory }>> => {
    const url =
      `new_purchase_listing?from=${from}&to=${to}&type=${type}` +
      (nextPageId ? `&nextPageId=${nextPageId}` : ``) +
      (nextDataId ? `&nextData=${nextDataId}` : ``);
    return getRequest(url);
  },

  post: async (
    purchaseId: string,
    data?: string[] | undefined,
  ): Promise<AxiosResponse<{ data: { items: RPrintItem[] } }>> =>
    postRequest(`stock_print_response/${purchaseId}`, { ids: data }),

  get: async (
    invoiceId: string,
  ): Promise<AxiosResponse<{ data: { bill: RPurchase; label: Record<string, string> } }>> =>
    getRequest(`purchase_entry/${invoiceId}`),

  getByBatchId: async (
    invoiceId: string,
    batchId: string,
  ): Promise<AxiosResponse<{ data: { bill: RPurchase; label: Record<string, string> } }>> =>
    postRequest(`get_purchase_item_search/${invoiceId}`, { batchId }),

  downloadReport: async (
    from: number,
    to: number,
    supplierId: string,
    type: string | null,
    isGrnDiscrepancies: boolean,
  ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    postRequest(
      `generate_purchase_report?from=${from}&to=${to}&supplierId=${supplierId}&type=${type}${
        isGrnDiscrepancies ? `&grnDiscrepancies=true` : ''
      }`,
      {},
    ),

  delete: async (invoiceId: string): Promise<AxiosResponse> =>
    postRequest(`delete_purchase_stock/${invoiceId}`),

  printLabel: async (
    invoiceId: string,
    data: Record<string, Record<string, number | string>>,
  ): Promise<AxiosResponse> => postRequest(`print_purchase_label/${invoiceId}`, data),

  logs: async (billId: string): Promise<AxiosResponse<{ data: RPurchaseLog }>> =>
    getRequest(`purchase_logs/${billId}`),

  listSupplier: SupplierService.list,

  updateGrn: async (id: string, data: unknown): Promise<AxiosResponse> =>
    postRequest(`wms_purchase_update/${id}`, data),
  returnGrn: async (id: string, data: unknown): Promise<AxiosResponse> =>
    postRequest(`wms_purchase_return/${id}`, data),

  csvLabelDownload: async (
    invoiceId: string,
    data: Record<string, Record<string, number | string>>,
  ): Promise<AxiosResponse> => postRequest(`label_csv_download/${invoiceId}`, data),
};

export const ReportHistoryService = {
  listSale: async (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { items: RSalesReturnHistory[] } }>> =>
    getRequest(`sales_return_listing?from=${from}&to=${to}`),

  // Get Sale & Purchase based on id
  get: async (
    id: string,
  ): Promise<AxiosResponse<{ data: { item: RSaleReturn | RPurchaseReturn } }>> =>
    getRequest(`get_return/${id}`),

  downloadReportPurchase: async (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    postRequest(`generate_purchase_return_report?from=${from}&to=${to}`, {}),

  downloadReportSale: async (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    postRequest(`generate_sales_return_report?from=${from}&to=${to}`, {}),

  listPurchase: async (
    from: number,
    to: number,
    type?: string,
  ): Promise<AxiosResponse<{ data: { items: RPurchaseReturnHistory[] | RPurchaseDraft[] } }>> =>
    getRequest(`purchase_return_history?from=${from}&to=${to}${type ? `&type=${type}` : ''}`),

  deletePurchaseReturn: async (invoiceId: string): Promise<AxiosResponse> =>
    postRequest(`delete_purchase_return/${invoiceId}`),

  deleteSalesReturn: async (invoiceId: string): Promise<AxiosResponse> =>
    postRequest(`delete_sales_return/${invoiceId}`),
};

export const ReportService = {
  gst: {
    saleList: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`sales_gst_report_listing`),
    saleT1Heading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=gstr_sales_report_template_1`),
    saleT2Heading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=gstr_sales_report_template_2`),
    saleB2bT1Heading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=gstr_b2b_sales_report_template_1`),
    saleGenerate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`sales_gst_report?from=${from}&to=${to}`, data),

    purchaseT1Heading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=gstr_purchase_report_template_1`),
    purchaseT2Heading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=gstr_purchase_report_template_2`),
    purchaseReturnT1Heading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=gstr_purchase_return_report_template_1`),
    purchaseList: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`purchase_gst_report_listing`),
    purchaseReturnList: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: RPurchaseReturnHistoryReport }>> =>
      getRequest(`pur_return_listing?from=${from}&to=${to}`),
    purchaseGenerate: async (
      data: TReportFilter,
      // month: number,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`purchase_gst_report?from=${from}&to=${to}`, data),

    gstr3bList: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`gstr_3b_summary`),
    gstr3bGenerate: async (
      from: number,
      to: number,
      type: string,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`gstr_3b_report?from=${from}&to=${to}`, { downloadType: type }),
    gstr1Generate: async (
      from: number,
      to: number,
      type: string,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`gstr1_report?from=${from}&to=${to}`, { downloadType: type }),
    gstr1List: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`gstr1_report_listing`),
    gstConsolidateReportDownload: async (
      from: number,
      to: number,
      type: string,
      ids: string[],
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`gstr_consolidated_report?from=${from}&to=${to}&reportType=${type}`, {
        companyList: ids,
      }),
    gstr2aList: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`gstr_2a_report_listing`),
    gstr2aGenerate: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`gstr_2a_report?from=${from}&to=${to}`, {}),
  },

  nearExpiry: {
    list: async (from: number, to: number): Promise<AxiosResponse<{ data: RAllNearExpiry }>> =>
      getRequest(`near_expiry_items?start_epoch=${from}&end_epoch=${to}`),
    download: async (
      from: number,
      to: number,
      selectedSupplier: string,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(
        `generate_near_expiry_report?from=${from}&to=${to}&supplierId=${selectedSupplier}`,
        {},
      ),
  },

  batchWisePurchase: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`purchase_items_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`purchase_items_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`purchase_items_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=batchwise_purchase_report`),
  },

  batchWiseSale: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`sales_items_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`sales_items_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`sales_items_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=batchwise_sales_report`),
    getB2BHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=batchwise_b2b_sales_report`),
  },

  itemWiseSale: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`itemwise_sales_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`itemwise_sales_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`itemwise_sales_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=itemwise_sales_report`),
  },
  bounceItem: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`store_bounce_items_table`),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`store_bounced_items_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=store_bounced_items_report`),
  },

  branchPo: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`branch_po_tracking_table`),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`branch_po_tracking_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=branch_po_tracking_report`),
  },

  stockAudit: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`stock_audit_report_table`),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`stock_audit_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=stock_audit_report`),
  },

  itemWiseStock: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`itemwise_stock_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`itemwise_stock_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`itemwise_stock_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=itemwise_stock_report`),
  },

  staffWiseSale: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`staffwise_sales_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`staffwise_sales_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`staffwise_sales_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=staffwise_sales_report`),
  },

  itemMapping: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`mapping_items_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`mapping_items_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`mapping_items_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=item_mapping_report`),
  },

  itemMargin: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`item_margin_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`sales_item_margin_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`sales_item_margin_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=itemwise_sales_margin_report`),
    getB2BHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=itemwise_b2b_sales_margin_report`),
  },

  lowStock: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest('low_stock_table'),
    download: async (): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest('low_stock_report', {}),
    generate: async (
      data: TReportFilter,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`low_stock_report`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=low_stock_report`),
  },

  itemMaster: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest('item_master_report_table'),
    itemMasterReportHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=item_master_report`),
    generate: async (
      data: TReportFilter,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`item_master_report`, data),
  },

  nonMoving: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest('non_moving_items'),
    download: async (): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest('non_moving_report', {}),
    generate: async (
      data: TReportFilter,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`non_moving_report`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=non_moving_items_report`),
  },

  overBought: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`overbought_items_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`overbought_items_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`overbought_items_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=overbought_report`),
  },

  overSold: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`oversold_items_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`oversold_items_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`oversold_items_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=oversold_report`),
  },

  shiftManagement: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`shift_management_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`shift_management_report?from=${from}&to=${to}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`shift_management_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=shift_management_report`),
  },

  salesPerson: {
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=salesman_incentive_report`),
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`salesman_incentive_table`),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`salesman_incentive_report?from=${from}&to=${to}`, data),
  },

  schedule: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`schedule_type_table`),
    download: async (
      from: number,
      to: number,
      type: string,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`schedule_type_report?from=${from}&to=${to}&type=${type}`, {}),
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`schedule_type_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=schedule_type_report`),
  },

  stockAdjustment: {
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`stock_adjustment_items_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=stock_adjustment_report`),
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`stock_adjustment_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`stock_adjustment_items_report?from=${from}&to=${to}`, {}),
  },

  stockReport: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`stock_items_table`),
    generate: async (
      data: TReportFilter,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`stock_report`, data),
    download: async (id: string): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`stock_report/${id}`, {}),

    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=batchwise_stock_report`),
  },

  tallyMasterReport: {
    list: async (
      consolidated: boolean,
    ): Promise<AxiosResponse<{ data: { items: SMasterTallyType[] } }>> =>
      getRequest(`tally_masters_listing` + (consolidated ? `?consolidated=true` : ``)),
    generate: async (
      type: string[],
      cidList?: string[],
    ): Promise<AxiosResponse<{ data: { userMessage: string; data: string } }>> =>
      postRequest(`tally_masters`, cidList ? { cidList: cidList, type: type } : { type: type }),
    download: async (id: string): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`stock_report/${id}`, {}),
  },

  tallyTransactionReport: {
    list: async (
      consolidated: boolean,
    ): Promise<AxiosResponse<{ data: { items: STransactionTallyType[] } }>> =>
      getRequest(`tally_transactions_listing` + (consolidated ? `?consolidated=true` : ``)),
    generate: async (
      type: string[],
      from: number,
      to: number,
      cidList?: string[],
    ): Promise<AxiosResponse<{ data: { userMessage: string; data: string } }>> =>
      postRequest(
        `tally_transactions?from=${from}&to=${to}`,
        cidList ? { cidList: cidList, type: type } : { type: type },
      ),
    download: async (id: string): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
      postRequest(`stock_report/${id}`, {}),
  },

  newItem: {
    generate: async (
      data: TReportFilter,
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`new_item_report?from=${from}&to=${to}`, data),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=new_items_report`),
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`new_item_table`),
    download: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { report: string } }>> =>
      postRequest(`new_item_report?from=${from}&to=${to}`, {}),
  },

  cycleCountReport: {
    list: async (): Promise<AxiosResponse<{ data: { items: RPastGeneratedReport[] } }>> =>
      getRequest(`cycle_count_report_list`),
    getHeading: async (): Promise<AxiosResponse<{ data: RReportHeaderData }>> =>
      getRequest(`report_column_headings?report_name=cycle_count_report`),
    generate: async (
      data: TReportFilter,
    ): Promise<AxiosResponse<{ data: { userMessage: RPastGeneratedReport } }>> =>
      postRequest(`cycle_count_report`, data),
  },

  generatedReport: {
    list: async (
      from: number,
      to: number,
    ): Promise<AxiosResponse<{ data: { items: RGeneratedReport[] } }>> =>
      getRequest(`gstr_consolidated_report_listing?from=${from}&to=${to}`),
  },
};

export const DashboardService = {
  getSale: async (from: number, to: number): Promise<AxiosResponse<{ data: RSalesDashboard }>> =>
    getRequest(`new_sales_dashboard?from=${from}&to=${to}`),
  getWholeSale: async (from: number, to: number): Promise<AxiosResponse> =>
    getRequest(`new_wholesale_dashboard?from=${from}&to=${to}`),
  getPurchase: async (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: RPurchaseDashboard }>> =>
    getRequest(`new_purchase_dashboard?from=${from}&to=${to}`),
  getStock: async (): Promise<AxiosResponse<{ data: RStockDashboard }>> =>
    getRequest('new_stock_dashboard'),
  getLowStock: async (): Promise<AxiosResponse<{ data: RLowStockDashboard }>> =>
    getRequest('low_stock_dashboard'),
  getChart: async (from: number, to: number): Promise<AxiosResponse<{ data: RChartDashboard }>> =>
    getRequest(`comparison_dashboard?from=${from}&to=${to}`),
  getPaymentData: async (
    from: number,
    to: number,
    type?: string,
  ): Promise<AxiosResponse<{ data: RPaymentData }>> =>
    getRequest(`bill_payments?from=${from}&to=${to}&type=${type}`),
};

export const WantedNoteService = {
  list: async (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { items: RAllWantedNoteData[] } }>> =>
    getRequest(`wanted_note_listing?from=${from}&to=${to}`),
  update: async (id: string, data: TUpdateWantedNote): Promise<AxiosResponse> =>
    postRequest(`update_wanted_note_entry/${id}`, data),
  create: async (
    data: TCreateWantedNote,
    skuId: string,
  ): Promise<AxiosResponse<{ data: { item: RAllWantedNoteData } }>> =>
    postRequest(`create_wanted_note_entry/${skuId}`, data),
  updateSupplier: async (id: string, data: TUpdateWantedNote): Promise<AxiosResponse> =>
    postRequest(`update_wanted_note_supplier/${id}`, data),
  getItemById: async (
    wantedNoteId: string,
  ): Promise<AxiosResponse<{ data: { item: RAllWantedNoteData } }>> =>
    getRequest(`get_wanted_note_item/${wantedNoteId}`),
  updateStatus: async (data: TWantedNodeUpdateStatus, status: string): Promise<AxiosResponse> =>
    postRequest(`update_wanted_note_status?status=${status}`, data),
  delete: async (id: string): Promise<AxiosResponse> =>
    postRequest(`delete_wanted_note_entry/${id}`, {}),
  getSupplierList: async (id: string): Promise<AxiosResponse<{ data: RSupplierListType }>> =>
    getRequest(`get_supplier_list/${id}`),
  downloadReport: async (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { report: string } }>> =>
    postRequest(`download_wanted_note?from=${from}&to=${to}`, {}),

  // order assistant
  orderAssistantList: async (
    salesFrom: number,
    salesTo: number,
  ): Promise<AxiosResponse<{ data: { items: RAssistant[] } }>> =>
    getRequest(`wanted_note_assistant?sales_from=${salesFrom}&sales_to=${salesTo}`),
  addMultiItemToWantedNote: async (data: TMultiAddWantedNote): Promise<AxiosResponse> =>
    postRequest('create_bulk_wanted_note_entry', data),

  searchPurchaseOrderItem: async (search: string) => getRequest(`search_po_item?search=${search}`),
  mapPurchaseOrderItem: async (wantedNoteId: string, skuId: string) =>
    postRequest(`map_po_item/${wantedNoteId}`, { id: skuId }),
  addToLiveOrderCart: async (data: unknown) => postRequest(`place_liveorder`, data),
};

export const WantedNoteServiceNew = {
  search: async (searchText: string): Promise<AxiosResponse> =>
    getRequest(`shortbook_sku_search?search=${searchText}`),
  getProduct: (skuId: string): Promise<AxiosResponse> => getRequest(`supplier_batch/${skuId}`),
  submit: async (data: TWantedData): Promise<AxiosResponse> =>
    postRequest(`add_shortbook_entries`, data),
  submitEditEntries: async (data: TWantedData, status: string): Promise<AxiosResponse> =>
    postRequest(`edit_shortbook_entries?status=${status}`, data),
  getHistory: async (
    form: number,
    to: number,
    status: string,
    platform?: string,
  ): Promise<
    AxiosResponse<{ data: { items: RWantedSupplier[]; async?: boolean; task?: string } }>
  > => {
    const url = `all_shortbook_entries?status=${status}`;
    return getRequest(WantedNoteServiceNew.formUrl(url, status, form, to, platform));
  },
  deleteEntry: async (skuId: string): Promise<AxiosResponse> =>
    postRequest(`delete_shortbook_entry/${skuId}`),
  changeStatus: async (data: { [ids: string]: string[] }, status: string): Promise<AxiosResponse> =>
    postRequest(`change_item_status?status=${status}`, data),
  newChangeStatus: async (
    data: { [ids: string]: string[] },
    status: string,
  ): Promise<AxiosResponse> => postRequest(`new_status_change?status=${status}`, data),
  getEditData: async (
    data: { [ids: string]: string[] },
    type: string,
  ): Promise<AxiosResponse<{ data: { items: RWantedItemData[] } }>> =>
    postRequest(`get_shortbook_entries?type=${type}`, data),
  loadBulkItem: async (
    from: number | string,
    to: number | string,
    data: TLoadBulk,
  ): Promise<AxiosResponse> => {
    const url = `load_bulk_items${(from ? '?from=' + from : '') + (to ? '&to=' + to : '')}`;
    return postRequest(url, data);
  },

  deleteBulkItems: async (data: { [ids: string]: string[] }): Promise<AxiosResponse> =>
    postRequest(`multi_delete_shortbook_entries`, data),
  whatsappBillLink: async (id: string): Promise<AxiosResponse> => postRequest(`order_share/${id}`),
  getBranchList: async (): Promise<AxiosResponse> => getRequest(`get_company_branches`),
  downloadReport: async (
    form: number,
    to: number,
    status: string,
    body: { [ids: string]: string[] },
    platform?: string,
  ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> => {
    const url = `generate_shortbook_report?status=${status}`;
    return postRequest(WantedNoteServiceNew.formUrl(url, status, form, to, platform), body);
  },
  formUrl: (
    url: string,
    status: string,
    form: number,
    to: number,
    platform: string | undefined,
  ) => {
    if (['ordered', 'delivered'].includes(status)) {
      return (url += `&from=${form}&to=${to}`);
    } else {
      if (StoreUtils.getKeyFromActiveSession(['config', 'enablePlatform'])) {
        return (url += `&platform=${platform}`);
      } else {
        url;
      }
    }
    return url;
  },
  getLiveOrderData: async (data: {
    ids: string[];
  }): Promise<AxiosResponse<{ data: { items: RCartData } }>> =>
    postRequest(`get_liveorder_items`, data),
  upload: (s3Urls: string[]): Promise<AxiosResponse> =>
    postRequest(`upload_shortbook_excel`, { fileLink: s3Urls }),
  convertShortBookEntries: (data: TMappedData[]): Promise<AxiosResponse> =>
    postRequest(`convert_to_shortbook_entries`, data),
};

export const SettingService = {
  list: async (): Promise<AxiosResponse<{ data: { users: RUserList[] } }>> =>
    getRequest(`user_listing`),
  update: async (id: string, data: TUserData): Promise<AxiosResponse> =>
    putRequest(`user/${id}`, data),
  add: async (data: TUserData): Promise<AxiosResponse<{ data: { user: RUserList } }>> =>
    postRequest('user', data),
  getUser: async (id: string): Promise<AxiosResponse<{ data: { user: RUserList } }>> =>
    getRequest(`get_user/${id}`),
  deleteUser: async (id: string): Promise<AxiosResponse> => postRequest(`delete_user/${id}`),
  listSessions: async (): Promise<AxiosResponse<AxiosResponse<{ items: RSession[] }>>> =>
    getRequest(`session`),
  invalidateSession: async (id: string): Promise<AxiosResponse> =>
    postRequest(`delete_session/${id}`),
  getBillPrefix: async (): Promise<AxiosResponse<{ data: RBillPrefix }>> =>
    getRequest('get_company_prefix'),
  billPinShare: async (id: string): Promise<AxiosResponse> => postRequest(`bill_pin_share/${id}`),
  updateBillPrefix: async (data: TBillPrefix): Promise<AxiosResponse> =>
    postRequest('update_company_prefix', data),
  // By sending old version, we can get the latest app link
  getLatestApp: async (): Promise<AxiosResponse<{ app_url: string }>> =>
    getRequest('check_web_update/windows/x86_64/0.0.0'),

  chainConfig: async (): Promise<AxiosResponse<{ data: RChainConfigData }>> =>
    getRequest(`chain_configuration`),
  changeSuperAdmin: async (data: TSuperAdminData): Promise<AxiosResponse> =>
    postRequest(`update_super_admin`, data),
  getStoreDetail: async (id: string): Promise<AxiosResponse<{ data: RStoreData }>> =>
    getRequest(`get_chain_store_details/${id}`),
  updateStore: async (id: string, data: TStoreData): Promise<AxiosResponse> =>
    postRequest(`update_chain_store/${id}`, data),
};

export const WmsZoneService = {
  wmsZoneListing: async (): Promise<AxiosResponse<{ data: { zones: RZoneTypes[] } }>> =>
    getRequest(`get_all_zones`),
  zoneUserListing: async (
    zoneId: string,
  ): Promise<AxiosResponse<{ data: { users: RZoneUserTypes[] } }>> =>
    getRequest(`get_zone_users/${zoneId}`),
  addZone: async (zoneName: string): Promise<AxiosResponse> =>
    postRequest(`add_zone`, { name: zoneName }),
  updateZone: async (zoneId: string, zoneName: string): Promise<AxiosResponse> =>
    postRequest(`update_zone/${zoneId}`, { name: zoneName }),
  locationListing: async (
    zoneId: string,
  ): Promise<AxiosResponse<{ data: { locations: RLocationTypes[]; zones: RZoneTypes[] } }>> =>
    getRequest(`get_all_locations/${zoneId}`),
  updateZoneUserAssignment: async (
    zoneId: string,
    users: RZoneUserTypes[],
  ): Promise<AxiosResponse> => postRequest(`update_zone_user_details/${zoneId}`, { users: users }),
  updateLocationAssignment: async (
    locationId: string,
    locations: RLocationTypes[],
  ): Promise<AxiosResponse> =>
    postRequest(`update_zone_location_details/${locationId}`, { locations: locations }),
  addLocation: async (
    locationId: string,
    data: { name: string; zoneId: string },
  ): Promise<AxiosResponse> => postRequest(`add_location/${locationId}`, data),
};

export const SalesReturnService = {
  search: async (searchText: string): Promise<AxiosResponse> =>
    getRequest(`sales_return_sku_search?search=${encodeURIComponent(searchText)}`),
  fetchProduct: async (id: string): Promise<AxiosResponse> =>
    getRequest(`sales_return_stock/${id}`),
  getDraftBill: async (): Promise<AxiosResponse> => getRequest(`get_temp_sales_return`),
  resetBills: async (): Promise<AxiosResponse> => getRequest(`reset_sales_return`),
  saveBill: async (data: unknown): Promise<AxiosResponse> =>
    postRequest(`save_temp_sales_return`, data),
  convertSaleToReturn: async (
    billId: string,
  ): Promise<AxiosResponse<{ data: { item: RReturnFromSale } }>> =>
    getRequest(`convert_sales_to_return/${billId}`),
  editBills: async (
    orderId: string,
  ): Promise<AxiosResponse<{ data: { return: RReturnEditBills } }>> =>
    getRequest(`sales_return_item/${orderId}`),
  submitBill: async (
    data: TSalesReturnBill,
    orderId: string | undefined,
  ): Promise<AxiosResponse> => {
    const URL = orderId ? `sales_return_submit/${orderId}` : `sales_return_submit/new`;
    return postRequest(URL, data);
  },
  markPaid: async (billId: string, status: string): Promise<string> => {
    const data = { paymentMode: status };
    postRequest(`bill_paid_status/${billId}`, data);
    return status;
  },
};

export const StockTransferService = {
  searchList: async (query: string): Promise<AxiosResponse> =>
    getRequest(`search_wholesale_item?search=${query}`),

  selectSearch: async (id: string, checkQty?: boolean): Promise<AxiosResponse> => {
    const url =
      checkQty === undefined
        ? `stock_transfer_batches/${id}`
        : `stock_transfer_batches/${id}?checkQty=${checkQty}`;
    return getRequest(url);
  },

  searchListByBranch: async (searchString: string, branchId: string): Promise<AxiosResponse> =>
    getRequest(`search_branch_po_item?search=${encodeURIComponent(searchString)}&id=${branchId}`),

  create: (data: TStockTransferSubmitBill, orderId: string | undefined): Promise<AxiosResponse> => {
    const url = `submit_stock_transfer` + (orderId ? `?id=${orderId}` : '');
    return postRequest(url, data);
  },
  update: (data: TStockTransferSubmitBill, id: string): Promise<AxiosResponse> =>
    postRequest(`update_stock_transfer/${id}`, data),

  resetBill: (): Promise<AxiosResponse> => postRequest('reset_stock_transfer', {}),

  saveDraftBill: (data: unknown): Promise<AxiosResponse> =>
    postRequest('save_stock_transfer', data),
  getDraftBill: (): Promise<AxiosResponse<{ data: RStockTransferSavedBill }>> =>
    getRequest('temp_stock_transfer'),

  getPurchaseBill: (id: string): Promise<AxiosResponse> =>
    getRequest(`get_purchase_to_wholesale/${id}`),

  getOrderBill: (id: string): Promise<AxiosResponse<{ data: RStockTransferSavedBill }>> =>
    getRequest(`transfer_po/${id}`),

  print: (billId: string): Promise<AxiosResponse> =>
    postRequest(`print_stock_transfer/${billId}`, {}),

  getEditBill: (id: string): Promise<AxiosResponse> => getRequest(`edit_stock_transfer/${id}`),

  // Pharmacy / Customer
  getChainPharmacies: async (
    type?: string,
  ): Promise<AxiosResponse<{ data: { storeGstIn: string; items: RChainPharmacy[] } }>> =>
    getRequest(`branch` + (type ? `?type=${type}` : '')),
};

export const StockTransferHistoryService = {
  list: async (type: string, from: number, to: number): Promise<AxiosResponse<{ data: ROut }>> =>
    getRequest(`stock_transfer_listing?type=${type}&from=${from}&to=${to}`),

  delete: async (id: string): Promise<AxiosResponse> => postRequest(`delete_stock_transfer/${id}`),

  downloadCSV: (
    from: number,
    to: number,
    type: string,
  ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    postRequest(`generate_stock_transfer_report?from=${from}&to=${to}&type=${type}`),

  downloadBillCSV: (billId: string): Promise<AxiosResponse<{ data: { report: string } }>> =>
    postRequest(`download_stock_transfer_csv/${billId}`, {}),

  // In Req.
  acceptReq: async (id: string): Promise<AxiosResponse> =>
    postRequest(`accept_stock_transfer/${id}`),

  // details
  getDetails: (
    id: string,
  ): Promise<AxiosResponse<{ data: { item: ROutBillDetails; label: Record<string, string> } }>> =>
    getRequest(`stock_transfer/${id}`),
};

export const ChainPharmacyService = {
  list: StockTransferService.getChainPharmacies,
  getDetails: (id: string): Promise<AxiosResponse<{ data: { item: RPharmacyDetails } }>> =>
    getRequest(`branch/${id}`),
  inventoryDetails: async ({
    id,
    expiry,
    manufacturer,
    gstQuery,
    stock,
    rack,
    categoryQuery,
    minMargin,
    maxMargin,
    nextPageId,
    nextDataId,
  }: TBranchesFilterTypes): Promise<AxiosResponse<{ data: { items: MasterItemResponse[] } }>> => {
    const url =
      `branch_inventory/${id}?expiry=${expiry}&manufacturer=${
        manufacturer + gstQuery
      }&stock=${stock}&rack=${
        rack + categoryQuery
      }&min_margin=${minMargin}&max_margin=${maxMargin}` +
      (nextPageId ? `&nextPageId=${nextPageId}` : ``) +
      (nextDataId ? `&nextData=${nextDataId}` : ``);

    return getRequest(url);
  },
  getBranchManufacturer: (id: string): Promise<AxiosResponse> =>
    getRequest(`get_manufacturers/${id}`),
};

export const MasterDashboardService = {
  getDashboardData: async (): Promise<AxiosResponse<{ data: RDashboardData }>> =>
    getRequest(`admin_dashboard`),
  generateDashboard: async (from: number, to: number, branches: string[]): Promise<AxiosResponse> =>
    postRequest(`generate_dashboard?from=${from}&to=${to}&branches=${branches}`, {}),
};

export const ManageExpenseService = {
  list: async (from: number, to: number): Promise<AxiosResponse<{ data: RExpense }>> =>
    getRequest(`expense_listing?from=${from}&to=${to}`),
  create: async (data: TItem): Promise<AxiosResponse<{ data: { expense: RExpense['data'][0] } }>> =>
    postRequest(`create_expense_entry`, data),
  downloadReport: async (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { report: string } }>> =>
    postRequest(`expense_report?from=${from}&to=${to}`, {}),
  delete: async (id: string): Promise<AxiosResponse> => postRequest(`delete_expense_entry/${id}`),
};

export const CashAndBankBookService = {
  get: async (to: number, from: number): Promise<AxiosResponse<{ data: RCashAndBankBookTypes }>> =>
    getRequest(`cash-bank-book?to=${to}&from=${from}`),
  saveBankDetails: async (data: TBankDetails): Promise<AxiosResponse> => postRequest(`bank`, data),
  getBankData: async (): Promise<AxiosResponse<{ data: { items: RBankDetails[] } }>> =>
    getRequest(`bank`),
  getBankTransactionDetails: async (
    id: string,
    type: string,
    to: number,
    from: number,
  ): Promise<AxiosResponse<{ data: RPassbookData }>> =>
    getRequest(`cash-bank-book/${id}?to=${to}&from=${from}&type=${type}`),
  getCashDetails: async (
    id: string,
    to: number,
    from: number,
  ): Promise<AxiosResponse<{ data: RCashData }>> =>
    getRequest(`cash-bank-book/${id}?to=${to}&from=${from}`),
  getContraItems: async (): Promise<AxiosResponse<{ data: { items: RBankAccount[] } }>> =>
    getRequest(`contra_items`),
  createContraVoucher: async (data: TContraVoucher): Promise<AxiosResponse> =>
    postRequest(`create_contra_voucher`, data),
  markChequeClear: async (data: TChequeItem): Promise<AxiosResponse> =>
    postRequest(`mark_cheque_clear/${data.id}`, data),
  createCashEntry: async (data: TCashEntry): Promise<AxiosResponse> =>
    postRequest(`create_cash_entry`, data),
  createPettyCashEntry: async (data: TCashEntry): Promise<AxiosResponse> =>
    postRequest(`create_petty_cash_entry`, data),
  createBankEntry: async (id: string, data: TBankEntry): Promise<AxiosResponse> =>
    postRequest(`create_bank_entry/${id}`, data),
  delete: async (id: string): Promise<AxiosResponse> =>
    postRequest(`delete_cash_bank_book_entry/${id}`),
};

export const MedicineWikipediaService = {
  getMedicine: async (id: string): Promise<AxiosResponse<{ data: RMedicineDetails }>> =>
    getRequest(`get_med_wiki/${id}`),
  search: async (name: string): Promise<AxiosResponse<{ data: { items: RMedicineSearch[] } }>> =>
    getRequest(`search_med_results?search=${encodeURIComponent(name)}`),
};

export const StoreService = {
  get: async (): Promise<AxiosResponse<{ data: { company: RProfile } }>> =>
    getRequest(`get_company`),
  update: async (data: TProfile): Promise<AxiosResponse> => postRequest(`update_company`, data),
  getDocument: async (): Promise<AxiosResponse<{ data: { documents: RDocument } }>> =>
    getRequest(`get_document_details`),
  updateDocument: async (data: RDocument): Promise<AxiosResponse> =>
    postRequest(`update_company_documents`, data),
  getPlan: async (): Promise<AxiosResponse<{ data: RPlanDetails }>> =>
    getRequest(`subscription_listing`),
  createPlan: async (data: TPlanDetails): Promise<AxiosResponse<{ data: RPlanDetails }>> =>
    postRequest(`add_subscription_entry`, data),
  updatePlan: async (data: TPlanDetails): Promise<AxiosResponse> =>
    postRequest(`update_subscription_entry/${data.id}`, data),

  getPlanStatus: async (): Promise<AxiosResponse<{ data: RPlanStatus }>> =>
    getRequest(`get_plan_status`),
  updatePlanStatus: async (
    query: 'okay' | 'contact',
  ): Promise<AxiosResponse<{ data: { link: string } }>> =>
    postRequest(`update_plan_check_date?ans=${query}`, {}),
};

export const WholeSaleBillingService = {
  // Billing
  search: async (query: string): Promise<AxiosResponse> =>
    getRequest(`search_wholesale_item?search=${encodeURIComponent(query)}`),

  pastRetailer: async (retailerId: string, from: number, to: number): Promise<AxiosResponse> =>
    getRequest(`retailer_details/${retailerId}?type=pastHistory&from=${from}&to=${to}`),

  addBill: async (billId: string, idSku?: string): Promise<AxiosResponse> =>
    getRequest(`get_bill_items/${billId}` + (idSku ? `?sku=${idSku}` : '')),

  create: (data: TSubmitBill, orderId: string | undefined): Promise<AxiosResponse> => {
    const url = 'submit_wholesale' + (orderId ? `?id=${orderId}` : '');
    return postRequest(url, data);
  },
  update: (data: TSubmitBill, id: string): Promise<AxiosResponse> =>
    postRequest(`update_wholesale/${id}`, data),

  resetBill: (): Promise<AxiosResponse> => postRequest('reset_wholesale', {}),

  saveDraftBill: (data: unknown): Promise<AxiosResponse> => postRequest('save_wholesale', data),
  getDraftBill: (): Promise<AxiosResponse<{ data: RWholeSaleSavedBill }>> =>
    getRequest('temp_wholesale'),

  print: (billId: string): Promise<AxiosResponse> => postRequest(`print_wholesale/${billId}`, {}),
  downloadCSV: (billId: string): Promise<AxiosResponse<{ data: { fileLink: string } }>> =>
    getRequest(`generate_wholesale_csv/${billId}`),

  getEditBill: (id: string): Promise<AxiosResponse> => getRequest(`edit_wholesale/${id}`),

  getPurchaseBill: StockTransferService.getPurchaseBill,

  getOrderBill: (id: string): Promise<AxiosResponse<{ data: RWholeSaleSavedBill }>> =>
    getRequest(`transfer_po/${id}`),

  // Pharmacy / Customer
  getPharmacies: async (): Promise<AxiosResponse<{ data: { retailer: RRetailer[] } }>> =>
    getRequest(`store_retailer`),
  addPharmacies: async (data: TPharmacy): Promise<AxiosResponse> => postRequest(`retailer`, data),
  editPharmacy: async (id: string, data: TPharmacy): Promise<AxiosResponse> =>
    putRequest(`retailer/${id}`, data),
  getBulkRetailers: (
    ids: string[],
  ): Promise<AxiosResponse<{ data: { items: RBulkRetailers[] } }>> =>
    postRequest(`get_bulk_retailers`, { ids }),
  updateBulkRetailers: async (data: RBulkRetailers[]): Promise<AxiosResponse> =>
    postRequest(`update_bulk_retailers`, data),
  list: async (): Promise<AxiosResponse<{ data: { retailer: RRetailer[] } }>> =>
    getRequest(`billing_retailer_listing`),
};

export const WholeSaleBillHistoryService = {
  list: (from: number, to: number): Promise<AxiosResponse<{ data: RWholeSaleHistoryData }>> =>
    getRequest(`wholesale_listing?from=${from}&to=${to}`),

  delete: async (billId: string): Promise<AxiosResponse> =>
    postRequest(`delete_wholesale/${billId}`),

  downloadCSV: (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    postRequest(`generate_wholesale_report?from=${from}&to=${to}`),

  // details
  getDetails: (
    id: string,
  ): Promise<
    AxiosResponse<{ data: { wholesale: RBillDetails; label?: Record<string, string> } }>
  > => getRequest(`wholesale/${id}`),
};

export const RetailerLedgerService = {
  list: async (): Promise<AxiosResponse<{ data: { retailer: RRetailer[] } }>> =>
    getRequest(`store_retailer`),
  create: async (data: TPharmacy): Promise<AxiosResponse> => postRequest(`retailer`, data),

  get: async (id: string): Promise<AxiosResponse> => getRequest(`retailer/${id}`),

  downloadLedger: async (
    retailerId: string,
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { s3Link: string } }>> =>
    getRequest(`download_retailer_ledger/${retailerId}?from=${from}&to=${to}`),

  detailsByType: async (
    retailerId: string,
    type: 'wholesale' | 'return' | 'ledger' | 'voucher',
    from: number,
    to: number,
  ): Promise<
    AxiosResponse<{
      data: RRetailerBill | RRetailerLedger | RRetailerBillReturn | RRetailerVoucherList;
    }>
  > => getRequest(`retailer_details/${retailerId}?type=${type}&from=${from}&to=${to}`),
};

export const WholesaleReturnService = {
  search: async (query: string): Promise<AxiosResponse> =>
    getRequest(`wholesale_return_sku_search?search=${encodeURIComponent(query)}`),
  getEditBill: async (
    id: string,
  ): Promise<AxiosResponse<{ data: { return: RWholesaleReturnBill } }>> =>
    getRequest(`wholesale_return_item/${id}`),
  convertOrderToBill: async (
    id: string,
  ): Promise<AxiosResponse<{ data: { item: RWholesaleReturnBill } }>> =>
    getRequest(`convert_wholesale_to_return/${id}`),
  create: async (data: TSubmitBill, id: string | undefined): Promise<AxiosResponse> => {
    const url = `wholesale_return_submit` + (id ? `/${id}` : `/new`);
    return postRequest(url, data);
  },
  selectSearch: async (id: string): Promise<AxiosResponse> =>
    getRequest(`sales_return_stock/${id}`), // TODO

  reset: (): Promise<AxiosResponse> => getRequest(`reset_wholesale_return`),

  saveDraftBill: (data: unknown): Promise<AxiosResponse> =>
    postRequest('save_temp_wholesale_return', data),
  getDraftBill: (): Promise<AxiosResponse<{ data: RWholeSaleReturnSavedBill }>> =>
    getRequest('get_temp_wholesale_return'),
};

export const WholesaleReturnHistoryService = {
  list: async (
    from: number,
    to: number,
  ): Promise<AxiosResponse<{ data: { items: RWholesaleReturnList[] } }>> =>
    getRequest(`wholesale_return_listing?from=${from}&to=${to}`),
  billDetails: (id: string): Promise<AxiosResponse<{ data: { return: RWholesaleReturn } }>> =>
    getRequest(`wholesale_return_item/${id}`),

  delete: (id: string): Promise<AxiosResponse> => postRequest(`delete_wholesale_return/${id}`, {}),
};

export const WholesaleRetailerService = {
  downloadReport: async (): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    getRequest(`generate_retailer_report`),
};

export const BulkEditService = {
  itemEdit: async (data: unknown): Promise<AxiosResponse<{ data: { items: RBulkEdit[] } }>> =>
    postRequest('get_bulk_sku', data),
  itemSave: async (data: unknown): Promise<AxiosResponse<{ data: { items: RBulkEdit[] } }>> =>
    postRequest('bulk_sku_update', data),

  stockEdit: async (
    data: unknown,
    enableZeroStock: boolean,
  ): Promise<AxiosResponse<{ data: { items: RBulkStockEdit[] } }>> =>
    postRequest(`get_bulk_stock?enableZeroStock=${enableZeroStock}`, data),

  stockSave: async (data: unknown): Promise<AxiosResponse> =>
    postRequest('bulk_stock_update', data),
};

export const DunzoService = {
  list: async (): Promise<AxiosResponse<{ data: { dunzo_skus: RDunzoItems[] } }>> =>
    getRequest(`get_dunzo_items`),

  downloadCSV: (): Promise<AxiosResponse<{ data: { refreshDeeplink: string } }>> =>
    postRequest(`generate_dunzo_catalogue`),

  changeAlwaysAvailabe: (skuId: string, alwaysAvailableBoolean: boolean): Promise<AxiosResponse> =>
    postRequest(`dunzo_always_avail/${skuId}?alwaysAvailable=${alwaysAvailableBoolean}`),

  changeAvailable: (variantId: string, availableBoolean: boolean): Promise<AxiosResponse> =>
    postRequest(`sync_dunzo_item_avail/${variantId}?available=${availableBoolean}`),

  sync: (): Promise<AxiosResponse> => postRequest('sync_dunzo_catalogue'),
};

export const NotificationService = {
  list: async (): Promise<AxiosResponse<{ data: { items: RNotification[] } }>> =>
    getRequest(`get_web_notifications`),
  markAsRead: async (id: string): Promise<AxiosResponse> =>
    getRequest(`mark_notification_read/${id}`),
  count: async (): Promise<AxiosResponse<{ data: { count: number } }>> =>
    getRequest(`get_web_notifications_count`),
};

// Sku Mapping Service
export const SkuMappingService = {
  list: async (
    status: string,
  ): Promise<
    AxiosResponse<{
      data: {
        totalItems: number;
        items: RSkuItem[];
        countOfMappedItems?: number;
        countOfUnmappedItems?: number;
        isAddAllowed: boolean;
      };
    }>
  > => getRequest(`inventory_mapping_data?status=${status}`),
  getCloseMatches: async (
    id: string,
  ): Promise<AxiosResponse<{ data: { closeMatches: RCloseMatch[] } }>> =>
    getRequest(`close_matches/${id}`),
  getCloseMatchesByName: async (
    searchText: string,
    requestChainMapping: boolean,
  ): Promise<AxiosResponse<{ data: { closeMatches: RCloseMatch[] } }>> =>
    getRequest(
      `close_matches_by_name?searchText=${encodeURIComponent(
        searchText,
      )}&&requestChainMapping=${requestChainMapping}`,
    ),

  postCloseMatches: async (id: string, existingSkuItems: string[]): Promise<AxiosResponse> =>
    postRequest(`close_matches_filtered/${id}`, { existingSkuItems: existingSkuItems }),
  mapSku: async (
    localSku: string,
    masterSku: string,
  ): Promise<AxiosResponse<{ data: { userMessage: string } }>> =>
    getRequest(`ros_map_sku/${localSku}?masterSku=${masterSku}`),

  getMappingInfo: async (
    status: string,
  ): Promise<AxiosResponse<{ data: { items: RMappingInfo[] } }>> =>
    getRequest(`master_mapping_data?status=${status}`),

  acceptMapping: async (mapId: string): Promise<AxiosResponse<{ data: { userMessage: string } }>> =>
    getRequest(`map_pending_sku/${mapId}`),

  rejectMapping: async (mapId: string): Promise<AxiosResponse<{ data: { userMessage: string } }>> =>
    getRequest(`discard_pending_sku/${mapId}`),

  search: async (
    query: string,
    requestChainMapping = false,
  ): Promise<AxiosResponse<{ data: { items: RCloseMatch[] } }>> =>
    getRequest(
      `mapping_search?search=${encodeURIComponent(
        query,
      )}&requestChainMapping=${requestChainMapping}`,
    ),

  addNewSku: async (skuId: string): Promise<AxiosResponse<{ data: { userMessage: string } }>> =>
    getRequest(`add_new_sku/${skuId}`),
};

export const MergeDuplicate = {
  list: async (
    from = 0,
    to = 0,
    status: string,
  ): Promise<AxiosResponse<{ data: { duplicates: RAllDuplicateMergeData[] } }>> =>
    getRequest(`list_duplicates?status=${status}&from=${from}&to=${to}`),
  generateDuplicates: async (): Promise<AxiosResponse> => getRequest(`generate_duplicates`),
};

export const OtherServices = {
  spotlightSearch: async (query: string): Promise<AxiosResponse> =>
    getRequest(`search_sales_item?search=${encodeURIComponent(query)}`),
};

// Other Api calls
export const updatePaymentMode = async (id: string, status: string): Promise<string> => {
  const data = { paymentMode: status };
  await postRequest(`purchase_paid_status/${id}`, data);
  return status;
};

export const LoginService = {
  generateOtp: async (data: OtpDataType): Promise<AxiosResponse> =>
    postRequest(`user/otp/generate`, data),
  login: async (data: LoginDataType): Promise<AxiosResponse> => postRequest(`user/login`, data),
  logOut: async (): Promise<AxiosResponse> => postRequest(`logout_session`, {}),
};

export { PurchaseOrderService };
