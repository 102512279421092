import { SHIFT_MANAGEMENT_DIALOG, SHIFT_REFRESH_DIALOG } from 'constants/events';
import CurrencyDialog from 'pages/ShiftManagement/Dialoges/CurrencyDialog';
import ShiftRefreshDialog from 'pages/ShiftManagement/Dialoges/ShiftRefreshDialog';
import React from 'react';

const ShiftStart = () => {
  const [isCurrencyDialogOpen, setIsCurrencyDialogOpen] = React.useState<boolean>(false);
  const [isShiftRefreshDialogOpen, setIsShiftRefreshDialogOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.addEventListener(SHIFT_MANAGEMENT_DIALOG, () => {
      setIsCurrencyDialogOpen(true);
    });
    window.addEventListener(SHIFT_REFRESH_DIALOG, () => {
      setIsShiftRefreshDialogOpen(true);
    });
  }, []);

  return (
    <>
      {isCurrencyDialogOpen ? (
        <CurrencyDialog
          open={isCurrencyDialogOpen}
          shift={'start'}
          onSubmit={() => setIsCurrencyDialogOpen(false)}
        />
      ) : null}
      {isShiftRefreshDialogOpen ? <ShiftRefreshDialog open={isShiftRefreshDialogOpen} /> : null}
    </>
  );
};

export default ShiftStart;
