import { useState } from 'react';
import { HelpServices } from 'services/api/endpoints/learnmoreSection';
import { SHelpSectionData } from '../types';

const useLearnmoreData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<SHelpSectionData | null>(null);

  const fetchData = (screen: string) => {
    setIsLoading(true);
    HelpServices.fetchHelpSectionData(screen)
      .then((response) => {
        setData(response.data.data.body);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  console.log(data);

  return { data, isLoading, fetchData };
};

export default useLearnmoreData;
