import { SCaseReducer } from './types';
import { SBatch } from '../../../BillEntry/types';
import { SearchProductType } from '../../../../components/Common/ProductSearchField/types';
import PurchaseUtils from '../../utils';
import { allowAddOrDeleteItem, INITIAL_STATE, PurchaseEntryKeyMap } from '../../constants';
import { SClient, SCustomer, SPurchaseEntry } from '../../types';
import { ObjectUtils, roundNumber, StoreUtils } from '../../../../utils/util';
import { DEFAULT_GST } from 'utils/constants';
import { SRack } from 'pages/Inventory/components/AddSkewStock/types';

const setColumnFocusedIndex: SCaseReducer<number> = (state, action) => {
  state.client.focusedColumnIndex = action.payload;
};

const setFocusedIndex: SCaseReducer<number> = (state, action) => {
  state.client.focusedRowIndex = action.payload;
};

const setTableFocusedIndex: SCaseReducer<{ rowIndex: number; colIndex: number }> = (
  state,
  action,
) => {
  state.client.focusedRowIndex = action.payload.rowIndex;
  state.client.focusedColumnIndex = action.payload.colIndex;
};

const updateClient: SCaseReducer<Partial<SClient>> = (state, action) => {
  state.client = {
    ...state.client,
    ...action.payload,
  };
};

const setSearchProducts: SCaseReducer<Partial<SClient['searchProducts']>> = (state, action) => {
  state.client.searchProducts = {
    ...state.client.searchProducts,
    ...action.payload,
  };
};

const loadCustomer: SCaseReducer<Partial<SCustomer>> = (state, action) => {
  state.customer = {
    ...state.customer,
    ...ObjectUtils.mergeObjectsWithDefaults(action.payload, INITIAL_STATE.SCustomer()),
  };
};

const setSubmitData: SCaseReducer<Partial<SClient['submitData']>> = (state, action) => {
  state.client.submitData = {
    ...state.client.submitData,
    ...action.payload,
  };
};

const loadRows: SCaseReducer<{
  rows: SPurchaseEntry[];
  // new = reset whole slice + loadRows
  // append = append rows in current
  // newRowsOnly = add new Rows
  mode: 'new' | 'append' | 'newRowsOnly';
  addEmptyRow?: boolean;
  disableAddRow?: boolean;
}> = (state, action) => {
  const { addEmptyRow = true, rows, mode, disableAddRow = false } = action.payload;
  let items = ObjectUtils.isListEmpty(rows) ? [INITIAL_STATE.SPurchaseEntry()] : rows;
  // If last item has id, add last empty row
  if (!disableAddRow && (addEmptyRow || items.at(-1)?.id)) {
    items = [...items, INITIAL_STATE.SPurchaseEntry()];
  }

  if (mode === 'new') {
    // Replace Only Rows with new rows and Racks Data, all else will be reset to initial state
    return {
      ...INITIAL_STATE.SPurchaseEntryReducer(),
      rows: items,
      client: {
        ...state.client,
        racks: state.client.racks,
      },
    };
  } else if (mode === 'newRowsOnly') {
    // Append the rows to end of the current rows
    state.rows = [...items];
  } else if (mode === 'append') {
    // Append the rows to end of the current rows
    state.rows = [
      ...state.rows.slice(0, -1), // Remove Last Empty Row
      ...items,
    ];
  }
};

const loadRow: SCaseReducer<{ row: SPurchaseEntry; rowIndex?: number }> = (state, action) => {
  const payload = action.payload;
  let index = payload.rowIndex;
  if (index === undefined) {
    index = state.rows.length - 1;
  }

  state.rows[index] = payload.row;
};

const updateDiscountOnRows: SCaseReducer<number> = (state, action) => {
  const overallDiscount = action.payload;

  state.rows = state.rows.map((item) => ({
    ...item,
    ...PurchaseUtils.calculateTotal(item, overallDiscount),
  }));
};

const handleRowChange: SCaseReducer<{ key: string; value: unknown; rowIndex: number }> = (
  state,
  action,
) => {
  const payload = action.payload;
  let row = state.rows[payload.rowIndex];
  const overallDiscountPercent = +state.customer.discountPercent;
  switch (payload.key) {
    case PurchaseEntryKeyMap.name: {
      row = {
        ...row,
        id: '',
        [payload.key]: payload.value as string,
      };
      break;
    }
    case PurchaseEntryKeyMap.qty:
    case PurchaseEntryKeyMap.ptr:
    case PurchaseEntryKeyMap.gst:
    case PurchaseEntryKeyMap.freeQty:
    case PurchaseEntryKeyMap.mrp:
    case PurchaseEntryKeyMap.discount:
    case PurchaseEntryKeyMap.discountPrice: {
      const newValue = payload.value as number;

      row = {
        ...row,
        [payload.key]: newValue,
      };

      const total = PurchaseUtils.calculateTotal(row, overallDiscountPercent);

      row = {
        ...row,
        ...total,
      };
      break;
    }
    case PurchaseEntryKeyMap.batch: {
      if (typeof payload.value === 'string') {
        row = {
          ...row,
          batchNo: payload.value as string,
        };
      } else {
        const incomingBatch = payload.value as SBatch;
        row = {
          ...row,
          batchNo: incomingBatch.batchNo as string,
          ptr: incomingBatch.ptr || 0,
        };
        if (!state.client.isUploadMode) {
          const total = PurchaseUtils.calculateTotal(row, overallDiscountPercent);

          row = {
            ...row,
            expiry: incomingBatch.expiry || null,
            mrp: incomingBatch.mrp || 0,
            ...total,
          };
        }
      }
      break;
    }
    case PurchaseEntryKeyMap.rack: {
      // Check if value is an instance of SRack
      if (payload.value instanceof Object) {
        row = {
          ...row,
          rack: (payload.value as SRack).rack,
          rackId: (payload.value as SRack).rackId,
        };
      } else {
        row = {
          ...row,
          rack: payload.value as string,
          rackId: '',
        };
      }
      break;
    }
    default:
      row = {
        ...row,
        [payload.key]: payload.value,
      };
  }

  state.rows[payload.rowIndex] = row;
};

const updateCustomer: SCaseReducer<SCustomer> = (state, action) => {
  state.customer = action.payload;
};

const calculateOverallTotal: SCaseReducer<void> = (state) => {
  const purchaseItems = state.rows;
  const customer = state.customer;
  let netTotalAmount =
    purchaseItems.reduce((acc, item) => acc + item.netItemAmount, 0) -
    (customer.creditAmount || 0) +
    +(customer.extraCharges || 0) +
    +(customer.tcs || 0);
  let roundOff = 0;

  // RoundOff Setting
  if (StoreUtils.getKeyFromActiveSession(['config', 'roundOff'])) {
    roundOff = roundNumber(netTotalAmount, 0) - netTotalAmount;
    netTotalAmount = roundNumber(netTotalAmount, 0);
  }

  state.total = {
    ...state.total,
    totalValue: purchaseItems.reduce((acc, item) => acc + item.itemAmount, 0),
    totalGst: purchaseItems.reduce((acc, item) => acc + item.gstAmount, 0),
    netTotalAmount,
    roundOff,
    overallItemDiscount: purchaseItems.reduce(
      (acc, item) => acc + item.discountAmount + +item.discountPrice,
      0,
    ),
    // discount amount contains discount percent in rs + discount price(sch amount)
    totalDiscount: purchaseItems.reduce(
      (acc, item) => acc + item.discountAmount + +item.discountPrice + +item.overallItemDiscount,
      0,
    ),
  };
};

const addSearchedProductRow: SCaseReducer<{
  productData: SearchProductType;
  index: number;
  qrMode?: boolean;
  orderType?: string;
  disableAddNewRow?: boolean;
}> = (state, action) => {
  const { productData, index, qrMode, orderType, disableAddNewRow } = action.payload;
  const isUploadMode = state.client.isUploadMode;

  if (isUploadMode) {
    // Handle Purchase File Upload Flow click handler
    const row = {
      ...state.rows[index],

      qty: qrMode ? 1 : state.rows[index].qty,
      mrp: state.rows[index].mrp || productData.mrp,

      // Change the Sku Data
      id: productData.id,
      name: productData.name,
      skuId: productData.id,
      isNew: productData.isNew || false,
      packaging: productData.packaging || '',
      rack: productData.rack || state.rows[index].rack || '',
      hsnCode: productData.hsnCode || state.rows[index].hsnCode || '',
      gst: productData.gst || state.rows[index].gst || DEFAULT_GST,
    };

    if (StoreUtils.isWMSStore() && productData.productId) {
      row.productId = productData.productId;
    }
    state.rows[index] = row;
  } else {
    // Handle Normal Flow click handler
    const qty = qrMode ? 1 : state.rows[index].qty ?? '';
    const row = PurchaseUtils.convertSearchToProduct(
      productData,
      qty,
      state.rows[index],
      state.customer.discountPercent,
    );
    state.rows[index] = row;
  }

  // If last item has id, add last empty row
  if (state.rows.at(-1)?.id && allowAddOrDeleteItem(orderType) && !disableAddNewRow) {
    state.rows = [...state.rows, INITIAL_STATE.SPurchaseEntry()];
  }

  // If via QR Focus on the last row product field
  if (qrMode) {
    state.client.focusedColumnIndex = 2;
  }

  // Reset Search Product
  state.client.searchProducts = {
    items: [],
    isFetching: false,
    isOpen: false,
    searchString: '',
  };
};

const handleLotChange: SCaseReducer<{
  index: number;
  newLot: string;
  schemePercent: number;
  schemeAmount: number;
}> = (state, action) => {
  const payload = action.payload;
  let row = state.rows[payload.index];
  const overallDiscountPercent = +state.customer.discountPercent;

  row = {
    ...row,
    schemePercent: payload.schemePercent,
    schemeAmount: payload.schemeAmount,
    lot: payload.newLot,
  };

  const total = PurchaseUtils.calculateTotal(row, overallDiscountPercent);

  row = {
    ...row,
    ...total,
  };

  state.rows[payload.index] = row;
};

const deleteProductRow: SCaseReducer<number> = (state, action) => {
  if (action.payload !== state.rows.length - 1) {
    state.rows = state.rows.filter((_, index) => index !== action.payload);
  }
};

const resetSlice: SCaseReducer<void> = (state) => {
  const initialState = INITIAL_STATE.SPurchaseEntryReducer();
  // Reset all data except racks
  return {
    ...initialState,
    client: { ...initialState.client, racks: state.client.racks },
  };
};

export default {
  loadRow,
  loadRows,
  loadCustomer,
  setFocusedIndex,
  calculateOverallTotal,
  updateClient,
  setTableFocusedIndex,
  setColumnFocusedIndex,
  setSearchProducts,
  addSearchedProductRow,
  handleRowChange,
  setSubmitData,
  handleLotChange,
  deleteProductRow,
  updateCustomer,
  resetSlice,
  updateDiscountOnRows,
};
