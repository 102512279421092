import { Popover, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useId } from 'react';
import useTableNavigation from '../../../hooks/useTableNavigation';
import styles from './productSearchField.module.scss';
import { BillingProps, Props, SearchProductType } from './types';
import Button from '../../FormControl/Button';
import { CircularLoader } from '../../Loader';

const customerPurchaseHistoryHeading = (
  <span className={styles.customerHeading}>Customer Past Purchase</span>
);
const retailerPurchaseHistoryHeading = (
  <span className={styles.customerHeading}>Retailer Past Purchase</span>
);

const noDataContent = (
  <span className={styles.noRowMessage}>
    Item is not part of your Current Item Master.
    <br /> Click Add Item to add it.
  </span>
);

const loadingContent = (
  <span className={styles.noRowMessage}>
    <CircularLoader />
  </span>
);

/**
 * @deprecated Please use ProductSearchField V2
 */
const ProductSearchField: React.FC<Props> = ({
  ctx,
  data,
  rowMapping,
  columns,
  rowClick,
  rowIndex,
  handleAddItem,
  isLoading,
  isCustomerHistory,
  isRetailerHistory,
  ...props
}) => {
  const { anchorEl, close } = ctx;

  const openEl = Boolean(anchorEl);

  const handleClose = () => {
    close();
    if (props.onClose) props.onClose();
  };

  return (
    <>
      <Popover
        id='1p-product-search-popover'
        anchorEl={anchorEl}
        onClose={handleClose}
        open={openEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        className={styles.productPopOver}
      >
        <div className={styles.productsCard}>
          <Table sx={{ minWidth: 640 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(({ key, ...rest }) => (
                  <TableCell key={key} {...rest}>
                    {key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isCustomerHistory ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>{customerPurchaseHistoryHeading}</TableCell>
                </TableRow>
              ) : null}
              {isRetailerHistory ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>{retailerPurchaseHistoryHeading}</TableCell>
                </TableRow>
              ) : null}
              {isLoading ? (
                // Loading Row
                <TableRow>
                  <TableCell colSpan={columns.length}>{loadingContent}</TableCell>
                </TableRow>
              ) : data.length > 0 ? (
                <ProductSearchRow
                  data={data as SearchProductType[]}
                  rowMapping={rowMapping}
                  rowClick={rowClick}
                  rowIndex={rowIndex}
                />
              ) : (
                // No data message Row
                <TableRow>
                  <TableCell colSpan={columns.length}>{noDataContent}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {handleAddItem ? (
          <Button variant='text' onClick={handleAddItem} text='Add Item [Alt + I]' />
        ) : null}
      </Popover>
    </>
  );
};

const ProductSearchRow: React.FC<BillingProps> = (props) => {
  const rowRef = React.useRef<HTMLTableRowElement>(null);
  const prefixRow = useId();

  const handleProductAdd = (productIndex: number) => {
    if (productIndex === -1) return;
    // TODO:- Just pass skuId
    props.rowClick(props.data[productIndex], props.rowIndex);
  };

  const { navigationIndex, setNavigationIndex } = useTableNavigation(
    rowRef,
    props.data,
    props.data.length,
    handleProductAdd,
  );

  return (
    <>
      {props.data.map((row, index: number) => (
        <Tooltip
          open={index === navigationIndex}
          title={row.molecule || ''}
          placement={'right'}
          key={index}
        >
          <TableRow
            ref={index === navigationIndex ? rowRef : null}
            id={`${prefixRow}+${index}`}
            className={`${styles.productRow} ${index === navigationIndex ? styles.active : ''}`}
            onMouseMove={() => setNavigationIndex(index)}
          >
            {props.rowMapping.map(({ key, formatter, ...rest }, index: number) => (
              <TableCell key={index} {...rest} onClick={() => props.rowClick(row, props.rowIndex)}>
                {key === 'index' ? (
                  index + 1
                ) : key === 'name' ? (
                  <span>
                    {row[key as keyof typeof row] as React.ReactNode}
                    {/* Purchase 1P-Master New TagLine */}
                    {row.isNew && <span className={styles.newTagLine}> New</span>}
                  </span>
                ) : formatter ? (
                  formatter(row[key as keyof typeof row], row)
                ) : (
                  (row[key as keyof typeof row] as React.ReactNode) ?? '-'
                )}
              </TableCell>
            ))}
          </TableRow>
        </Tooltip>
      ))}
    </>
  );
};

export default ProductSearchField;
