import React from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Button from 'components/FormControl/Button';
import { Props } from 'pages/ShiftManagement/types';
import DialogBox from 'components/Dialoges/DialogBox';
import styles from '../dialogs.module.scss';

const UserNotifyDialog: React.FC<Props> = ({ open, onClose, popupData }) => (
  <DialogBox open={open} onClose={onClose}>
    <DialogContent className={styles.container}>
      <DialogTitle className={styles.title}>Users Still Active</DialogTitle>
      <DialogContentText className={styles.content}>{popupData?.popupMsg}</DialogContentText>
      <DialogActions className={styles.actionsContainer}>
        <Button text={'Continue'} size='small' variant='contained' onClick={onClose} />
      </DialogActions>
    </DialogContent>
  </DialogBox>
);
export default UserNotifyDialog;
