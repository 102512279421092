import { SearchProductType } from '../../../components/Common/ProductSearchField/types';
import { SBillEntry } from './types';
import { roundNumber } from '../../../utils/util';
import { DEFAULT_GST } from '../../../utils/constants';
import { INITIAL_STATE } from './constants';
import dayjs from 'dayjs';
import { RAddSkewStock } from '../../Inventory/inventory.interface';

export const BillingUtils = {
  calculateTotal: (data: SBillEntry, overallDiscountPercent: string | number) => {
    // calculating per item
    const pts = +data.pts || 0;
    const effectivePtr = +data.effectivePtr || 0;
    const discountPrice = +data.discountPrice || 0;
    const lotDiscount = +data.lotDiscount || 0;
    const discountPercent = +(data.discountPercent || 0) + +(overallDiscountPercent || 0) || 0;
    const gst = +data.gst || 0;
    const freeQty = +data.freeQty || 0;
    const qty = +data.qty || 0;
    const ptr = +data.ptr || 0;

    const discountPricePerQty = qty === 0 ? 0 : discountPrice / qty;
    const discountPercentPerQty = (ptr * discountPercent) / 100;

    const gstAmount = (gst * (ptr - discountPricePerQty - discountPercentPerQty) * qty) / 100;
    const tempFreeQty = +(+effectivePtr * +freeQty) / qty;

    const newBase = ptr - discountPercentPerQty - discountPricePerQty;
    const margin = (1 - pts / (newBase - (tempFreeQty || 0))) * 100;
    return {
      basePrice: roundNumber(newBase),
      marginPercent: roundNumber(margin),
      netItemAmount: roundNumber(newBase * qty + gstAmount),
      itemAmount: roundNumber(ptr * qty),
      lotDiscount: roundNumber(lotDiscount),
      discountAmount: roundNumber(discountPercentPerQty * qty + discountPrice),
      gstAmount: roundNumber(gstAmount),
    };
  },
  /**
   * Convert Search Result Data into Product Row
   */
  convertSearchToProduct: (
    productData: SearchProductType,
    selectedBatchIdx: number,
    billQty: number | string,
    overallDiscountPercent: string | number,
  ) => {
    const productBatches = productData?.batches || [];
    const selectedBatchItem = productData.batches?.[selectedBatchIdx] || {};
    const gst = productData.gst ?? DEFAULT_GST;
    const itemPts =
      selectedBatchItem.pts ?? productData.pts ?? selectedBatchItem.ptr ?? productData.ptr ?? 0;
    const itemPtr =
      selectedBatchItem.bbSellingPrice ??
      productData.bbSellingPrice ??
      selectedBatchItem.ptr ??
      productData.ptr ??
      0;

    let row: SBillEntry = {
      ...INITIAL_STATE.SBillEntry(),
      id: productData.id,
      batchId: selectedBatchItem ? selectedBatchItem.id : '',
      name: productData.name,
      mrp: productData.mrp,
      hsnCode: productData.hsnCode || '',
      batches: productBatches,
      effectivePtr: itemPtr,
      ptr: itemPtr,
      pts: itemPts,
      batchNo: selectedBatchItem?.batchNo || '',
      bbSellingPrice: productData.bbSellingPrice,
      gst: gst,
      expiry: selectedBatchItem?.expiry || null,
      freeQty: 0,
      lot: '',
      qty: billQty,
      lotDiscount: 0,
    };

    const total = BillingUtils.calculateTotal(row, overallDiscountPercent);
    row = {
      ...row,
      ...total,
    };
    return row;
  },
  validate: {
    name: (productId: string, productName: string) => productName !== '' && productId !== '',
    qty: (productQty: number | string) => +productQty > 0,
    batch: (productBatchId: string, productBatchNo: string) =>
      productBatchId !== '' && productBatchNo !== '',
    mrp: (productMRP: number | string) => +productMRP > 0,
    ptr: (productPTR: number | string) => +productPTR > 0,
    base: (base: number | string) => +base > 0,
    date: (value: number | null) =>
      value !== null && !Number.isNaN(value) && dayjs.unix(value).isValid(),
    customer: {
      name: (value: string) => value !== '',
      id: (value: string) => value !== '',
    },
  },
  convertSkewToProduct: (item: RAddSkewStock, overallDiscount: string | number) => {
    const sku = item.sku;
    const batches = item.stock;
    const selectedBatchItem = batches?.[0] || {};

    const itemPts = selectedBatchItem.pts ?? sku.pts ?? selectedBatchItem.ptr ?? sku.ptr ?? 0;
    const itemPtr =
      selectedBatchItem.bbSellingPrice ??
      sku.bbSellingPrice ??
      selectedBatchItem.ptr ??
      sku.ptr ??
      0;

    let row: SBillEntry = {
      ...INITIAL_STATE.SBillEntry(),
      id: sku.id,
      batchId: selectedBatchItem ? selectedBatchItem.id : '',
      name: sku.name,
      mrp: sku.mrp,
      hsnCode: sku.hsnCode || '',
      batches: batches,
      effectivePtr: sku.ptr,
      ptr: itemPtr,
      pts: itemPts,
      batchNo: selectedBatchItem?.batchNo || '',
      gst: sku.gst,
      expiry: selectedBatchItem?.expiry || null,
      freeQty: 0,
      lot: '',
      qty: 1,
      lotDiscount: 0,
      bbSellingPrice: sku.bbSellingPrice,
    };
    row = {
      ...row,
      ...BillingUtils.calculateTotal(row, overallDiscount),
    };
    return row;
  },
  calculateLotChange: (lotFree: number, lotQty: number) => {
    lotFree = +lotFree;
    lotQty = +lotQty;
    let ratio = 0;
    let newLot = '';
    if (lotFree > 0 && lotQty > 0) {
      ratio = lotFree / (lotFree + lotQty);
      newLot = `${lotQty}+${lotFree}`;
    }
    // converting to percentage
    const discount = ratio * 100;
    return {
      discount: discount,
      lot: newLot,
    };
  },
};
