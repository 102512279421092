import { DEFAULT_GST, RS_SYMBOL } from '../../../utils/constants';
import { SBillEntry, SCustomer, SOverallTotal } from './types';
import { WHOLESALE_BILL_PAYMENT_TYPE } from '../../../components/Common/PaymentFlow/WholesalePayment/constants';
import { DateConst } from '../../../utils/date';
import { SReducerState as SBillTableReducer } from './reducers/billingTable/types';
import { SReducerState as SCustomerReducer } from './reducers/customer/types';
import {
  ColumnType,
  RowMapType,
  SearchProductType,
} from '../../../components/Common/ProductSearchField/types';
import { formattedRupee, GlobalAppUtils, truncate } from '../../../utils/util';
import styles from '../../BillEntry/features/BillingTable/table.module.scss';

export const BILLING_TABLE_COLUMN: { name: string; align?: 'center' | 'left' }[] = [
  { name: '#' },
  { name: 'Product' },
  { name: 'BillQty.' },
  { name: 'Qty' },
  { name: 'Batch No' },
  { name: 'Expiry Date' },
  { name: 'PTS' },
  { name: 'PTR', align: 'center' },
  { name: 'MRP', align: 'center' },
  { name: 'GST%', align: 'center' },
  { name: 'HSN' },
  { name: 'Free Qty' },
  { name: 'LOT' },
  { name: 'Disc%' },
  { name: `Disc${RS_SYMBOL}` },
  { name: 'Margin%' },
  { name: `Base${RS_SYMBOL}` },
  { name: 'Total', align: 'center' },
  { name: '' },
];

// Initial State(s)
export const INITIAL_STATE = {
  SBillEntry: (): SBillEntry => ({
    id: '',
    batchNo: '',
    batchId: '',
    discountPercent: '',
    discountPrice: '',
    expiry: null,
    freeQty: 0,
    marginPercent: 0,
    lot: '',
    mrp: '',
    name: '',
    ptr: 0,
    effectivePtr: 0,
    pts: 0,
    billQty: 0,
    qty: '',
    hsnCode: '',
    gst: DEFAULT_GST,
    itemAmount: 0,
    netItemAmount: 0,
    gstAmount: 0,
    discountAmount: 0,
    batches: [],
    basePrice: 0,
    lotDiscount: 0,
  }),
  SCustomer: (): SCustomer => ({
    retailerName: '',
    retailerId: '',
    paymentMode: WHOLESALE_BILL_PAYMENT_TYPE.WPending.Pending,
    billedOn: DateConst.todayUnix(),
    paymentDueDate: DateConst.todayUnix(),
    refNo: '',
    customerDiscountPrice: 0,
    customerDiscountPercent: 0,
    retailerNo: '',
    billNo: '',
  }),
  SOverallTotal: (): SOverallTotal => ({
    netTotalAmount: 0,
    totalValue: 0,
    totalDiscount: 0,
    totalGst: 0,
    totalItems: 0,
    roundOff: 0,
  }),
  SBillTableReducer: (): SBillTableReducer => ({
    rows: [INITIAL_STATE.SBillEntry()],
    total: {} as SBillTableReducer['total'],
    client: {
      focusedRowIndex: 0,
      focusedColumnIndex: 0,
      searchProducts: {
        items: [],
        isFetching: false,
        isOpen: false,
      },
      submitData: {
        data: {} as SBillTableReducer['client']['submitData']['data'],
        showSubmitModal: false,
        allowSubmit: true,
        allowValidationSubmit: true,
      },
      overallDiscountPercent: 0,
      isSubmittedBillValid: true,
    },
  }),
  SCustomerReducer: (): SCustomerReducer => ({
    customer: INITIAL_STATE.SCustomer(),
  }),
};

export const PRODUCT_SEARCH_TABLE_CONST: { columns: ColumnType[]; rowMapping: RowMapType[] } = {
  columns: [
    { key: 'Product' },
    { key: 'MRP' },
    { key: 'Stock' },
    { key: 'GST(%)' },
    { key: 'Rack' },
    { key: 'Pack' },
    { key: 'Manufacturer' },
  ],
  rowMapping: [
    { key: 'name', width: '200', align: 'left' },
    {
      key: 'mrp',
      width: '90',
      align: 'left',
      formatter: (value) => formattedRupee(value as number),
      className: styles.productMrp,
    },
    {
      key: 'stock',
      align: 'left',
      formatter: (value, row) =>
        GlobalAppUtils.getUnitStock(value as string, (row as SearchProductType).unitRatio),
    },
    { key: 'gst', width: '40', align: 'left' },
    { key: 'rack', align: 'left' },
    {
      key: 'packaging',
      align: 'left',
      formatter: (value) => GlobalAppUtils.formatPackaging(value as string),
      width: '90',
    },
    {
      key: 'manufacturer',
      width: '150',
      align: 'left',
      formatter: (value) => truncate((value || '') as string),
    },
  ],
};

// KeyMaps
export const BillEntryKeyMap = {
  name: 'name',
  qty: 'qty',
  batch: 'batchNo',
  expiry: 'expiry',
  mrp: 'mrp',
  ptr: 'ptr',
  discount: 'discountPercent',
  discountPrice: 'discountPrice',
  freeQty: 'freeQty',
  gst: 'gst',
  hsnCode: 'hsnCode',
  lot: 'lot',
  marginPercent: 'marginPercent',
  basePrice: 'basePrice',
};

export const BILL_TYPE = {
  return: 'return',
  edit: 'edit',
};
