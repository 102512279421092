import {
  Box,
  Dialog,
  Divider,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import TextField from '../../FormControl/TextField';
import styles from './spotlightSearch.module.scss';
import React from 'react';
import useFocus from '../../../hooks/useFocus';
import { OtherServices } from '../../../services/api';
import { SearchProductType } from '../ProductSearchField/types';
import { ApiError } from '../../../services/api/base';
import { useAppContext } from '../../../contexts/AppContext';
import Icon from '../../FormControl/Icon';
import { useNavigate } from 'react-router-dom';
import { SEARCH_CHAR_LIMIT } from '../../../utils/constants';
import { formattedRupee } from '../../../utils/util';
import { Props, Option } from './types';

const initialMsgBox = (
  <div className={styles.noMessageBox}>
    <span>To continue searching, enter 3 or more characters and press Enter</span>
  </div>
);

const noMsgBox = (
  <div className={styles.noMessageBox}>
    <span>No result found</span>
  </div>
);

let isNoResult = false;

const SpotlightSearch: React.FC<Props> = (props) => {
  const [search, setSearch] = React.useState('');
  const [options, setOptions] = React.useState<Option[]>([] as Option[]);
  const [selectedOption, setSelectedOption] = React.useState(-1);

  const navigate = useNavigate();
  const { inputRef: searchRef, setFocus: setSearchFocus } = useFocus();
  const rowRef = React.useRef<HTMLDivElement>(null);
  const boxRef = React.useRef<HTMLDivElement>(null);
  const { snack } = useAppContext();

  const handleClose = () => {
    setSearch('');
    setOptions([] as Option[]);
    setSelectedOption(-1);
    props.onClose();
  };

  const handleSearch = async (value: string) => {
    try {
      const response = await OtherServices.spotlightSearch(value);
      const responseData = response.data?.data;
      let items: SearchProductType[];
      if (responseData.qr) {
        // When It's QR Always select the single item without showing product search box
        items = [responseData?.items || {}];
      } else {
        // Else open product search box
        items = responseData?.items || [];
      }
      if (items.length === 0) isNoResult = true;
      setOptions(
        items.map((item, index) => {
          const description = `${item.packaging ? `P: ${item.packaging} | ` : ''} ${
            item.mrp && `M: ${formattedRupee(item.mrp)}`
          }`;
          if (index === 0)
            return {
              title: item.name,
              deeplink: `/inventory/${item.id}`,
              category: 'Inventory',
              description: description,
              index,
            };
          else
            return {
              title: item.name,
              deeplink: `/inventory/${item.id}`,
              description: description,
              index,
            };
        }),
      );
    } catch (error) {
      snack.show((error as ApiError).userMessage, 'error');
      console.log(error);
    }
  };

  const handleSearchReset = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setOptions([] as Option[]);
    setSelectedOption(-1);
    isNoResult = false;
  };

  React.useEffect(() => {
    // Focus on Search Text
    setSearchFocus();
  }, []);

  const handleItemNavigation = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowUp') {
      setSelectedOption(selectedOption === 0 ? options.length - 1 : selectedOption - 1);
    } else if (e.key === 'ArrowDown') {
      setSelectedOption(selectedOption === options.length - 1 ? 0 : selectedOption + 1);
    } else if (e.key === 'Enter') {
      handleSelection(options[selectedOption].title, options[selectedOption].deeplink);
    }
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // If there is list of option means `Enter` is for selecting item
      if (options.length > 0) {
        e.stopPropagation();
        handleItemNavigation(e);
      }
      // Else it's for search
      else if (searchRef.current?.value && searchRef.current?.value.length >= SEARCH_CHAR_LIMIT) {
        handleSearch(searchRef.current?.value);
        boxRef.current?.focus();
      }
    }
  };

  React.useEffect(() => {
    // On Navigation change the scroll
    rowRef && rowRef.current && rowRef.current.scrollIntoView();
  }, [selectedOption]);

  const handleSelection = (title: string, deeplink?: string) => {
    if (deeplink) navigate(deeplink);
    handleClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} onKeyDown={handleItemNavigation}>
      <Box className={styles.spotlightWrapper}>
        <TextField
          autoFocus
          variant='standard'
          placeholder='Quick Search'
          className={styles.spotlightSearchBox}
          onChange={handleSearchReset}
          onKeyDown={handleSearchKeyDown}
          defaultValue={search}
          fullWidth
          inputRef={searchRef}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <Icon icon={'ri-send-plane-2-line'} color='#fff' />
              </InputAdornment>
            ),
          }}
        />

        <Box className={styles.spotlightResult} ref={boxRef}>
          {options.length > 0
            ? // Option list
              options.map((item, index) => (
                <div key={item.index} ref={index === selectedOption ? rowRef : null}>
                  {item.category && (
                    <>
                      {index !== 0 && <Divider className={styles.divider} />}
                      <Typography variant='body2' className={styles.category}>
                        {item.category}
                      </Typography>
                    </>
                  )}
                  <ListItem
                    disablePadding
                    className={`${styles.item} ${index === selectedOption && styles.activeItem}`}
                    onClick={() => handleSelection(item.title, item.deeplink)}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={item.title}
                        secondary={
                          <>
                            <Typography variant='body2' className={styles.secondaryText}>
                              {item.description}
                            </Typography>
                          </>
                        }
                        className={styles.itemText}
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              ))
            : isNoResult
            ? noMsgBox
            : initialMsgBox}
        </Box>
      </Box>
    </Dialog>
  );
};

export default SpotlightSearch;
