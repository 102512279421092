import { SReducerState as SGrnEntryReducer } from './reducers/GrnEntry/types';
import { SBatchDetails, SBillEntry, SClient } from './types';

export const INITIAL_STATE = {
  SBatchDetails: (): SBatchDetails => ({
    acceptedQty: 0,
    batchNo: '',
    expiry: null,
    mrp: 0,
    receivedQty: 0,
    rejectedQty: 0,
    rejectedReason: '',
  }),
  SBillEntry: (): SBillEntry => ({
    openDetails: true,
    batchNo: '',
    name: '',
    id: '',
    expiry: null,
    gst: 0,
    mrp: 0,
    poQty: 0,
    index: 0,
    rack: '',
    stockId: '',
    packaging: '',
    batchDetails: [INITIAL_STATE.SBatchDetails()],
  }),
  SClient: (): SClient => ({
    expandAll: false,
    isValidBill: true,
    printState: {
      open: false,
      batchId: null,
    },
  }),
  SGrnEntry: (): SGrnEntryReducer => ({
    client: INITIAL_STATE.SClient(),
    rows: [INITIAL_STATE.SBillEntry()],
  }),
};
