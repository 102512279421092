import { SCaseReducer } from './types';
import { SClient } from '../types';
import { SearchProductType } from '../../../../components/Common/ProductSearchField/types';
import { INITIAL_STATE } from '../constants';

const updateClient: SCaseReducer<Partial<SClient>> = (state, action) => {
  state.client = {
    ...state.client,
    ...action.payload,
  };
};

const setFocusedRow: SCaseReducer<number> = (state, action) => {
  state.client.focusedRow = action.payload;
};

const setFocusedColumn: SCaseReducer<number> = (state, action) => {
  state.client.focusedColumn = action.payload;
};

export const setSearchProducts: SCaseReducer<Partial<SClient['searchProducts']>> = (
  state,
  action,
) => {
  state.client.searchProducts = {
    ...state.client.searchProducts,
    ...action.payload,
  };
};

const addSearchedProductRow: SCaseReducer<{ product: SearchProductType; rowIndex: number }> = (
  state,
  action,
) => {
  const payload = action.payload;
  const productData = payload.product;

  state.rows[payload.rowIndex] = {
    id: productData.id,
    name: productData.name,
    wrongQty: 0,
    acceptedQty: 0,
    missingQty: 0,
    orderedQty: 12,
    damagedQty: 0,
    batchNo: '-',
  };

  // If last item has id, add last empty row
  if (state.rows.at(-1)?.id) {
    state.rows = [...state.rows, INITIAL_STATE.SItem()];
  }

  // Reset Search Product
  state.client.searchProducts = {
    items: [],
    isFetching: false,
    isOpen: false,
  };
};

const deleteProductRow: SCaseReducer<number> = (state, action) => {
  if (action.payload !== state.rows.length - 1) {
    state.rows = state.rows.filter((_, index) => index !== action.payload);
  }
};

const handleRowChange: SCaseReducer<{
  key: string;
  value: unknown;
  rowIndex: number;
}> = (state, action) => {
  const { rowIndex, value, key } = action.payload;
  let row = state.rows[rowIndex];
  switch (key) {
    case 'name': {
      row = {
        ...INITIAL_STATE.SItem(),
        name: value as string,
      };
      break;
    }
    default: {
      row = {
        ...row,
        [key]: value as number,
      };
      break;
    }
  }
  state.rows[rowIndex] = row;
};
export default {
  updateClient,
  setFocusedRow,
  setFocusedColumn,
  setSearchProducts,
  deleteProductRow,
  addSearchedProductRow,
  handleRowChange,
};
