import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import React, { lazy, Suspense } from 'react';
import { MedicineLoader } from '../components/Loader';
import { SalesReturnProvider } from '../pages/SalesReturn/contexts/SalesReturnContext';
import { StockTransferProvider } from 'pages/StockTransfer/context/stockTransferContext';
import Reports from '../pages/Reports';
import { WholeSaleBillEntryProvider } from '../pages/WholeSale/BillEntry/context/billEntryContext';
import { PurchaseReturnProvider } from '../pages/PurchaseReturn/context/PurchaseReturnContext';
import { WMSWholeSaleBillEntryProvider } from '../pages/WMSWholesale/context/billEntryContext';
import ItemWiseStockReport from 'pages/Reports/ItemwiseStock';

const Routing = () => {
  const Loader = (Component: React.FC) => {
    const Wrapper = (props: object) => (
      <Suspense fallback={<MedicineLoader />}>
        <Component {...props} />
      </Suspense>
    );
    Wrapper.displayName = `Loader(${Component.displayName || Component.name || 'Component'})`;
    return Wrapper;
  };

  const RequestSku = Loader(lazy(() => import('../pages/RequestedSku')));

  const Login = Loader(lazy(() => import('../pages/Login/Login')));
  const BillEntry = Loader(lazy(() => import('../pages/BillEntry')));

  const BillHistory = Loader(lazy(() => import('../pages/BillHistory/BillHistoryPage')));
  const SalesReturnHistory = Loader(lazy(() => import('../pages/SalesReturnHistory')));
  const SalesReturnHistoryDetails = Loader(
    lazy(() => import('../pages/SalesReturnHistory/SalesReturnDetails')),
  );
  const BillDetail = Loader(lazy(() => import('../pages/BillHistory/BillDetailPage')));
  const WantedNote = Loader(lazy(() => import('../pages/WantedNoteNew')));
  const WantedNoteHistory = Loader(lazy(() => import('../pages/WantedNoteHistory')));

  const OnlineOrder = Loader(lazy(() => import('../pages/OnlineOrder')));

  const CustomerPage = Loader(lazy(() => import('../pages/Customer')));
  const CustomerProfile = Loader(lazy(() => import('../pages/Customer/CustomerDetails/')));
  const MergeHistory = Loader(lazy(() => import('../pages/MergeHistory')));

  const DoctorPage = Loader(lazy(() => import('../pages/Doctor')));
  const DoctorProfile = Loader(lazy(() => import('../pages/Doctor/DoctorDetails')));

  const Dunzo = Loader(lazy(() => import('../pages/Integrations/Dunzo')));
  // WMS section
  const FKHBillEntry = Loader(lazy(() => import('../pages/fkh/BillEntry')));
  const FKHBillDetails = Loader(lazy(() => import('../pages/fkh/BillDetails')));
  const FKHOnlineOrder = Loader(lazy(() => import('../pages/fkh/OnlineOrder')));
  const FKHSalesReturn = Loader(lazy(() => import('../pages/fkh/SalesReturn')));
  const FKHReturnDetails = Loader(lazy(() => import('../pages/fkh/SalesReturnDetails')));
  const FKHSalesReturnHistory = Loader(lazy(() => import('../pages/fkh/SalesReturnHistory')));

  const PurchaseEntry = Loader(lazy(() => import('../pages/PurchaseEntryRedux')));

  const PurchaseOrder = Loader(lazy(() => import('../pages/PurchaseOrder')));
  const PurchaseHistory = Loader(lazy(() => import('../pages/PurchaseHistory/PurchaseHistory')));
  const PurchaseHistoryDetail = Loader(
    lazy(() => import('../pages/PurchaseHistory/PurchaseHistoryDetailPage')),
  );
  const PurchaseReturnHistory = Loader(lazy(() => import('../pages/PurchaseReturnHistory')));
  const PurchaseReturnHistoryDetails = Loader(
    lazy(() => import('../pages/PurchaseReturnHistory/ReturnHistoryDetails')),
  );
  const ChequePayment = Loader(lazy(() => import('../pages/ChequePayment')));

  const Supplier = Loader(lazy(() => import('../pages/Suppliers')));
  const SupplierDetails = Loader(lazy(() => import('../pages/Suppliers/SupplierDetails')));

  const PurchaseReturn = Loader(lazy(() => import('../pages/PurchaseReturn')));
  const PurchaseReturnWrapper = (
    <PurchaseReturnProvider>
      <PurchaseReturn />
    </PurchaseReturnProvider>
  );

  const SalesReturn = Loader(lazy(() => import('../pages/SalesReturn')));
  const SalesReturnWrapper = (
    <SalesReturnProvider>
      <SalesReturn />
    </SalesReturnProvider>
  );

  const WholeSaleBillEntry = Loader(lazy(() => import('../pages/WholeSale/BillEntry')));
  const WholeSaleBillEntryWrapper = (
    <WholeSaleBillEntryProvider>
      <WholeSaleBillEntry />
    </WholeSaleBillEntryProvider>
  );

  const WMSWholeSaleBillEntry = Loader(lazy(() => import('../pages/WMSWholesale')));
  const WMSWholeSaleBillEntryWrapper = (
    <WMSWholeSaleBillEntryProvider>
      <WMSWholeSaleBillEntry />
    </WMSWholeSaleBillEntryProvider>
  );

  const GrnHistory = Loader(lazy(() => import('../pages/WMS/GrnHistory')));
  const GrnHistoryDetails = Loader(lazy(() => import('../pages/WMS/GrnHistoryDetails')));
  const GrnEntry = Loader(lazy(() => import('../pages/WMS/GrnEntry')));
  const PutawayHistory = Loader(lazy(() => import('../pages/WMS/PutawayHistory')));
  const PickListHistory = Loader(lazy(() => import('../pages/WMS/PickListHistory')));

  const WholeSaleBillHistory = Loader(lazy(() => import('../pages/WholeSale/BillHistory')));
  const WholeSaleBillHistoryDetail = Loader(
    lazy(() => import('../pages/WholeSale/BillHistory/Details')),
  );
  const Retailer = Loader(lazy(() => import('../pages/WholeSale/Retailers')));
  const RetailerDetails = Loader(lazy(() => import('../pages/WholeSale/Retailers/Details')));
  const SalePerson = Loader(lazy(() => import('../pages/WholeSale/SalesPerson')));

  const WholesaleReturn = Loader(lazy(() => import('../pages/WholesaleReturn/ReturnBilling')));
  const WholesaleReturnHistory = Loader(
    lazy(() => import('../pages/WholesaleReturn/ReturnHistory')),
  );
  const WholesaleReturnHistoryDetails = Loader(
    lazy(() => import('../pages/WholesaleReturn/ReturnHistory/BillDetails')),
  );

  const PaymentHistory = Loader(lazy(() => import('../pages/Payment')));

  const Inventory = Loader(lazy(() => import('../pages/Inventory')));
  const InventoryItemView = Loader(lazy(() => import('../pages/Inventory/InventoryItemView')));
  const ItemMapping = Loader(lazy(() => import('../pages/ItemMapping')));
  const StockTransfer = Loader(lazy(() => import('../pages/StockTransfer')));
  const StockTransferWrapper = (
    <StockTransferProvider>
      <StockTransfer />
    </StockTransferProvider>
  );
  const TransferDetails = Loader(lazy(() => import('../pages/StockTransferDetails')));
  const TransferBillDetails = Loader(
    lazy(() => import('../pages/StockTransferDetails/BillDetails')),
  );
  const ChainPharmacy = Loader(lazy(() => import('../pages/ChainPharmacyLedger')));
  const ChainPharmacyDetails = Loader(lazy(() => import('../pages/ChainPharmacyLedger/Ledger')));

  const MedicineWikipedia = Loader(lazy(() => import('../pages/MedicineWikipedia')));

  const Dashboard = Loader(lazy(() => import('../pages/Dashboard')));

  const GstSalesReport = Loader(lazy(() => import('../pages/Reports/GstReport/GSTRSales')));
  const GstPurchaseReport = Loader(lazy(() => import('../pages/Reports/GstReport/GSTRPurchase')));
  const Gstr3bReport = Loader(lazy(() => import('../pages/Reports/GstReport/GSTR3B')));
  const Gstr2aReport = Loader(lazy(() => import('../pages/Reports/GstReport/GSTR2A')));
  const Gstr1Report = Loader(lazy(() => import('../pages/Reports/GstReport/GSTR1')));

  // Tally Section
  const TallyMaster = Loader(lazy(() => import('../pages/Reports/Tally/TallyMaster')));
  const TallyTransaction = Loader(lazy(() => import('../pages/Reports/Tally/TallyTransaction')));

  const NearExpiryReport = Loader(lazy(() => import('../pages/Reports/NearExpiryReport')));
  const StockAdjustmentReport = Loader(lazy(() => import('../pages/Reports/StockAdjustment')));
  const StockReport = Loader(lazy(() => import('../pages/Reports/StockReport')));
  const NonMovingItemsReport = Loader(lazy(() => import('../pages/Reports/NonMovingItems')));
  const ScheduleReport = Loader(lazy(() => import('../pages/Reports/Schedule')));
  const ItemMappingReport = Loader(lazy(() => import('../pages/Reports/ItemMapping')));
  const OverBoughtReport = Loader(lazy(() => import('../pages/Reports/OverBoughtReport')));
  const OverSoldReport = Loader(lazy(() => import('../pages/Reports/OverSoldReport')));
  const LowStockReport = Loader(lazy(() => import('../pages/Reports/LowStock')));
  const ItemMarginReport = Loader(lazy(() => import('../pages/Reports/ItemMargin')));
  const ItemMasterReport = Loader(lazy(() => import('../pages/Reports/ItemMasterReport')));
  const BatchWiseSalesReport = Loader(lazy(() => import('../pages/Reports/BatchwiseSales')));
  const ItemWiseSalesReport = Loader(lazy(() => import('../pages/Reports/ItemwiseSales')));
  const StaffWiseSalesReport = Loader(lazy(() => import('../pages/Reports/StaffwiseSales')));
  const BatchWisePurchaseReport = Loader(lazy(() => import('../pages/Reports/BatchWisePurchase')));
  const NewItemReport = Loader(lazy(() => import('../pages/Reports/NewItem')));
  const GeneratedReport = Loader(lazy(() => import('../pages/Reports/GeneratedReport')));
  const CycleCountReport = Loader(lazy(() => import('../pages/Reports/CycleCountReport')));
  const SalesPersonReport = Loader(lazy(() => import('../pages/Reports/SalesPerson')));
  const ShiftManagementReport = Loader(
    lazy(() => import('../pages/Reports/ShiftManagementReport')),
  );
  const ManageExpenses = Loader(lazy(() => import('../pages/ManageExpenses')));
  const BranchPoReport = Loader(lazy(() => import('../pages/Reports/BranchPoReport')));
  const CashAndBankBook = Loader(lazy(() => import('../pages/CashAndBankBook')));
  const BounceItemReport = Loader(lazy(() => import('../pages/Reports/BounceItemReport')));
  const StockAuditReport = Loader(lazy(() => import('../pages/Reports/StockAuditReport')));
  const BankDetails = Loader(lazy(() => import('../pages/CashAndBankBook/BankDetails')));
  const ViewPayment = Loader(lazy(() => import('../pages/ViewPayment/index')));
  const StoreProfile = Loader(lazy(() => import('../pages/StoreProfile')));
  const Settings = Loader(lazy(() => import('../pages/Settings')));
  const PrePrintedSetting = Loader(lazy(() => import('../pages/Settings/features/PrePrintedBill')));
  const Page404 = Loader(lazy(() => import('../pages/Status/Status404')));
  const Page403 = Loader(lazy(() => import('../pages/Status/Status403')));

  return (
    <Routes>
      <Route path='/' element={<PrivateRoute />}>
        <Route path='/' element={<Navigate to='/bill-entry/draft/1' replace />} />
        {/* Billing */}
        <Route path='/bill-entry/draft/:billIdx' element={<BillEntry />} />
        <Route path='/bill-entry/:billId' element={<BillEntry />} />
        <Route path='/order/:orderId' element={<BillEntry />} />
        <Route path='/bill-history' element={<BillHistory />} />
        <Route path='/bill-history/:billId' element={<BillDetail />} />
        <Route path='/order-history/:orderId' element={<BillDetail />} />
        <Route path='/online-orders' element={<OnlineOrder />} />
        <Route path='/sales-return' element={SalesReturnWrapper} />
        <Route path='/sales-return/:orderId' element={SalesReturnWrapper} />
        <Route path='/sales-return-history' element={<SalesReturnHistory />} />
        <Route path='/sales-return-history/:returnId' element={<SalesReturnHistoryDetails />} />
        {/* Purchase */}
        <Route path='/purchase-entry' element={<PurchaseEntry />} />
        <Route path='/purchase-entry/:purchaseId' element={<PurchaseEntry />} />
        <Route path='/purchase-order/:orderId' element={<PurchaseEntry />} />
        <Route path='/purchase-order' element={<PurchaseOrder />} />
        <Route path='/purchase-history' element={<PurchaseHistory />} />
        <Route path='/purchase-history/:purchaseId' element={<PurchaseHistoryDetail />} />
        <Route path='/purchase-return' element={PurchaseReturnWrapper} />
        <Route path='/purchase-return/:purchaseId' element={PurchaseReturnWrapper} />
        <Route path='/purchase-return-history' element={<PurchaseReturnHistory />} />
        <Route
          path='/purchase-return-history/:returnId'
          element={<PurchaseReturnHistoryDetails />}
        />
        <Route path='/suppliers' element={<Supplier />} />
        <Route path='/suppliers/:id' element={<SupplierDetails />} />
        <Route path='/cheque-payment' element={<ChequePayment />} />
        {/* WholeSale */}
        <Route path='/wholesale'>
          <Route path='order/:orderId' element={WholeSaleBillEntryWrapper} />
          <Route path='bill-entry' element={WholeSaleBillEntryWrapper} />
          <Route path='bill-entry/:billId' element={WholeSaleBillEntryWrapper} />
          <Route path='bill-history' element={<WholeSaleBillHistory />} />
          <Route path='bill-history/:orderId' element={<WholeSaleBillHistoryDetail />} />
          <Route path='retailers' element={<Retailer />} />
          <Route path='retailers/:retailerId' element={<RetailerDetails />} />
          <Route path='sales-person' element={<SalePerson />} />
        </Route>
        {/* WMS */}
        <Route path='/wms'>
          <Route path='' element={<Navigate to='/wms/bill-entry/draft/1' replace />} />
          <Route path='bill-entry/draft/:trayIndex' element={WMSWholeSaleBillEntryWrapper} />
          <Route
            path='bill-entry/draft/:trayIndex/:orderId'
            element={WMSWholeSaleBillEntryWrapper}
          />
          <Route path='grn-history' element={<GrnHistory />} />
          <Route path='grn-history/:id' element={<GrnHistoryDetails />} />
          <Route path='grn-entry/:id' element={<GrnEntry />} />
          <Route path='putaway-history' element={<PutawayHistory />} />
          <Route path='picklist-history' element={<PickListHistory />} />
        </Route>
        {/* FKH */}
        <Route path='/fkh'>
          <Route path='invoice-order' element={<FKHBillEntry />} />
          <Route path='invoice-order/:orderId' element={<FKHBillEntry />} />
          <Route path='invoice-details/:billId' element={<FKHBillDetails />} />
          <Route path='online-order' element={<FKHOnlineOrder />} />
          <Route path='online-order/:orderId' element={<FKHOnlineOrder />} />
          <Route path='sales-return' element={<FKHSalesReturn />} />
          <Route path='sales-return/:returnId' element={<FKHSalesReturn />} />
          <Route path='return-details/:billId' element={<FKHReturnDetails />} />
          <Route path='sales-return-history' element={<FKHSalesReturnHistory />} />
        </Route>
        {/* Wholesale return*/}
        <Route path={'wholesale-return-billing'} element={<WholesaleReturn />} />
        <Route path={'wholesale-return-billing/:billId'} element={<WholesaleReturn />} />
        <Route path={'wholesale-return-history'} element={<WholesaleReturnHistory />} />
        <Route
          path={'wholesale-return-history/:billId'}
          element={<WholesaleReturnHistoryDetails />}
        />
        {/* Customers */}
        <Route path='/customers' element={<CustomerPage />} />
        <Route path='/customers/:customerId' element={<CustomerProfile />} />
        {/* Doctors */}
        <Route path='/doctors' element={<DoctorPage />} />
        <Route path='/doctors/:doctorId' element={<DoctorProfile />} />
        {/* Payments */}
        <Route path='/payments-history' element={<PaymentHistory />} />
        <Route path='/merge-duplicate' element={<MergeHistory />} />
        <Route path='/item-mapping' element={<ItemMapping />} />
        {/* Inventory */}
        <Route path='/requested-sku' element={<RequestSku />} />
        <Route path='/inventory' element={<Inventory />} />
        <Route path='/inventory/:skuId' element={<InventoryItemView />} />
        <Route path='/stock-transfer' element={StockTransferWrapper} />
        <Route path='/branch-order/:orderId' element={StockTransferWrapper} />
        <Route path='/stock-transfer/:billId' element={StockTransferWrapper} />
        <Route path='/transfer-details' element={<TransferDetails />} />
        <Route path='/transfer-details/:billId' element={<TransferBillDetails />} />
        <Route path='/branches' element={<ChainPharmacy />} />
        <Route path='/branches/:id' element={<ChainPharmacyDetails />} />
        {/* Medicine Wikipedia */}
        <Route path='/medicine-wikipedia' element={<MedicineWikipedia />} />
        {/* Reports */}
        <Route path='/report' element={<Reports />} />
        <Route path='/report/gst-report-sales' element={<GstSalesReport />} />
        <Route path='/report/gst-report-purchase' element={<GstPurchaseReport />} />
        <Route path='/report/gst-report-gstr3b' element={<Gstr3bReport />} />
        <Route path='/report/gst-report-gstr1' element={<Gstr1Report />} />
        <Route path='/report/expiry-report' element={<NearExpiryReport />} />
        <Route path='/report/stock-report' element={<StockReport />} />
        <Route path='/report/bounce-item-report' element={<BounceItemReport />} />
        <Route path='/report/stock-audit-report' element={<StockAuditReport />} />
        <Route path='/report/stock-adjustment-report' element={<StockAdjustmentReport />} />
        <Route path='/report/non-moving-items-report' element={<NonMovingItemsReport />} />
        <Route path='/report/schedule-report' element={<ScheduleReport />} />
        <Route path='/report/item-mapping-report' element={<ItemMappingReport />} />
        <Route path='/report/overbought-report' element={<OverBoughtReport />} />
        <Route path='/report/oversold-report' element={<OverSoldReport />} />
        <Route path='/report/low-stock-report' element={<LowStockReport />} />
        <Route path='/report/item-master-report' element={<ItemMasterReport />} />
        <Route path='/report/itemwise-sales-margin-report' element={<ItemMarginReport />} />
        <Route path='/report/batchwise-sales-report' element={<BatchWiseSalesReport />} />
        <Route path='/report/itemwise-sales-report' element={<ItemWiseSalesReport />} />
        <Route path='/report/staffwise-sales-report' element={<StaffWiseSalesReport />} />
        <Route path='/report/salesperson-report' element={<SalesPersonReport />} />
        <Route path='/report/shift-management-report' element={<ShiftManagementReport />} />
        <Route path='/report/batchwise-purchase-report' element={<BatchWisePurchaseReport />} />
        <Route path='/report/new-item-report' element={<NewItemReport />} />
        <Route path='/report/itemwise-stock-report' element={<ItemWiseStockReport />} />
        <Route path='/report/gst-report-gstr2a' element={<Gstr2aReport />} />
        <Route path='/report/generated-report' element={<GeneratedReport />} />
        <Route path='/report/cycle-count-report' element={<CycleCountReport />} />
        <Route path='/report/branch-po-report' element={<BranchPoReport />} />
        <Route path='/report/tally-master' element={<TallyMaster />} />
        <Route path='/report/tally-transaction' element={<TallyTransaction />} />
        {/* WantedNote */}
        <Route path='/wanted-note' element={<WantedNote />} />
        <Route path='/wanted-note-history' element={<WantedNoteHistory />} />
        {/* Manage Expenses */}
        <Route path='/manage-expenses' element={<ManageExpenses />} />
        {/* Cash & Bank Book */}
        <Route path='/cash-bank-book' element={<CashAndBankBook />} />
        <Route path='/cash-bank-book/:id' element={<BankDetails />} />
        {/* Dashboard */}
        <Route path='/dashboard' element={<Dashboard />} />
        {/* ViewPayment */}
        <Route path='/viewPayment' element={<ViewPayment />} />
        {/* Store Profile */}
        <Route path='/store-profile' element={<StoreProfile />} />
        {/* Others */}
        <Route path='/settings' element={<Settings />} />
        <Route path='/pre-printed-configuration' element={<PrePrintedSetting />} />
        <Route path='/dunzo' element={<Dunzo />} />
        {/* Status */}
        <Route path='/status/404' element={<Page404 />} />
        <Route path='/status/403' element={<Page403 />} />
        <Route path='*' element={<Navigate to='/status/404' />} />
      </Route>
      <Route path='login' element={<PublicRoute />}>
        <Route path='/login' element={<Login />} />
      </Route>
    </Routes>
  );
};

export default Routing;
