import { SearchProductType } from '../../components/Common/ProductSearchField/types';
import { DEFAULT_GST } from '../../utils/constants';
import { SPurchaseEntry } from './types';
import { RAddSkewStock } from '../Inventory/inventory.interface';
import { roundNumber, StoreUtils } from '../../utils/util';
import dayjs from 'dayjs';
import { localStore } from '../../services/browserStorage';

const PurchaseUtils = {
  calculateTotal: (row: SPurchaseEntry, overallDiscount: number | string | undefined) => {
    const ptr = +(row.ptr || 0);
    const qty = +(row.qty || 0);
    const gst = +(row.gst || 0);
    const discount = +(row.discountPercent || 0);
    const discountPrice = +(row.discountPrice || 0);

    const itemAmount = ptr * qty - discountPrice;
    const { schemeAmount } = PurchaseUtils.getLotKeys(row.lot, itemAmount); // Calculate Lot Discount Amount
    const curItemAmount = itemAmount - schemeAmount; // Reduce Lot Discount Amount first
    const discountAmount = curItemAmount * (discount / 100);
    const overallItemDiscount = (curItemAmount - discountAmount) * (+(overallDiscount || 0) / 100);
    const gstAmount = (curItemAmount - discountAmount - overallItemDiscount) * (gst / 100);
    const netItemAmount = curItemAmount - discountAmount - overallItemDiscount + gstAmount;
    // Calculate MarginPercent
    const itemValue = +row.mrp * (qty + +(row.freeQty || 0));
    const margin = roundNumber(((itemValue - netItemAmount) / itemValue) * 100);
    return { itemAmount, netItemAmount, gstAmount, discountAmount, overallItemDiscount, margin };
  },
  /**
   * Convert Search Result Data into Product Row
   */
  convertSearchToProduct: (
    productData: SearchProductType,
    billQty: number | string,
    oldData: SPurchaseEntry,
    overallDiscount: number | string,
  ) => {
    const ptr = oldData.ptr ?? 0;
    const gst = productData.gst ?? oldData.gst ?? DEFAULT_GST;
    const discountPercent = oldData.discountPercent ?? 0;
    const discountPrice = oldData.discountPrice ?? 0;
    const row: SPurchaseEntry = {
      id: productData.id,
      name: productData.name,
      rack: productData.rack || '',
      rackId: productData.rackId || '',
      skuId: productData.id,
      packaging: productData.packaging || '',
      hsnCode: productData.hsnCode || '',
      productId: productData.productId || '',

      // For edit keep the old values of the stock
      mrp: oldData.mrp || productData.mrp,
      batchNo: oldData.batchNo || '',
      gst: gst,
      expiry: oldData.expiry || null,
      ptr: ptr,
      freeQty: oldData.freeQty ?? 0,
      lot: oldData.lot || '',
      qty: billQty,
      discountPercent: discountPercent,
      discountPrice: discountPrice,
      barcode: oldData.barcode || '',

      itemAmount: 0,
      netItemAmount: 0,
      gstAmount: 0,
      discountAmount: 0,
      overallItemDiscount: 0,
      margin: '',
    };
    const total = PurchaseUtils.calculateTotal(row, overallDiscount);
    return { ...row, ...total };
  },
  /** convert new sku to product row */
  convertSkewToProduct: (
    item: RAddSkewStock,
    oldData: SPurchaseEntry,
    overallDiscount: string | number,
    isUploadMode: boolean,
  ) => {
    let row: SPurchaseEntry = oldData;
    if (isUploadMode) {
      row = {
        ...oldData,
        id: item.sku.id,
        skuId: item.sku.id,
        name: item.sku.name,
        packaging: item.sku.packaging,
        hsnCode: item.sku.hsnCode || oldData.hsnCode || '',
        rack: item.sku.rack || oldData.rack || '',
        barcode: item.sku.barcode || oldData.barcode || '',
        isNew: false,
      };
    } else {
      const qty = oldData.qty || 0;
      const ptr = oldData.ptr;
      const discountPercent = oldData.discountPercent ?? 0;
      const discountPrice = oldData.discountPrice ?? 0;
      row = {
        ...oldData,
        id: item.sku.id,
        name: item.sku.name,
        rack: item.sku.rack || oldData.rack,
        skuId: item.sku.id,
        packaging: item.sku.packaging,
        gst: item.sku.gst,
        barcode: item.sku.barcode || oldData.barcode || '',

        hsnCode: item.sku.hsnCode,
        mrp: item.sku.mrp || oldData.mrp,
        ptr: ptr,
        qty: qty,
        discountPercent: discountPercent,
        discountPrice: discountPrice,
      };
    }
    const total = PurchaseUtils.calculateTotal(row, overallDiscount);
    return { ...row, ...total };
  },

  calculateLotChange: (lotFree: number, lotQty: number, lotRate: number, itemAmount: number) => {
    const schemePercent =
      lotFree > 0 && lotQty > 0 ? roundNumber((lotFree / (lotFree + lotQty)) * 100) : lotRate;
    const schemeAmount = (schemePercent * itemAmount) / 100;
    const newLot = lotFree > 0 && lotQty > 0 ? `${lotQty}+${lotFree}` : `${roundNumber(lotRate)}%`;
    return { schemePercent, schemeAmount, lot: newLot };
  },

  validate: {
    name: (productId: string, productName: string, checkId: boolean, uploadMode: boolean) =>
      uploadMode && !checkId ? productName !== '' : productName !== '' && productId !== '',
    qty: (productQty: number | string, productFreeQty: number | string) =>
      +productQty + +productFreeQty > 0,
    batch: (productBatchNo: string) => productBatchNo !== '',
    mrp: (productMRP: number | string) => +productMRP > 0,
    ptr: (productPTR: number | string) => +productPTR > 0,
    date: (value: number | null) =>
      value !== null && !Number.isNaN(value) && dayjs.unix(value).isValid(),
    hsnCode: (value: number | null | string) =>
      StoreUtils.getKeyFromActiveSession(['config', 'hsnMandate']) ? !!value : true,
  },
  removeLocalDraft: () =>
    localStore.remove(`purchase_${StoreUtils.getKeyFromActiveSession(['cid'])}`),
  saveLocalDraft: (data: object) =>
    localStore.set(`purchase_${StoreUtils.getKeyFromActiveSession(['cid'])}`, data),
  getLocalDraft: () => localStore.get(`purchase_${StoreUtils.getKeyFromActiveSession(['cid'])}`),

  getLotKeys: (lot: string, itemAmount: number) => {
    if (typeof lot === 'string' && lot.includes('+')) {
      const [lotQty, lotFree] = lot.split('+').map(Number);
      const { schemePercent, schemeAmount } = PurchaseUtils.calculateLotChange(
        lotFree,
        lotQty,
        0,
        itemAmount,
      );

      return {
        lot,
        lotQty,
        lotFree,
        schemePercent,
        schemeAmount,
      };
    }

    const schemeRate = typeof lot === 'string' ? parseFloat(lot) : 0;
    return {
      lot: lot,
      lotQty: 0,
      lotFree: 0,
      schemePercent: schemeRate,
      schemeAmount: (+schemeRate * +itemAmount) / 100 || 0,
    };
  },
};

export default PurchaseUtils;
