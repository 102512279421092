import styles from './keyShortcut.module.scss';
import { Box, Dialog, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import ShortcutMapTab from './Tab';
import { KeyShortCutConst } from './constants';
import { Props } from './types';

const KeyShortcutDialog: React.FC<Props> = (props) => {
  const [tab, setTab] = React.useState(props.tab ?? 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTab(newValue);

  return (
    <Dialog open={props.open} title={'Keyboard shortcuts'} onClose={props.onClose}>
      <Box className={styles.container}>
        <Box className={styles.header}>
          <Typography variant='h4' component='h1' className={styles.title}>
            Keyboard shortcuts
          </Typography>
        </Box>
        <Box className={styles.body}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={tab}
            onChange={handleChange}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label={'Global'} value={0} />
            <Tab label={'Bill Entry'} value={1} />
            <Tab label={'Purchase Entry'} value={2} />
          </Tabs>

          <div hidden={tab !== 0} className={styles.tabPanel}>
            <ShortcutMapTab shortcut={KeyShortCutConst.global} />
          </div>
          <div hidden={tab !== 1} className={styles.tabPanel}>
            <ShortcutMapTab shortcut={KeyShortCutConst.billing} />
          </div>
          <div hidden={tab !== 2} className={styles.tabPanel}>
            <ShortcutMapTab shortcut={KeyShortCutConst.purchase} />
          </div>
        </Box>
      </Box>
    </Dialog>
  );
};

export default KeyShortcutDialog;
