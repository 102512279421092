import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';
import Button from '../../FormControl/Button';

const RefreshDialog: React.FC<{ open: boolean }> = (props) => (
  <Dialog open={props.open}>
    <DialogContent>
      <DialogContentText>
        Welcome back! We noticed your system was idle for a while. Please click continue to keep
        using it.
      </DialogContentText>
      <DialogActions>
        <Button
          text={'Continue'}
          onClick={() => {
            window.location.reload();
          }}
        />
      </DialogActions>
    </DialogContent>
  </Dialog>
);

export default RefreshDialog;
