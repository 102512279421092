import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { handleLogout } from '../../utils/auth';
import { getVersionAsString, StoreUtils } from '../../utils/util';
import { localStore } from '../browserStorage';
import { SHIFT_MANAGEMENT_DIALOG, SHIFT_REFRESH_DIALOG } from 'constants/events';

const axiosClient: AxiosInstance = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axiosClient.defaults.headers.common = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  responseType: 'json',
  'session-token': StoreUtils.getActiveSession().session?.['session-token'],
};

// Request interceptor for API calls
axiosClient.interceptors.request.use(
  async (config) => {
    config.headers.set({
      'session-token': StoreUtils.getActiveSession().session?.['session-token'],
      'x-web-version': getVersionAsString(),
    });
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  function (response) {
    const planStatus = response.headers['plan-status'];
    if (planStatus) {
      localStore.set('currentPlanStatus', planStatus);
    }
    const { error } = response?.data || {};

    if (error) {
      // If Session expired, Let user re-login
      if (error.userMessage.includes('Session expired') || error.errorCode === 1001) {
        handleLogout(false);
      }
      if (error.errorCode === 700) {
        window.dispatchEvent(new Event(SHIFT_MANAGEMENT_DIALOG));
      }
      if (error.errorCode === 701) {
        window.dispatchEvent(new Event(SHIFT_REFRESH_DIALOG));
      }
      console.error(
        `Interceptors error:-> ${JSON.stringify(error)}\nRequest URL:-> ${
          response.request.responseURL
        }`,
      );
      return Promise.reject({ ...error, response });
    }
    // Dispatch any action on success
    return response;
  },
  async function (error) {
    console.log('error', error);
    if (error.response.status === 401) {
      // Add Logic to
      // 1. Redirect to login page or
      // 2. Request refresh token
      handleLogout(false);
    }
    return await Promise.reject(error);
  },
);
export function getRequest(URL: string) {
  return axiosClient.get(`/${URL}`).then((response: AxiosResponse) => response);
}

export function postRequest(URL: string, payload?: unknown) {
  return axiosClient.post(`/${URL}`, payload).then((response: AxiosResponse) => response);
}

export function patchRequest(URL: string, payload: unknown) {
  return axiosClient.patch(`/${URL}`, payload).then((response: AxiosResponse) => response);
}

export function deleteRequest(URL: string) {
  return axiosClient.delete(`/${URL}`).then((response: AxiosResponse) => response);
}

export function putRequest(URL: string, payload: unknown, config?: unknown) {
  return axiosClient
    .put(`/${URL}`, payload, config ? config : {})
    .then((response: AxiosResponse) => response);
}

export function putRequestWithS3(URL: string, payload: File, type: string) {
  // TODO: Change this to use the axiosClient
  return fetch(URL, {
    method: 'PUT',
    headers: { 'Content-Type': type, 'x-amz-acl': 'public-read' },
    body: payload,
  });
}

export type ApiError = {
  errorCode: number;
  userMessage: string;
  response: AxiosResponse;
};
