import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { INITIAL_STATE } from '../../constants';

const billingTableSlice = createSlice({
  name: 'billingTable',
  initialState: INITIAL_STATE.SBillTableReducer(),
  reducers: reducers,
});

export const billingSliceActions = billingTableSlice.actions;
export const {
  setFocusedIndex,
  setColumnFocusedIndex,
  handleRowChange,
  setSearchProducts,
  setSubmitData,
  calculateOverallTotal,
  resetSlice: resetBillingSlice,
  updateClient,
  addSplitBatch,
  updateLoyaltyOnRows,
  handleSlabWiseDiscountApply,
} = billingTableSlice.actions;

export default billingTableSlice.reducer;
