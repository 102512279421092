import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { IDLE_TIMEOUT } from '../utils/constants';

const useIdleTimeout = () => {
  const [show, setShow] = React.useState(false);

  // System idle check
  useIdleTimer({
    onIdle: () => {
      setShow(true);
    },
    timeout: IDLE_TIMEOUT,
  });

  return { show };
};

export default useIdleTimeout;
