import { AxiosResponse } from 'axios';
import { getRequest, postRequest, putRequestWithS3 } from '../services/api/base';
import { UPLOAD_URL } from '../constants/urls';
import { DateConst } from './date';

export const uploadFileName = async (data: unknown): Promise<AxiosResponse> =>
  postRequest(`${UPLOAD_URL}`, data);

export const uploadFileToS3 = async (fileUrl: string, data: File): Promise<Response> =>
  putRequestWithS3(fileUrl, data, data.type);

export const getJsonFromS3 = async (url: string): Promise<Response> => fetch(url);

export const checkUploadFileStatus = async (
  trackingId: string,
): Promise<
  AxiosResponse<{ data: { task: { status: string; result: unknown; s3Link: string } } }>
> => getRequest(`get_async_data_status/${trackingId}`);

export const uploadToServer = async (file: File) => {
  const fileData = {
    filename: DateConst.todayUnix() + '_' + file.name,
  };
  const response = await uploadFileName(fileData);
  if (response.status !== 200) throw new Error(`HTTP error: ${response.status}`);
  const { data } = response.data;
  return await uploadToS3(data.uploadUrl, file);
};

const uploadToS3 = async (fileUrl: string, file: File) => {
  const response = await uploadFileToS3(fileUrl, file);
  if (response.status !== 200) throw new Error(`HTTP error: ${response.status}`);
  return response.url.split('?')[0];
};

export const convertBase64ToFile = (base64: string, filename: string, mimeType: string) => {
  const urlSafeBase64 = base64.replace(/_/g, '/').replace(/-/g, '+');
  const bin = atob(urlSafeBase64);
  const len = bin.length;
  const buf = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    buf[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buf.buffer], { type: mimeType });
  return new File([blob], filename, { type: mimeType });
};
