import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import styles from './multiSelect.module.scss';
import React from 'react';
import { Props } from './types';

const OnePMultiSelect: React.FC<Props> = (props) => {
  const { label, value, items, width, ...rest } = props;
  return (
    <FormControl variant={props.variant} sx={{ width: width }} className={styles.formControl}>
      <InputLabel id='1p-select-label' className={styles.topMargin}>
        {label}
      </InputLabel>
      <Select
        labelId='1p-multi-select-label'
        id='1p-multi-select-labelId'
        value={value}
        label={label}
        multiple
        {...rest}
        renderValue={(selected) => (selected as []).join(', ')}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item} sx={{ height: '40px' }}>
            <Checkbox checked={value.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OnePMultiSelect;
