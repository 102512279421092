import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import Routing from './routes';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import useKeyShortCut from './hooks/useKeyShortCut';
import { AppProvider } from './contexts/AppContext';
import FireBaseNotify from './components/FireBaseNotify';
import { SidebarProvider } from './contexts/SidebarContext';
import PlanExpiry from './components/Dialoges/PlanExpiry';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useKeyShortCut([
    {
      key: 'alt+n',
      handler: () => navigate('/'),
      condition: !location.pathname.split('/')[1].startsWith('bill-entry'),
    },
    {
      key: 'alt+p',
      handler: () => navigate('/purchase-entry'),
      condition: !location.pathname.split('/')[1].startsWith('purchase-entry'),
    },
    {
      key: 'alt+o',
      handler: () => navigate('/online-orders'),
      condition: !location.pathname.split('/')[1].startsWith('online-order'),
    },
    {
      key: 'alt+s',
      handler: () => navigate('/settings'),
      condition: !location.pathname.split('/')[1].startsWith('settings'),
    },
    {
      key: 'ctrl+q',
      handler: () => navigate('fkh/invoice-order/invoice'),
    },
  ]);

  return (
    <SidebarProvider>
      <ThemeProvider>
        <AppProvider>
          <CssBaseline />
          <Routing />
          <FireBaseNotify />
          <PlanExpiry />
        </AppProvider>
      </ThemeProvider>
    </SidebarProvider>
  );
};
export default App;
