import TextField from '@mui/material/TextField';
import { Props } from './types';
import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { UserAccessControlData } from '../../../utils/accessControl';

const OnePTextField: React.FC<Props> = (props) => {
  /**
   * label - display user label on the TextField: required
   * ...Rest - TextField props(https://mui.com/material-ui/api/text-field/#props)
   */
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'xl'));

  const {
    label,
    variant,
    inputType,
    onChange: onChangeCallback,
    size,
    onFocus,
    accessKey,
    ...rest
  } = props;

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!onChangeCallback) return;

    // Handle Validation
    switch (inputType) {
      case 'mobile':
        if (/^[0-9]{0,10}$/.test(e.target.value)) onChangeCallback(e);
        break;
      case 'integer':
        if (/^[0-9]*$/.test(e.target.value)) onChangeCallback(e);
        break;
      case 'decimal':
        if (/^[0-9]*\.?[0-9]*$/.test(e.target.value)) onChangeCallback(e);
        break;
      case 'percentage':
        if (/^[0-9.]*$/.test(e.target.value) && +e.target.value <= 100) onChangeCallback(e);
        break;
      case 'negativePer':
        if (
          /^-?[0-9.]*$/.test(e.target.value) &&
          (e.target.value === '-' || (+e.target.value >= -100 && +e.target.value <= 100))
        )
          onChangeCallback(e);
        break;
      default:
        onChangeCallback(e);
    }
  };
  return (
    <TextField
      id='1p-basic-text'
      autoComplete='off'
      variant={variant || 'outlined'}
      label={label || ''}
      onChange={handleOnChange}
      size={size || (isSmall ? 'small' : 'medium')}
      {...rest}
      onFocus={(event) => {
        event.target.select(); // Select all text on focus for easy editing
        onFocus && onFocus(event);
      }}
      disabled={UserAccessControlData.get(accessKey) || rest.disabled}
    />
  );
};

export default OnePTextField;
