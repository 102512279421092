import styles from './header.module.scss';
import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { StoreUtils } from '../../../utils/util';
import Icon from '../../../components/FormControl/Icon';
import { TableIconButton } from '../../../components/Styled';
import { useLocation, useNavigate } from 'react-router-dom';
import ShopSection from './ShopSection';
import HelpSection from './HelpSection';
import LearnMoreSection from './learnmore';
import NotificationSection from './NotificationSection';
import useIdleTimeout from '../../../hooks/useIdleTimeout';
import RefreshDialog from '../../../components/Dialoges/RefreshDialog';
import { localStore } from '../../../services/browserStorage';

const PATH_MAP: Record<string, string> = {
  '': 'Bill Entry',
  'bill-entry': 'Bill Entry',
  wholesale: 'WholeSale',
  'wholesale-return-billing': 'Wholesale Return',
  'wholesale-return-history': 'Wholesale Return History',
  order: 'Online Order',
  dunzo: 'Dunzo Catalogue',
  'online-orders': 'Online Order',
  'bill-history': 'Bill History',
  'order-history': 'Online Order History',
  'sales-return': 'Sales Return',
  'sales-return-history': 'Sales Return History',
  'purchase-entry': 'Purchase Entry',
  'purchase-order': 'Purchase Order',
  'purchase-history': 'Purchase History',
  'purchase-return': 'Purchase Return',
  'purchase-return-history': 'Purchase Return History',
  suppliers: 'Suppliers',
  'cheque-payment': 'Cheque Payment',
  customers: 'Customers',
  doctors: 'Doctors',
  'payments-history': 'Payments History',
  inventory: 'Inventory',
  'medicine-wikipedia': 'Med Wiki',
  report: 'Reports',
  'merge-duplicate': 'Merge Duplicates',
  'item-mapping': 'Item Mapping',
  'gst-report-sales': 'GSTR Sales Report',
  'gst-report-purchase': 'GSTR Purchase Report',
  'gst-report-gstr3b': 'GSTR-3B Report',
  'gst-report-gstr2a': 'GSTR-2A Report',
  'gst-report-gstr1': 'GSTR-1 Report',
  'expiry-report': 'Expiry report',
  'branch-po-report': 'Branch-Po Report',
  'bounce-item-report': 'Bounce Item Report',
  'stock-audit-report': 'Stock Audit Report',
  'stock-report': 'Stock report',
  'tally-master': 'Tally Master',
  'tally-transaction': 'Tally Transaction',
  'stock-adjustment-report': 'Stock adjustment report',
  'non-moving-items-report': 'Non moving items report',
  'schedule-report': 'Schedule report',
  'item-mapping-report': 'Item mapping report',
  'overbought-report': 'Overbought report',
  'oversold-report': 'Oversold report',
  'low-stock-report': 'Low stock report',
  'item-master-report': 'Item master report',
  'itemwise-sales-margin-report': 'Itemwise sales margin report',
  'batchwise-sales-report': 'Batchwise sales report',
  'itemwise-sales-report': 'Itemwise sales report',
  'staffwise-sales-report': 'Staffwise sale report',
  'shift-management-report': 'Shift management report',
  'salesperson-report': 'Salesperson Report',
  'batchwise-purchase-report': 'Batchwise purchase report',
  'new-item-report': 'New item report',
  'wanted-note': 'Wanted note',
  'wanted-note-history': 'Wanted Note History',
  'cash-bank-book': 'Cash & Bank Book',
  'manage-expenses': 'Manage expenses',
  dashboard: 'Dashboard',
  viewPayment: 'View Payment',
  'store-profile': 'Store Profile',
  settings: 'Settings',
  'stock-transfer': 'Stock Transfer',
  'transfer-details': 'Transfer Details',
  branches: 'Branches',
  'generated-report': 'Generated Report',
  'pre-printed-configuration': 'Pre Print Configuration',
  'itemwise-stock-report': 'Itemwise Stock Report',

  // wms
  wms: 'WMS',
};

const SUB_PATH: Record<string, string> = {
  'bill-entry': 'Wholesale Bill Entry',
  'bill-history': 'Wholesale Bill History',
  retailers: 'Wholesale Retailers',
  'sales-person': 'Sales Person',
  // wms
  'grn-history': 'GRN History',
  'grn-entry': 'GRN Entry',
  'picklist-history': 'Picklist History',
  'putaway-history': 'Putaway History',
  // fkh
  'invoice-order': 'Invoice Order',
  'online-order': 'Online Order',
  'invoice-details': 'Invoice Details',
};

const Header: React.FC<{ onIdxChange: (idx: number) => void }> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { show } = useIdleTimeout();
  const planStatus = localStore.get('currentPlanStatus');

  // Header location split for report
  const path = location.pathname.includes('report' || 'wms')
    ? location.pathname.split('/').pop()
    : location.pathname.split('/')[1];

  const subPath = location.pathname.split('/')?.[2];

  /**
   * sub path mapping is there then will show subpath or else path
   */
  const pathName = SUB_PATH[subPath] || (path ? PATH_MAP[path] : '');
  const {
    session: { userData },
  } = StoreUtils.getActiveSession();

  const screenkey = SUB_PATH[subPath] ? `${path}/${subPath}` : path ?? '';

  return (
    <header>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className={styles.header}
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          {/* Back Button */}
          <Tooltip title={'Go Back'}>
            <TableIconButton onClick={() => navigate(-1)}>
              <Icon icon={'ri-arrow-go-back-line'} size={'1.2rem'} />
            </TableIconButton>
          </Tooltip>
          {/* Change Active Shop */}
          <ShopSection onIdxChange={props.onIdxChange} />

          <Stack direction='row' alignItems='center'>
            <Icon icon={'ri-arrow-right-s-fill'} />
            <Typography variant='subtitle1' className={styles.path}>
              {pathName}
            </Typography>
          </Stack>
        </Stack>
        <div>
          {planStatus !== 'active' && (
            <Stack direction='row' alignItems='center' spacing={0}>
              <Icon icon={'ri-error-warning-line'} color='#d32926' />
              <p className={styles.planStatus}>{localStore.get('planStatusMsg')}</p>
            </Stack>
          )}
        </div>

        <Stack direction='row' alignItems='center' spacing={0}>
          {/* Help Section */}
          <span className={styles.userInfo}>Hi, {userData.name}</span>
          <HelpSection />
          <LearnMoreSection screenkey={screenkey} />
          <NotificationSection />
        </Stack>
      </Stack>
      {show && <RefreshDialog open={show} />}
    </header>
  );
};

export default Header;
