import React from 'react';
import { Props } from './types';

const OnePIcon: React.FC<Props> = (props) => (
  <i
    className={`${props.icon} ${props.className || ''}`}
    style={{
      padding: '0 4px',
      fontSize: `${props.size || '1rem'}`,
      color: props.color,
    }}
  />
);

export default OnePIcon;
