import { SCaseReducer } from './types';
import { SCustomer } from '../../types';
import { INITIAL_STATE } from '../../constants';
import { ObjectUtils } from '../../../../../utils/util';

const loadCustomer: SCaseReducer<Partial<SCustomer>> = (state, action) => ({
  ...INITIAL_STATE.SCustomerReducer(),
  customer: ObjectUtils.mergeObjectsWithDefaults(action.payload, INITIAL_STATE.SCustomer()),
});
const updateCustomer: SCaseReducer<SCustomer> = (state, action) => {
  state.customer = {
    ...state.customer,
    ...action.payload,
  };
};

const resetSlice: SCaseReducer<void> = () => INITIAL_STATE.SCustomerReducer();

export default { loadCustomer, updateCustomer, resetSlice };
