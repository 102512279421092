import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styles from './reports.module.scss';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { StoreUtils, capitalize } from '../../utils/util';
import { REPORT_DETAILS } from './constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/FormControl/Icon';
import { UserAccessControlData, ACCESS_CONTROL_KEYS_ENUM } from '../../utils/accessControl';
import Button from '../../components/FormControl/Button';
import { UserUtils } from 'utils/auth';

const Reports = () => {
  const navigate = useNavigate();
  const isStoreAdmin = UserUtils.isStoreAdmin();
  const shiftManagementConfig = StoreUtils.getKeyFromActiveSession(['config', 'shiftManagement']);
  const stockTransferConfig = StoreUtils.getKeyFromActiveSession(['config', 'stock_transfer']);

  const isDisabled = (name: string) => {
    if (
      name === 'Expiry Report' &&
      UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.EXPIRY_REPORT_READ)
    )
      return true;
    if (
      name !== 'Expiry Report' &&
      UserAccessControlData.get(ACCESS_CONTROL_KEYS_ENUM.REPORTS_READ)
    )
      return true;
    if (name === 'Salesperson Incentive Report' && !isStoreAdmin) return true;
    if (name === 'Shift Management Report' && (!isStoreAdmin || !shiftManagementConfig))
      return true;

    if (name === 'Bounce Item Report' && !stockTransferConfig) return true;

    if (name === 'Stock Audit Report' && !StoreUtils.getActiveSession().session.userData.isAdmin)
      return true;
    return false;
  };

  const handleGenerateReportClick = () => {
    navigate('generated-report');
  };

  return (
    <Box className={styles.container}>
      <Box>
        <div className={styles.generatedReportBtn}>
          <Button
            variant={'contained'}
            text={'Generated Report'}
            disableRipple={true}
            onClick={handleGenerateReportClick}
          />
        </div>
        {Object.entries(REPORT_DETAILS).map((data, index) =>
          data[1] ? (
            <Box key={index} className={styles.box}>
              {['Tally', 'Wms'].includes(data[0]) && StoreUtils.isWMSStore() ? (
                <>
                  <Typography variant='h2' className={styles.title}>
                    <Icon size='1.3rem' icon={'ri-file-chart-line'} />
                    {`${capitalize(data[0])} Report`}
                  </Typography>
                  <Divider className={styles.divide} />
                </>
              ) : (
                ''
              )}
              {!['Tally', 'Wms'].includes(data[0]) ? (
                <>
                  <Typography variant='h2' className={styles.title}>
                    <Icon size='1.3rem' icon={'ri-file-chart-line'} />
                    {`${capitalize(data[0])} Report`}
                  </Typography>
                  <Divider className={styles.divide} />
                </>
              ) : (
                ''
              )}

              <Grid container spacing={2}>
                {data[1].map((items, index) =>
                  !items.isWms || StoreUtils.isWMSStore() ? (
                    <Grid key={index} item xs={3} className={styles.grid}>
                      <Card
                        className={`${styles.card} ${
                          isDisabled(items.name) ? styles.disabledCard : ''
                        }`}
                        onClick={(e) =>
                          isDisabled(items.name) ? e.preventDefault : navigate(items.url)
                        }
                      >
                        <CardContent className={styles.cardContent}>
                          <Typography gutterBottom variant='h5' component='div'>
                            {items.name}
                          </Typography>
                          <Typography fontSize={'12px'} variant='body2' color='text.secondary'>
                            {items.description}
                          </Typography>
                        </CardContent>
                        <Stack className={styles.background}>
                          <Icon icon='ri-arrow-right-line' className={styles.arrowIcon} />
                        </Stack>
                        {isDisabled(items.name) && (
                          <Stack className={styles.lockIconContainer}>
                            <Icon size='4rem' icon='ri-lock-fill' className={styles.lockIcon} />
                          </Stack>
                        )}
                      </Card>
                    </Grid>
                  ) : null,
                )}
              </Grid>
            </Box>
          ) : null,
        )}
      </Box>
    </Box>
  );
};

export default Reports;
