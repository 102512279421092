import { formattedRupee, GlobalAppUtils, StoreUtils, truncate } from '../../utils/util';
import { SBillEntry, SCustomer, STableColumnData } from './types';
import { DateConst } from '../../utils/date';
import { BILL_PAYMENT_TYPE } from '../../components/Common/PaymentFlow/SalePayment/constants';
import {
  ColumnType,
  RowMapType,
  SearchProductType,
} from '../../components/Common/ProductSearchField/types';
import styles from './features/BillingTable/table.module.scss';
import { SReducerState as SBillTableReducer } from './reducers/billingTable/types';
import { SReducerState as SCustomerReducer } from './reducers/customer/types';
import { SHideColumnsState } from './features/HideColumns/types';
import { PRODUCT_SEARCH_INFO_MESSAGE } from '../../utils/constants';

export const INITIAL_STATE = {
  SBillEntry: (): SBillEntry => ({
    id: '',
    name: '',
    batchNo: '',
    batchId: '',
    batches: [],
    expiry: null,
    billQty: '',
    mrp: 0,
    price: 0,
    sellingPrice: 0,
    discount: '',
    discountAmount: 0,
    loyalty: 0,
    unitRatio: 0,
    looseEnabled: false,
    stock: '',
    gst: 0,
    itemAmount: 0,
    netItemAmount: 0,
    loyaltyAmount: 0,
    gstAmount: 0,
    sgst: 0,
    cgst: 0,
    wantednoteId: '',
    threshold: 0,
    maxThreshold: 0,
    hsnCode: '',
    manufacturer: '',
    packaging: '',
    rack: '',
    discountEnabled: false,
    molecule: '',
    totalStock: '',
  }),
  SCustomer: (): SCustomer => ({
    name: '',
    contactNo: '',
    customerId: '',
    doctor: '',
    doctorId: '',
    billedOn: DateConst.todayUnix(),
    isBilledOnChange: false,
    deliveryCharge: 0,
    discount: 0,
    discountPercent: 0,
    paymentMode: TYPE_OF_PAYMENT.Cash,
    saleType: SALES_TYPE.Sale,
    reminderDay: 0,
    whatsAppBill: !StoreUtils.getKeyFromActiveSession(['config', 'noAutoBillMsg']),
    note: '',
    loyalty: 0,
    enabledLoyalty: false,
    estimate: false,
  }),
  SBillTableReducer: (): SBillTableReducer => ({
    rows: [INITIAL_STATE.SBillEntry()],
    total: {
      totalAmount: 0,
      netAmount: 0,
      totalSaving: 0,
      loyaltyEarned: 0,
      loyaltyRedeemed: 0,
      totalDiscountAmount: 0,
      gst: 0,
      isSchedule: false,
    },
    client: {
      focusedRowIndex: 0,
      focusedColumnIndex: 0,
      searchProducts: {
        items: [],
        isFetching: false,
        isOpen: false,
        searchString: '',
      },
      submitData: {
        data: {} as SBillTableReducer['client']['submitData']['data'],
        showSplitView: false,
        showSubmitModal: false,
        allowSubmit: true,
        allowValidationSubmit: true,
      },
      overallDiscountPercent: 0,
      deliveryCharge: 0,
      isSubmittedBillValid: true,
      inventoryViewSku: '',
      hideColumns: GET_HIDE_COLUMN_CONFIG(),
      matchedIndex: -1,
      messageSectionOpen: false,
      isProcessing: false,
    },
  }),
  SCustomerReducer: (): SCustomerReducer => ({
    customer: INITIAL_STATE.SCustomer(),
    client: {
      pendingBills: [],
      dueAmount: 0,
      pastBoughtHistory: [],
      pastBills: [],
      vouchers: 0,
      isEditFirstLoad: false,
      isBillEdit: false,
    },
  }),
};

export const TYPE_OF_PAYMENT = { ...BILL_PAYMENT_TYPE.WPending };

export const SALES_TYPE = {
  Sale: 'Sale',
  Delivery: 'Delivery',
};

export const SCHEDULE_WARNING_TYPE = ['h1', 'x', 'g'];
export const BILLING_TABLE_COLUMNS: STableColumnData[] = [
  { name: '#', mandatory: true },
  {
    name: 'Product',
    info: PRODUCT_SEARCH_INFO_MESSAGE,
    mandatory: true,
  },
  { name: 'Pack' },
  { name: 'Batch', mandatory: true },
  { name: 'Expiry', mandatory: true },
  { name: 'Qty', mandatory: true },
  { name: 'Disc' },
  { name: 'S.Price' },
  { name: 'U.MRP' },
  { name: 'Loose B.', mandatory: true },
  { name: 'T.Stock', align: 'center', hideDefault: true },
  { name: 'Stock', align: 'center' },
  { name: 'Rack', align: 'center', hideDefault: true },
  { name: 'Eff PTR', align: 'center', hideDefault: true },
  { name: 'Molecule', align: 'center', hideDefault: true },
  { name: 'Min T.', align: 'center', hideDefault: true },
  { name: 'Max T.', align: 'center', hideDefault: true },
  { name: 'MFR', align: 'center', hideDefault: true },
  { name: 'MRP', align: 'center' },
  { name: 'T.Disc', align: 'center' },
  { name: 'GST%', align: 'center' },
  { name: 'Total', align: 'center', mandatory: true },
  { name: '' },
];

export const PRODUCT_SEARCH_TABLE_CONST: { columns: ColumnType[]; rowMapping: RowMapType[] } = {
  columns: [
    { key: 'Product' },
    { key: 'MRP' },
    { key: 'Stock' },
    { key: 'U.Ratio' },
    { key: 'Rack' },
    { key: 'Pack' },
    { key: 'Manufacturer' },
  ],
  rowMapping: [
    { key: 'name', width: '200', align: 'left' },
    {
      key: 'mrp',
      width: '90',
      align: 'left',
      formatter: (value) => formattedRupee(value as number),
      className: styles.productMrp,
    },
    {
      key: 'stock',
      width: '80',
      align: 'left',
      formatter: (value, row) =>
        GlobalAppUtils.getUnitStock(value as string, (row as SearchProductType).unitRatio),
    },
    { key: 'unitRatio', align: 'left' },
    { key: 'rack', align: 'left' },
    {
      key: 'packaging',
      align: 'left',
      formatter: (value) => GlobalAppUtils.formatPackaging(value as string),
      width: '90',
    },
    {
      key: 'manufacturer',
      width: '150',
      align: 'left',
      formatter: (value) => truncate((value || '') as string),
    },
  ],
};

export const GET_HIDE_COLUMN_CONFIG = (): SHideColumnsState => {
  const activeSession = StoreUtils.getActiveSession();
  const columnConfig = activeSession.session?.config?.webColumnConfig?.billEntry;
  if (columnConfig) return columnConfig;

  return BILLING_TABLE_COLUMNS.reduce((acc, columnData: STableColumnData) => {
    if (columnData.name) {
      acc[columnData.name] = columnData.hideDefault ? false : true;
    }
    return acc;
  }, {} as SHideColumnsState);
};
