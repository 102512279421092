export const KeyShortCutConst = {
  global: [
    { title: 'Go to Bill Entry', keys: ['Alt', 'N'] },
    { title: 'Go to Online Order', keys: ['Alt', 'O'] },
    { title: 'Go to Purchase Entry', keys: ['Alt', 'P'] },
    { title: 'Go to Settings', keys: ['Alt', 'S'] },
    { title: 'Use global spotlight search', keys: ['Ctrl', 'Space'] },
  ],
  billing: [
    { title: 'Submit Bill', keys: ['F1'] },
    { title: 'Submit Bill and Print', keys: ['F2'] },
    { title: 'Reset Bill', keys: ['Alt', 'R'] },
    { title: 'Active QR Scan Mode', keys: ['Alt', 'Q'] },
    { title: 'Navigate from one Item line to another', keys: [['⬆', '⬇']] },
    { title: 'Navigate from one field to another', keys: [['Tab', 'Enter']] },
    { title: 'Add new/current item to Wantednote', keys: ['Alt', 'F10'] },
    { title: 'Add single item to Counter Sales', keys: ['Alt', 'D'] },
    { title: 'Add all item to Counter Sales', keys: ['Alt', 'E'] },
    { title: 'Delete item', keys: ['Ctrl', 'D'] },
    { title: 'View Item Inventory', keys: ['Ctrl', 'I'] },
  ],
  purchase: [
    { title: 'Submit Purchase', keys: ['F1'] },
    { title: 'Reset Purchase', keys: ['Alt', 'R'] },
  ],
};
