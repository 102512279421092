import { SCaseReducer } from './types';
import { SBatch, SBillEntry, SClient, SOverallTotal } from '../../types';
import { ObjectUtils, roundNumber, StoreUtils } from '../../../../utils/util';
import { GET_HIDE_COLUMN_CONFIG, INITIAL_STATE, SCHEDULE_WARNING_TYPE } from '../../constants';
import dayjs, { Dayjs } from 'dayjs';
import { BillingUtils } from '../../utils';
import { SearchProductType } from '../../../../components/Common/ProductSearchField/types';
import { SOverallDiscountProfile } from 'pages/Settings/types';

const loadRows: SCaseReducer<{ rows: SBillEntry[]; mode: 'new' | 'append' }> = (state, action) => {
  const payload = action.payload;
  let items = ObjectUtils.isListEmpty(payload.rows) ? [INITIAL_STATE.SBillEntry()] : payload.rows;
  // If last item has id, add last empty row
  if (items.at(-1)?.id) items = [...items, INITIAL_STATE.SBillEntry()];

  if (payload.mode === 'new') {
    // Replace Only Rows with new rows, all else will be reset to initial state except client.HideColumn
    return {
      ...INITIAL_STATE.SBillTableReducer(),
      client: {
        ...INITIAL_STATE.SBillTableReducer().client,
        hideColumns: GET_HIDE_COLUMN_CONFIG(),
      },
      rows: items,
    };
  } else if (payload.mode === 'append') {
    // Append the rows to end of the current rows
    state.rows = [
      ...state.rows.slice(0, -1), // Remove Last Empty Row
      ...items,
    ];
  }
};

const loadRow: SCaseReducer<{ row: SBillEntry; rowIndex?: number }> = (state, action) => {
  const payload = action.payload;
  let index = payload.rowIndex;
  if (index === undefined) {
    index = state.rows.length - 1;
  }

  state.rows[index] = payload.row;

  // If last item has id, add last empty row
  if (state.rows.at(-1)?.id) state.rows.push(INITIAL_STATE.SBillEntry());
};

const setFocusedIndex: SCaseReducer<number> = (state, action) => {
  state.client.focusedRowIndex = action.payload;
};

const setColumnFocusedIndex: SCaseReducer<number> = (state, action) => {
  state.client.focusedColumnIndex = action.payload;
};

const updateClient: SCaseReducer<Partial<SClient>> = (state, action) => {
  state.client = {
    ...state.client,
    ...action.payload,
  };
};

const setSearchProducts: SCaseReducer<Partial<SClient['searchProducts']>> = (state, action) => {
  state.client.searchProducts = {
    ...state.client.searchProducts,
    ...action.payload,
  };
};

const setSubmitData: SCaseReducer<Partial<SClient['submitData']>> = (state, action) => {
  state.client.submitData = {
    ...state.client.submitData,
    ...action.payload,
  };
};

const handleRowChange: SCaseReducer<{
  key: string;
  value: unknown;
  rowIndex: number;
  batchId?: string;
}> = (state, action) => {
  const payload = action.payload;
  let row = state.rows[payload.rowIndex];
  const overallDiscountPercent = +state.client.overallDiscountPercent;
  const loyaltyPercent = (+state.total.loyaltyRedeemed / +state.total.totalAmount) * 100;

  switch (payload.key) {
    case 'name': {
      row = {
        ...INITIAL_STATE.SBillEntry(),
        [payload.key]: payload.value as string,
      };

      break;
    }
    case 'expiry': {
      const value = payload.value as Dayjs | null;
      row[payload.key] = dayjs(value).unix();

      break;
    }
    case 'billQty':
    case 'discount':
    case 'price': {
      row[payload.key] = payload.value as number;

      const rowTotal = BillingUtils.calculateTotal(
        row.price,
        row.billQty,
        row.discount,
        overallDiscountPercent,
        row.gst,
        row.loyalty,
        loyaltyPercent,
      );
      if (['price', 'discount', 'billQty'].includes(payload.key))
        row.marginPercent = BillingUtils.calculateMarginPercent(
          row.effPtr,
          rowTotal.netItemAmount,
          row.billQty,
          row.looseEnabled,
          row.unitRatio,
        );
      row = {
        ...row,
        ...rowTotal,
      };
      break;
    }
    case 'sellingPrice': {
      row.discount = roundNumber(((+row.price - (payload.value as number)) / +row.price) * 100);

      const rowTotal = BillingUtils.calculateTotal(
        row.price,
        row.billQty,
        row.discount,
        overallDiscountPercent,
        row.gst,
        row.loyalty,
        loyaltyPercent,
      );

      row.marginPercent = BillingUtils.calculateMarginPercent(
        row.effPtr,
        rowTotal.netItemAmount,
        row.billQty,
        row.looseEnabled,
        row.unitRatio,
      );

      row = {
        ...row,
        ...rowTotal,
      };
      row.sellingPrice = payload.value as string;
      break;
    }
    case 'unitRatio':
    case 'looseEnabled': {
      row = {
        ...row,
        [payload.key]: payload.value as number | boolean,
      };
      row = _handleLooseBilling(row, overallDiscountPercent, loyaltyPercent);
      break;
    }
    case 'batchNo': {
      const selectedBatch = ([...row.batches, ...(row.unavailableBatches || [])] || []).find(
        (batch) => batch.id === (payload.batchId as string),
      );
      const { expiry, mrp, stock, id: batchId, loyalty } = selectedBatch || {};
      const newMrp = mrp || row.mrp;
      const newPrice = newMrp;
      const newDiscount = +(selectedBatch?.discount ?? row.discount ?? 0);
      const loyaltyPercent = (+state.total.loyaltyRedeemed / +state.total.totalAmount) * 100;

      row = {
        ...row,
        batchNo: (payload.value as string) || '',
        expiry: expiry || null,
        mrp: newMrp,
        price: newPrice,
        stock: stock || '',
        batchId: batchId || (payload.value as string) || '',
        effPtr: selectedBatch?.effPtr,
        discount: newDiscount,
        loyalty: loyalty,

        ...BillingUtils.calculateTotal(
          newPrice,
          row.billQty,
          newDiscount,
          overallDiscountPercent,
          row.gst,
          loyalty,
          loyaltyPercent,
        ),
      };

      row = _handleLooseBilling(row, overallDiscountPercent, loyaltyPercent);
      break;
    }
    case 'unavailableBatches': {
      row[payload.key] = payload.value as SBatch[];
      break;
    }
  }

  state.rows[payload.rowIndex] = row;
};

const addSearchedProductRow: SCaseReducer<{
  product: SearchProductType;
  rowIndex: number;
  prefill?: boolean;
  qr?: {
    mode: boolean;
    clearIndex: number;
  };
}> = (state, action) => {
  const payload = action.payload;
  let selectedBatchIdx = 0;
  const productData = payload.product;

  if (payload.qr?.mode) {
    // If QR mode select show batchNo based on batchId
    const batchIdx = productData.batches.findIndex((item) => item.id === productData.batchId);
    if (batchIdx > -1) {
      selectedBatchIdx = batchIdx;
    }
    // If QR scanned product merged to existing row, clear the qr data from original scanned index
    if (payload.qr.clearIndex && payload.qr.clearIndex !== payload.rowIndex) {
      state.rows[payload.qr.clearIndex] = INITIAL_STATE.SBillEntry();
    }
  }
  // Derived Bill Qty for different mode
  const billQty = payload.qr?.mode
    ? productData.looseEnabled && productData.unitRatio > 1
      ? +state.rows[payload.rowIndex].billQty + productData.unitRatio
      : +state.rows[payload.rowIndex].billQty + 1
    : payload?.prefill
    ? 1
    : '';
  const loyaltyPercent = (+state.total.loyaltyRedeemed / +state.total.totalAmount) * 100;

  state.rows[payload.rowIndex] = BillingUtils.convertSearchToProduct(
    productData,
    selectedBatchIdx,
    billQty,
    state.client.overallDiscountPercent,
    loyaltyPercent,
  );

  // If last item has id, add last empty row
  if (state.rows.at(-1)?.id) {
    state.rows = [...state.rows, INITIAL_STATE.SBillEntry()];
  }

  // Show Item Note
  if (StoreUtils.getKeyFromActiveSession(['config', 'showItemNote']) && productData.itemNote) {
    alert(productData.itemNote);
  }

  // If via QR Focus on the last row product field
  if (payload.qr?.mode) {
    state.client.focusedRowIndex = state.rows.length - 1;
  }

  // Reset Search Product
  state.client.searchProducts = {
    items: [],
    isFetching: false,
    isOpen: false,
    searchString: '',
  };
};

const addSplitBatch: SCaseReducer<void> = (state) => {
  const currentRowIndex = state.client.focusedRowIndex;
  const currentRow = { ...state.rows[currentRowIndex] };
  const unitRatio = currentRow.looseEnabled ? currentRow.unitRatio || 1 : 1;
  const currentStockQty = BillingUtils.getUnitStock(currentRow.stock, unitRatio);
  const currentQty = currentRow.billQty;

  // If current qty is less than stock no need of split
  if (+currentQty < currentStockQty) return;

  const remainingBatches = currentRow.batches.filter((batch) => batch.id !== currentRow.batchId);
  // If no batches remaining, don't split.
  if (remainingBatches.length === 0) return;

  const overallDiscountPercent = +state.client.overallDiscountPercent;
  const loyaltyPercent = (+state.total.loyaltyRedeemed / +state.total.totalAmount) * 100;
  const newRows: SBillEntry[] = [];
  currentRow['billQty'] = currentStockQty;
  // Update the current row with new qty
  newRows.push({
    ...currentRow,
    ...BillingUtils.calculateTotal(
      currentRow.price,
      currentRow.billQty,
      currentRow.discount,
      overallDiscountPercent,
      currentRow.gst,
      currentRow.loyalty,
      loyaltyPercent,
    ),
  });

  const remainingBatchesLength = remainingBatches.length;
  let index = 0;
  let balance = +currentQty - currentStockQty;
  // Based on remaining qty, try to split in available batch(s)
  while (balance) {
    const nextAvailableBatch = remainingBatches[index];
    const nextAvailableBatchStock = BillingUtils.getUnitStock(nextAvailableBatch.stock, unitRatio);

    const newQty =
      index === remainingBatchesLength - 1 || balance < nextAvailableBatchStock
        ? balance
        : nextAvailableBatchStock;
    currentRow['billQty'] = newQty;
    const row = _handleBatchChange(
      currentRow,
      remainingBatches[index].id,
      overallDiscountPercent,
      loyaltyPercent,
    );
    newRows.push({ ...row });
    balance -= newQty;
    index += 1;
  }

  state.rows = [
    ...state.rows.slice(0, currentRowIndex),
    ...newRows,
    ...state.rows.slice(currentRowIndex + 1),
  ];
};

const deleteProductRow: SCaseReducer<number> = (state, action) => {
  if (action.payload !== state.rows.length - 1) {
    state.rows = state.rows.filter((_, index) => index !== action.payload);
  }
};

const calculateOverallTotal: SCaseReducer<void> = (state) => {
  const rows = state.rows;
  const originalBillTotal = state.total.totalAmount;
  let totalAmount = 0;
  let netAmount = 0;
  let gst = 0;
  let totalEffPtr = 0;
  let isSchedule = false;
  let loyaltyEarned = 0;
  let roundOff = 0;
  let totalDiscountAmount = 0;
  let matchedIndex = -1;

  const totalDiscountSetting = StoreUtils.getKeyFromActiveSession(['config', 'overallAmountRange']);

  for (const row of rows) {
    totalAmount += row.itemAmount || 0;
    netAmount += row.netItemAmount || 0;
    loyaltyEarned += row.loyaltyAmount || 0;
    gst += row.gstAmount;
    totalDiscountAmount += row.discountAmount;
    if (typeof row.effPtr === 'number') {
      totalEffPtr += (row.looseEnabled ? row.effPtr / +row.unitRatio : row.effPtr) * +row.billQty;
    }
    // Check for schedule warn drugs
    SCHEDULE_WARNING_TYPE.includes((row.scheduleType || '').toLowerCase()) && (isSchedule = true);
  }

  if (totalDiscountSetting) {
    matchedIndex = totalDiscountSetting.findIndex(
      (item: SOverallDiscountProfile) =>
        totalAmount >= +item.amountStart && totalAmount < +item.amountEnd,
    );
    let messageSectionOpen = false;
    if (originalBillTotal !== totalAmount && matchedIndex > -1) {
      messageSectionOpen = true;
    }
    state.client = { ...state.client, messageSectionOpen, matchedIndex };
  }

  const totalMarginPercent = (1 - totalEffPtr / netAmount) * 100;
  const totalSaving = totalAmount - netAmount;

  // adding delivery charges
  netAmount += +state.client.deliveryCharge;

  // RoundOff Setting
  if (StoreUtils.getKeyFromActiveSession(['config', 'roundOff'])) {
    roundOff = roundNumber(netAmount, 0) - netAmount;
    netAmount = roundNumber(netAmount, 0);
  }

  state.total = {
    ...state.total,
    totalDiscountAmount,
    totalAmount,
    netAmount,
    gst,
    totalMarginPercent,
    totalSaving,
    roundOff,
    isSchedule,
    loyaltyEarned: Math.floor(loyaltyEarned),
  };
};

const updateTotal: SCaseReducer<Partial<SOverallTotal>> = (state, action) => {
  state.total = {
    ...state.total,
    ...action.payload,
  };
};

const _handleLooseBilling = (
  row: SBillEntry,
  overallDiscountPercent: number,
  loyaltyPercent: number,
) => {
  const looseEnabled = row.looseEnabled;
  const unitRatio = row.unitRatio || 1;
  const newPrice = roundNumber(looseEnabled ? row.mrp / unitRatio : row.mrp);

  const newRow = {
    ...row,
    price: newPrice,
    ...BillingUtils.calculateTotal(
      newPrice,
      row.billQty,
      row.discount,
      overallDiscountPercent,
      row.gst,
      row.loyalty,
      loyaltyPercent,
    ),
  };
  newRow['marginPercent'] = BillingUtils.calculateMarginPercent(
    newRow.effPtr,
    newRow.netItemAmount,
    newRow.billQty,
    newRow.looseEnabled,
    newRow.unitRatio,
  );
  return newRow;
};

const _handleBatchChange = (
  row: SBillEntry,
  batchId: string,
  overallDiscountPercent: number,
  loyaltyPercent: number,
) => {
  const selectedBatch = (row.batches || []).find((batch) => batch.id === (batchId as string));
  const { expiry, mrp, stock, batchNo, loyalty } = selectedBatch || {};
  const newMrp = mrp || row.mrp;
  const newPrice = newMrp;

  row = {
    ...row,
    batchNo: batchNo || '',
    expiry: expiry || null,
    mrp: newMrp,
    price: newPrice,
    stock: stock || '',
    batchId: batchId || batchNo || '',
    effPtr: selectedBatch?.effPtr,

    ...BillingUtils.calculateTotal(
      newPrice,
      row.billQty,
      row.discount,
      overallDiscountPercent,
      row.gst,
      loyalty,
      loyaltyPercent,
    ),
  };

  row = _handleLooseBilling(row, overallDiscountPercent, loyaltyPercent);
  return row;
};
const updateDiscountOnRows: SCaseReducer<number | string> = (state, action) => {
  const overallDiscount = action.payload;
  state.client.overallDiscountPercent = overallDiscount;

  const loyaltyPercent = (+state.total.loyaltyRedeemed / +state.total.totalAmount) * 100;
  state.rows = state.rows.map((row) => {
    const newRow = {
      ...row,
      ...BillingUtils.calculateTotal(
        row.price,
        row.billQty,
        row.discount,
        overallDiscount,
        row.gst,
        row.loyalty,
        loyaltyPercent,
      ),
    };

    newRow['marginPercent'] = BillingUtils.calculateMarginPercent(
      newRow.effPtr,
      newRow.netItemAmount,
      newRow.billQty,
      newRow.looseEnabled,
      newRow.unitRatio,
    );
    return newRow;
  });
};

const handleSlabWiseDiscountApply: SCaseReducer<number> = (state, action) => {
  const selectedDiscount = action.payload;
  const overallDiscount = state.client.overallDiscountPercent;
  const loyaltyPercent = (+state.total.loyaltyRedeemed / +state.total.totalAmount) * 100;

  state.rows = state.rows.map((row) => {
    if (!row.discountEnabled) {
      return row;
    }
    let newRow = { ...row, discount: selectedDiscount };
    newRow = {
      ...newRow,
      ...BillingUtils.calculateTotal(
        newRow.price,
        newRow.billQty,
        newRow.discount,
        overallDiscount,
        newRow.gst,
        newRow.loyalty,
        loyaltyPercent,
      ),
    };
    newRow['marginPercent'] = BillingUtils.calculateMarginPercent(
      newRow.effPtr,
      newRow.netItemAmount,
      newRow.billQty,
      newRow.looseEnabled,
      newRow.unitRatio,
    );
    return newRow;
  });
};

const updateLoyaltyOnRows: SCaseReducer<number> = (state, action) => {
  const totalAmount = state.total.totalAmount;
  const overallLoyalty = action.payload;
  const loyaltyPercent = (+overallLoyalty / totalAmount) * 100;

  state.total.loyaltyRedeemed = overallLoyalty;
  state.rows = state.rows.map((row) => ({
    ...row,
    ...BillingUtils.calculateTotal(
      row.price,
      row.billQty,
      +row.discount,
      state.client.overallDiscountPercent,
      row.gst,
      row.loyalty,
      loyaltyPercent,
    ),
  }));
};

const resetSlice: SCaseReducer<void> = () => INITIAL_STATE.SBillTableReducer();

export default {
  loadRows,
  loadRow,
  setFocusedIndex,
  updateClient,
  handleRowChange,
  setSearchProducts,
  setSubmitData,
  addSearchedProductRow,
  setColumnFocusedIndex,
  deleteProductRow,
  addSplitBatch,
  calculateOverallTotal,
  updateDiscountOnRows,
  updateLoyaltyOnRows,
  updateTotal,
  resetSlice,
  handleSlabWiseDiscountApply,
};
