import { Box } from '@mui/material';
import DialogBox from '../DialogBox';
import styles from './verificationModal.module.scss';
import OTPInput from 'pages/Login/components/OtpInput';
import { useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';

const VerificationDialog = ({
  open,
  onClose,
  handleSubmit,
}: {
  open: boolean;
  onClose: () => void;
  handleSubmit: (code: string) => Promise<void> | undefined;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { pageLoading } = useAppContext();
  const handleChangeOtp = async (value: string) => {
    if (value.length === 4 && !loading) {
      // restricting for 4 digits and if it is not already submitting
      pageLoading.show();
      setLoading(true);
      await (handleSubmit && handleSubmit(value));
      pageLoading.hide();
      setLoading(false);
    }
  };
  const handleClose = () => {
    if (loading) return; // letting close if it is not in loading state
    onClose();
  };
  return (
    <DialogBox open={open} onClose={handleClose}>
      <Box className={styles.container}>
        <div className={styles.otp}>
          <p className={styles.otpTitle}>Please Enter Pin</p>
          <OTPInput
            autoFocus
            isPin
            isNumberInput
            className={`${styles.otpContainer} ${styles.mrTop}`}
            inputClassName={styles.otpInput}
            length={4}
            onChangeOTP={handleChangeOtp}
          />
        </div>
      </Box>
    </DialogBox>
  );
};
export default VerificationDialog;
