import { useMemo } from 'react';
import { RHelpSection, RHelpSectionData } from 'services/api/endpoints/learnmoreSection';

const useAccordionFilter = (
  helpSectionData: RHelpSectionData | null,
  language: 'English' | 'Kannada' | 'Hindi' | 'Telugu',
  searchQuery: string,
) => {
  const filteredItems = useMemo(() => {
    if (!helpSectionData || !helpSectionData[language]) return [];
    return (
      helpSectionData[language]?.filter(
        (item: RHelpSection) =>
          item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.content.some(
            (contentItem) =>
              contentItem.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
              contentItem.text.toLowerCase().includes(searchQuery.toLowerCase()),
          ),
      ) || []
    );
  }, [helpSectionData, language, searchQuery]);

  return filteredItems;
};

export default useAccordionFilter;
