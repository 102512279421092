import React from 'react';
import { dateRangeType } from '../../components/PageDatePicker/types';
import { getURLParams, replaceURLParams } from '../../utils/util';
import dayjs, { Dayjs } from 'dayjs';
import { DateUtils } from '../../utils/date';

const useDateRangeState = (callback?: () => void, initialRange?: { from: Dayjs; to: Dayjs }) => {
  const currentDate = new Date();
  const initialDateRange = {
    startDate:
      initialRange?.from.toDate() || new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    endDate: initialRange?.to.toDate() || currentDate,
    key: 'selection',
  };
  const params = getURLParams();

  const [dateRange, setDateRange] = React.useState<dateRangeType[]>([
    {
      ...initialDateRange,
      startDate: params['from'] ? new Date(+params['from'] * 1000) : initialDateRange.startDate,
      endDate: params['to'] ? new Date(+params['to'] * 1000) : initialDateRange.endDate,
    },
  ]);

  return {
    get: dateRange,
    set: (updater: React.SetStateAction<dateRangeType[]>) => {
      setDateRange((prev) => {
        const next = typeof updater === 'function' ? updater(prev) : updater;
        replaceURLParams({
          from: dayjs(next[0].startDate).unix(),
          to: dayjs(next[0].endDate).unix(),
        });
        // Call callback function if it exists
        callback && callback();
        return next;
      });
    },
    title: DateUtils.getTimeRangeTitle(dateRange[0].startDate, dateRange[0].endDate),
  };
};

export default useDateRangeState;
