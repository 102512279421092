import React from 'react';
import { Modal } from '@mui/material';
import Icon from '../../../../../components/FormControl/Icon';
import styles from '../sidebar.module.scss';
import { VideoModalProps } from './types';

const VideoModal: React.FC<VideoModalProps> = ({ selectedVideo, closeVideoModal }) => (
  <Modal open={!!selectedVideo} onClose={closeVideoModal}>
    <div className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <div className={styles.closeModal} onClick={closeVideoModal}>
          <Icon icon={'ri-close-line'} size='1.3rem' />
        </div>
        {selectedVideo && (
          <iframe
            title='Video Player'
            width='100%'
            height='100%'
            src={selectedVideo}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )}
      </div>
    </div>
  </Modal>
);

export default VideoModal;
