import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '../../constants';
import reducers from './reducers';

const grnEntrySlice = createSlice({
  name: 'grn-entry',
  initialState: INITIAL_STATE.SGrnEntry(),
  reducers: reducers,
});

export const customerSliceActions = grnEntrySlice.actions;
export const {
  handleRowChange,
  addBatchToItem,
  deleteBatchFromItem,
  loadRows,
  updateClient,
  setPrintState,
  handleExpandToggle,
} = grnEntrySlice.actions;
export default grnEntrySlice.reducer;
