import { Tooltip } from '@mui/material';
import { TableIconButton } from '../../../components/Styled';
import styles from './header.module.scss';
import { getAllStoreNames, StoreUtils } from '../../../utils/util';
import { initMicrosoftClarity } from '../../../utils/misc';
import Icon from '../../../components/FormControl/Icon';
import React from 'react';
import useMenu from '../../../components/FormControl/Menu/useMenu';
import { useAppContext } from '../../../contexts/AppContext';
import { sessionStore } from '../../../services/browserStorage';

const ShopSection: React.FC<{ onIdxChange: (idx: number) => void }> = ({ onIdxChange }) => {
  const { pageLoading } = useAppContext();

  const [listOfCompanies, setListOfCompanies] = React.useState<string[]>([]);
  const {
    MenuComponent: ShopMenu,
    handleOpen: openShopMenu,
    handleClose: closeShopMenu,
    open: isShopMenuOpen,
  } = useMenu();
  const getActiveStoreName = () =>
    StoreUtils.getKeyFromActiveSession(['userData', 'company']) || '';

  React.useEffect(() => {
    setListOfCompanies(getAllStoreNames());
  }, []);

  const handleSessionChange = async (_: string, index: number) => {
    pageLoading.show();
    closeShopMenu();
    StoreUtils.changeActiveSession(index);
    // When Store Change Keep the variable track it and prevent auto-save to happen with old store
    // data and new store session.
    sessionStore.set('isStoreChange', true);
    onIdxChange(index);
    // setLogRocketUser();
    initMicrosoftClarity();
    pageLoading.hide();
  };

  return (
    <>
      <Tooltip title={'Change Shop'} placement='right'>
        <TableIconButton onClick={(e) => openShopMenu(e)} className={styles.shopButton}>
          <p className={styles.companyName}>{getActiveStoreName()}</p>
          <Icon icon={'ri-arrow-down-s-fill'} size={'1.2rem'} />
        </TableIconButton>
      </Tooltip>
      <ShopMenu
        menuOptions={listOfCompanies}
        menuTitle={'Select Store'}
        open={isShopMenuOpen}
        onClose={closeShopMenu}
        onItemSelect={handleSessionChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </>
  );
};

export default ShopSection;
