import { SCaseReducer } from './types';
import { SearchProductType } from '../../../../../components/Common/ProductSearchField/types';
import { BillEntryKeyMap, INITIAL_STATE } from '../../constants';
import { BillingUtils } from '../../utils';
import { ObjectUtils, roundNumber, StoreUtils } from '../../../../../utils/util';
import { SBillEntry, SClient } from '../../types';
import dayjs, { Dayjs } from 'dayjs';

const loadRows: SCaseReducer<{ rows: SBillEntry[] }> = (state, action) => {
  const payload = action.payload;

  let items = ObjectUtils.isListEmpty(payload.rows) ? [INITIAL_STATE.SBillEntry()] : payload.rows;

  // If last item has id, add last empty row
  if (items.at(-1)?.id) items = [...items, INITIAL_STATE.SBillEntry()];

  state.rows = [...items];
};
const setFocusedIndex: SCaseReducer<number> = (state, action) => {
  state.client.focusedRowIndex = action.payload;
};

const setSearchProducts: SCaseReducer<Partial<SClient['searchProducts']>> = (state, action) => {
  state.client.searchProducts = {
    ...state.client.searchProducts,
    ...action.payload,
  };
};

const addSearchedProductRow: SCaseReducer<{
  product: SearchProductType;
  rowIndex: number;
  prefill?: boolean;
  qr?: { mode: boolean; clearIndex: number };
}> = (state, action) => {
  const payload = action.payload;
  let selectedBatchIdx = 0;
  const productData = payload.product;
  if (payload.qr?.mode) {
    // If QR mode select show batchNo based on batchId
    const batchIdx = productData.batches.findIndex((item) => item.id === productData.batchId);
    if (batchIdx > -1) {
      selectedBatchIdx = batchIdx;
    }
    // If QR scanned product merged to existing row, clear the qr data from original scanned index
    if (payload.qr.clearIndex && payload.qr.clearIndex !== payload.rowIndex) {
      state.rows[payload.qr.clearIndex] = INITIAL_STATE.SBillEntry();
    }
  }
  // Derived Bill Qty for different mode
  const billQty = payload.qr?.mode
    ? +state.rows[payload.rowIndex].qty + 1
    : payload?.prefill
    ? 1
    : '';

  state.rows[payload.rowIndex] = BillingUtils.convertSearchToProduct(
    productData,
    selectedBatchIdx,
    billQty,
    state.client.overallDiscountPercent,
  );

  // If last item has id, add last empty row
  if (state.rows.at(-1)?.id) {
    state.rows = [...state.rows, INITIAL_STATE.SBillEntry()];
  }

  // If via QR Focus on the last row product field
  if (payload.qr?.mode) {
    state.client.focusedRowIndex = state.rows.length - 1;
  }

  // Reset Search Product
  state.client.searchProducts = {
    items: [],
    isFetching: false,
    isOpen: false,
  };
};

const deleteProductRow: SCaseReducer<number> = (state, action) => {
  if (action.payload !== state.rows.length - 1) {
    state.rows = state.rows.filter((_, index) => index !== action.payload);
  }
};

const setColumnFocusedIndex: SCaseReducer<number> = (state, action) => {
  state.client.focusedColumnIndex = action.payload;
};

const loadRow: SCaseReducer<{ row: SBillEntry; rowIndex?: number }> = (state, action) => {
  const payload = action.payload;
  let index = payload.rowIndex;
  if (index === undefined) {
    index = state.rows.length - 1;
  }

  state.rows[index] = payload.row;

  // If last item has id, add last empty row
  if (state.rows.at(-1)?.id) state.rows.push(INITIAL_STATE.SBillEntry());
};

const handleRowChange: SCaseReducer<{
  key: string;
  value: unknown;
  rowIndex: number;
  batchId?: string;
  lotDiscount?: number;
  lot?: string;
}> = (state, action) => {
  const payload = action.payload;
  let row = state.rows[payload.rowIndex];
  const overallDiscount = state.client.overallDiscountPercent;

  switch (payload.key) {
    case BillEntryKeyMap.name:
      row = {
        ...INITIAL_STATE.SBillEntry(),
        id: '',
        [payload.key]: payload.value as string,
      };
      break;
    case BillEntryKeyMap.marginPercent: {
      row = {
        ...INITIAL_STATE.SBillEntry(),
        [payload.key]: payload.value as number,
      };
      const freeQtyRs = (+row.freeQty * +row.effectivePtr) / +row.qty;

      const newBase = +row.effectivePtr / (1 - Number(payload.value) / 100) + freeQtyRs;

      const tempBase = roundNumber(newBase + +row.discountPrice / +row.qty);
      const discountPercent = ((+row.ptr - (tempBase || 0)) / row.ptr) * 100;
      row = {
        ...row,
        discountPercent: roundNumber(discountPercent),
        basePrice: roundNumber(newBase),
      };
      break;
    }
    case BillEntryKeyMap.qty:
    case BillEntryKeyMap.ptr:
    case BillEntryKeyMap.gst:
    case BillEntryKeyMap.freeQty:
    case BillEntryKeyMap.mrp:
    case BillEntryKeyMap.discount:
    case BillEntryKeyMap.discountPrice: {
      row = {
        ...row,
        [payload.key]: payload.value,
      };
      if (payload.key == BillEntryKeyMap.discount) {
        row = {
          ...row,
          lotDiscount: 0,
          lot: '',
        };
      }
      const total = BillingUtils.calculateTotal(row, overallDiscount);
      row = {
        ...row,
        ...total,
      };
      break;
    }
    case BillEntryKeyMap.batch: {
      const selectedBatch = (row.batches || []).find(
        (batch) => batch.id === (payload.batchId as string),
      );
      const {
        expiry,
        id: batchId,
        ptr,
        pts: stockPts,
        mrp,
        bbSellingPrice: stockBbSellingPrice,
      } = selectedBatch || {};

      // If new batch available, take the batch details else keep the previous details
      const itemPts = stockPts ?? row.pts ?? ptr ?? row.ptr ?? 0;
      const itemPtr = stockBbSellingPrice ?? row.bbSellingPrice ?? ptr ?? row.ptr ?? 0;
      row = {
        ...row,
        batchNo: (payload.value as string) || '',
        pts: roundNumber(itemPts),
        ptr: roundNumber(itemPtr),
        mrp: mrp ? mrp : row.mrp,
        effectivePtr: itemPtr,
        expiry: expiry || null,
        batchId: batchId || (payload.value as string) || '',
      };

      const total = BillingUtils.calculateTotal(row, overallDiscount);
      row = {
        ...row,
        ...total,
      };
      break;
    }
    case BillEntryKeyMap.lot: {
      const newDiscountPercent = roundNumber(
        +row.discountPercent - +row.lotDiscount + (payload.lotDiscount || 0),
      );
      const discountAmount =
        ((newDiscountPercent * row.ptr) / 100) * +row.qty + +row.discountPrice + row.lotDiscount;

      row = {
        ...row,
        lotDiscount: payload.lotDiscount || 0,
        discountPercent: newDiscountPercent,
        discountAmount: discountAmount,
        lot: payload.lot || '',
      };

      const total = BillingUtils.calculateTotal(row, overallDiscount);
      row = {
        ...row,
        ...total,
      };
      break;
    }
    case 'expiry': {
      const value = payload.value as Dayjs | null;
      row[payload.key] = dayjs(value).unix();
      break;
    }
    default: {
      row = {
        ...row,
        [payload.key]: payload.value,
      };
    }
  }
  state.rows[payload.rowIndex] = row;
};

const calculateOverallTotal: SCaseReducer<void> = (state) => {
  const billItems = state.rows;
  let netTotalAmount = 0;
  let discount = 0;
  let gst = 0;
  let totalValue = 0;
  let totalItems = 0;
  billItems.map((item) => {
    if (!item.id) return;

    totalItems += 1;
    netTotalAmount += item.netItemAmount || 0;
    discount += item.discountAmount || 0;
    gst += item.gstAmount || 0;
    totalValue += item.itemAmount || 0;
  });

  let roundOff = 0;
  if (StoreUtils.getKeyFromActiveSession(['config', 'roundOff'])) {
    roundOff = roundNumber(netTotalAmount, 0) - netTotalAmount;
    netTotalAmount = roundNumber(netTotalAmount, 0);
  }
  state.total = {
    totalItems: totalItems,
    totalGst: gst,
    netTotalAmount: netTotalAmount,
    totalDiscount: discount,
    roundOff: roundOff,
    totalValue: totalValue,
  };
};

const resetSlice: SCaseReducer<void> = () => INITIAL_STATE.SBillTableReducer();

const updateClient: SCaseReducer<Partial<SClient>> = (state, action) => {
  state.client = {
    ...state.client,
    ...action.payload,
  };
};

const setSubmitData: SCaseReducer<Partial<SClient['submitData']>> = (state, action) => {
  state.client.submitData = {
    ...state.client.submitData,
    ...action.payload,
  };
};

const updateDiscountOnRows: SCaseReducer<number | string> = (state, action) => {
  const overallDiscount = action.payload;
  state.client.overallDiscountPercent = overallDiscount;
  state.rows = state.rows.map((row) => {
    const newRow = {
      ...row,
      ...BillingUtils.calculateTotal(row, overallDiscount),
    };

    return newRow;
  });
};

export default {
  loadRow,
  loadRows,
  resetSlice,
  handleRowChange,
  setFocusedIndex,
  setSearchProducts,
  addSearchedProductRow,
  setColumnFocusedIndex,
  deleteProductRow,
  calculateOverallTotal,
  setSubmitData,
  updateClient,
  updateDiscountOnRows,
};
