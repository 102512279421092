import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '../../constants';
import reducers from './reducers';

const purchaseEntrySlice = createSlice({
  name: 'purchaseEntry',
  initialState: INITIAL_STATE.SPurchaseEntryReducer(),
  reducers: reducers,
});

export const purchaseSliceActions = purchaseEntrySlice.actions;

export const {
  setFocusedIndex,
  setColumnFocusedIndex,
  handleRowChange,
  updateCustomer,
  setSearchProducts,
  calculateOverallTotal,
  loadRow,
  setTableFocusedIndex,
  updateClient,
  deleteProductRow,
} = purchaseEntrySlice.actions;

export default purchaseEntrySlice.reducer;
