import { FilterLabelType } from './types';
import { FilterDateMap, createRange } from 'utils/date';

export const DEFAULT_RANGES: FilterLabelType[] = [
  'Today',
  'Yesterday',
  'Last 7 Days',
  'Last 30 Days',
  'Last 90 Days',
];

export const getStaticRanges = (staticRanges: FilterLabelType[]) =>
  createRange(
    staticRanges?.map(
      (
        item,
      ): {
        label: string;
        range: () => {
          startDate: Date;
          endDate: Date;
        };
      } => ({
        label: item,
        range: () => ({
          startDate: FilterDateMap[item].from.toDate(),
          endDate: FilterDateMap[item].to.toDate(),
        }),
      }),
    ),
  );
