import { SCaseReducer } from './types';
import { SCustomer, SCustomerClient } from '../../types';
import { ObjectUtils } from '../../../../utils/util';
import { INITIAL_STATE } from '../../constants';

const loadCustomer: SCaseReducer<Partial<SCustomer>> = (state, action) => ({
  ...INITIAL_STATE.SCustomerReducer(),
  client: {
    ...INITIAL_STATE.SCustomerReducer().client,
    isEditFirstLoad: state.client.isEditFirstLoad,
    isBillEdit: state.client.isBillEdit,
  },
  customer: ObjectUtils.mergeObjectsWithDefaults(action.payload, INITIAL_STATE.SCustomer()),
});

const resetSlice: SCaseReducer<void> = () => INITIAL_STATE.SCustomerReducer();

const updateCustomer: SCaseReducer<SCustomer> = (state, action) => {
  state.customer = action.payload;
};

const updateClient: SCaseReducer<Partial<SCustomerClient>> = (state, action) => {
  state.client = {
    ...state.client,
    ...action.payload,
  };
};

export default {
  loadCustomer,
  updateCustomer,
  resetSlice,
  updateClient,
};
