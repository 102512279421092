import React from 'react';
import { Props, SCustomer } from './types';
import { CustomerService } from '../../../../services/api';
import { validation } from '../../constants';
import { Box, Divider, Grid } from '@mui/material';
import TextField from '../../../../components/FormControl/TextField';
import styles from './addCustomer.module.scss';
import useFocus from '../../../../hooks/useFocus';
import { useAppContext } from '../../../../contexts/AppContext';
import { RCustomer, TCustomer } from '../../../../services/api/types/customer.types';
import { ApiError } from '../../../../services/api/base';
import SlideUpDialog from '../../../../components/Dialoges/SlideUpDialog';
import { initialCustomerState } from './constants';
import CustomerProfileSearch from '../CustomerProfileSearch';
import DoctorSearch from 'components/Common/DoctorSearch';
import { SDoctor } from 'components/Common/DoctorSearch/types';
import Select from 'components/FormControl/Select';
import { BILL_RETURN_PAYMENT_TYPE } from 'components/Common/PaymentFlow/SalePayment/constants';

const AddCustomer: React.FC<Props> = (props) => {
  const [formState, setFormState] = React.useState<SCustomer>({ ...initialCustomerState });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isFormValid, setIsFormValid] = React.useState<boolean>(true);

  const { inputRef: nameRef, setFocus: setNameFocus } = useFocus();
  const {
    snack: { show: showSnackbar },
  } = useAppContext();

  React.useEffect(() => {
    if (props.open) {
      if (props.id) {
        CustomerService.get(props.id).then((response) => {
          const responseData = response.data.data;
          setFormState({
            name: responseData.name,
            phone: responseData.phone,
            discount: responseData.discount,
            address: responseData?.address || '',
            city: responseData?.city || '',
            pincode: responseData?.pincode || '',
            profileId: responseData?.profileId || '',
            doctorId: responseData?.doctorId || '',
            doctorName: responseData?.doctorName || '',
            paymentMode: responseData?.paymentMode || '',
          });
        });
      } else if (props.data) {
        setFormState((prev) => ({ ...prev, ...props.data }));
      }
    }

    setNameFocus();
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
    setFormState({} as SCustomer);
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (validate()) {
        let customerRequestData: TCustomer = {
          name: formState.name,
          phone: formState.phone,
          discount: +(formState.discount || 0),
          doctorId: formState.doctorId,
          doctorName: formState.doctorName,
          paymentMode: formState.paymentMode,
          address: formState.address,
          city: formState.city,
          pincode: formState.pincode ? +formState.pincode : '',
          profileId: formState.profileId,
          profileName: formState.profileName,
        };

        if (props.id) {
          const response = await CustomerService.update(props.id, customerRequestData);
          customerRequestData = response.data.data.data;
          showSnackbar('Customer updated successfully', 'success');
        } else {
          const response = await CustomerService.create(customerRequestData);
          customerRequestData = response.data.data.data;
          showSnackbar('Customer added successfully', 'success');
        }
        handleClose();
        props.onSubmit(customerRequestData as RCustomer);
      }
    } catch (error) {
      console.log(error);
      showSnackbar((error as ApiError).userMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange = (key: string, newValue: unknown) => {
    setFormState({
      ...formState,
      [key]: newValue,
    });
  };

  const validate = () => {
    const isValid = validation('name', formState.name) && validation('phone', formState.phone);
    setIsFormValid(isValid);
    return isValid;
  };

  return (
    <SlideUpDialog
      open={props.open}
      onClose={handleClose}
      containerClass={styles.container}
      title={props.id ? 'Edit Customer' : 'Add Customer'}
      actionText={props.id ? 'Save' : 'Add'}
      actionType={'primary'}
      onAction={handleSubmit}
      loading={isLoading}
    >
      <Divider />

      <Box className={styles.form}>
        <form aria-label='customer-form' autoComplete='off'>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label='Name'
                fullWidth
                inputRef={nameRef}
                value={formState.name || ''}
                onChange={(event) => handleChange('name', event.target.value)}
                variant='standard'
                required
                error={!isFormValid && !validation('name', formState.name)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Contact'
                fullWidth
                value={formState.phone || ''}
                onChange={(event) => handleChange('phone', event.target.value)}
                inputType='mobile'
                variant='standard'
                required
                error={!isFormValid && !validation('phone', formState.phone)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Discount'
                fullWidth
                value={formState.discount || ''}
                onChange={(event) => handleChange('discount', event.target.value)}
                inputType='percentage'
                variant='standard'
              />
            </Grid>
            <Grid item xs={6}>
              <CustomerProfileSearch
                value={formState.profileId}
                onChange={(id, name) => {
                  setFormState({
                    ...formState,
                    profileId: id,
                    profileName: name,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <DoctorSearch
                variant={'standard'}
                selectedDoctorId={formState.doctorId}
                selectedDoctorName={formState.doctorName}
                onDoctorChange={(newDoctor: SDoctor) =>
                  setFormState({ ...formState, doctorId: newDoctor.id, doctorName: newDoctor.name })
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Select
                name={'paymentMode'}
                value={formState.paymentMode}
                onChange={(e) => handleChange(e.target.name, e.target.value as string)}
                items={Object.values(BILL_RETURN_PAYMENT_TYPE.WPending)}
                label='Payment Mode'
                variant='standard'
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                rows={2}
                variant='standard'
                fullWidth
                label='Address'
                value={formState.address || ''}
                onChange={(e) => {
                  handleChange('address', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant='standard'
                fullWidth
                label='City'
                value={formState.city || ''}
                onChange={(e) => {
                  handleChange('city', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant='standard'
                fullWidth
                label='Pincode'
                inputType='integer'
                inputProps={{ maxLength: 6 }}
                value={formState.pincode || ''}
                onChange={(e) => {
                  handleChange('pincode', e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </SlideUpDialog>
  );
};

export default AddCustomer;
