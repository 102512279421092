import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import styles from './planExpiry.module.scss';
import React from 'react';
import { StoreService } from '../../../services/api';
import Button from '../../FormControl/Button';
import { localStore } from '../../../services/browserStorage';
import Icon from '../../FormControl/Icon';

const PlanExpiry = () => {
  const [planStatus, setPlanStatus] = React.useState('');

  const getPlanStatus = async () => {
    const response = await StoreService.getPlanStatus();
    const responseData = response.data.data;
    localStore.set('planStatusMsg', responseData.msg);
    if (responseData.planStatus === 'expiring') {
      localStore.set('planEndDate', responseData.planEndDate);
    }
  };

  React.useEffect(() => {
    if (planStatus && planStatus !== 'active') getPlanStatus();
  }, [planStatus]);

  React.useEffect(() => {
    setPlanStatus(localStore.get('currentPlanStatus'));
  }, [localStore.get('currentPlanStatus')]);

  const handlePlanUpdate = async (query: 'okay' | 'contact') => {
    const response = await StoreService.updatePlanStatus(query);
    localStore.remove('currentPlanStatus');
    localStore.remove('planEndDate');
    const redirectUrl = response.data.data.link;
    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    } else {
      window.location.reload();
    }
  };

  if (!planStatus || planStatus === 'active') return null;
  return (
    <Dialog open={true} className={styles.dialog}>
      <DialogTitle>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Icon icon={'ri-information-line'} size={'1.2rem'} />
          Plan Update
        </Stack>
      </DialogTitle>
      <DialogContent>
        {planStatus === 'expired' ? (
          <p className={styles.expired}>{localStore.get('planStatusMsg')}</p>
        ) : (
          <p className={styles.expiring}>{localStore.get('planStatusMsg')}</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button text='Ok' onClick={() => handlePlanUpdate('okay')} />
        <Button text='Contact Us' variant='contained' onClick={() => handlePlanUpdate('contact')} />
      </DialogActions>
    </Dialog>
  );
};

export default PlanExpiry;
