// External Services
import LogRocket from 'logrocket';
import { requestForToken } from '../firebase';
import { patchRequest } from '../services/api/base';
import { StoreUtils } from './util';
import { clarity } from 'clarity-js';

export const clickToPrint = async (fileUrl: string) => {
  const newIframe = document.createElement('iframe');
  newIframe.width = '0';
  newIframe.height = '0';
  document.body.appendChild(newIframe);
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((myBlob: Blob) => {
      const objectURL = URL.createObjectURL(myBlob);
      newIframe.src = '';
      newIframe.src = objectURL;
      URL.revokeObjectURL(objectURL);
    })
    .then(() => {
      window.setTimeout(function () {
        newIframe?.contentWindow?.focus();
        newIframe?.contentWindow?.print();
      }, 200);
    });
};

export const htmlToPrint = async (fileUrl: string) => {
  const printWindow = window.open(fileUrl, '_blank', 'height=400,width=600');
  window.onfocus = () => {
    // Close the print window when focus is returned to the parent page
    printWindow?.close();
  };
};

export const clickToDownload = async (fileUrl: string) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const clickToDownloadXML = async (fileUrl: string) => {
  try {
    const response = await fetch(fileUrl);
    const responseBlob = await response.blob();
    // Create a temporary URL for the blob
    const blobUrl = window.URL.createObjectURL(new Blob([responseBlob]));

    // Create an anchor element
    const link = document.createElement('a');
    link.href = blobUrl;

    const fileName = fileUrl.split('/').pop();

    // Set the download attribute to specify the file name
    link.download = fileName || '1Pharmacy';

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up: revoke the temporary URL
    window.URL.revokeObjectURL(blobUrl);
  } catch (e) {
    console.log(e);
  }
};

export const downloadJsonFile = async (fileUrl: string, fileName?: string) => {
  try {
    const response = await fetch(fileUrl);
    const responseBlob = await response.blob();
    const blobUrl = window.URL.createObjectURL(new Blob([responseBlob]));

    const link = document.createElement('a');
    link.href = blobUrl;

    link.download = (fileName ? fileName : '1Pharmacy') + '.json';

    link.click();

    window.URL.revokeObjectURL(blobUrl);
  } catch (e) {
    console.log(e);
  }
};

// LogRocket
export const initLogRocket = () => {
  try {
    const logRocketId = process.env.REACT_APP_LOG_ROCKET_ID;
    if (process.env.REACT_APP_TYPE === 'prod' && logRocketId) {
      console.log('LogRocket initialized');
      LogRocket.init(logRocketId, {
        release: process.env.REACT_APP_VERSION,
      });
    }
  } catch (error) {
    console.error('LogRocket initialized error', error);
  }
};
export const setLogRocketUser = () => {
  try {
    const { session } = StoreUtils.getActiveSession();
    if (session && process.env.REACT_APP_TYPE === 'prod') {
      console.log('LogRocket User has been set');
      LogRocket.identify(session?.userData?.company, {
        email: session?.userData?.email,
        phone: session?.userData?.phone,
        cid: session?.cid,
        sessionToken: session?.['session-token'],
      });
    }
  } catch (error) {
    console.error('LogRocket User set error', error);
  }
};
export const initMicrosoftClarity = () => {
  try {
    const { session } = StoreUtils.getActiveSession();
    if (session && process.env.REACT_APP_TYPE === 'prod') {
      clarity.consent();
      clarity.set('cid', session?.cid);
      clarity.set('adminNo', session?.config?.adminNo);
      clarity.set('email', session?.userData?.email);
      clarity.set('phone', session?.userData?.phone);
      clarity.set('name', session?.userData?.name);
      clarity.set('session_token', session?.['session-token']);
      clarity.start({
        projectId: process.env.REACT_APP_CLARITY_PROJECT_ID,
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true,
      });
      console.log('Microsoft clarity setup completed');
    }
  } catch (error) {
    console.log('Microsoft clarity set error', error);
  }
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const sendLogRocketEvent = (eventName: string, data: any) => {
  if (process.env.REACT_APP_TYPE === 'prod') {
    LogRocket.track(eventName, data);
  }
};

// Firebase
export const sendFCMTokenToServer = async () => {
  try {
    const userId = StoreUtils.getKeyFromActiveSession(['session-token']);
    if (userId) {
      const token = await requestForToken();
      if (token) {
        await patchRequest(`user/${userId}`, { webFcmToken: token });
        sendLogRocketEvent('FCM token sent to server', { userId: userId as string, token });
        console.log('FCM token has been sent to server', token);
      }
    }
  } catch (error) {
    console.error('FCM token sent server error', error);
  }
};
