import React from 'react';
import { Props } from './types';
import OnePMenu from './index';

const useMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const MenuComponent: React.FC<Props> = (props) => (
    <OnePMenu {...props} anchorEl={anchorEl} onClose={handleClose} />
  );

  return {
    open: !!anchorEl,
    MenuComponent,
    handleClose,
    handleOpen,
  };
};

export default useMenu;
