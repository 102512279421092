import { useState, useEffect } from 'react';

const useSidebarState = () => {
  const [language, setLanguage] = useState<'English' | 'Kannada' | 'Hindi' | 'Telugu'>('English');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const handleChangeLanguage = (value: 'English' | 'Kannada' | 'Hindi' | 'Telugu') => {
    setLanguage(value);
    setSearchQuery('');
  };

  const handleSearchInputChange = (value: string) => {
    setSearchQuery(value);
  };

  const openVideoModal = (videoLink: string) => {
    const autoplayVideoLink = videoLink + '?autoplay=1';
    setSelectedVideo(autoplayVideoLink);
  };

  const closeVideoModal = () => {
    setSelectedVideo(null);
  };

  const resetToDefault = () => {
    setLanguage('English');
    setSearchQuery('');
  };

  useEffect(() => {
    resetToDefault();
  }, []);

  return {
    language,
    searchQuery,
    selectedVideo,
    handleChangeLanguage,
    handleSearchInputChange,
    openVideoModal,
    closeVideoModal,
  };
};

export default useSidebarState;
