import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import styles from './home.module.scss';
import Header from './Header';
import { SidebarLayoutProps } from './types';
import { StoreUtils } from '../../utils/util';
import { BackdropLoader, PdfLoader } from '../../components/Loader';
import { useAppContext } from '../../contexts/AppContext';
import SpotlightSearch from '../../components/Common/SpotlightSearch';
import useToggle from '../../hooks/useToggle';
import useKeyShortCut from '../../hooks/useKeyShortCut';

const HomeLayout: React.FC<SidebarLayoutProps> = () => {
  const [currentSessionIdx, setCurrentSessionIdx] = React.useState(
    StoreUtils.getActiveSession().idx,
  );
  const { pageLoading, pdfLoading } = useAppContext();
  const { value: spotlightSearch, toggle: toggleSpotlightSearch } = useToggle();

  useKeyShortCut([
    {
      key: 'ctrl+Space',
      handler: toggleSpotlightSearch,
    },
  ]);

  const handleSessionChange = (idx: number) => {
    setCurrentSessionIdx(idx);
  };

  return (
    <>
      <SpotlightSearch open={spotlightSearch} onClose={toggleSpotlightSearch} />
      <section className={styles.container}>
        <Sidebar />
        <main className={styles.mainWrapper}>
          <Header onIdxChange={handleSessionChange} />
          <BackdropLoader loading={pageLoading.get} />
          <PdfLoader loading={pdfLoading.get} />
          <Outlet key={currentSessionIdx} />
        </main>
      </section>
    </>
  );
};

export default HomeLayout;
