import { SBatchDetails } from './types';

export const GrnEntryUtils = {
  getItemRemainingQty: (poQty: number, batchDetails: SBatchDetails[], batchIndex: number) =>
    poQty -
    +batchDetails.reduce(
      (acc, item, index) => acc + (index === batchIndex ? 0 : +item.receivedQty),
      0,
    ),
  allowAddBatch: (poQty: number, batchDetails: SBatchDetails[]) =>
    poQty - +batchDetails.reduce((acc, item) => acc + +item.receivedQty, 0) > 0,
};
