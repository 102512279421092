import { ProductCell } from 'components/Table/MRTTable/CustomCell';
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table';
import { SReportItemType } from './types';
import { MRTCellType } from 'components/Table/MRTTable/types';

export const TABLE_MAPPING: { [key: string]: MRTColumnDef } = {
  Name: {
    accessorKey: 'name',
    header: 'Name',
    Cell: ({ cell }: { cell: unknown }) => {
      const data = cell as MRTCellType<SReportItemType>;
      const skuId = data.row.original.id;
      if (!skuId) return data.row.original.name;
      return <ProductCell value={data.getValue<string>()} href={`/inventory/${skuId}`} />;
    },
  },
  Packaging: {
    accessorKey: 'packaging',
    header: 'Packaging',
  },
  Rack: {
    accessorKey: 'rack',
    header: 'Rack',
  },
  Category: {
    accessorKey: 'category',
    header: 'Catgeory',
  },
  Manufacturer: {
    accessorKey: 'manufacturer',
    header: 'Manufacturer',
  },
  'Current Stock': {
    accessorKey: 'stock',
    header: 'Current Stock',
  },
  'B2C Sale Qty': {
    accessorKey: 'b2cSaleQty',
    header: 'B2C Sale Qty',
  },
  'B2B Sale Qty': {
    accessorKey: 'b2bSaleQty',
    header: 'B2B Sale Qty',
  },
  'Stk Transfer Qty': {
    accessorKey: 'stockTransferQty',
    header: 'StkTransfer Qty',
  },
  'Total Sale Qty': {
    accessorKey: 'totalSaleQty',
    header: 'Total Sale Qty',
  },
  'Purchase Qty': {
    accessorKey: 'purchaseQty',
    header: 'PurchaseQty',
  },
  'Purchase Margin %': {
    accessorKey: 'marginPercent',
    header: 'Purchase Margin %',
  },
  'Supplier Name': {
    accessorKey: 'supplierName',
    header: 'Supplier Name',
  },
};

export const REPORT_TYPE = {
  All: 'all',
  B2C: 'sales',
  B2B: 'b2b_sales',
  'Stock Transfer': 'stk_transfer',
};
