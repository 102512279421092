import React from 'react';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Icon from '../../FormControl/Icon';
import { SearchBarProps } from './types';
import styles from './searchBar.module.scss';

const SearchBar: React.FC<SearchBarProps> = (props) => (
  <FormControl className={styles.searchControl}>
    <OutlinedInput
      className={styles.searchBar}
      placeholder={'Search'}
      fullWidth={true}
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange(e.target.value)}
      startAdornment={
        <InputAdornment position='start'>
          <Icon icon={'ri-search-line'} size='1rem' />
        </InputAdornment>
      }
      autoFocus={props.autoFocus}
    />
  </FormControl>
);

export default SearchBar;
