import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { INITIAL_STATE } from '../../constants';

const customerSlice = createSlice({
  name: 'customer',
  initialState: INITIAL_STATE.SCustomerReducer(),
  reducers: reducers,
});

export const customerSliceActions = customerSlice.actions;
export const { resetSlice: resetCustomerSlice, updateClient } = customerSlice.actions;
export default customerSlice.reducer;
