import { Menu, MenuItem } from '@mui/material';
import { Props } from './types';

const OnePMenu = (props: Props) => {
  const { menuOptions, onItemSelect, menuTitle, ...rest } = props;

  return (
    <Menu {...rest}>
      {menuTitle && <MenuItem disabled>{menuTitle}</MenuItem>}
      {menuOptions.map((item, index: number) => (
        <MenuItem key={index} onClick={() => onItemSelect(item, index)}>
          {item}
        </MenuItem>
      ))}
    </Menu>
  );
};
export default OnePMenu;
