import React, { useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Toolbar,
  Typography,
  OutlinedInput,
  Divider,
  Tooltip,
} from '@mui/material';
import { CircularLoader } from 'components/Loader';
import { RHelpSection, RHelpSectionData } from 'services/api/endpoints/learnmoreSection';
import useSidebarState from './hooks/useSidebarState';
import useAccordionFilter from './hooks/useAccordionFilter';
import styles from './sidebar.module.scss';
import Icon from '../../../../components/FormControl/Icon';
import AccordionItem from './sidebarComponent/AccordionItem';
import VideoModal from './sidebarComponent/VideoModal';
import { SSidebarProps } from './types';
import useMenu from 'components/FormControl/Menu/useMenu';
import { useLocation } from 'react-router-dom';

const Sidebar: React.FC<SSidebarProps & { helpSectionData: RHelpSectionData | null }> = ({
  isOpen,
  onClose,
  helpSectionData,
  isLoading,
}) => {
  const {
    language,
    searchQuery,
    handleChangeLanguage,
    handleSearchInputChange,
    openVideoModal,
    closeVideoModal,
    selectedVideo,
  } = useSidebarState();

  const location = useLocation();

  useEffect(() => {
    handleChangeLanguage('English');
    handleSearchInputChange('');
  }, [location]);

  const filteredItems = useAccordionFilter(helpSectionData, language, searchQuery);
  const noMatchesFound = searchQuery.length > 0 && filteredItems.length === 0;

  const {
    MenuComponent: LanguageMenu,
    handleOpen: openLanguageMenu,
    handleClose: closeLanguageMenu,
    open: isLanguageMenuOpen,
  } = useMenu();

  const handleLanguageItemClick = (_: string) => {
    handleChangeLanguage(_ as 'English' | 'Kannada' | 'Hindi' | 'Telugu');
    closeLanguageMenu();
  };

  return (
    <Drawer anchor='right' open={isOpen} onClose={onClose}>
      <Toolbar className={styles.learnMore} variant={'dense'}>
        <Typography variant='h4' noWrap component='div' className={styles.learnmoreText}>
          Learn More
        </Typography>
        <Tooltip title={'Select Language'} placement='left'>
          <IconButton onClick={(e) => openLanguageMenu(e)}>
            <Icon icon={'ri-global-line'} size='1.3rem' className={styles.closeIcon} />
          </IconButton>
        </Tooltip>
        <IconButton onClick={onClose}>
          <Icon icon={'ri-close-line'} size='1.3rem' className={styles.closeIcon} />
        </IconButton>
      </Toolbar>
      <LanguageMenu
        menuOptions={['English', 'Kannada', 'Hindi', 'Telugu']}
        menuTitle={'Select Language'}
        onItemSelect={handleLanguageItemClick}
        open={isLanguageMenuOpen}
        onClose={closeLanguageMenu}
      />

      <Toolbar className={styles.searchBar}>
        <OutlinedInput
          className={styles.searchbarText}
          placeholder='Search here'
          value={searchQuery}
          size={'small'}
          onChange={(e) => handleSearchInputChange(e.target.value)}
          startAdornment={
            <Icon icon={'ri-search-line'} size='1.3rem' className={styles.searchIcon} />
          }
          endAdornment={
            <IconButton className={styles.clearButton} onClick={() => handleSearchInputChange('')}>
              <Icon icon={'ri-close-line'} size='1.4rem' className={styles.searchIcon} />
            </IconButton>
          }
        />
      </Toolbar>
      <List className={styles.sideBar}>
        <Divider />

        {isLoading ? (
          <div className={styles.loaderIcon}>
            <CircularLoader size={25} />
          </div>
        ) : noMatchesFound ? (
          <ListItem>
            <ListItemText primary={<span className={styles.noitemText}>No Result Found!</span>} />
          </ListItem>
        ) : filteredItems && filteredItems.length ? (
          filteredItems.map((item: RHelpSection, index: number) => (
            <AccordionItem key={index} item={item} openVideoModal={openVideoModal} />
          ))
        ) : (
          <ListItem>
            <ListItemText primary={<span className={styles.noitemText}>Coming Soon!!!</span>} />
          </ListItem>
        )}
      </List>
      <VideoModal selectedVideo={selectedVideo} closeVideoModal={closeVideoModal} />
    </Drawer>
  );
};

export default Sidebar;
