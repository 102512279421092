import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import Button from '../../FormControl/Button';
import styles from './update.module.scss';

const Update = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <Dialog open={open} className={styles.dialog}>
      <DialogTitle>Update Available</DialogTitle>
      <DialogContent>
        <Paper>
          <Typography variant={'subtitle1'}>
            A new version of the app is available. Please update to the latest version to continue
            using the app.
          </Typography>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          text='Update Now'
          onClick={() => {
            window.location.reload();
            setOpen(false);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default Update;
