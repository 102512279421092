import React from 'react';
import MaterialReactTable, { MRT_ColumnDef as MRTColumnDef } from 'material-react-table';
import { OnePTableProps } from './types';
import styles from './table.module.scss';
import { defaultTableStyles, hideActionStyles, initialPagination } from './constants';
import { StoreUtils } from '../../../utils/util';
import { PAGE_SIZE_OPTIONS } from 'utils/constants';

const OnePTable: React.FC<OnePTableProps> = (props) => {
  const marginAccess = StoreUtils.marginAccess.get();
  // Handle Column based on margin access
  let columns = props.columns;
  if (!marginAccess) {
    columns = columns.filter((column: MRTColumnDef) => column.accessorKey !== 'marginPercent');
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={props.rows ?? []}
      {...(props.enableMultiRowSelection !== undefined && {
        enableMultiRowSelection: props.enableMultiRowSelection,
      })}
      state={{
        isLoading: props.isLoading,
        globalFilter: props.searchString,
        ...(props.expandedRows && { expanded: props.expandedRows }),
        ...(props.rowSelection && { rowSelection: props.rowSelection }), // If multiple rows can be selected, handle state
        ...(props.pagination && { pagination: props.pagination.get }), // If pagination, handle state
      }}
      autoResetPageIndex={!props.pagination}
      autoResetExpanded={props.autoResetExpanded}
      {...(props.pagination && {
        onPaginationChange: props.handlePaginationChange
          ? props.handlePaginationChange
          : props.pagination.set,
      })} // If pagination, handle setState
      initialState={{
        showGlobalFilter: true,
        density: props.density || 'comfortable',
        ...(!props.pagination && {
          pagination: initialPagination(),
        }), // If not pagination, keep initial
        // Hide custom row added for global
        columnVisibility: { batchString: false, barcode: false },
      }}
      {...defaultTableStyles}
      muiTablePaginationProps={{
        className: styles.tablePagination,
        rowsPerPageOptions: props.handlePaginationChange ? [] : Object.values(PAGE_SIZE_OPTIONS),
        showLastButton: props.handlePaginationChange ? false : true,
        labelDisplayedRows: props.handlePaginationChange
          ? ({ from, to }) => `${from} - ${to} of ` + (props.totalDataSize || 'many')
          : undefined,
      }}
      renderDetailPanel={props.renderDetailPanel}
      enableColumnResizing={true}
      enableColumnActions={false}
      {...(props.onExpandedChange && { onExpandedChange: props.onExpandedChange })}
      {...(props.defaultColumnConfig && { defaultColumn: props.defaultColumnConfig })}
      enableTopToolbar={props.enableTopToolbar}
      enableRowSelection={props.enableRowSelection || false}
      enablePagination={!props.disablePagination}
      muiTableBodyCellProps={({ row, column }) => ({
        onClick: () => props.clickHandler && !props.isLoading && props.clickHandler(row, column),
        className: `${styles.tableCell} ${props.clickHandler ? styles.tableColClickable : ''}`,
      })}
      muiTableBodyRowProps={({ row }) => ({
        className: `${styles.tableRow} ${
          props.clickHandler ? styles.tableRowClickable : ''
        } tableRow-${row.index + 1}`,
        // Added Unique className in each row for automation testing
      })}
      displayColumnDefOptions={{
        'mrt-row-actions': props.renderRowActions ? {} : hideActionStyles,
        'mrt-row-select': {
          size: 30,
        },
        'mrt-row-expand': {
          size: 10,
        },
      }}
      muiLinearProgressProps={({ isTopToolbar }) => ({
        color: 'primary',
        variant: 'query',
        sx: {
          display: isTopToolbar ? 'block' : 'none', // hide bottom progress bar
          height: '2px',
        },
      })}
      enableRowActions={!!props.renderRowActions}
      positionActionsColumn={'last'}
      positionExpandColumn={props.positionExpandColumn}
      renderRowActions={props.renderRowActions}
      onRowSelectionChange={props.onRowSelectionChange}
      positionToolbarAlertBanner={'bottom'}
      getRowId={props.getRowId}
      globalFilterFn={'includesString'}
      enableRowNumbers={!!props.rowNumberMode}
      rowNumberMode={props.rowNumberMode}
    />
  );
};

export default OnePTable;
