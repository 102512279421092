import { PRODUCT_SEARCH_INFO_MESSAGE, RS_SYMBOL } from 'utils/constants';
import { SWantedItem, SWantedTableColumnData } from './types';
import { ColumnType, RowMapType } from 'components/Common/ProductSearchField/types';
import styles from '../../pages/BillEntry/features/BillingTable/table.module.scss';
import { roundNumber, StoreUtils } from 'utils/util';
import { ACCESS_CONTROL_KEYS_ENUM } from 'utils/accessControl';

export const supplierInitialState = { id: '', name: '', type: '' };

export const initialState: SWantedItem = {
  id: '',
  skuId: '',
  qty: 0,
  stock: '',
  name: '',
  previousSupplier: [],
  supplier: supplierInitialState,
  customer: [],
  mrp: 0,
  ptr: 0,
  gst: 0,
  scheme: '',
  schQty: 0,
  schFreeQty: 0,
  marginPercent: 0,
  discountPercent: 0,
};

export const WantedNoteUtils = {
  validate: {
    name: (id: string, name: string) => id !== '' && name !== '',
    qty: (qty: number | string) => +qty > 0,
  },
};

export const WANTED_NOTE_COLUMNS: SWantedTableColumnData[] = [
  { name: '#' },
  {
    name: 'Product',
    info: PRODUCT_SEARCH_INFO_MESSAGE,
  },
  { name: 'Supplier', bulkApply: true },
  { name: 'Qty' },
  { name: 'Disc%' },
  { name: 'Scheme', align: 'center' },
  { name: 'MRP' },
  { name: 'PTR' },
  { name: 'Margin%' },
  { name: 'Stock' },
  { name: 'Min T.' },
  { name: 'Max T.' },
  { name: 'Customer' },
];

export const PRODUCT_TABLE_CONST: { columns: ColumnType[]; rowMapping: RowMapType[] } = {
  columns: [
    { key: 'Product' },
    { key: 'MRP' },
    { key: 'GST(%)' },
    { key: 'Rack' },
    { key: 'Stock' },
    { key: 'U.Ratio' },
    { key: 'Pack' },
    { key: 'Manufacturer' },
  ],
  rowMapping: [
    { key: 'name', width: '200', align: 'left' },
    {
      key: 'mrp',
      width: '90',
      align: 'left',
      formatter: (value) => `${RS_SYMBOL} ${(value as number).toFixed(2)}`,
      className: styles.productMrp,
    },
    { key: 'gst', width: '40', align: 'left' },
    { key: 'rack', align: 'left' },
    { key: 'stock', align: 'left' },
    { key: 'unitRatio', align: 'left' },
    { key: 'packaging', align: 'left' },
    { key: 'manufacturer', width: '150', align: 'left' },
  ],
};

export const SUPPLIER_TYPE = {
  'Previous Supplier': 'Previous Supplier',
  'All Supplier': 'All Supplier',
  Branches: 'Branches',
};

export const STOCK_FILTER = {
  '0-5': { startStock: 0, endStock: 5 },
  '0-10': { startStock: 0, endStock: 10 },
  '0-15': { startStock: 0, endStock: 15 },
  '>15': { startStock: 15 },
  All: { startStock: 'all', endStock: 'all' },
};

export const LOAD_BULK_TYPE = {
  inventory: 'Inventory',
  sales: 'Sales',
};

export const ORDER_TYPE = (): { [key: string]: string } => {
  const currentSession = StoreUtils.getActiveSession().session;
  const isWholesaleEnable = currentSession?.config[ACCESS_CONTROL_KEYS_ENUM.WHOLESALE];
  const isStockTransferEnable = currentSession?.config[ACCESS_CONTROL_KEYS_ENUM.STOCK_TRANSFER];

  return {
    ...(isWholesaleEnable && { B2B: 'b2b' }),
    B2C: 'b2c',
    ...(isStockTransferEnable && { 'Stock Transfer': 'stk-transfer' }),
  };
};

export const OrderTypeInitialState = () =>
  Object.keys(ORDER_TYPE()).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as Record<string, boolean>);

export const ROWS_PER_PAGE_OPTIONS = [50, 75, 100];
export const DEFAULT_ROWS_PER_PAGE = 100;

export const calculateMarginPercent = (row: SWantedItem) => {
  const ptr = +(row.ptr || 0);
  const qty = +(row.schQty || 0);
  const freeQty = +(row.schFreeQty || 0);
  const gst = +(row.gst || 0);
  const discount = +(row.discountPercent || 0);

  const itemAmount = ptr * qty;
  const discountAmount = itemAmount * (discount / 100);
  const curItemAmount = itemAmount - discountAmount;
  const gstAmount = curItemAmount * (gst / 100);
  const netItemAmount = curItemAmount + gstAmount;
  const itemValue = +row.mrp * (qty + freeQty);
  const marginPercent = roundNumber(((itemValue - netItemAmount) / itemValue) * 100);
  return { marginPercent };
};
