/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RS_SYMBOL } from './constants';
import { localStore, sessionStore } from '../services/browserStorage';
import { Session } from './types';
import { UserUtils } from './auth';

export const roundNumber = (value: number | string | undefined, digits = 2): number =>
  +(+(value || 0)).toFixed(digits);

/**
 * Allow Input to keep 2 precision point and also allow to enter '.' in input field
 */
export const roundNumberPrecision = (value: any) =>
  value !== '' && !Number.isInteger(parseFloat(value)) ? roundNumber(value) : value;

export const formattedRupee = (value: number | string | undefined): string =>
  `${RS_SYMBOL} ${roundNumber(value).toLocaleString('en-IN', {
    currency: 'INR',
  })}`;

export const truncate = (value: string | undefined, limit = 15) =>
  (value?.length || 0) > limit ? value?.substring(0, limit) + '...' : value;

export const percentageNumber = (value: number | string | undefined): string =>
  `${roundNumber(value)}%`;

export const wait = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const capitalize = (str: string | undefined): string =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : '';

export const isDefined = <T>(value: T | undefined): value is T => typeof value !== 'undefined';

// Function to check if the input is alphabetic, numeric, or alphanumeric
export const checkInput = (
  type: 'alphabet' | 'numeric' | 'alphanumeric',
  value: string | number | undefined,
): boolean => {
  if (typeof value === 'undefined') return false;

  const stringValue = String(value);
  if (type === 'alphabet') {
    return /^[a-zA-Z]+$/.test(stringValue);
  } else if (type === 'numeric') {
    return /^[0-9]+$/.test(stringValue);
  } else if (type === 'alphanumeric') {
    return /^[a-zA-Z0-9]+$/.test(stringValue);
  }

  return false;
};

// Utility functions to check if a value is alphabetic, numeric, or alphanumeric
export const isAlphabet = (value: string | number | undefined): boolean =>
  checkInput('alphabet', value);

export const isNumeric = (value: string | number | undefined): boolean =>
  checkInput('numeric', value);

export const isAlphaNumeric = (value: string | number | undefined): boolean =>
  checkInput('alphanumeric', value);

// custom sort for Amount with rupees sign
export const customNumericSort = <T extends Record<string, any>>(a: T, b: T, key: string): number =>
  a.original[key] - b.original[key];

export const customTotalQtySort = <T extends Record<string, any>>(
  a: T,
  b: T,
  key: string,
): number => {
  const keyA = a.original[key].split('+');
  const keyB = b.original[key].split('+');
  const keyASum = +keyA[0] + +(keyA?.[1] || 0);
  const keyBSum = +keyB[0] + +(keyB?.[1] || 0);
  return keyASum - keyBSum;
};

export const totalQtySum = (qty: string): string => {
  const keyA = qty.split('+');
  const sum = keyA.reduce((acc: number, curr: string) => {
    const num = parseFloat(curr);
    if (Number.isFinite(num)) {
      return acc + num;
    }
    return acc;
  }, 0);
  return sum.toString();
};

export const playNotificationSound = (url: string | undefined): void => {
  if (url) {
    const audio = new Audio(url);
    audio.play();
  }
};

export const getVersionAsString = (): string => {
  const version = process.env.REACT_APP_VERSION as string;
  const versionArr = version.split('.');
  return versionArr.join('');
};

export const replaceURLParams = (
  params: Record<string, string | number | boolean | string[]>,
): void => {
  const url = new URL(window.location.href);
  Object.keys(params).forEach((key) => {
    url.searchParams.set(key, params[key].toString());
  });
  window.history.replaceState({}, '', url.toString());
};

export const removeURLParams = (): void => {
  const url = new URL(window.location.href);
  window.history.replaceState({}, '', url.toString().split('?')[0]);
};

export const getURLParams = (): Record<string, string | string[]> => {
  const url = new URL(window.location.href);
  const params: Record<string, string | string[]> = {};
  url.searchParams.forEach((value, key) => {
    if (value.includes(',')) params[key] = value.split(',');
    else params[key] = value;
  });
  return params;
};

export const getAllStoreNames = () => {
  const { sessions } = StoreUtils.getActiveSession();
  const listOfStores: string[] = [];
  if (sessions) {
    sessions.reduce((acc: string[], curr: Session) => {
      acc.push(curr.userData.company);
      return acc;
    }, listOfStores);
  }
  return listOfStores;
};

export const getAllStoreDetails = (): { id: string; name: string }[] => {
  const { sessions } = StoreUtils.getActiveSession();
  const listOfStores: { id: string; name: string }[] = [];
  if (sessions) {
    sessions.reduce((acc: { id: string; name: string }[], curr: Session) => {
      acc.push({ name: curr.userData.company, id: curr.cid });
      return acc;
    }, listOfStores);
  }
  return listOfStores;
};

/**
 * Objects utils
 */
export const ObjectUtils = {
  isObjSame: <T, E>(obj1: T, obj2: E): boolean => JSON.stringify(obj1) === JSON.stringify(obj2),
  /**
   * Merge two objects with default values
   * @param obj
   * @param defaultObj
   */
  mergeObjectsWithDefaults: <O extends object, D extends object>(obj: O, defaultObj: D): D =>
    Object.entries(defaultObj).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: key in obj ? obj[key as keyof O] : value }),
      {} as D,
    ),
  pick: <T extends object>(
    obj: T,
    keys: Array<{
      key?: keyof T;
      label?: string;
      convert?: (val: T[keyof T] | React.ReactElement) => any;
      value?: any;
    }>,
  ): Array<{ label: string; value: React.ReactNode }> => {
    const picked: Array<{ label: string; value: React.ReactNode }> = [];
    for (const { key, label, convert, value } of keys) {
      if (key && label) {
        if (key in obj) {
          if (convert) {
            picked.push({ label, value: convert(obj[key]) });
          } else {
            picked.push({ label, value: obj[key] as React.ReactNode });
          }
        }
      } else {
        picked.push({ label: '', value });
      }
    }
    return picked;
  },
  isListEmpty: <D extends object>(list: D[] | undefined): boolean => (list || []).length === 0,
  compareStringWith: (str1: string, str2: string[]): boolean =>
    str2.map((s) => s.toLowerCase()).includes((str1 || '').toLowerCase()),
};

/**
 * Utils to access the multi sessions store
 */
export const StoreUtils = {
  getActiveSession: () => {
    const sessions = localStore.get('sessions');
    const idx = sessionStore.get('activeSessionIdx') ?? localStore.get('lastActiveSessionIdx') ?? 0;
    if (sessions) {
      return {
        session: sessions[idx],
        sessions,
        idx,
      };
    }
    return {};
  },
  getKeyFromActiveSession: (keys: string[]) => {
    const { session } = StoreUtils.getActiveSession();
    if (session) {
      return keys.reduce((acc, key) => acc?.[key], session);
    }
  },
  updateActiveSession: (newSession: Session) => {
    const { idx, sessions } = StoreUtils.getActiveSession();
    if (sessions) {
      sessions[idx] = newSession;
      localStore.set('sessions', sessions);
    }
  },

  storeSession: (sessions: []) => {
    localStore.set('sessions', sessions);
    localStore.set('lastActiveSessionIdx', 0);
    sessionStore.set('activeSessionIdx', 0);
  },

  changeActiveSession: (idx: number) => {
    sessionStore.set('activeSessionIdx', idx);
    localStore.set('lastActiveSessionIdx', idx);
  },

  marginAccess: {
    get: () => {
      const { session } = StoreUtils.getActiveSession();
      return session?.userData?.isAdmin
        ? session?.config?.showMargin
        : session?.userData?.access?.staffMargin === false;
    },
  },

  isWMSStore: () => {
    const { session } = StoreUtils.getActiveSession();
    return session?.config.wms;
  },

  isWmsB2cStore: () => {
    const { session } = StoreUtils.getActiveSession();
    return session?.config.b2c_wms;
  },
};

export const StringOperationUtils = {
  getSearchText: (searchText: string) => searchText.replace(/\s/g, '').toLowerCase(),
};

export const ChainStoreUtils = {
  isMainAdmin: () =>
    UserUtils.isChainStore() &&
    UserUtils.isChainStoreMaster() &&
    (UserUtils.is1PAdmin() ||
      UserUtils.isStoreAdmin() ||
      UserUtils.isSuperAdmin() ||
      UserUtils.isInternalAdmin()),
  isStaffMainStore: () =>
    UserUtils.isChainStore() &&
    UserUtils.isChainStoreMaster() &&
    !(
      UserUtils.is1PAdmin() ||
      UserUtils.isStoreAdmin() ||
      UserUtils.isSuperAdmin() ||
      UserUtils.isInternalAdmin()
    ),
  hasMasterAuthority: () => UserUtils.isChainStore() && UserUtils.isMasterApprover(),
};

export const GlobalAppUtils = {
  formatPackaging: (pack: string | undefined) => {
    pack = String(pack || '');
    if (pack.includes('of')) return pack.split('of')[1].trim();
    return pack;
  },
  getUnitStock: (stock: string, unitRatio = 1) => {
    let qty = stock;
    let looseQty = '0';
    if (stock) {
      if (stock.includes(':')) {
        const splitStock = stock.split(':');
        qty = splitStock[0];
        looseQty = splitStock[1];
      }
      return unitRatio > 1 ? `${stock} (${+qty * +unitRatio + +looseQty})` : `${stock}`;
    }
    return '-';
  },
};

export const SearchStringUtils = {
  getSearchString: (searchText: string) => {
    // Split the search text by space
    const searchArr = searchText.split(' ');
    // Check if the length of the first word is less tha equal to 2, then return the first two words else return the first word
    return searchArr[0].length <= 2 ? searchArr.slice(0, 2).join(' ') : searchArr[0];
  },
};

export const NumericUtils = {
  /**
   * To send numeric value to server
   */
  getNumeric: (value: any, defaultValue: string | number = '') =>
    value === '' || value === null || isNaN(value) ? defaultValue : +value,
};

export const ImageUtils = {
  reDrawImage: (file: File): Promise<File> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const dataURItoBlob = (dataURI: string) => {
        const base64String = dataURI.split(',')[1];
        const byteCharacters = Array.from(atob(base64String));
        const byteNumbers = new Uint8Array(byteCharacters.map((char) => char.charCodeAt(0)));
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        return new Blob([byteNumbers], { type: mimeString });
      };

      let resizedFile: File;
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const canvasSize = 264; // Set the canvas size to match the circular clip size
          const elem = document.createElement('canvas');
          elem.width = canvasSize;
          elem.height = canvasSize;
          const ctx = elem.getContext('2d');
          // Set the fill color to white
          if (ctx) {
            ctx.fillStyle = 'white';
            // Fill the entire canvas with white
            ctx.fillRect(0, 0, canvasSize, canvasSize);
            // Create a circular path
            ctx.beginPath();
            ctx.arc(canvasSize / 2, canvasSize / 2, canvasSize / 2, 0, Math.PI * 2);
            ctx.closePath();
            // Clip the context to the circular path
            ctx.clip();
            // Draw the image inside the circular path
            ctx.drawImage(img, 0, 0, canvasSize, canvasSize);
            const data = ctx.canvas.toDataURL('image/jpeg'); // Specify the format here
            const blob = dataURItoBlob(data as string);
            resizedFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
            resolve(resizedFile);
          }
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };
    }),
};

export const enableCategoryMapButton = () =>
  StoreUtils.getKeyFromActiveSession(['config', 'enableOverallDiscount']) ||
  StoreUtils.getKeyFromActiveSession(['config', 'enableLoyaltyPoints']) ||
  StoreUtils.getKeyFromActiveSession(['config', 'wholesale']);
