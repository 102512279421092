import { getRequest } from '../base';
import { AxiosResponse } from 'axios';

export interface RHelpSection {
  question: string;
  videoLink: string[];
  content: {
    title: string;
    text: string;
  }[];
  imageLink: string;
}
export interface RHelpSectionData {
  [language: string]: RHelpSection[];
}

export const HelpServices = {
  fetchHelpSectionData: async (
    screenkey: string,
  ): Promise<AxiosResponse<{ data: { body: RHelpSectionData } }>> =>
    getRequest(`help_section_web?screen=${screenkey}`),
};
