import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '../../constants';
import reducers from './reducers';

const billingTableSlice = createSlice({
  name: 'wholesaleBillingTable',
  initialState: INITIAL_STATE.SBillTableReducer(),
  reducers: reducers,
});

export const billingSliceActions = billingTableSlice.actions;

export const {
  setFocusedIndex,
  setColumnFocusedIndex,
  handleRowChange,
  setSearchProducts,
  setSubmitData,
  calculateOverallTotal,
  resetSlice: resetBillingSlice,
  updateClient,
} = billingTableSlice.actions;

export default billingTableSlice.reducer;
