import React from 'react';
import { Backdrop, Box, Dialog, IconButton, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Props } from './types';
import styles from './slideUpDialog.module.scss';
import { CircularLoader } from '../../Loader';
import Button from '../../FormControl/Button';
import Icon from '../../FormControl/Icon';

const Transition: Record<
  string,
  React.ForwardRefExoticComponent<
    TransitionProps & { children: React.ReactElement } & React.RefAttributes<unknown>
  >
> = {
  up: React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction='up' ref={ref} {...props} />;
  }),
  left: React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction='left' ref={ref} {...props} />;
  }),
};
const SlideUpDialog: React.FC<Props> = (props) => {
  const { direction = 'up' } = props;
  return (
    <Dialog open={props.open} onClose={props.onClose} TransitionComponent={Transition[direction]}>
      <Box className={`${styles[`slide${direction}Container`]} ${props.containerClass}`}>
        {props.loading ? (
          <Backdrop
            open={props.loading}
            sx={{
              position: 'relative',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularLoader />
          </Backdrop>
        ) : null}
        <Stack
          className={styles.header}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box>
            <Typography variant='h4' className={styles.title}>
              {props.title}
            </Typography>
            {props.subtitle ? (
              <Typography variant='body2' className={styles.subTitle}>
                {props.subtitle}
              </Typography>
            ) : null}
          </Box>
          <Box>
            {props.actionText && props.onAction ? (
              <Button
                text={props.actionText}
                className={`${props.actionType && styles[props.actionType]}`}
                size='small'
                variant='text'
                onClick={props.onAction}
                enableLoading={props.actionLoading}
              />
            ) : null}
            <IconButton className={styles.closeAction} onClick={props.onClose}>
              <Icon icon={'ri-close-line'} size='1.3rem' />
            </IconButton>
          </Box>
        </Stack>
        {props.children}
      </Box>
    </Dialog>
  );
};

export default SlideUpDialog;
