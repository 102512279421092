import { PageActionButton, PageContent } from '../../../components/Styled';
import Icon from '../../../components/FormControl/Icon';
import Table from '../../../components/Table/MRTTable';
import PageWrapper from '../../Wrappers/PageWrapper';
import useFilter from './hooks/useFilter';
import useDateRangePicker from 'components/PageDatePicker/useDateRangePicker';
import ReportFilterWrapper from '../Components/ReportFilterWrapper';
import { Grid } from '@mui/material';
import Select from 'components/FormControl/Select';
import styles from '../StockReport/stockReport.module.scss';
import ReportView from '../StockReport/ReportView';
import useReport from './useReport';
import { REPORT_TYPE } from './constants';
import ReportMultiSelect from '../Components/MultiSelect';
import { REPORT_COLUMNS } from '../Schedule/constants';

const ItemWiseStockReport = () => {
  const { openHandler, closeHandler, DateRangePopOver } = useDateRangePicker();
  const { dateRange, pagination } = useFilter();
  const {
    reportData,
    selectedFilter,
    columnsFilter,
    groupByFilter,
    isDataLoading,
    dialogLoading,
    reportColumns,
    reportContent,
    viewModal,
    handleApplyFilter,
    setViewModal,
    rowActionRender,
    handleGenerateReport,
    handleFilterChange,
  } = useReport({ dateRange: dateRange, closeHandler });
  return (
    <PageWrapper>
      <>
        {/* Main Section */}
        <PageContent>
          <ReportFilterWrapper>
            <>
              <Grid container gap={2}>
                <Grid item>
                  <Select
                    items={Object.keys(REPORT_TYPE)}
                    label={'Report Type'}
                    value={selectedFilter.type || ''}
                    className={styles.customHeight}
                    variant='outlined'
                    onChange={(e) => handleFilterChange(e.target.value as string, 'type')}
                    minWidth={100}
                    maxWidth={200}
                  />
                </Grid>
                <Grid item>
                  <ReportMultiSelect
                    items={columnsFilter}
                    value={selectedFilter.columns || []}
                    handleFilterChange={handleFilterChange}
                    itemKey='columns'
                  />
                </Grid>
                <Grid item>
                  <Select
                    items={groupByFilter}
                    label={'Group By'}
                    value={selectedFilter.groupBy || ''}
                    className={styles.customHeight}
                    variant='outlined'
                    onChange={(e) => handleFilterChange(e.target.value as string, 'groupBy')}
                    minWidth={100}
                    maxWidth={200}
                  />
                </Grid>
                <Grid item>
                  <>
                    <PageActionButton
                      variant='outlined'
                      text={dateRange.title}
                      size={'small'}
                      startIcon={<Icon icon='ri-calendar-2-line' size='1.2rem' />}
                      onClick={openHandler}
                    />
                    <DateRangePopOver
                      disableFuture
                      dateRange={dateRange.get}
                      apply={handleApplyFilter}
                    />
                  </>
                </Grid>
              </Grid>
              <PageActionButton
                variant='contained'
                text={'Generate Report'}
                className={styles.btn}
                onClick={() => handleGenerateReport()}
              />
            </>
          </ReportFilterWrapper>
          <Table
            rows={reportData}
            columns={REPORT_COLUMNS}
            isLoading={isDataLoading}
            pagination={pagination}
            renderRowActions={({ row }) => rowActionRender(row)}
          />
        </PageContent>
        {viewModal ? (
          <ReportView
            columns={reportColumns}
            rows={reportContent}
            loading={dialogLoading}
            onClose={() => setViewModal(false)}
            open={viewModal}
          />
        ) : null}
      </>
    </PageWrapper>
  );
};
export default ItemWiseStockReport;
