import React from 'react';
import { wait } from '../utils/util';

const useFocus = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const setFocus = async () => {
    await wait(300);
    inputRef.current?.select();
  };

  return { inputRef, setFocus };
};

export default useFocus;
