import { FormControl, InputLabel, MenuItem, Select, Theme, useMediaQuery } from '@mui/material';
import styles from './select.module.scss';
import React from 'react';
import { Props } from './types';

const OnePSelect: React.FC<Props> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'xl'));

  const { label, value, items, minWidth, maxWidth, required, noItemsText, ...rest } = props;
  return (
    <FormControl
      variant={props.variant}
      sx={{ minWidth: minWidth, maxWidth: maxWidth }}
      className={styles.formControl}
      required={required}
    >
      <InputLabel id='1p-select-label'>{label}</InputLabel>
      <Select
        labelId='1p-select-label'
        id='1p-select-labelId'
        value={value}
        size={isSmall ? 'small' : 'medium'}
        label={label}
        autoWidth
        {...rest}
      >
        {items.length > 0 ? (
          items.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value=''>
            {noItemsText || 'No items'}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default OnePSelect;
