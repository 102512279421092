export const LOW_STOCK_REMINDER_CATEGORY = 'Low Stock';
export const NEAR_EXPIRY_CATEGORY = 'Near Expiry';

export const ALL = 'All';

export const FILTERS = {
  All: 'All',
  'Low Stock': 'Low Stock',
  'Near Expiry': 'Near Expiry',
  'Refill Reminder': 'Refill Reminder',
};
