import React from 'react';
import { onMessageListener } from '../../firebase';
import Update from '../Dialoges/Update';
import { NotificationPayload, notificationState } from './types';
import { playNotificationSound } from '../../utils/util';

// Handle All Firebase Notification(s)
const FireBaseNotify = () => {
  const [notification, setNotification] = React.useState<notificationState>(
    {} as notificationState,
  );

  onMessageListener().then((payload: unknown) => {
    const payloadData = payload as NotificationPayload;
    console.log('Received foreground Message ', payloadData);
    setNotification({
      type: payloadData.data?.type || '',
      notificationSoundUrl: payloadData.data?.notificationSoundUrl || '',
    });
  });

  switch (notification.type) {
    case 'update': {
      return <Update />;
    }
    case 'order': {
      const audioUrl = notification?.notificationSoundUrl;
      playNotificationSound(audioUrl);
      return null;
    }
    default:
      return null;
  }
};

export default FireBaseNotify;
