import { DEFAULT_GST, PRODUCT_SEARCH_INFO_MESSAGE, RS_SYMBOL } from '../../utils/constants';
import { DateConst } from '../../utils/date';
import { SReducerState as SPurchaseEntryReducer } from './reducers/purchaseEntry/types';
import { SCustomer, SPurchaseEntry } from './types';
import { PURCHASE_PAYMENT_TYPE } from '../../components/Common/PaymentFlow/PurchasePayment/constants';
import dayjs from 'dayjs';
import { formattedRupee, GlobalAppUtils, StoreUtils, truncate } from '../../utils/util';
import { ColumnType, RowMapType } from '../../components/Common/ProductSearchField/types';
import styles from '../BillEntry/features/BillingTable/table.module.scss';

export const INITIAL_STATE = {
  SPurchaseEntry: (): SPurchaseEntry => ({
    id: '',
    batchNo: '',
    packaging: '',
    discountPercent: '',
    discountPrice: '',
    expiry: null,
    freeQty: 0,
    lot: '',
    mrp: '',
    name: '',
    ptr: '',
    margin: 0,
    qty: '',
    rack: '',
    rackId: '',
    skuId: '',
    hsnCode: '',
    barcode: '',
    gst: DEFAULT_GST,
    itemAmount: 0,
    netItemAmount: 0,
    gstAmount: 0,
    discountAmount: 0,
    overallItemDiscount: 0,
    batches: undefined,
  }),
  SCustomer: (): SCustomer => ({
    invoiceNo: '',
    invoicedOn: DateConst.todayUnix(),
    supplierId: '',
    supplierName: '',
    paymentMode: TYPE_OF_PAYMENT.Pending,
    note: '',
    creditAmount: 0,
    extraCharges: 0,
    expectedAmount: 0,
    bankId: '',
    paySplit: undefined,
    grnStatus: undefined,
    grnCompleted: undefined,
    wmsGrnDiscrepancy: undefined,

    tcs: 0,
    discount: 0,
    discountPercent: 0,

    poId: '',
  }),
  SPurchaseEntryReducer: (): SPurchaseEntryReducer => ({
    rows: [INITIAL_STATE.SPurchaseEntry()],
    total: {
      overallItemDiscount: 0,
      netTotalAmount: 0,
      totalDiscount: 0,
      totalGst: 0,
      totalValue: 0,
      roundOff: 0,
    },
    client: {
      mapItems: [],
      mappingModalOpen: false,
      page: 0,
      dueAmount: 0,
      totalCreditNotes: 0,
      vouchers: 0,
      pendingBills: [],
      templateMapModal: false,
      focusedRowIndex: 0,
      focusedColumnIndex: 0,
      searchProducts: {
        items: [],
        isFetching: false,
        isOpen: false,
        searchString: '',
      },
      submitData: {
        data: {} as SPurchaseEntryReducer['client']['submitData']['data'],
        showSplitView: false,
        showSubmitModal: false,
        allowSubmit: true,
        allowValidationSubmit: true,
      },
      isSubmittedBillValid: true,
      inventoryViewSku: '',
      isUploadMode: false,
      isUploadCheckSku: false,
      lotModalOpen: false,
      racks: [],
    },
    customer: {
      bankId: '',
      invoiceNo: '',
      invoicedOn: DateConst.todayUnix(),
      supplierId: '',
      supplierName: '',
      paymentMode: TYPE_OF_PAYMENT.Pending,
      note: '',
      creditAmount: 0,
      extraCharges: 0,
      expectedAmount: 0,
      tcs: 0,
      discount: 0,
      discountPercent: 0,
      uploadType: '',
    },
  }),
};

export const TYPE_OF_PAYMENT = { ...PURCHASE_PAYMENT_TYPE.WPending };

// KeyMaps
export const PurchaseEntryKeyMap = {
  name: 'name',
  qty: 'qty',
  batch: 'batchNo',
  expiry: 'expiry',
  mrp: 'mrp',
  ptr: 'ptr',
  discount: 'discountPercent',
  discountPrice: 'discountPrice',
  freeQty: 'freeQty',
  gst: 'gst',
  rack: 'rack',
  hsnCode: 'hsnCode',
  barcode: 'barcode',
  batches: 'batches',
};

export const PURCHASE_TABLE_COLUMN: { name: string; info?: string }[] = [
  { name: '#' },
  {
    name: 'Product',
    info: PRODUCT_SEARCH_INFO_MESSAGE,
  },
  { name: 'Pack' },
  { name: StoreUtils.isWMSStore() ? 'FKH' : 'Product Id' },
  { name: 'Barcode' },
  { name: 'Quantity' },
  { name: 'Free Qty' },
  { name: 'Batch No' },
  { name: 'Expiry Date' },
  { name: 'MRP' },
  { name: 'PTR' },
  { name: 'Margin%' },
  { name: 'Rack' },
  { name: 'GST%' },
  { name: 'HSN' },
  { name: 'LOT' },
  { name: 'Disc%' },
  { name: `Disc${RS_SYMBOL}` },
  { name: 'Total' },
  { name: '' },
];

export const PRODUCT_SEARCH_TABLE_CONST: { columns: ColumnType[]; rowMapping: RowMapType[] } = {
  columns: [
    { key: 'Product' },
    { key: 'MRP' },
    { key: 'GST(%)' },
    { key: 'Stock' },
    { key: 'Rack' },
    { key: 'Pack' },
    { key: 'U.Ratio' },
    { key: 'Manufacturer' },
  ],
  rowMapping: [
    { key: 'name', width: '200', align: 'left' },
    {
      key: 'mrp',
      width: '90',
      align: 'left',
      formatter: (value) => formattedRupee(value as number),
      className: styles.productMrp,
    },
    { key: 'gst', width: '40', align: 'left' },
    { key: 'stock', align: 'left' },
    { key: 'rack', align: 'left' },
    {
      key: 'packaging',
      align: 'left',
      width: '110',
      formatter: (value) => GlobalAppUtils.formatPackaging(value as string),
    },
    { key: 'unitRatio', align: 'left' },
    {
      key: 'manufacturer',
      width: '150',
      align: 'left',
      formatter: (value) => truncate((value || '') as string),
    },
  ],
};

export const PurchaseUtils = {
  validate: {
    name: (productId: string, productName: string, checkId: boolean, uploadMode: boolean) =>
      uploadMode && !checkId ? productName !== '' : productName !== '' && productId !== '',
    qty: (productQty: number | string, productFreeQty: number | string) =>
      +productQty + +productFreeQty > 0,
    batch: (productBatchNo: string) => productBatchNo !== '',
    mrp: (productMRP: number | string) => +productMRP > 0,
    ptr: (productPTR: number | string) => +productPTR > 0,
    date: (value: number | null) =>
      value !== null && !Number.isNaN(value) && dayjs.unix(value).isValid(),
    hsnCode: (value: number | null | string) =>
      StoreUtils.getKeyFromActiveSession(['config', 'hsnMandate']) ? !!value : true,
  },
};

export const BRANCH_PO = 'branch-po';

export const allowAddOrDeleteItem = (orderType: string | undefined) => orderType !== BRANCH_PO;

export const ADD_NEW_SUPPLIER = 'Add New Supplier';

export const INITIAL_STATE_LOT_DATA = {
  lot: '',
  lotFree: 0,
  lotQty: 0,
  schemePercent: 0,
  schemeAmount: 0,
};
