import React from 'react';
import { getURLParams, replaceURLParams } from '../../../../utils/util';
import usePagination from '../../../../hooks/filterstate/usePagination';
import useDateRangeState from '../../../../hooks/filterstate/useDateRangeState';

const useFilter = () => {
  const params = getURLParams();

  const dateRangeState = useDateRangeState(() => paginationState.reset());

  const [searchQuery, setSearchQuery] = React.useState<string>(
    (params['searchQuery'] as string) || '',
  );
  const searchQueryState = {
    get: searchQuery,
    set: (value: string) => {
      setSearchQuery(value);
      replaceURLParams({ searchQuery: value });
      paginationState.reset();
    },
  };

  const paginationState = usePagination();

  return {
    dateRange: dateRangeState,
    searchQuery: searchQueryState,
    pagination: paginationState,
  };
};

export default useFilter;
