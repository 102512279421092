import { SearchProductType } from 'components/Common/ProductSearchField/types';
import React from 'react';

const useTableNavigation = (
  ref: React.RefObject<HTMLDivElement>,
  data: SearchProductType[],
  length: number,
  handleEnterEvent: (index: number) => void,
  handleRightClickEvent?: (index: number) => void,
) => {
  const [navigationIndex, setNavigationIndex] = React.useState(0);

  React.useEffect(() => {
    ref && ref.current && ref.current.scrollBy(0, -1 * ref.current.scrollHeight);
    window.addEventListener('keydown', handleKey);
    return () => window.removeEventListener('keydown', handleKey);
  }, [navigationIndex, data[0]]); // added data[0] because of stale closure issue in product selection

  const handleKey = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        if (navigationIndex < length - 1) {
          setNavigationIndex((prev) => prev + 1);
          // ref && ref.current && ref.current.scrollBy(0, 40);
          ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        } else if (navigationIndex === length - 1) {
          setNavigationIndex(0);
          // ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
          ref && ref.current && ref.current.scrollBy(0, -1 * ref.current.scrollHeight);
        }
        break;
      case 'ArrowUp':
        if (navigationIndex > 0) {
          setNavigationIndex((prev) => prev - 1);
          // ref && ref.current && ref.current.scrollBy(0, -40);
          ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        } else if (navigationIndex === 0) {
          setNavigationIndex(length - 1);
          // ref && ref.current && ref.current.scrollBy(0, ref.current.scrollHeight);
          ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
        break;
      case 'ArrowRight':
        if (!handleRightClickEvent) return;
        handleRightClickEvent(navigationIndex);
        break;
      case 'Enter':
        handleEnterEvent(navigationIndex);
        setNavigationIndex(-1);
        break;
      case 'Escape':
        setNavigationIndex(-1);
        break;
    }
  };

  return { navigationIndex, setNavigationIndex };
};

export default useTableNavigation;
