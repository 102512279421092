import { PaymentModeChip } from 'components/Common/PaymentFlow/PaymentModeChip';
import { EntryAtCell, ProductCell } from 'components/Table/MRTTable/CustomCell';
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table';
import { SPastReportType } from '../types';
import { MRTCellType } from 'components/Table/MRTTable/types';
import { SReportItemType } from './types';

export const MAPPING: { [key: string]: MRTColumnDef } = {
  'Bill No.': {
    accessorKey: 'billedNo',
    header: 'Bill No.',
  },
  'Contact No': {
    accessorKey: 'contactNo',
    header: 'Contact No',
  },
  'Billed On': {
    accessorKey: 'billedOn',
    header: 'Billed On',
  },
  Name: {
    accessorKey: 'name',
    header: 'Name',
  },
  'Item Name': {
    accessorKey: 'itemName',
    header: 'Item Name',
    Cell: ({ cell }: { cell: unknown }) => {
      const data = cell as MRTCellType<SReportItemType>;
      const skuId = data.row.original.id;
      if (!skuId) return data.row.original.name;
      return <ProductCell value={data.getValue<string>()} href={`/inventory/${skuId}`} />;
    },
  },
  Molecule: {
    accessorKey: 'molecule',
    header: 'Molecule',
  },
  'Batch No.': {
    accessorKey: 'batchNo',
    header: 'Batch No',
  },
  Doctor: {
    accessorKey: 'doctor',
    header: 'Doctor',
  },
  Expiry: {
    accessorKey: 'expiry',
    header: 'Expiry',
  },
  Qty: {
    accessorKey: 'qty',
    header: 'Qty',
  },
};

export const TYPES = ['H1', 'H', 'X', 'G'];

export const REPORT_COLUMNS = [
  {
    accessorKey: 'index',
    header: '#',
    maxSize: 10,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'createdOn',
    header: 'Created On',
    accessorFn: (row: SPastReportType) => <EntryAtCell at={row.createdOnStr} />,
  },
  {
    accessorKey: 'createdBy',
    header: 'Created By',
  },
  {
    accessorKey: 'startEpoch',
    header: 'Start Date',
  },
  {
    accessorKey: 'endEpoch',
    header: 'End Date',
  },
  {
    accessorKey: 'reportType',
    header: 'Report Type',
  },
  {
    accessorKey: 'downloadStatus',
    header: 'Download Status',
    accessorFn: (row: SPastReportType) =>
      row.downloadStatus ? <PaymentModeChip paymentMode={row.downloadStatus} /> : '',
  },
  {
    accessorKey: 'viewStatus',
    header: 'View Status',
    accessorFn: (row: SPastReportType) =>
      row.viewStatus ? <PaymentModeChip paymentMode={row.viewStatus} /> : '',
  },
];
