import React from 'react';
import { useAppContext } from 'contexts/AppContext';
import { useDebouncedCallback } from 'use-debounce';
import styles from './itemModal.module.scss';
import { ApiError } from 'services/api/base';
import { SearchProductType } from 'components/Common/ProductSearchField/types';
import SlideUpDialog from 'components/Dialoges/SlideUpDialog';
import { Box, Divider, Grid } from '@mui/material';
import TextField from '../../../../components/FormControl/TextField';
import { validation } from './constants';
import { SEARCH_CHAR_LIMIT } from 'utils/constants';
import ProductSearchField from 'components/Common/ProductSearchField';
import { PRODUCT_TABLE_CONST, initialState } from 'pages/WantedNoteNew/constant';
import { Props } from './types';
import { SWantedItem } from 'pages/WantedNoteNew/types';
import { WantedNoteServiceNew } from 'services/api';
import SupplierSearch from '../SupplierSearch';
import useFetchSupplierAndCustomer from 'pages/WantedNoteNew/useFetchSupplierAndCustomer';
import CustomerSearch from '../CustomerSearch';
import { TWantedData } from 'services/api/types/wantedNoteNew.types';
import { Customer } from '../CustomerSearch/types';

const ItemModal = (props: Props) => {
  const [itemState, setItemState] = React.useState<SWantedItem>(initialState);
  const [searchedProducts, setSearchedProducts] = React.useState<SearchProductType[]>([]);
  const [isSupplierOpen, setIsSupplierOpen] = React.useState(false);
  const [isCustomerOpen, setIsCustomerOpen] = React.useState(false);
  const { supplierList, branchList, isSupplierLoading } = useFetchSupplierAndCustomer({
    isSupplierOpen,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const {
    snack: { show: showSnackbar },
    dialog: { confirm },
  } = useAppContext();

  /**
   * for searching suggestion items
   */
  const debouncedSearch = useDebouncedCallback(async (value: string) => {
    try {
      if (!value) return;
      const response = await WantedNoteServiceNew.search(value);
      const items: SearchProductType[] = response.data?.data?.items || [];
      setSearchedProducts(items);
    } catch (error) {
      showSnackbar((error as ApiError).userMessage, 'error');
      console.log(error);
    }
  }, 600);

  const handleItemChange = (value: string, key: string) => {
    if (key === 'name' && value.length >= SEARCH_CHAR_LIMIT) debouncedSearch(value.trim());
    setItemState((prev) => ({ ...prev, [key]: value }));
  };

  const handleClose = () => {
    props.onClose();
    setIsValid(true);
  };

  /**
   * for getting prefill qty
   */
  const getPrefillQty = (
    maxThreshold: number | undefined,
    threshold: number | undefined,
    stock: string,
  ) =>
    stock
      ? maxThreshold
        ? ((maxThreshold - Number(stock.split(':')[0])) as number)
        : threshold
        ? threshold
        : 1
      : 1;

  /**
   * handling selected product from item suggestion table
   */
  const handleClickProduct = async (product: SearchProductType) => {
    productCtx.close();

    try {
      const response = await WantedNoteServiceNew.getProduct(product.id);
      const productData = response.data?.data || [];
      const prefillQty = getPrefillQty(
        productData.maxThreshold,
        productData.threshold,
        productData.stock || '0',
      );
      setItemState((prev) => ({
        ...prev,
        skuId: product.id,
        name: product.name,
        qty: prefillQty,
        stock: productData.stock || '0',
        threshold: productData.threshold || '-',
        maxThreshold: productData.maxThreshold || '-',
        previousSupplier: productData.previousSupplier || [],
        supplier: productData.previousSupplier[0],
        customer: itemState.customer || [],
      }));
    } catch (error) {
      showSnackbar((error as ApiError).userMessage, 'error');
      console.log(error);
    }
  };

  /**
   * handler for saving item to wanted note
   */
  const handleSave = async () => {
    if (validate()) {
      try {
        setIsLoading(true);
        const dataToSubmit: TWantedData = {
          items: [
            {
              skuId: itemState.skuId,
              name: itemState.name,
              qty: +itemState.qty,
              supplierId: itemState.supplier.id || '',
              supplierName: itemState.supplier.name || '',
              customers: itemState.customer.reduce((acc, customer) => {
                acc[customer.id] = customer.name;
                return acc;
              }, {} as { [id: string]: string }),
            },
          ],
        };
        const response = await WantedNoteServiceNew.submit(dataToSubmit);
        const resData = response.data.data;
        const shouldPopupOpen = resData.popup || false;
        if (shouldPopupOpen) {
          const choice = await confirm({
            title: resData.title,
            description: resData.msg,
            okText: 'Yes',
            cancelText: 'No',
            backDropClose: false,
          });

          if (choice) {
            dataToSubmit.popupAnswer = true;
            await WantedNoteServiceNew.submit(dataToSubmit);
            showSnackbar('Item Added Successfully', 'success');
          } else {
            dataToSubmit.popupAnswer = false;
            await WantedNoteServiceNew.submit(dataToSubmit);
          }
        } else {
          showSnackbar('Item Added Successfully', 'success');
        }
        handleClose();
      } catch (error) {
        showSnackbar((error as ApiError).userMessage, 'error');
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      showSnackbar('Please provide proper details', 'error');
    }
  };

  const handleChange = (key: keyof typeof itemState, value: unknown) => {
    const clonedData = { ...itemState };

    const newValue = value as string;
    const updatedData = {
      ...clonedData,
      [key as keyof typeof clonedData]: newValue,
    };
    setItemState(updatedData);
  };

  /**
   * for Item suggestion table
   */
  const [productInputAnchorEl, setProductInputAnchorEl] = React.useState<HTMLInputElement | null>(
    null,
  );
  const productCtx = {
    anchorEl: productInputAnchorEl,
    open: (element: HTMLInputElement | HTMLTextAreaElement | null) =>
      setProductInputAnchorEl(element as HTMLInputElement),
    close: () => setProductInputAnchorEl(null),
  };

  /**
   * for getting item for edit from wantedNodeId
   */
  const getItem = async (skuId: string) => {
    try {
      const response = await WantedNoteServiceNew.getProduct(skuId);
      const data = response.data?.data;

      const newCustomerArray = itemState.customer.slice();
      if (props.itemData?.customer?.name) {
        newCustomerArray.push(props.itemData.customer);
      }
      const newItemState: SWantedItem = {
        ...itemState,
        name: data.name,
        skuId: data.id || '-',
        qty: getPrefillQty(data.maxThreshold, data.threshold, data.stock) || '-',
        threshold: data.threshold || '-',
        maxThreshold: data.maxThreshold || '-',
        stock: data.stock || '-',
        previousSupplier: data.previousSupplier || [],
        supplier: data.previousSupplier[0],
        customer: newCustomerArray as Customer[],
      };
      setItemState(newItemState);
    } catch (err) {
      console.log(err);
      showSnackbar((err as ApiError).userMessage, 'error');
    }
  };

  const validate = () => {
    const itemValidation = validation('name', itemState.name) && validation('qty', itemState.qty);
    setIsValid(itemValidation);
    return itemValidation;
  };

  React.useEffect(() => {
    if (!props.open) return;
    // resetting states
    setItemState(initialState);
    if (props.type === 'add' && props.itemData?.skuId) {
      getItem(props.itemData?.skuId as string);
    } else if (props.type === 'addPrefill' && props.itemData) {
      setItemState({
        ...initialState,
        qty: 0,
        name: props.itemData.name,
        skuId: props.itemData.skuId,
      });
    }
  }, [props.open]);

  return (
    <SlideUpDialog
      open={props.open}
      onClose={handleClose}
      containerClass={styles.container}
      title={'Add Item to Wanted Note'}
      actionText={'Add'}
      actionType='primary'
      onAction={handleSave}
      loading={isLoading}
    >
      <Divider />
      <form aria-label='item-modal' autoComplete='off'>
        <Box className={styles.form}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className={styles.name}
                name='name'
                label='Item Name'
                value={itemState.name}
                variant='standard'
                required
                onChange={(e) => {
                  handleItemChange(e.target.value, e.target.name);
                  productCtx.open(e.currentTarget);
                }}
                error={!isValid && !validation('name', itemState.name)}
              />
              {itemState.name.length >= SEARCH_CHAR_LIMIT ? (
                <ProductSearchField
                  ctx={productCtx}
                  data={searchedProducts}
                  columns={PRODUCT_TABLE_CONST.columns}
                  rowMapping={PRODUCT_TABLE_CONST.rowMapping}
                  rowClick={handleClickProduct}
                  rowIndex={0}
                  isLoading={!searchedProducts.length}
                />
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={styles.qty}
                name='qty'
                label='Qty.'
                value={itemState.qty}
                variant='standard'
                inputType='integer'
                required
                onChange={(e) => handleItemChange(e.target.value, e.target.name)}
                error={!isValid && !validation('qty', itemState.qty)}
              />
            </Grid>

            <Grid item xs={6}>
              <SupplierSearch
                allSupplier={supplierList}
                branchList={branchList}
                prevSupplier={itemState.previousSupplier}
                supplier={itemState.supplier}
                variant={'standard'}
                handleChange={(newValue) => handleChange('supplier', newValue)}
                open={isSupplierOpen}
                onOpen={() => setIsSupplierOpen(true)}
                onClose={() => setIsSupplierOpen(false)}
                isSupplierLoading={isSupplierLoading}
              />
            </Grid>
          </Grid>
          <Box className={styles.customerSearch}>
            <CustomerSearch
              selectedCustomer={itemState.customer}
              variant={'standard'}
              handleChange={(newValue) => handleChange('customer', newValue)}
              open={isCustomerOpen}
              onOpen={() => setIsCustomerOpen(true)}
              onClose={() => setIsCustomerOpen(false)}
            />
          </Box>
        </Box>
      </form>
    </SlideUpDialog>
  );
};
export default ItemModal;
