import { SSupplier } from 'components/Common/SupplierSearch/types';
import React from 'react';
import { SupplierService, WantedNoteServiceNew } from 'services/api';
import { Props, SBranchItem } from './types';
import { RBranchItem } from 'services/api/types/wantedNoteNew.types';

const useFetchSupplierAndCustomer = ({ isSupplierOpen }: Props) => {
  const [supplierList, setSupplierList] = React.useState<SSupplier[]>([]);
  const [branchList, setBranchList] = React.useState<SBranchItem[]>([]);
  const [isSupplierLoading, setIsSupplierLoading] = React.useState<boolean>(false);

  const fetchSupplierData = async () => {
    try {
      setIsSupplierLoading(true);
      const supplierResponse = await SupplierService.list('');
      const branchResponse = await WantedNoteServiceNew.getBranchList();
      const supplierData = supplierResponse.data?.data?.items || [];
      const branchData = branchResponse.data?.data?.branch || [];

      if (supplierData.length > 0) {
        setSupplierList(
          supplierData.map((item) => ({ name: item.name, id: item.id, email: item.email })),
        );
      }

      if (branchData.length > 0) {
        setBranchList(
          branchData.map((branch: RBranchItem) => ({
            id: branch.id,
            name: branch.name,
            type: branch.type,
          })),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSupplierLoading(false);
    }
  };

  React.useEffect(() => {
    if (isSupplierOpen && supplierList.length === 0 && branchList.length === 0) {
      fetchSupplierData();
    }
  }, [isSupplierOpen]);

  return {
    supplierList,
    branchList,
    isSupplierLoading,
    setSupplierList,
    setBranchList,
  };
};

export default useFetchSupplierAndCustomer;
