import React, { useContext } from 'react';
import { SidebarContext } from 'contexts/SidebarContext';
import { styled, Divider, Theme, CSSObject, Stack, Typography, Box } from '@mui/material';

import styles from './sidebar.module.scss';
import MuiDrawer from '@mui/material/Drawer';
import logo1p from '../../../static/icons/1p-icon.svg';
import logo from '../../../static/LOGO.svg';
import SidebarMenu from './SidebarMenu';
import { handleLogout } from '../../../utils/auth';
import { StoreUtils, truncate } from '../../../utils/util';
import ShiftEnd from '../../../pages/ShiftManagement/components/ShiftEnd';
import ShiftStart from 'pages/ShiftManagement/components/ShiftStart/ShiftStart';

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.sidebar.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (props) => props !== 'open',
})(({ theme, open }) => ({
  width: theme.sidebar.width,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const { isSidebarOpen, openSidebar, closeSidebar } = useContext(SidebarContext);
  const companyLogo = StoreUtils.getKeyFromActiveSession(['userData', 'logo']);

  const {
    session: {
      userData: { name, phone },
    },
  } = StoreUtils.getActiveSession();

  return (
    <section className={styles.sideBarWrapper}>
      <Drawer
        variant='permanent'
        open={isSidebarOpen}
        className={styles.sideBarDrawer}
        onMouseEnter={openSidebar}
        onMouseLeave={closeSidebar}
      >
        <div className={styles.sideBarHeaderWrapper}>
          {!isSidebarOpen ? (
            <img src={companyLogo || logo1p} alt='1p-logo' className={styles.miniLogo} />
          ) : (
            <img src={companyLogo || logo} alt='pharmacy-logo' className={styles.logo} />
          )}
        </div>
        <Divider className={`${styles.divider} ${isSidebarOpen && styles.dividerPadding}`} />
        {isSidebarOpen ? (
          <Stack
            direction='row'
            justifyContent='center'
            flexWrap={'wrap'}
            divider={<Divider orientation='vertical' flexItem color='#fff' />}
            gap={1}
            className={styles.loginInfo}
          >
            <Typography>{truncate(name as string)}</Typography>
            <Typography>{phone}</Typography>
          </Stack>
        ) : (
          <Box className={styles.emptyBox} />
        )}

        <SidebarMenu />
        <Divider className={`${styles.divider} ${isSidebarOpen && styles.dividerPadding}`} />

        <div className={styles.logoutWrapper}>
          <ShiftStart />
          <ShiftEnd isSidebarOpen={isSidebarOpen} />
          <div onClick={() => handleLogout()} className={styles.logoutContainer}>
            <i className={`ri-logout-box-line ${styles.icon}`} />{' '}
            {isSidebarOpen && <span>Log out</span>}
          </div>
        </div>
      </Drawer>
    </section>
  );
};
export default Sidebar;
