import React from 'react';
import { Button, CircularProgress, Stack, Tooltip } from '@mui/material';
import { OnePButtonProps } from './types';
import Icon from '../Icon';
import { UserAccessControlData } from '../../../utils/accessControl';

const OnePButton: React.FC<OnePButtonProps> = React.forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState(false);
  const { accessKey, text, onClick, enableLoading = false, ...rest } = props;
  const accessData = UserAccessControlData.get(accessKey);
  const handleClickEvent = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!onClick || loading) return;

    try {
      setLoading(true);
      await onClick(e);
    } finally {
      // Handle Fast Server Response
      const timeout = setTimeout(() => {
        setLoading(false);
        clearTimeout(timeout);
      }, 400);
    }
  };

  return (
    <>
      {accessData ? (
        <Tooltip title={"You don't have access"} placement='top'>
          <Stack sx={rest?.sx} className={rest?.className}>
            <Button {...rest} disabled startIcon={<Icon icon='ri-git-repository-private-fill' />}>
              {text}
            </Button>
          </Stack>
        </Tooltip>
      ) : (
        <Button onClick={handleClickEvent} {...rest} ref={ref} disabled={loading || props.disabled}>
          {loading && enableLoading ? <CircularProgress size={20} color='inherit' /> : <>{text}</>}
        </Button>
      )}
    </>
  );
});

OnePButton.displayName = 'OnePButton';

export default OnePButton;
