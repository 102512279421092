import { SCurrencyInventoryType } from './types';

export const getInitialState = (): SCurrencyInventoryType => ({
  cash: {
    '500': 0,
    '200': 0,
    '100': 0,
    '50': 0,
    '20': 0,
    '10': 0,
    '5': 0,
    '2': 0,
    '1': 0,
  },
  total: 0,
  card: 0,
  upi: 0,
  logout: false,
});
