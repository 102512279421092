import React, { PropsWithChildren } from 'react';
import { Alert, AlertColor, Slide, SlideProps, Snackbar } from '@mui/material';
import {
  AppContextType,
  ConfirmData,
  DialogContextState,
  SnackContextState,
  WantedNoteDataType,
  WantedNoteItemType,
} from './types';
import InfoDialog from '../components/Dialoges/InfoDialog';
import WantedNoteItemComponent from '../pages/WantedNoteNew/components/ItemModal/index';
import VerificationDialog from 'components/Dialoges/VerificationDialog';
import { ApiError } from 'services/api/base';

const AppContext = React.createContext<AppContextType>({} as AppContextType);

const TransitionRight = (props: SlideProps) => <Slide {...props} direction='left' />;

let snackAutoHideTimer = 5000; // Default 5 seconds

export const AppProvider: React.FC<PropsWithChildren> = (props) => {
  // SnackBar
  const [snackBarState, setSnackBarState] = React.useState<SnackContextState>(
    {} as SnackContextState,
  );

  const show = React.useCallback(
    (msg: string | React.ReactNode, type: AlertColor, hideEarly?: boolean) => {
      setSnackBarState({ isOpen: true, type, message: msg });
      if (hideEarly) snackAutoHideTimer = 2000; // 2 seconds
      else snackAutoHideTimer = 5000; // 5 seconds
    },
    [],
  );

  const hide = React.useCallback(() => {
    setSnackBarState((prev) => ({ ...prev, isOpen: false }));
  }, []);

  // Dialog
  const [dialogState, setDialogState] = React.useState<DialogContextState>(
    {} as DialogContextState,
  );
  const fn = React.useRef<(choice: boolean) => void>();

  const confirm = React.useCallback(
    (data: ConfirmData) =>
      new Promise((resolve) => {
        setDialogState({ ...data, open: true, ack: data.ack });

        fn.current = (choice: boolean) => {
          resolve(choice);
          setDialogState((prev) => ({ ...prev, open: false }));
        };
      }),
    [],
  );

  // passcode verification
  const [passCodeModalOpen, setPassCodeModalOpen] = React.useState<boolean>(false);
  const passCodeFn = React.useRef<(code: string) => Promise<void>>();
  const passCodeOnCloseFn = React.useRef<() => void>();

  const passCodeVerification = React.useCallback(
    (fn: (code: string) => Promise<void>, onClose?: () => void) =>
      new Promise((resolve, reject) => {
        setPassCodeModalOpen(true); // opening modal
        passCodeOnCloseFn.current = onClose;
        passCodeFn.current = async (code: string) => {
          try {
            await fn(code);
            resolve(code);
            setPassCodeModalOpen(false);
          } catch (e) {
            reject();
            console.log(e);
            show((e as ApiError).userMessage, 'error');
            if ((e as ApiError).userMessage !== 'Invalid PIN') {
              // if passcode is not wrong and data is wrong then we close modal
              setPassCodeModalOpen(false);
              passCodeOnCloseFn.current && passCodeOnCloseFn.current();
            }
          }
        };
      }),
    [],
  );

  // Global App Loader
  const [loading, setLoading] = React.useState(false);
  const loadingState = {
    get: loading,
    show: () => setLoading(true),
    hide: () => setLoading(false),
  };

  // PDF (file upload) Loader
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const pdfLoadingState = {
    get: pdfLoading,
    show: () => setPdfLoading(true),
    hide: () => setPdfLoading(false),
  };

  // wanted Note
  const [wantedNote, setWantedNote] = React.useState<WantedNoteDataType>({} as WantedNoteDataType);
  const wantedNoteState = {
    set: (data: WantedNoteDataType) => {
      setWantedNote({
        wantedNoteItem: data.wantedNoteItem || ({} as WantedNoteItemType),
        type: data.type,
        open: data.open,
        callBack: data.callBack,
      });
    },
  };

  const [isNewAppNotification, setIsNewAppNotification] = React.useState(false);
  const notificationState = {
    get: isNewAppNotification,
    show: () => {
      setIsNewAppNotification(true);
    },
    hide: () => {
      setIsNewAppNotification(false);
    },
  };

  return (
    <AppContext.Provider
      value={{
        snack: { show },
        dialog: { confirm, passCodeVerification },
        pageLoading: loadingState,
        pdfLoading: pdfLoadingState,
        wantedNoteDialog: wantedNoteState,
        appNotification: notificationState,
      }}
    >
      {props.children}
      {snackBarState.isOpen && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackBarState.isOpen}
          TransitionComponent={TransitionRight}
          autoHideDuration={snackAutoHideTimer}
          onClose={hide}
          ClickAwayListenerProps={{ onClickAway: () => null }}
        >
          <Alert onClose={hide} severity={snackBarState.type} className={'alert'} variant='filled'>
            {snackBarState.message}
          </Alert>
        </Snackbar>
      )}
      {dialogState.open && (
        <InfoDialog
          {...dialogState}
          handleOk={() => fn.current && fn.current(true)}
          handleClose={() => fn.current && fn.current(false)}
        />
      )}
      {passCodeModalOpen ? (
        <VerificationDialog
          open={passCodeModalOpen}
          onClose={() => {
            setPassCodeModalOpen(false);
            passCodeOnCloseFn.current && passCodeOnCloseFn.current();
          }}
          handleSubmit={(code) => passCodeFn.current && passCodeFn.current(code)}
        />
      ) : null}
      {wantedNote.open && (
        <WantedNoteItemComponent
          type={wantedNote.type}
          open={wantedNote.open}
          onClose={() => {
            setWantedNote((prev) => ({
              ...prev,
              open: false,
            }));
          }}
          itemData={wantedNote.wantedNoteItem}
        />
      )}
    </AppContext.Provider>
  );
};

export const useAppContext = () => React.useContext(AppContext);
