import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE } from '../constants';
import reducers from './reducers';

const salesReturnSlice = createSlice({
  name: 'wmsSalesReturn',
  initialState: INITIAL_STATE.SSalesReturn(),
  reducers: reducers,
});
export const wmsSalesReturnSlice = salesReturnSlice.actions;
export const {
  setFocusedColumn,
  deleteProductRow,
  setSearchProducts,
  updateClient,
  handleRowChange,
  setFocusedRow,
} = salesReturnSlice.actions;

export default salesReturnSlice.reducer;
