import React from 'react';
import { initialPagination } from '../../components/Table/MRTTable/constants';
import { getURLParams, replaceURLParams } from '../../utils/util';
import { MRTPaginationType } from '../../components/Table/MRTTable/types';

const usePagination = (defaultPagination: MRTPaginationType = initialPagination()) => {
  const params = getURLParams();
  const [pagination, setPagination] = React.useState({
    pageIndex: +params.pageIndex || defaultPagination.pageIndex,
    pageSize: +params.pageSize || defaultPagination.pageSize,
  });

  return {
    get: pagination,
    set: (updater: React.SetStateAction<MRTPaginationType>) => {
      setPagination((prev) => {
        const next = typeof updater === 'function' ? updater(prev) : updater;
        replaceURLParams({ ...next });
        return next;
      });
    },
    reset: () => {
      setPagination({ ...defaultPagination });
      replaceURLParams({ ...defaultPagination });
    },
  };
};

export default usePagination;
