import { SCustomerList } from './types';
import { customNumericSort, formattedRupee, percentageNumber } from '../../utils/util';
import { ColorCell, ColoredAmountCell } from '../../components/Table/MRTTable/CustomCell';
import { MRTCellType } from '../../components/Table/MRTTable/types';
import { DateConverters } from 'utils/date';

export const customerDataColumns = [
  {
    accessorKey: 'index',
    header: '#',
    maxSize: 10,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'customerNo',
    header: 'Customer No',
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'phone',
    header: 'Contact',
  },
  {
    accessorKey: 'latestBill',
    header: 'Last Buy',
    accessorFn: (row: SCustomerList) =>
      row.latestBill ? DateConverters.epochToString(+row.latestBill) : '-',
  },
  {
    accessorKey: 'discount',
    accessorFn: (row: SCustomerList) => percentageNumber(row.discount),
    header: 'D. Disc',
  },
  {
    accessorKey: 'billCount',
    header: 'No. of Bills',
  },
  {
    accessorKey: 'profileName',
    header: 'Profile',
    Cell: ({ cell }: { cell: MRTCellType<SCustomerList> }) => {
      const row = cell.row;
      return <ColorCell value={cell.getValue<string>()} color={row.original.color} />;
    },
  },
  {
    accessorKey: 'loyaltyEarned',
    header: 'L.P. Earned',
    loyalty: true,
  },
  {
    accessorKey: 'loyaltyRedeemed',
    header: 'L. P. Redeemed',
    loyalty: true,
  },
  {
    accessorKey: 'loyalty',
    header: 'L.P. Balance',
    loyalty: true,
  },
  {
    accessorKey: 'netTotalAmt',
    accessorFn: (row: SCustomerList) => formattedRupee(row.netTotalAmt),
    header: 'Net Amount',
    sortingFn: customNumericSort,
  },
  {
    accessorKey: 'dueAmount',
    header: 'T. Balance',
    accessorFn: (row: SCustomerList) => (
      <ColoredAmountCell amount={row.dueAmount} isSuccess={row.dueAmount >= 0} />
    ),
    sortingFn: customNumericSort,
  },
];

export const validation = (key: string, value: string | number | undefined): boolean => {
  switch (key) {
    case 'name':
      return (value as string).length > 0;
    case 'phone':
      return (value as string).length === 10;
    case 'discount':
      return (value as string) !== '';
    default:
      return false;
  }
};
