import React from 'react';
import { Props } from './types';
import CurrencyDialog from './Dialoges/CurrencyDialog';
import UserNotifyDialog from './Dialoges/UserNotifyDialog';

const AdminShiftEnd: React.FC<Props> = ({ open, onClose, popupData }) =>
  popupData?.showCurrencyPopup ? (
    <CurrencyDialog open={open} shift={'end'} onSubmit={onClose} onClose={onClose} />
  ) : (
    <UserNotifyDialog open={open} onClose={onClose} popupData={popupData} />
  );

export default AdminShiftEnd;
