import { Backdrop, Box, CircularProgress } from '@mui/material';
import React from 'react';
import { BackdropProps, Props } from './types';
import { ReactComponent as MedicineIcon } from 'static/medicine-loader.svg';
import './loader.scss';
import PdfLoaderGif from 'static/pdfLoader.gif';

export const CircularLoader: React.FC<Props> = ({ color, size }) => (
  <CircularProgress
    size={size || 28}
    sx={{
      color: color || 'inherit',
    }}
    disableShrink
  />
);

export const BackdropLoader: React.FC<BackdropProps> = (props) => (
  <Backdrop
    open={props.loading}
    sx={{
      zIndex: (theme) => theme.zIndex.tooltip + 1,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    }}
  >
    <CircularLoader size={32} color={'primary.dark'} />
  </Backdrop>
);

export const PdfLoader: React.FC<BackdropProps> = (props) => (
  <Backdrop
    open={props.loading}
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    }}
  >
    <img alt={'pdf loading gif'} src={PdfLoaderGif} height={120} width={120} />
  </Backdrop>
);

export const MedicineLoader = () => (
  <Box
    sx={{
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    }}
    display='flex'
    alignItems='center'
    justifyContent='center'
  >
    <MedicineIcon />
  </Box>
);
