import React from 'react';
import { ProductCellProps, StackCellProps, ExpiryCellProps } from './types';
import styles from './cell.module.scss';
import { Stack, Typography } from '@mui/material';
import { DateUtils } from '../../../../utils/date';
import { formattedRupee } from '../../../../utils/util';

export const ProductCell: React.FC<ProductCellProps> = (props) => (
  <a
    href={props.href}
    className={styles.cellLink}
    rel='noreferrer'
    {...(props.newTab && { target: '_blank' })}
  >
    {props.value}
  </a>
);

export const StackCell: React.FC<StackCellProps> = (props) => (
  <Stack direction='column' spacing={0}>
    <Typography
      variant='body2'
      fontWeight='500'
      color={props.color || '#000'}
      fontSize='13px'
      padding={0}
      margin={0}
    >
      {props.title}
    </Typography>
    <Typography variant='subtitle2' fontSize='12px' lineHeight={0.8}>
      {props.subtitle}
    </Typography>
  </Stack>
);

export const ExpiryCell: React.FC<ExpiryCellProps> = (props) => {
  const isNearExpiry = props.value !== '-' && !DateUtils.isAfterXMonth(props.value, 3);
  return <Typography className={`${isNearExpiry && styles.expiryValue}`}>{props.value}</Typography>;
};

export const ColoredAmountCell: React.FC<{ amount: number; isSuccess: boolean }> = (props) => (
  <span className={`${props.isSuccess ? styles.posAmount : styles.negAmount}`}>
    {formattedRupee(props.amount)}
  </span>
);

export const ColorCell: React.FC<{
  value?: React.ReactNode;
  color?: string;
  children?: React.ReactNode;
}> = (props) =>
  props.children ? (
    <>{props.children}</>
  ) : (
    <span style={{ color: props.color || '#000' }}>{props.value}</span>
  );

export const EntryAtCell: React.FC<{ at: string }> = ({ at }) =>
  at === '-' ? <>'-'</> : <StackCell title={at.split('.')[0]} subtitle={at.split('.')[1]} />;
