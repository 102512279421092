export const doctorDataColumns = [
  {
    accessorKey: 'index',
    header: '#',
    maxSize: 10,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'phone',
    header: 'Contact',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'registerNo',
    header: 'Registration No',
  },
  {
    accessorKey: 'medicalSpeciality',
    header: 'Speciality',
  },
];

export const validation = (key: string, value: string | number | undefined): boolean => {
  switch (key) {
    case 'name':
      return (value as string).length > 0;
    case 'phone':
      return /^[0-9]{10}$/.test(value as string) || ((value as string) || '').length === 0;
    case 'pincode':
      return /^[0-9]{6}$/.test(value as string) || ((value as string) || '').length === 0;
    default:
      return false;
  }
};
